<template>
  <div id="items-view" class="mw-wide">
    <div class="issue-list-sections">
      <IssueSection
        v-for="wrapper in issueSections.issues"
        :wrapper="wrapper"
        :issueSections="issueSections"
        :key="wrapper.wrapperContext.sprint.id"
        :ref="'section:' + wrapper.wrapperContext.sprint.id"
      ></IssueSection>
    </div>
  </div>
</template>
<script src="./IssueSections.js" />
