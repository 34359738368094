export default function(mock) {
  let newId = 10000;

  mock.onPut(/\/api\/sprint\/\w+/).reply(200, {});
  mock.onDelete(/\/api\/sprint\/\w+/).reply(200, {});
  mock.onPost(/\/api\/sprint/).reply(function() {
    var id = ++newId;
    return [
      200,
      {
        id: 's' + id,
        numeral: id,
      },
    ];
  });
}
