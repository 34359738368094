import PropertyMap from './PropertyMap';
import _ from 'lodash';
import { Model } from '@/namespace';

class StatusMap extends PropertyMap {
  toModel(key) {
    return Model.Status.getByKey(this.projectId, key);
  }

  sort(array) {
    array.sort(function (a, b) {
      return a.priority - b.priority;
    });
  }

  calculatedValue() {
    return this.calcValue();
  }

  calcValueId() {
    return this.calcValue().id;
  }

  calcValue() {
    var array = this.toArray();

    var status = array[0];
    if (array.length > 1) {
      if (status.priority < Model.Status.PRIORITY_INPROGRESS) {
        var lastStatus = array[array.length - 1];
        if (lastStatus.priority >= Model.Status.PRIORITY_INPROGRESS) {
          status = this.toModel('inprogress');
        }
      }
    }
    if (array.length === 0) {
      return this.toModel('todo');
    }
    return status;
  }

  toSortedArray() {
    return this.toArray();
  }

  isDone() {
    return this.calcValue().isDone();
  }
  containsArchived() {
    return this.containsKey('archived');
  }
  getLowestStatus() {
    return this.firstValue();
  }

  containsDone() {
    return this.lastValue().isDone();
  }

  containsValue(value) {
    return this.keys[value.key];
  }

  isArchived() {
    return this.length() === 1 && this.containsKey('archived');
  }
  cKeys() {
    // REFACTOR

    return this.keyArray();
  }
  getProgress() {
    var done, todo;
    _.each(this.toArray(), s => {
      if (s.priority >= Model.Status.PRIORITY_DONE) {
        done = true;
      } else {
        todo = true;
      }
    });

    if (done) {
      return todo ? 'mixed' : 'done';
    } else {
      return 'todo';
    }
  }
}
export default StatusMap;
