import BaseModel from '@/models/base/BaseModel';
import { getModelById } from '@/models';
const activityTexts = {
  created: 'created the item',
  title: 'changed title',
  description: 'changed description',
  deleted: 'deleted the item',
  text: 'updated the text',
};

class Activity extends BaseModel {
  constructor(attributes) {
    super(attributes);
    this.at = new Date(attributes.at);
  }

  get model() {
    return getModelById(this.id);
  }

  get date() {
    return this.at;
  }
}

export default Activity;
