<template>
  <div class="SelectProjectType">
    <h3>Project Type</h3>

    <q-option-group
      v-model="type"
      :options="projectTypes"
      @input="onInput"
      color="primary"
      inline
    />

    <div class="info" v-if="type === 'scrum'">
      With Scrum, each Sprint is represented as a Work Board.
    </div>
    <div class="info" v-if="type === 'kanban'">
      With Kanban, a Kanban Board is setup as default with the possibility to create additional Work
      Boards.
    </div>
    <div class="info" v-if="type === 'plain'">
      The project will be setup without any pre defined Work Board.
    </div>
  </div>
</template>

<script>
export default {
  props: ['modelValue'],
  data() {
    return {
      type: this.modelValue,
      projectTypes: [
        { value: 'scrum', label: 'Scrum' },
        { value: 'kanban', label: 'Kanban' },
        { value: 'plain', label: 'Plain' },
      ],
    };
  },

  methods: {
    onInput(val) {
      this.$emit('update:modelValue', val);
    },
  },
};
</script>

<style lang="scss">
.SelectProjectType {
  padding: 10px 0;

  .info {
    font-size: 13px;
    padding: 10px 5px;
  }
  h3 {
    margin: 5px 0 5px 5px;
    font-size: 14px;
  }
}
</style>
