import _ from 'lodash';
import { Util } from '@/namespace';
import { getNewPriority } from '@/models/priority/priorityCalculation';

export function dragWithinCurrentContainer(dragSession) {
  doSorting(dragSession, true);
}

export function doSorting(dragSession, sortAndDragInto = false) {
  const { dragModel, containers, containerHandler, scrollHandler, dropTargetHandler } = dragSession;
  if (!containers.current) {
    return;
  }

  var clonePosition = findClonePosition(dragSession);
  const { item, delta } = clonePosition;

  if (item == null || item.getModel == null) {
    return;
  }
  const itemModel = item.getModel();
  if (itemModel === dragModel) {
    return;
  }

  if (delta <= 10 || !sortAndDragInto) {
    sortByFoundModel(itemModel, dragSession);
    dropTargetHandler && dropTargetHandler.clear();
    return;
  }

  if (!scrollHandler.isOnScroll()) {
    if (clonePosition.within || delta < item.$el.offsetHeight / 2) {
      containerHandler && containerHandler.waitForDragIntoNewParentElement(item);
      return;
    }
  }

  dropTargetHandler && dropTargetHandler.clear();
}

function sortByFoundModel(itemModel, dragSession) {
  const { containers, dragModel, direction } = dragSession;
  const modelList = containers.current.getModelList();
  const position = _.findIndex(modelList, itemModel);

  if (position == null) {
    return;
  }
  var newPriority = getNewPriority(modelList, position + (direction.up ? -1 : 0));
  if (newPriority != null) {
    //console.log('do sort to pos ' + position + ' ' + newPriority);
    dragModel.setPriority(newPriority);
    modelList.sort(Util.priorityComparator);
  }
}

function findClonePosition({ containers, direction, cloneHandler }) {
  let listElements = containers.current.getListElements(),
    clone = cloneHandler.getClone();

  if (!clone) {
    return;
  }

  const boxOffset = clone.boxOffset();

  let foundDelta = null,
    foundItem,
    within = false;

  listElements.forEach(item => {
    const itemOffset = item.$el.boxOffset();

    const delta = direction.up
      ? boxOffset.top - itemOffset.top
      : itemOffset.bottom - boxOffset.bottom;

    if (delta >= 0) {
      if (foundDelta != null && delta > foundDelta) {
        return;
      }
      foundItem = item;
      foundDelta = delta;
      within = direction.up ? boxOffset.bottom < itemOffset.bottom : boxOffset.top > itemOffset.top;
    }
  });

  return {
    item: foundItem,
    delta: foundDelta,
    within,
  };
}
