import _ from 'lodash';

import StatusMap from '@/models/propertymap/StatusMap';
import MemberMap from '@/models/propertymap/MemberMap';
import SprintMap from '@/models/propertymap/SprintMap';

const definitions = {
  status: {
    ID: 'statusId',
    MAP: 'statusMap',
    PropertyMap: StatusMap,
  },
  member: {
    ID: 'memberId',
    MAP: 'memberMap',
    PropertyMap: MemberMap,
  },
  sprint: {
    ID: 'sprintId',
    MAP: 'sprintMap',
    PropertyMap: SprintMap,
  },
};

export default function(property) {
  const { MAP, PropertyMap } = definitions[property];
  return function(wrapper) {
    if (wrapper.isDestroyed) {
      //console.warn('try calculate on destroyed');
      return false;
    }

    const newKeys = {};

    function addPropertyVals(issue) {
      _.assign(newKeys, issue.getPropertyMap(property).getKeys());
    }

    _.each(wrapper.issues, addPropertyVals);

    if (!wrapper.isPartial()) {
      addPropertyVals(wrapper.issue);
    }

    // In normal case, add the issue statusId to map
    if (property === 'status') {
      // dont know if this is needed anymore since we add all if non-partial (above)
      if (!wrapper.isPartial() && wrapper.issue.statusId) {
        newKeys[wrapper.issue.statusId] = true;
      }
    }

    if (_.keys(newKeys).length === 0) {
      console.error('Keys are empty');
    }

    const existing = wrapper[MAP];
    if (!existing) {
      wrapper.set(MAP, PropertyMap.make(newKeys, wrapper.asIssue().projectId));
      return false;
    }
    if (existing.isEqualKeys(newKeys)) {
      return false;
    }
    wrapper.set(MAP, PropertyMap.make(newKeys, wrapper.asIssue().projectId));
    return true;
  };
}
