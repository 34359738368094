<template>
  <div>
    <div class="page-content with-footer SelectProjectTypeModal">
      <section><SelectProjectType v-model="type" /></section>
    </div>
    <footer><a-btn color="primary" @click="save">Save</a-btn></footer>
  </div>
</template>
<script>
import SelectProjectType from './SelectProjectType.vue';
export default {
  components: { SelectProjectType },
  props: ['project'],
  smallModal: true,
  data() {
    return {
      type: this.project.type,
    };
  },
  methods: {
    save() {
      const { project, type } = this;
      project.type = type;
      project.save();
      this.$emit('close');
    },
  },
};
</script>
