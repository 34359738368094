import StoreModel from './base/StoreModel';
import _ from 'lodash';
import { Model, Util } from '@/namespace';
import statics from './issue/issue-statics';
import {
  initialCalculation,
  calculateAndTriggerChanged,
  calculateProperty,
} from '@/models/issue/calculation';
import { cleanupPriorityMap } from '@/models/priority/priorityCalculation';
import { convertModelMap } from '@/common/model/convertmodels';

import { fillActivityMap, handleNewActivity } from './activityHandler';

import GithubData from '@/models/GithubData';
import { assertNoParentCycle } from './issue/issue-helpers';

class Issue extends StoreModel {
  static NAME = 'Issue';
  static define = {
    title: null,
    description: null,
    parent: {
      model: 'Issue',
      modelId: 'parentId',
      set(val) {
        this.parentId = val.id;
        return val;
      },
      serialize: false,
    },
    parentId: null,
    projectId: null,
    statusId: {
      set(val) {
        return val instanceof StoreModel ? val.key : val;
      },
    },
    sprintId: {
      set(val) {
        return val instanceof StoreModel ? val.id : val;
      },
    },
    memberId: {
      set(val) {
        return val instanceof StoreModel ? val.id : val;
      },
    },

    issues: {
      value() {
        return [];
      },
      serialize: false,
    },
    priorityMap: {
      value() {
        return {};
      },
    },
    tags: {
      model: 'Tag',
    },
    comments: {
      model: 'Comment',
      value() {
        return [];
      },
      serialize: false,
    },
    additions: {
      value() {
        return {};
      },
      serialize: false,
    },
    files: {
      serialize: false,
    },
    estimate: null,
    totalEstimate: {
      value() {
        return 0;
      },
      serialize: false,
    },
    issueType: {
      set(value) {
        if (!value) {
          return null;
        }
        var id = value.id || value;
        if (id === 'item') {
          return null;
        }
        return id;
      },
    },
    explodeChildren: {
      serialize(value) {
        if (!value) {
          return value;
        }
        return { ...value };
      },
    },
    githubData: {
      model: 'GithubData',
      serialize: false,
    },
    activityMap: {
      serialize: false,
      value() {
        return null;
      },
    },
  };

  get fillable() {
    return (
      this.adds ||
      this.description ||
      !_.isEmpty(this.comments) ||
      this.files ||
      !_.isEmpty(this.additions)
    );
  }

  get level() {
    return this.parent ? this.parent.level + 1 : 0;
  }

  softSave() {
    var changes = this.serializedChanges();
    if (_.keys(changes).length == 0) {
      return Promise.resolve();
    }
    return this.constructor.update(this, changes).then(data => {
      this.backup();
    });
  }

  fillFiles() {
    var files = new Model.FileList({ parent: this });
    this.set('files', files);

    this.files.fetch();
  }

  addFile(file) {
    if (this.files == null) {
      var files = new Model.FileList({ parent: this });
      this.set('files', files);
    }
    return this.files.add(file);
  }

  static upsynch(id, data) {
    return Util.jsonajax({
      url: '/issue/' + id,
      data: data,
      method: 'PUT',
    });
  }

  static fill(id) {
    return Util.jsonajax({
      url: '/issue/fill/' + id,
      method: 'GET',
    });
  }

  postDestroy() {
    this.parent.issues.remove(this);

    calculateAndTriggerChanged(this);
  }

  postInitModel(onLoad = false) {
    if (this.parent) {
      onLoad ? this.parent.issues.push(this) : this.parent.issues.add(this);
    }
    if (!onLoad) {
      calculateAndTriggerChanged(this);
    }
  }

  closestProperty(propertyName) {
    return this[propertyName] || this.parent.closestProperty(propertyName);
  }

  initialCalculation() {
    return initialCalculation(this);
  }

  isProject() {
    return this.parent == null;
  }

  getUrl() {
    if (this.isProject()) {
      return '/' + this.id + '/project';
    } else {
      return '/' + this.projectId + '/item/' + this.get('key');
    }
  }

  get url() {
    if (this.isProject()) {
      return '/' + this.id + '/project';
    } else {
      return '/' + this.projectId + '/item/' + this.get('key');
    }
  }

  getParent() {
    return this.parent;
  }

  setExplodeChildren(key, value) {
    const newValue = {};
    newValue[key] = value;
    const newMap = _.assign({}, this.explodeChildren, newValue);
    this.explodeChildren = newMap;
  }

  asIssue() {
    return this;
  }
  isWrapper() {
    return false;
  }

  allIssues() {
    var result = [];
    appendChildIssues(this, result);
    return result;
  }

  setProperty(property, value) {
    this.set(property + 'Id', value);
    calculateProperty(this, property);
  }
  getPropertyMap(property) {
    return this[property + 'Map'];
  }
  getEstimateMap() {
    return this.estimateMap;
  }
  setPriorityValue(key, priority) {
    var priorityMap = _.assign({}, this.priorityMap);
    priorityMap[key] = priority;
    this.attr('priorityMap', priorityMap);
  }

  addTag(tag) {
    Util.assert(tag != null);
    if (this.tags == null) {
      this.set('tags', []);
    }
    this.tags.add(tag);
  }
  removeTag(tag) {
    if (this.tags == null) {
      return;
    }
    this.tags.remove(tag);
  }

  postFill(response) {
    const json = response.originModel;
    this.setAttributes(json);
    this.initModel();
    if (this.noFiles) {
      this.fillFiles();
    }
  }

  save(opt = {}) {
    if (this.isNew()) {
      return StoreModel.prototype.save.call(this, opt).then(() => {});
    }

    var childChanges = serializedChildChanges(this.issues),
      changes = this.serializedChanges();

    if (_.isEmpty(changes) && _.isEmpty(childChanges)) {
      return Promise.resolve();
    }

    if (changes.parentId) {
      assertNoParentCycle(this);
    }

    calculateAndTriggerChanged(this);

    cleanupPriorityMap(this);
    changes = this.serializedChanges();

    if (!_.isEmpty(childChanges)) {
      changes.children = childChanges;
    }

    return this.constructor.update(this, changes, opt).then(data => {
      this.backup();
      propagateToChildren(this, is => {
        is.backup();
      });
      if (data.models) {
        convertModelMap(data.models);
      }
      handleNewActivity(data);
    });
  }
}

function appendChildIssues(issue, resultList, safeBreaker) {
  resultList.push(issue);
  if (safeBreaker < 0) {
    console.error('safebreaker when appending childs');
    return;
  }
  issue.issues.forEach(function(child) {
    appendChildIssues(child, resultList, --safeBreaker || 1000);
  });
}

_.assign(Issue, statics);
_.assign(Issue.prototype, {
  fillActivityMap,
});
Model.Issue = Issue;
export default Issue;

function serializedChildChanges(issues) {
  return _.reduce(
    issues,
    (changes, is) => {
      var change = is.serializedChanges();
      if (!_.isEmpty(change)) {
        change.id = is.id;
        changes.push(change);
      }
      if (is.issues.length) {
        changes.push(...serializedChildChanges(is.issues));
      }

      return changes;
    },
    []
  );
}

function propagateToChildren(issue, f) {
  _.each(issue.issues, is => {
    f(is);
    propagateToChildren(is, f);
  });
}
