<template>
  <div class="AgilooLogo">
    <div class="agiloo-logo"></div>

    <a class="agiloo-title" href="/"></a>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.AgilooLogo {
  position: absolute;
  left: 20px;
  top: 10px;

  .agiloo-title {
    background: url('/static/images/agiloo-title-blue.svg');
    background-size: 90px auto;
    background-repeat: no-repeat;
    display: inline-block;
    width: 90px;
    height: 35px;
    position: relative;
    left: 0;
    top: 0;
  }

  .agiloo-logo {
    background: url('/static/images/agiloo-logo-blue.svg');
    background-size: 26px 26px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 40px;
    height: 32px;
    position: relative;
    top: 1px;
    left: 6px;
    margin-left: 0;
  }

  &.white {
    .agiloo-logo {
      background: url('/static/images/agiloo-logo.svg');
      background-size: 26px 26px;
      background-repeat: no-repeat;
    }
    .agiloo-title {
      background: url('/static/images/agiloo-title.svg');
      background-size: 90px auto;
      background-repeat: no-repeat;
    }
  }
}

#app.loggedIn .AgilooLogo {
  display: none;
}
</style>
