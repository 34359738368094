<template>
  <div class="TableIcon">
    <div class="row"></div>
    <i class="icon-" />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.TableIcon {
  position: relative;
  width: 20px;
  height: 20px;

  .icon- {
    font-size: 9px;
    position: absolute;
  }
  &.row-before,
  &.row-after,
  &.row-delete {
    .row {
      background: #888;
      position: absolute;
      height: 6px;
      border-radius: 3px;
      position: absolute;
      width: 100%;
    }
  }
  &.column-before,
  &.column-after,
  &.column-delete {
    .row {
      background: #888;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      position: absolute;
      width: 6px;
    }
  }

  &.row-before,
  &.row-after,
  &.column-before,
  &.column-after {
    .icon- {
      &:before {
        content: '\ed61';
      }
    }
  }
  &.row-delete,
  &.column-delete {
    .icon- {
      &:before {
        content: '\ed62';
      }
    }
  }

  &.row-before {
    .row {
      top: 13px;
    }
    .icon- {
      top: 2px;
    }
  }
  &.row-after {
    .row {
      bottom: 13px;
    }
    .icon- {
      bottom: 2px;
    }
  }
  &.row-delete {
    .row {
      top: 13px;
    }
    .icon- {
      top: 2px;
    }
  }
  &.column-after {
    .row {
      right: 13px;
    }
    .icon- {
      right: 2px;
    }
  }
  &.column-before {
    .row {
      left: 13px;
    }
    .icon- {
      left: 2px;
    }
  }
  &.column-delete {
    .row {
      left: 13px;
    }
    .icon- {
      left: 2px;
    }
  }
}
</style>
