import { getNewPriority, setNewPriority } from '@/models/priority/priorityCalculation';

export function dropIn(parent, dragModel) {
  var wrapperContext = parent.wrapperContext;
  dragModel.setWrapperContext(wrapperContext);

  if (wrapperContext.propertyToSave === 'sprint') {
    setNewPriority(dragModel.issue, wrapperContext.sprint, null, dragModel.priority);
  } else if (wrapperContext.propertyToSave === 'status') {
    setNewPriority(
      dragModel.issue,
      wrapperContext.sprint,
      wrapperContext.status,
      dragModel.priority
    );
  }

  var opt = {
    propagateDown: true,
  };
  return dragModel.saveProperty(
    wrapperContext.propertyToSave,
    wrapperContext[wrapperContext.propertyToSave],
    opt
  );
}

export function removeDragModel(parent, dragModel) {
  var existingWrapper = parent.findById(dragModel.id);
  if (existingWrapper == null) {
    return;
  }

  if (existingWrapper.issues.length === dragModel.issues.length) {
    parent.remove(existingWrapper);
  } else {
    existingWrapper.issues.removeAll(dragModel.issues);
  }
}

export function addDragModel(parent, dragModel, upwards) {
  var existingWrapper = parent.findById(dragModel.id);
  if (existingWrapper) {
    existingWrapper.addAll(dragModel.issues);
  } else {
    parent.add(dragModel, { direction: upwards });
  }
}
