import projectFill from './project-fill';
const projects = [
  {
    id: 'p1',
    title: 'Project 1',
  },
];

export default function(mock) {
  mock.onPut(/\/api\/project\/\w+/).reply(200, {});
  mock.onGet('/api/project/findall').reply(200, projects);
  mock.onGet(/\/api\/project\/fill\/\w+/).reply(200, projectFill);
}
