import StoreModel from './base/StoreModel';

import { Model, Util } from '@/namespace';

import { getModelById } from '@/models';
class Comment extends StoreModel {
  static NAME = 'Comment';
  static define = {
    created: {
      type: 'date',
    },
    updated: {
      type: 'date',
    },
    member: {
      model: 'Member',
      serialize(member) {
        return member.id;
      },
    },
    text: null,
    parentId: null,
  };

  postInitModel() {
    var parent = getModelById(this.parentId);
    if (parent && parent.filled) {
      parent.comments.add(this);
    }
  }
  postDestroy() {
    console.log('postDestroy');
    const parent = getModelById(this.parentId);
    parent && parent.filled && parent.comments.remove(this);
  }

  get date() {
    return this.created;
  }

  static destroy(model) {
    return Util.jsonajax({
      url: '/' + this.NAME.toLowerCase() + '/' + model.id,
      data: {
        id: model.id,
        parentId: model.parentId,
      },
      method: 'delete',
    });
  }

  static update(model, data) {
    return Util.jsonajax({
      url: '/' + this.NAME.toLowerCase() + '/' + model.id,
      data: { ...data, parentId: model.parentId },
      method: 'PUT',
    });
  }
}

Model.Comment = Comment;
export default Comment;
