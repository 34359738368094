<template>
  <a class="list-row sprint">
    <text-input
      v-model="title"
      v-focus-last="true"
      class="title text"
      placeholder="Title"
      @change="change"
    ></text-input>
  </a>
</template>
<script>
export default {
  props: ['suggestedTitle'],
  data() {
    return { title: this.suggestedTitle };
  },
  methods: {
    change() {
      this.$emit('change', { title: this.title });
    },
  },
};
</script>
