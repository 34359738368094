<template>
  <div>
    <div class="page-content SelectGithubRepos with-footer">
      <section>
        Select which repo/repos should be connected with
        <span class="project-title">{{ project.title }}</span>
      </section>
      <section v-if="repos.active.length">
        <h3>Connected Repositories</h3>
        <div class="list">
          <div v-for="repo in repos.active" :key="repo.repoId" class="list-row">
            <div>{{ repo.name }}</div>

            <q-btn @click="remove(repo)" rounded unelevated>Remove</q-btn>
          </div>
        </div>
      </section>
      <section>
        <h3>Available Repositories</h3>
        <div class="list">
          <div v-for="repo in repos.available" :key="repo.repoId" class="list-row">
            <div>{{ repo.name }}</div>
            <q-btn @click="remove(repo)" v-if="isActive(repo)" rounded unelevated>Remove</q-btn>
            <q-btn @click="select(repo)" color="primary" v-else rounded unelevated>Add</q-btn>
          </div>
        </div>
      </section>
    </div>
    <footer>
      <a-btn @click="save" color="primary">Save</a-btn>
    </footer>
  </div>
</template>
<script>
import { Util, App, _ } from '@';
export default {
  props: ['repos'],

  data() {
    return {
      projectId: App.vm.currentProject.id,
    };
  },
  computed: {
    project() {
      return App.vm.currentProject;
    },
  },
  loadingText: 'Loading github repos',
  getData() {
    return {
      repos: Util.jsonajax({
        url: '/github/listrepos',
        data: { projectId: App.vm.currentProject.id },
      }),
    };
  },
  methods: {
    remove(repo) {
      this.repos.active.remove(repo);
    },
    select(repo) {
      this.repos.active.push(repo);
    },

    isActive(repo) {
      return _.find(this.repos.active, r => {
        return r.repoId === repo.repoId;
      });
    },

    save() {
      Util.jsonajax({
        url: '/github/activerepos',
        data: { projectId: this.projectId, activeRepos: this.repos.active },
      });
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.SelectGithubRepos {
  .list-row {
    button {
      margin-left: auto;
    }
  }
  .list-row:last-child {
    border-bottom: none;
  }

  .project-title {
    color: var(--blue);
    font-weight: 500;
  }
}
</style>
