import { VueReCaptcha } from 'vue-recaptcha-v3';

import { App } from '@/namespace';

const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
// For more options see below

export default async function (app) {
  if (RECAPTCHA_SITE_KEY == null) {
    app.config.globalProperties.$recaptcha = () => {
      Promise.resolve();
    };
  } else {
    app.use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY });
  }

  await app.config.globalProperties.$recaptchaLoaded();
  App.$recaptcha = app.config.globalProperties.$recaptcha;
}
