<template>
  <FilterableMenu @close="close" :opt="opt">
    <template v-slot:default="{ item }">
      <span class="text">{{ item.title }}</span>
    </template>
  </FilterableMenu>
</template>
<script>
import { Model, App } from '@';

import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
import CreateProject from '@/pages/createproject/CreateProject.vue';
export default {
  props: ['projects'],
  getData() {
    return { projects: Model.Project.findAll() };
  },
  data: function() {
    return {
      opt: {
        isActive: project => project === App.vm.currentProject,
        items: this.projects,
        headerItems: [
          {
            text: 'View All Projects',
            url: '/projects',
          },
        ],
        footerItems: [
          {
            text: 'Create New Project',
            icon: 'icon-plus3',
            select: () =>
              App.openPopup(CreateProject, {
                onCreate: project => {
                  App.router.navigate(project.url);
                  this.close();
                },
              }),
          },
        ],
      },
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
