import { Model } from '@/namespace';

export function shouldExplode(issue, wrapperContext) {
  if (!wrapperContext.statusBoard || wrapperContext.sprint == null) {
    return false;
  }
  issue = issue.asIssue();
  if (issue.issues.length === 0) {
    return false;
  }

  const explode = issue.explodeChildren && issue.explodeChildren[wrapperContext.sprint.id];

  if (explode != null) {
    return explode;
  }
  return issue.issueType === 'epic';
}

export function breakOutExploded(issues, wrapperContext, level) {
  const topIssues = [];
  breakOutChildren(issues);

  return topIssues;

  function breakOutChildren(issues) {
    issues.forEach(is => {
      if (!shouldExplode(is, wrapperContext)) {
        topIssues.push(is);
        return;
      }
      const exploded = is.issues.map(issue => newWrapper(issue));
      breakOutChildren(exploded);
    });
  }

  function newWrapper(issue) {
    return Model.IssueWrapper.createNew({
      issue,
      issues: issue.issues,
      wrapperContext,
      level: level + 1,
      //exploded: true,
    });
  }
}
