import BaseModel from './base/BaseModel';
import _ from 'lodash';

import { Model, Util } from '@/namespace';
class LoginCredentials extends BaseModel {
  serialize() {
    return {
      ...this,
    };
  }

  async login(recaptcha) {
    const data = this.serialize();
    data.username = data.email;

    await Util.jsonajax({
      url: '/auth/login',
      data,
      method: 'POST',
      headers: { recaptcha },
    });

    Model.Profile.clearCache();
    const profile = await Model.Profile.find();

    Model.Profile.setProfile(profile);
  }
}

export default LoginCredentials;
