<template>
  <div class="AddPremiumUsers split-section" v-if="possibleUsers">
    <aside>
      <h3>Add Premium Users</h3>
      <div>
        Select users that will be paid for. For projects requiring Premium, any users who has not
        Premium account will not be able to access the project.
      </div>
    </aside>
    <div>
      <div class="list">
        <a
          class="list-row member"
          v-for="user in possibleUsers"
          @click.prevent="toggleSelected(user)"
        >
          <checkbox :selected="user.selected" :fixed="user.fixed" />
          <user-icon :user="user"></user-icon>

          <div class="title">
            {{ user.name }}
          </div>

          <div class="fixed-width email mobile-hidden">
            {{ user.email }}
          </div>
        </a>
      </div>
      <div class="flex">
        <a-btn class="right" color="primary" @click="addUsers">
          Set selected Users as premium and continue
        </a-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { findPossibleUsers } from '@/models/billing/billingService';
export default {
  props: ['subscription'],
  data() {
    return { possibleUsers: null };
  },

  async mounted() {
    this.possibleUsers = await findPossibleUsers();
  },

  methods: {
    addUsers() {
      var users = this.possibleUsers.filter(user => user.selected);
      this.$emit('select', users);
    },
    toggleSelected(user) {
      if (user.fixed) {
        return;
      }
      user.selected = !user.selected;
    },
  },
};
</script>
