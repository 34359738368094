import { App, Model, Util } from '@/namespace';
import { convertModelMap } from '@/common/model/convertmodels';

export function fillWithData(data) {
  this.filled = true;

  data.models.Issue.push({
    id: this.id,
    projectId: this.id,
    statusId: 'todo',
    sprintId: 'backlog',
    memberId: 'unassigned',
    title: this.title,
  });

  convertModelMap(data.models, true);
  App.timer.log('convertModelMap');
  this.sprints.push(Model.Sprint.get('backlog'));
  this.sprints.sort(Model.Sprint.comparator);
  this.members.push(Model.Member.get('unassigned'));
  this.members.sort(Model.Member.comparator);
  this.statuses.sort(Model.Status.comparator);
  this.issue = Model.Issue.get(this.id);

  App.timer.log('beforeInitialCalculation');

  this.issue.initialCalculation();
  App.timer.log('initialCalculation');
  this.backup();
}

export function fill() {
  return Util.jsonajax({
    url: '/project/fill/' + this.id,
    method: 'GET',
  }).then(data => {
    this.fillWithData(data);

    return this;
  });
}
