<template>
  <div class="page-content narrow info-pages">
    <AgilooLogo />
    <section class="header">
      <h1>About Agiloo</h1>
    </section>

    <section class>
      <p>
        Agiloo is a Project Management Tool for agile projects. It is maintained and developed by
        Agiloo Digital AB, Stockholm, Sweden.
      </p>
      <p>
        Agiloo is run on the Heroku platform and is developed with
        <a href="https://nodejs.org/" target="_blank">Node.js</a>
        and
        <a href="https://vuejs.org/" target="_blank">Vue.js</a>
        as the main programming frameworks.
      </p>

      <h2>Contact Agiloo</h2>
      <div class="text" style>
        Send mail to
        <a href="mailto:info@agiloo.com">info@agiloo.com</a>
      </div>
    </section>
    <section class="filler"></section>
    <page-footer />
  </div>
</template>
<script>
export default {
  route: '/aboutagiloo',
  noAuthentication: true,
};
</script>
