import BaseModel from '@/models/base/BaseModel';
import { _, App, Model } from '@';
import FilterRule from './FilterRule';

function isHit(text, re) {
  try {
    return text.search(re) != -1;
  } catch (error) {
    console.error(error);
    return false;
  }
}

const modelSearch = {
  tag: function(re, excluded) {
    return _.filter(App.vm.currentProject.tags, m => {
      return isHit(m.title, re) && !excluded(m);
    });
  },
  status: function(re) {
    return _.filter(App.vm.currentProject.activeStatuses, m => {
      return isHit(m.name, re);
    });
  },
  issueType: function(re) {
    return _.filter(App.vm.currentProject.issueTypes, m => {
      return isHit(m.name, re);
    });
  },
  member: function(re) {
    return _.filter(App.vm.currentProject.members, m => {
      return isHit(m.displayName, re);
    });
  },
  sprint: function(re) {
    return _.filter(App.vm.currentProject.sprints, m => {
      return isHit(m.title, re);
    });
  },
  filterRule: function(re) {
    return _.filter(FilterRule.allRules, m => {
      return isHit(m.text, re);
    });
  },
  issue: function(re, excluded) {
    var result = [];

    searchInChildren(App.vm.currentProject.issue);

    function searchInChildren(issue) {
      issue.issues.forEach(function(child) {
        if (excluded(child)) {
          return;
        }
        if (isHit(child.title, re)) {
          result.push(child);
        }
        searchInChildren(child);
      });
    }

    return result;
  },
  projectissue: function(re) {
    if (isHit(App.vm.currentProject.issue.title, re)) {
      return [App.vm.currentProject.issue];
    }
  },
  document: function(re) {
    return _.filter(App.vm.currentProject.documents, m => {
      return isHit(m.title, re);
    });
  },
};
const MAX_RESULTS = 20;
export class TextItem extends BaseModel {
  static NAME = 'TextItem';
}
export function findSearchResult(text, options) {
  var re = new RegExp(text.trim(), 'i');

  var excluded = function() {
    return false;
  };
  if (!_.isEmpty(options.excludes)) {
    excluded = function(model) {
      return _.includes(options.excludes, model);
    };
  }

  var result = [];
  _.each(options.models, modelName => {
    var items = modelSearch[modelName](re, excluded);
    result.pushAll(items);
  });
  result.sort((a, b) => {
    return sortValue(a) - sortValue(b);
  });
  result = result.slice(0, MAX_RESULTS);

  return result;
}

function sortValue(item) {
  if (item instanceof Model.Issue) {
    return 100000 + item.level * 100 + statusValue(item);
  }
  if (item instanceof Model.Sprint) {
    return 1000;
  }

  return 0;
}

function statusValue(issue) {
  const lowestStatus = issue.statusMap.getLowestStatus();
  if (lowestStatus.priority >= Model.Status.PRIORITY_ARCHIVED) {
    return 10000;
  }
  if (lowestStatus.priority >= Model.Status.PRIORITY_DONE) {
    return 9000;
  }
  return 0;
}
