<template>
  <div>
    <div class="filter-rule">{{ item.text }}</div>
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
