<template>
  <div class="PastInvoices split-section">
    <aside>
      <h3>Payments</h3>
    </aside>
    <div>
      <div class="list" v-if="invoices">
        <InvoiceRow v-for="invoice in invoices" :invoice="invoice" />
      </div>

      <div class="flex" v-else>
        <a-btn class="right" @click="loadInvoices">View Invoices</a-btn>
      </div>
    </div>
  </div>
</template>
<script>
import InvoiceRow from './InvoiceRow.vue';
import { loadInvoices } from '@/models/billing/billingService';
export default {
  props: ['subscription'],

  data() {
    return {
      invoices: null,
    };
  },
  methods: {
    async loadInvoices() {
      const invoices = await loadInvoices();
      this.invoices = invoices;
    },
  },
  components: { InvoiceRow },
};
</script>
