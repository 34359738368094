<template>
  <a
    class="DocumentListItem list-row navigate-slide"
    :class="cssClass"
    @mousedown="mousedown"
    @touchstart="mousedown"
    :href="document.url"
  >
    <div class="title">{{ document.title }}</div>
  </a>
</template>
<script>
import { App } from '@/namespace';
export default {
  props: ['document'],
  data() {
    return {
      dragStatus: {},
    };
  },
  computed: {
    cssClass() {
      return { ...this.dragStatus };
    },
  },
  methods: {
    mousedown(ev) {
      App.startDragSession({ ev, dragItem: this });
    },
    getModel() {
      return this.document;
    },
    clearDragStatus() {
      this.dragStatus = {};
    },
    setDragStatus(name, value) {
      this.dragStatus[name] = value;
    },
  },
};
</script>
<style lang="scss">
.DocumentListItem {
  .title {
    font-size: 15px;
  }
}
</style>
