import { Model, App } from '@/namespace';
import _ from 'lodash';
import confirmPropagateDown from './confirmPropagateDown';
import StatusMap from '@/models/propertymap/StatusMap';
import { initNewPriority } from '@/models/priority/priorityCalculation';
import ConfirmParentStatusModal from '@/actions/saveProperty/ConfirmParentStatusModal.vue';
export default function saveProperty(wrapper, property, value, opt = {}) {
  var issue = wrapper.asIssue(),
    PROPERTY_ID = property + 'Id',
    lastCalculatedSprint = wrapper.getPropertyMap('sprint').calcValue();

  if (issue.isProject()) {
    return Promise.all(
      _.map(wrapper.issues, is => {
        return saveProperty(is, property, value, opt);
      })
    );
  }

  return Promise.resolve()
    .then(() => {})
    .then(() => {
      if (opt.propagateDown !== false) {
        return propagateDown();
      } else {
        return setPropertyOnIssue(issue);
      }
    })
    .then(() => {
      if (opt.updatePriority && property === 'sprint') {
        var direction = Model.Sprint.comparator(lastCalculatedSprint, value) > 0;
        initNewPriority(issue, value, direction);
      }
      return issue.save();
    })
    .then(() => {
      return propagateUp();
    });

  function setPropertyOnIssue(issue) {
    if (issue.partial) {
      return;
    }
    issue.asIssue().set(property + 'Id', value);
  }

  function propagateDown() {
    return confirmPropagateDown(wrapper, property, value, opt)
      .then(filteredWrapper => {
        // TODO look on how to keep save null on children with same value as parent. The store null logic should be performed later, on save(), if same value as parent, set to null
        setPropertyOnIssue(wrapper);
        if (filteredWrapper) {
          performPropagateDown(filteredWrapper);
        }
      })
      .then(() => {
        if (property === 'status') {
          cleanupParentsWithLowStatusId(wrapper.asIssue());
        } else {
          cleanupParentsWithLoosePropertyId(wrapper.asIssue());
        }
      });
  }

  function performPropagateDown(issue) {
    setPropertyOnIssue(issue);
    _.forEach(issue.issues, function(is) {
      performPropagateDown(is);
    });
  }

  function cleanupParentsWithLowStatusId(issue) {
    var childrenKeys = {};
    if (issue.issues.length === 0) {
      childrenKeys[issue.closestProperty('statusId')] = true;
      return childrenKeys;
    }

    _.each(issue.issues, is => {
      _.assign(childrenKeys, cleanupParentsWithLowStatusId(is));
    });
    var childrenStatusMap = StatusMap.make(childrenKeys, issue.projectId);
    // isEmpty checks are temporary, while its possible issue have no statusId set
    if (issue.statusMap.getLowestStatus().lowerPriorityThan(childrenStatusMap.getLowestStatus())) {
      issue.set(PROPERTY_ID, value);
    }
    return childrenKeys;
  }

  function cleanupParentsWithLoosePropertyId(issue) {
    var hasOtherId = false,
      propertyKey = value.getKey();

    if (issue.issues.length === 0) {
      return issue[PROPERTY_ID] !== propertyKey;
    }
    _.each(issue.issues, is => {
      hasOtherId = hasOtherId || cleanupParentsWithLoosePropertyId(is);
    });

    if (!hasOtherId && issue[PROPERTY_ID] !== propertyKey) {
      issue.set(PROPERTY_ID, value);
    }
    return hasOtherId;
  }

  function propagateUp() {
    if (property === 'status') {
      return checkIfParentShouldUpdateStatus(issue, opt);
    }
  }
}

function checkIfParentShouldUpdateStatus(issue, opt) {
  var parent = issue.parent;
  if (parent.isProject()) {
    return;
  }

  var childrenStatusKeys = _.reduce(
    parent.issues,
    function(statusKeys, is) {
      _.assign(statusKeys, is.statusMap.getKeys());
      return statusKeys;
    },
    {}
  );

  var childrenStatusMap = StatusMap.make(childrenStatusKeys, issue.projectId);

  if (!childrenStatusMap.isDone()) {
    return;
  }
  var lowestChildStatus = childrenStatusMap.getLowestStatus();

  if (parent.statusMap.getLowestStatus().priority < lowestChildStatus.priority) {
    return confirmParentStatusChange(lowestChildStatus, parent, opt).then(confirmed => {
      if (confirmed) {
        return saveProperty(
          parent,
          'status',
          lowestChildStatus,
          _.assign({}, opt, { propagateDown: false })
        );
      }
    });
  } else if (parent.statusMap.isDone()) {
    return checkIfParentShouldUpdateStatus(parent, opt);
  }
}

function confirmParentStatusChange(status, parent, opt) {
  if (opt.confirmAll) {
    return Promise.resolve(true);
  }
  return new Promise(function(resolve) {
    App.openPopover(
      ConfirmParentStatusModal,
      {
        parent: parent,
        status: status,
        resolve: resolve,
      },
      { type: 'confirmmodal' }
    );
  });
}
