import PropertyMap from './PropertyMap';
import _ from 'lodash';
import { Model } from '@/namespace';

class SprintMap extends PropertyMap {
  toModel(id) {
    return Model.Sprint.store.get(id);
  }

  sort(array) {
    array.sort(function(a, b) {
      return Model.Sprint.comparator.call(null, a, b);
    });
  }

  calcValue() {
    var array = this.toArray();
    return array[0] || Model.Sprint.backlog;
  }

  combinedTitle() {
    var list = this.toArray();
    if (list.length == 1) {
      return list[0].title;
    }
    if (list.length == 0) {
      return '';
    }

    return list[0].title + ' ... ' + list[list.length - 1].title;
  }
}
export default SprintMap;
