import _ from 'lodash';
import BaseModel from '@/models/base/BaseModel';
import DateFns from '@/common/date-fns';

export default class DateMap extends BaseModel {
  constructor(attributes) {
    super(attributes, { dates: [] });
  }

  static createNew(modelList, reversed = false) {
    var result = new DateMap();
    result.reversed = reversed;
    _.each(modelList, model => {
      result.add(model);
    });
    result.sort();
    return result;
  }

  get datesReversed() {
    return _.reverse(this.dates);
  }

  add(model) {
    if (!model) {
      return;
    }

    var date = model.createdAt || model.at || model.date;
    const dayList = this.findDayList(date);
    this.reversed ? dayList.addFirst(model) : dayList.add(model);
  }

  remove(model) {
    var date = model.createdAt || model.at || model.date;
    var dateList = this.findDayList(date);
    dateList.remove(model);
    if (_.isEmpty(dateList)) {
      this.dates.remove(dateList);
    }
  }

  sort() {
    const rev = this.reversed ? -1 : 1;
    this.dates.sort((d1, d2) => {
      return (d1.day - d2.day) * rev;
    });
    this.dates.forEach(date => {
      date.sort((d1, d2) => {
        return (d1.date - d2.date) * rev;
      });
    });
  }

  findDayList(date) {
    var day = asDay(date),
      result = _.find(this.dates, function(dayList) {
        return dayList.day === day;
      });
    if (result == null) {
      result = [];
      result.day = day;
      result.date = date;
      this.dates.push(result);
    }
    return result;
  }

  get empty() {
    return this.dates.length === 0;
  }

  get length() {
    return this.dates.length;
  }
}

function asDay(date) {
  return date.getFullYear() * 1000 + DateFns.getDayOfYear(date);
}
