<template>
  <div class="DragCloneHandler">
    <div class="DragClone" v-if="dragSession" :style="dragSession.cloneStyle" ref="clone">
      <div ref="cloneTarget" class="cloneTarget"></div>
      <div class="drop-target-info" :class="{ visible: markDropTarget }">
        <div class="text">Hold to drag into</div>
      </div>
    </div>
  </div>
</template>
<script>
import { App } from '@/namespace';
import DragSession from './DragSession';
export default {
  data() {
    return { dragSession: null, markDropTarget: false };
  },
  methods: {
    getClone() {
      return this.$refs.cloneTarget;
    },
    start(dragItem) {
      this.$refs.cloneTarget.append(dragItem.$el.cloneNode(true));
    },
    stop() {
      this.$refs.cloneTarget.html('');
      this.dragSession = null;
    },
    setMarkDropTarget(value) {
      this.markDropTarget = value;
    },
  },
  mounted() {
    App.startDragSession = ({ ev, dragItem, dragHandler }) => {
      this.dragSession = new DragSession({
        ev,
        dragItem,
        dragHandler,
        cloneHandler: this,
      });
    };
  },
};
</script>
<style lang="scss">
.DragClone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 10000;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);

  .drop-target-info {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 1px;

    .text {
      font-size: 12px;
      background: rgba(255, 255, 255, 0.8);
      opacity: 0;
      margin: 0 auto;
      display: inline-block;
      padding: 2px 5px;
    }

    &.visible {
      .text {
        transition: opacity 300ms, background 300ms;
        transition-delay: 300ms;
        opacity: 1;
      }
    }
  }
}
</style>
