<template>
  <div class="page-footer">
    <div class="links">
      <a href="/aboutagiloo">About Agiloo</a>
      <a href="/pricing">Pricing</a>
      <a href="/help/getstarted">Help Center</a>
      <a href="/termsconditions">Terms and Conditions</a>
      <a href="/privacypolicy">Privacy Policy</a>
      <a href="/cookiepolicy">Use of Cookies</a>
      <a href="https://twitter.com/agilooapp" target=" _blank "><i class="icon-twitter "></i></a>
    </div>
  </div>
</template>
<script>
export default {};
</script>
