import _ from 'lodash';
import isEmail from 'validator/lib/isEmail';

export function requiredValue(field = 'Value') {
  return val => !_.isEmpty(val) || field + ' must be entered';
}

export function requiredEmail() {
  return val => (!_.isEmpty(val) && isEmail(val)) || 'The email is not a valid email address';
}
