export default function(mock) {
  let newId = 10000;

  mock.onPut(/\/api\/status\/\w+/).reply(200, {});
  mock.onPut(/\/api\/status/).reply(200, {});
  mock.onDelete(/\/api\/status\/\w+/).reply(200, {});
  mock.onPost(/\/api\/status/).reply(function() {
    var id = ++newId;
    return [
      200,
      {
        id: 's' + id,
        numeral: id,
      },
    ];
  });
}
