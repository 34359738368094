<template>
  <div contenteditable="true" class="input text-input" @blur="blur" @keydown="keydown"></div>
</template>
<script>
import Keys from '@/common/keys';
export default {
  props: {
    modelValue: null,
    saveOnBlur: {
      default: true,
    },
  },
  mounted: function () {
    this.$el.innerText = this.modelValue || '';
  },
  methods: {
    keydown: function (ev) {
      if (ev.keyCode == Keys.ENTER) {
        this.$el.blur();
        !this.saveOnBlur && this.$emit('save');
        ev.stop();
      }
      if (ev.keyCode == Keys.ESCAPE) {
        this.$el.innerText = this.modelValue;
        this.doCancel = true;
        this.$el.blur();
        this.$emit('close');
      }
      if ((ev.ctrlKey || ev.metaKey) && ev.which === 83) {
        // Check for the Ctrl key being pressed, and if the key = [S] (83)
        this.$el.blur();
        ev.stop();
        return false;
      }
    },
    blur: function () {
      if (this.doCancel) {
        //this.element.trigger('cancel');
        this.$emit('change');
        delete this.doCancel;
        return;
      }

      this.$emit('update:modelValue', this.$el.innerText);
      this.$emit('change');
      this.saveOnBlur && this.$emit('save');
    },
  },
  watch: {
    modelValue: function () {
      this.$el.innerText = this.modelValue;
    },
  },
};
</script>
<style lang="scss">
.text-input {
  &.bordered {
    border: none;
    border-bottom: 1px solid #e8e8e8;
  }
}
</style>
