<template>
  <div class="ColorPicker">
    <div class="colors">
      <a
        class="color"
        v-for="color in colors"
        :id="color.id"
        :class="{ selected: isSelected(color) }"
        :style="{ 'background-color': '#' + color.code }"
        @click.prevent="select(color)"
      ></a>
    </div>
  </div>
</template>
<script>
import { Model } from '@/namespace';
export default {
  props: ['color'],
  data: function() {
    return { colors: Model.Color.getAll() };
  },

  methods: {
    isSelected(color) {
      return color === this.color;
    },
    select(color) {
      this.$emit('select', color);
    },
  },
};
</script>
<style lang="scss">
.ColorPicker {
  .colors {
    display: flex;
    padding: 10px 0;
  }

  .color {
    width: 32px;
    height: 32px;
    margin-right: 5px;

    &#white {
      outline: 1px solid #e8e8e8;
      &.selected {
        outline: 1px solid $gray;
      }
    }
    &.selected {
      outline: 1px solid $gray;
    }
  }
}
</style>
