<template>
  <span>
    <MemberLink :id="activity.by" />
    <template v-if="templateType === 'standardText'">
      <span> {{ text }} </span>
      <component :is="targetTemplate" :id="activity.id" class="navigate-slide" />
    </template>
    <template v-else-if="templateType === 'sprint'">
      <span> added </span>
      <IssueLink :id="activity.id" class="navigate-slide" />
      <span> to </span>
      <SprintLink :id="activity.to" />
    </template>
    <template v-else-if="templateType === 'status'">
      <span> moved </span>
      <IssueLink :id="activity.id" class="navigate-slide" />
      <span> to </span>
      <StatusLink :id="activity.to" />
    </template>
    <template v-else-if="templateType === 'member'">
      <template v-if="activity.to === 'unassigned'">
        <span> set </span>
        <IssueLink :id="activity.id" class="navigate-slide" />
        <span> as unassigned</span>
      </template>
      <template v-else>
        <span> assigned </span>
        <IssueLink :id="activity.id" class="navigate-slide" />
        <span> to </span>
        <MemberLink :id="activity.to" class="navigate-slide" />
      </template>
    </template>
  </span>
</template>
<script>
import { getModelById, Issue, Document, Sprint } from '@/models';
import {
  MemberLink,
  IssueLink,
  SprintLink,
  StatusLink,
  DocumentLink,
} from '@/components/propertylinks';
export default {
  props: ['activity'],
  computed: {
    templateType() {
      const { type } = this.activity;

      if (standardTexts[type]) {
        return 'standardText';
      }
      return type;
    },
    targetTemplate() {
      const { id } = this.activity;
      const model = getModelById(id);
      if (model instanceof Issue) {
        return IssueLink;
      }
      if (model instanceof Document) {
        return DocumentLink;
      }
      if (model instanceof Sprint) {
        return SprintLink;
      }
      return IssueLink;
    },
    text() {
      return standardTexts[this.activity.type];
    },
  },
  components: { MemberLink, IssueLink, SprintLink, StatusLink },
};

const standardTexts = {
  created: ' created ',
  description: ' updated description on ',
  title: ' updated title on ',
  parent: ' changed parent on ',
  comment: ' added comment on ',
  estimate: ' changed estimate on ',
  text: ' updated text on ',
};
</script>
