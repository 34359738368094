import { initDirectives } from './directives';
//import './filters'; @TODO
import { formatTime, formatDate } from '@/utils';
import AgilooLogo from './AgilooLogo.vue';

import PageFooter from './page-footer/PageFooter.vue';

import CheckBox from './checkbox/CheckBox.vue';
import './selectors';

import './issue-collection';

import { initPropertyLinks } from './propertylinks';
import { initBadges } from './mainsearch/badges/index.js';
import { initSearchResultComponents } from './mainsearch/searchresults/index';
import { initElements } from '@/elements';
import ItemCollectionSettings from './item-collection-settings/ItemCollectionSettings.vue';
import SimpleIssueRow from './issue-collection/IssueList/SimpleIssueRow.vue';
import IssueSections from './issue-collection/IssueList/IssueSections.vue';
import ViewComments from './view-comments/ViewComments.vue';
import userIcon from './user-icon.vue';
import ColorPicker from '@/components/color-picker/ColorPicker.vue';
import EstimateBar from '@/pages/viewissue/estimate-bar.vue';
import EstimateBarInlist from '@/pages/viewissue/estimate-bar-inlist.vue';
import IssueParentPath from '@/pages/viewissue/IssueParentPath.vue';
import BreadCrumbs from '@/pages/viewissue/BreadCrumbs.vue';
import clickOutside from './click-outside';
// Vue.component('TipTapEditor', resolve => {
//   import(/* webpackChunkName: "tiptap-editor"  */ './tiptap-editor/TipTapEditor').then(
//     AsyncComponent => {
//       resolve(AsyncComponent.default);
//     }
//   );
// });
import TipTapEditor from '@/components/tiptap/TipTap.vue';

export function initGlobalComponents(app) {
  initElements(app);
  initDirectives(app);

  app.directive('click-outside', clickOutside);
  app.component('AgilooLogo', AgilooLogo);
  app.component('user-icon', userIcon);
  app.component('ColorPicker', ColorPicker);
  app.component('SimpleIssueRow', SimpleIssueRow);
  app.component('IssueSections', IssueSections);
  app.component('ViewComments', ViewComments);
  app.component('ItemCollectionSettings', ItemCollectionSettings);
  app.component('PageFooter', PageFooter);
  app.component('checkbox', CheckBox);
  app.component('EstimateBar', EstimateBar);
  app.component('EstimateBarInlist', EstimateBarInlist);

  app.component('IssueParentPath', IssueParentPath);
  app.component('BreadCrumbs', BreadCrumbs);
  app.component('TipTapEditor', TipTapEditor);
  initBadges(app);
  initPropertyLinks(app);
  initSearchResultComponents(app);

  Object.assign(app.config.globalProperties, {
    formatTime,
    formatDate,
  });
}
