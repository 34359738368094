<template>
  <div class="ViewGithub">
    <div class="">
      <template v-if="!empty(pullRequests)">
        <h3>Pull Requests</h3>

        <div class="row" v-for="pullRequest in pullRequests" :key="pullRequest.id">
          <a :href="pullRequest.url" target="_blank">{{ pullRequest.name }}</a>
          <div class="pullrequest-state">{{ pullRequest.state }}</div>
        </div>
      </template>
      <template v-if="!empty(branches)">
        <h3>Branches</h3>

        <div class="row" v-for="branch in branches" :key="branch.name">
          <a :href="branch.url" target="_blank">{{ branch.name }}</a>
        </div>
      </template>
      <template v-if="!empty(commitMap)">
        <h3>Commits</h3>
        <div v-for="dateHolder in commitMap.dates" :key="dateHolder.day">
          <div class="date-header">{{ formatDate(dateHolder.date) }}</div>

          <div class="row" v-for="commit in dateHolder" :key="commit.id">
            <a :href="commit.url" target="_blank">{{ commit.message }}</a>
            <div class="date">{{ formatTime(commit.date) }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import DateMap from '@/common/model/DateMap';

import _ from 'lodash';
export default {
  props: ['issue'],
  computed: {
    githubData() {
      return this.issue.githubData;
    },
    commitMap() {
      if (!this.githubData) {
        return null;
      }
      return DateMap.createNew(this.githubData.commits);
    },
    branches() {
      if (!this.githubData) {
        return null;
      }
      return this.githubData.branches;
    },
    pullRequests() {
      if (!this.githubData) {
        return null;
      }
      return this.githubData.pullRequests;
    },
  },

  methods: {
    empty(val) {
      return _.isEmpty(val);
    },
  },
};
</script>
<style lang="scss">
.ViewGithub {
  font-size: 15px;
  .box {
    padding: 0 20px;
  }
  h3 {
    border-bottom: 1px solid #e8e8e8;
    margin: 30px 0px 0px 10px;
    color: $orange-500;
  }

  .date-header {
    margin: 20px 10px 0;
  }
  .row {
    display: flex;
    padding: 5px;
    margin: 0 0 0 30px;

    &:not(:last-child) {
      //border-bottom: 1px solid #e8e8e8;
    }
    a {
      flex: 1;
      //color: $blue-d1;
    }

    .date {
      font-size: 12px;
      color: #777;
    }
  }
}
</style>
