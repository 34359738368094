import loadScript from 'simple-load-script';
import Subscription from '@/models/billing/Subscription';
import { Util, App } from '@/namespace';

import PaymentInfo from './PaymentInfo';
let stripe;
export function loadStripe() {
  if (stripe) {
    return Promise.resolve(stripe);
  }
  return Util.jsonajax({ url: '/billing/stripekey', method: 'GET' }).then(({ key }) => {
    return loadScript('https://js.stripe.com/v3/').then(() => {
      stripe = new window.Stripe(key);
      return stripe;
    });
  });
}

export function loadSubscription() {
  return Subscription.find();
}

export function findPaymentInfo() {
  return Util.jsonajax({ url: '/billing/paymentinfo' }).then(data => {
    return PaymentInfo.make(data);
  });
}

export function updatePaymentInfo(data) {
  return Util.jsonajax({ url: '/billing/updatepaymentinfo', data, handleError: true });
}

export async function createSubscription(data) {
  const stripe = await loadStripe();
  const checkoutSession = await Util.jsonajax({
    url: '/billing/createsubscription',
    data,
    handleError: true,
  });
  stripe.redirectToCheckout({
    sessionId: checkoutSession.id,
  });
}

export async function changeCard(data) {
  const stripe = await loadStripe();
  const checkoutSession = await Util.jsonajax({
    url: '/billing/changecard',
    data,
  });
  stripe.redirectToCheckout({
    sessionId: checkoutSession.id,
  });
}

export function loadInvoices() {
  return Util.jsonajax({ url: '/billing/pastinvoices' });
}

export function findPossibleUsers() {
  return Util.jsonajax({ url: '/billing/possibleusers' }).then(users => {
    return users.map(user => {
      var thisUser = user.id === App.vm.profile.userId;
      return {
        ...user,
        selected: thisUser,
        fixed: thisUser,
      };
    });
  });
}
