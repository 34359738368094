<template>
  <section>
    <h1>Sprints</h1>

    <div class>
      <p>
        A work item can be divided across multiple sprints, by assigning it's sub items to different
        sprints. That can be relevant when an item is too big for one sprint, and it makes sense to
        split it into smaller parts. Another case where it makes sense so split an item, is when the
        team was not able to finish it during a sprint. By defining sub items that reflects the work
        left, and assigning them to the next sprint, this problem can be handled.
      </p>

      <img src="/static/images/help/multiplesprints.png" class="bordered" />
      <p class="image-text">
        When sub items are assigned to different sprints, a list for each sprint is shown. You can
        change an item's sprint by dragging it from one sprint to another.
      </p>

      <img src="/static/images/help/multiplesprints2.png" class="bordered" />
      <p class="image-text">
        On the project items views, the work item
        <i>Create and edit projects</i>
        is displayed for both sprint 1 and sprint 2. When changing status or setting assigned
        member, only the sub items within the actual sprint are affected.
      </p>
    </div>
  </section>
</template>
<script>
export default {};
</script>
