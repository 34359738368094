import { App } from '@/namespace';
import tooltips from '../tooltips';
import MoreMenu from '../MoreMenu.vue';
import MemberIcon from '../MemberIcon.vue';
import CreateIssuePopover from './CreateIssuePopover.vue';
import dragContainerMixin from '@/components/drag/issueDragContainerMixin';
import CreateIssueCard from './CreateIssueCard.vue';
export default {
  mixins: [dragContainerMixin],
  props: ['wrapper'],
  name: 'BoardCard',
  methods: {
    setDragStatus(name, value) {
      this.wrapper.setDragStatus(name, value);
    },

    mousedown(ev) {
      App.startDragSession({ ev, dragItem: this });
    },

    createSiblingIssue({ sibling }) {
      this.wrapper.addCreateIssue({ sibling });
    },
    openMoreMenu(ev) {
      App.openPopover(
        MoreMenu,
        {
          issue: this.wrapper.issue,
          wrapper: this.wrapper,
          handleCreateSubIssue: () => {
            if (this.wrapper.level >= 2) {
              App.openPopover(
                CreateIssuePopover,
                { parentWrapper: this.wrapper },
                { type: 'menu' }
              );
            } else {
              this.wrapper.addCreateIssue({ position: 0 });
            }
          },
          handleCreateSiblingIssue: () => {
            this.$emit('createSiblingIssue', { sibling: this.wrapper });
          },
        },
        {
          type: 'menu',
          target: ev.currentTarget,
        }
      );
    },
  },
  computed: {
    isMain() {
      return this.wrapper.level === 1;
    },
    cssClass() {
      if (this.wrapper.level === 1) {
        return {
          main: true,
          exploded: this.wrapper.exploded,
          oneMember: this.wrapper.getPropertyMap('member').length() === 1,
          ...this.wrapper.dragStatus,
        };
      }
      return { sub: true, exploded: this.wrapper.exploded, ...this.wrapper.dragStatus };
    },
    tooltips() {
      return tooltips;
    },
    tooltipNoSubs() {
      const { wrapper } = this;
      return {
        content() {
          var count = wrapper.issues.length;
          var doneCount = wrapper.noIssuesDone();
          var result = doneCount + ' of ' + count + ' sub items are done';
          return result;
        },
      };
    },
  },
  components: { MemberIcon, CreateIssueCard },
};
