<template>
  <svg class="Icon" aria-hidden="true">
    <use xlink:href="#icon-board"></use>
  </svg>
</template>
<script>
export default {
  props: ['icon'],
  computed: {
    href() {
      return '#icon-' + this.icon;
    },
  },
};
</script>
<style lang="scss">
svg.Icon {
  fill: currentColor;
  height: 16px;
  width: 16px;
}
</style>
