import _ from 'lodash';
import { io } from 'socket.io-client';
import { convertModels, convertModelMap } from '@/common/model/convertmodels';
import { handleNewActivity } from '@/models/activityHandler';
import { App, Model, Util } from '@/namespace';

export default function socketHandler() {
  var socket;

  function initSocket() {
    if (socket) {
      return;
    }
    if (window.useFixtures) {
      socket = {
        on: function() {},
        emit: function() {},
      };
      return;
    }
    console.log('init socket');

    socket = io();
    socket.on('connect', function() {
      console.log('socket:connect ' + new Date());
      App.socketId = socket.id;
      reInitProjectConnections();
      /*
      var d = socket.io.reconnectionDelay();
      console.log(d);
      */
    });

    socket.on('connect_error', function() {
      console.log('socket:connect_error ' + new Date());
    });
    socket.on('reconnect_error', function() {
      console.log('socket:reconnect_error ' + new Date());
    });

    socket.on('reconnect', function() {
      App.socketId = socket.id;
      console.log('socket:reconnect ' + new Date());
    });

    socket.on('notification', function(notification) {
      var n = convertModels(notification, Model.Notification)[0];
      App.displayNotification(n);
      //App.displayInfo(n.text());
    });

    socket.on('projectupdate', function(update) {
      console.log('projectupdate');
      const { originModel, models, originModelName } = update;
      if (update.model === 'Profile') {
        Model.Profile.get().setAttributes(update.data);
        return;
      }
      if (originModel) {
        convertModels(originModel, Model[originModelName]);
      }
      if (models) {
        convertModelMap(models);
      }
      handleNewActivity(update);
    });
  }

  function log() {
    console.log(socket);
  }

  return {
    init: initSocket,
    reInitProjectConnections,
    log,
  };
}

function reInitProjectConnections() {
  console.log('reInitConnections');
  var data = {
    projects: {},
    idleTime: App.getIdleTimeInMinutes(),
  };

  _.each(Model.Project.getAll(), function(p) {
    if (!p.filled) {
      return;
    }
    data.projects[p.id] = {
      updateTime: p.updateTime,
    };
  });

  return Util.jsonajax(
    {
      url: '/project/reinitconnections',
      data: data,
      method: 'POST',
    },
    { noActivity: true }
  ).catch(function(error) {
    App.registeredError = null;
  });
}
