//import './canvas-to-blob-polyfill'; @TODO

function createThumbnail(file) {
  return new Promise(function (resolve) {
    var url = URL.createObjectURL(file);
    return createImage(url).then(function (image) {
      var canvas = resize(image);

      canvas.toBlob(function (blob) {
        resolve(blob);
      });
    });
  });
}

function createImage(src) {
  return new Promise(function (resolve) {
    var img = new Image();

    img.addEventListener(
      'load',
      function () {
        resolve(img);
      },
      false
    );

    img.src = src;
  });
}

function resize(image) {
  var size = 175;
  var scale = image.width / size;
  var canvas = document.createElement('canvas');
  canvas.width = image.width / scale;
  canvas.height = image.height / scale;
  var ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  return canvas;
}

export default createThumbnail;
