import IssueRow from './IssueRow.vue';
import dragContainerMixin from '@/components/drag/issueDragContainerMixin';
import CreateIssueRow from './CreateIssueRow.vue';
export default {
  mixins: [dragContainerMixin],
  data: function() {
    return { createNewIssue: false };
  },
  props: ['wrapper', 'issueSections'],
  methods: {
    openCreateIssue() {
      this.wrapper.issues.push({ isCreateIssue: true });
    },
    createSiblingIssue({ sibling }) {
      this.wrapper.addCreateIssue({ sibling });
    },
  },
  computed: {
    isEmpty() {
      return this.wrapper.issues.length === 0 && !this.createNewIssue;
    },
    sprintTitle() {
      if (!this.wrapper.issue.isProject() && this.wrapper.issue.sprintMap.isSingle()) {
        return 'Sub Items';
      }
      return this.wrapper.wrapperContext.sprint.title;
    },
    cssClass() {
      return {
        'no-0': this.isEmpty,
        ...this.wrapper.dragStatus,
      };
    },
  },
  components: {
    CreateIssueRow,
    IssueRow,
  },
};
