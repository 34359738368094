<template>
  <div
    class="member-icon selector member"
    selector="member"
    v-tooltip="tooltip"
    :member-id="wrapper.memberMap.assigneeKey()"
    @click.prevent.stop="openSelector"
  >
    <i class="icon-"></i>
  </div>
</template>
<script>
import { App } from '@/namespace';
export default {
  props: ['wrapper'],

  methods: {
    openSelector(ev) {
      App.openSelector({
        property: 'member',
        issue: this.wrapper,
        targetElement: ev.target,
      });
    },
  },
  computed: {
    tooltip() {
      const { wrapper } = this;
      return {
        content: function() {
          var memberMap = wrapper.memberMap;
          if (memberMap.length() > 1) {
            return 'Multiple members assigned';
          }
          var member = memberMap.calcValue();

          return member.displayName;
        },
      };
    },
  },
};
</script>
