import ResultIssue from './ResultIssue.vue';
import ResultMember from './ResultMember.vue';
import ResultSprint from './ResultSprint.vue';
import ResultTag from './ResultTag.vue';
import ResultFilterRule from './ResultFilterRule.vue';
import ResultStatus from './ResultStatus.vue';
import ResultIssueType from './ResultIssueType.vue';
import ResultTextItem from './ResultTextItem.vue';
import ResultDocument from './ResultDocument.vue';

export function initSearchResultComponents(app) {
  app.component('ResultIssue', ResultIssue);
  app.component('ResultMember', ResultMember);
  app.component('ResultDocument', ResultDocument);
  app.component('ResultSprint', ResultSprint);
  app.component('ResultTag', ResultTag);
  app.component('ResultFilterRule', ResultFilterRule);
  app.component('ResultStatus', ResultStatus);
  app.component('ResultIssueType', ResultIssueType);
  app.component('ResultTextItem', ResultTextItem);
}
