import _ from 'lodash';

export default class FilterQuery {
  constructor() {
    this.members = [];
    this.tags = [];
    this.textRegex = null;
    this.filterRules = [];
    this.statuses = [];
    this.issueTypes = [];
  }

  isEmpty() {
    return (
      this.textRegex == null &&
      this.members.length === 0 &&
      this.tags.length === 0 &&
      this.filterRules.length === 0 &&
      this.statuses.length === 0 &&
      this.issueTypes.length === 0
    );
  }

  addModel(m) {
    const name = m.constructor.NAME;
    if (name === 'Tag') {
      this.tags.push(m);
    } else if (name === 'Member') {
      this.members.push(m);
    } else if (name === 'FilterRule') {
      this.filterRules.push(m);
    } else if (name === 'Status') {
      this.statuses.push(m);
    } else if (name === 'IssueType') {
      this.issueTypes.push(m);
    }
  }

  includeArchived() {
    if (this.filterRules.length === 0) {
      return false;
    }
    return _.some(this.filterRules, function(rule) {
      return rule.includeArchive;
    });
  }
  setText(text) {
    const regex = _.isEmpty(text) ? null : new RegExp(text.toLowerCase(), 'i');
    this.textRegex = regex;
  }
  copy() {
    const result = new FilterQuery();
    result.members.pushAll(this.members);
    result.tags.pushAll(this.tags);
    result.filterRules.pushAll(this.filterRules);
    result.statuses.pushAll(this.statuses);
    result.issueTypes.pushAll(this.issueTypes);
    result.textRegex = this.textRegex;
    return result;
  }
}
