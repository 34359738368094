import StoreModel from './base/StoreModel';
import _ from 'lodash';

import { Model, Util } from '@/namespace';

const roles = [
  {
    id: 'admin',
    name: 'Admin',
    priority: 0,
  },
  {
    id: 'normal',
    name: 'Normal',
    priority: 1,
  },
  {
    id: 'viewer',
    name: 'Viewer',
    priority: 2,
  },
];

class Role extends StoreModel {
  static loadAll() {
    return Promise.resolve(roles);
  }
}

Model.Role = Role;
export default Role;
