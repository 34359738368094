<template>
  <div class="page-content page_project_settings medium">
    <section>
      <h1>Project Settings</h1>
    </section>

    <section class="settings-split">
      <div class="left">
        <h2>Status</h2>
        <div class="mobile-hidden">
          Select which statuses that should be available for Work Items.
        </div>
      </div>
      <div class="right">
        <EditProjectStatuses :project="project" />
      </div>
    </section>

    <section class="settings-split">
      <div class="left">
        <h2>Tags</h2>
      </div>
      <div class="right">
        <div class="tags box">
          <TagView v-for="tag in project.tags" :tag="tag" :key="tag.id" :showDelete="true" />

          <q-btn round id="create-tag" flat @click="createTag">
            <i class="icon-plus3"></i>
          </q-btn>
        </div>
      </div>
    </section>
    <section class="settings-split">
      <div class="left">
        <h2>Project Type</h2>
      </div>
      <div class="right">
        <q-btn class="project-type" unelevated rounded @click="editProjectType"
          >{{ project.type | capitalize }} <i class="icon-pen6"></i
        ></q-btn>
      </div>
    </section>

    <section class="settings-split">
      <div class="left">
        <h2>Github Integration</h2>
      </div>

      <div class="right">
        <GithubSettings :project="project" />
      </div>
    </section>

    <section class="settings-split">
      <div class="left">
        <h2>Export to JSON</h2>
        <div>Export Project to JSON</div>
      </div>
      <div class="right">
        <div class="">
          <a-btn color="primary" @click="exportJson" target="_blank">Export to JSON</a-btn>
        </div>
      </div>
    </section>

    <section class="settings-split">
      <div class="left">
        <h2>Delete Project</h2>
        <div>Deleting a project will also delete associated work items and sprints</div>
      </div>
      <div class="right">
        <a-btn @click="deleteProject" color="red">Delete Project</a-btn>
      </div>
    </section>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';

import TagView from '@/components/TagView';
import EditTag from '@/pages/edittag/EditTag.vue';

import EditProjectTypeModal from '@/pages/EditProjectTypeModal/EditProjectTypeModal.vue';

import GithubSettings from './GithubSettings.vue';
import EditProjectStatuses from './EditProjectStatuses.vue';
import SelectGithubReposModal from '@/components/github/SelectGithubRepos.vue';

export default {
  route: '/:projectId/projectsettings',
  props: ['project', 'githubrepos', 'profile'],
  menu: 'projectsettings',
  components: { GithubSettings, EditProjectStatuses, TagView },
  getData: function (props) {
    return {
      project: Model.Project.find(props.projectId),
      profile: App.vm.profile,
    };
  },
  mounted() {
    console.log('mounted');
    if (this.githubrepos != null) {
      App.openModal(SelectGithubReposModal);
    }
  },

  created() {
    App.setCurrentProject(this.project);
  },

  methods: {
    exportJson() {
      const link = '/api/project/' + this.project.id + '/backup';
      window.open(link, '_blank');
    },
    createTag() {
      App.openPopup(EditTag, {});
    },
    deleteProject() {
      var self = this;
      var deleteInfo = 'This will delete the project and all project items';

      App.openConfirmDialog({
        title: 'Delete Project',
        text: deleteInfo,
        onConfirm: function () {
          self.project
            .destroy()
            .then(function () {
              App.router.navigate('/projects', { forward: true });
              App.clearCurrentProjectId();
            })
            .catch(App.handleError);
        },
      });
    },

    editProjectType() {
      App.openPopup(EditProjectTypeModal, { project: this.project });
    },
  },
};
</script>
<style lang="scss" src="./ProjectSettings.scss"></style>
