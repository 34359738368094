<template>
  <a class="BoardCard create-issue" :class="cssClasses">
    <div class="body">
      <div class="top">
        <text-input
          v-model="title"
          v-focus
          class="title text"
          placeholder="Item Title"
          @change="change"
        ></text-input>
      </div>
    </div>
  </a>
</template>
<script>
import { _, Model } from '@';

import { getNewPriority, setNewPriority } from '@/models/priority/priorityCalculation';
export default {
  props: ['parentWrapper'],
  data: function() {
    return { title: '' };
  },

  methods: {
    change() {
      const { parentWrapper, title } = this;
      if (_.isEmpty(this.title)) {
        parentWrapper.cleanupCreateIssue();
        return;
      }

      var newIssue = Model.Issue.createNew({
        parent: parentWrapper.issue,
        title,
      });
      setInitialPriority(newIssue, parentWrapper);
      parentWrapper.applyChange(newIssue, {
        sortIndex: parentWrapper.issues.findIndex(is => is.isCreateIssue),
      });

      const postCreateHook = () => {
        parentWrapper.cleanupCreateIssue();
      };
      newIssue.save({ postCreateHook });
    },
  },
  computed: {
    cssClasses() {
      return this.parentWrapper.level === 0 ? { main: true } : { sub: true };
    },
  },
};

function setInitialPriority(issue, parentWrapper) {
  const sortIndex = parentWrapper.issues.findIndex(is => is.isCreateIssue);
  const priority = getNewPriority(parentWrapper.issues, sortIndex);
  setNewPriority(issue, null, null, priority);
}
</script>
