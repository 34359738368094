export default function(mock) {
  const loggedIn = {
      authenticated: true,
      color: 'blue',
      initials: 'JD',
      userSettings: {},
      featureToggle: { test: true },
    },
    failedRequest = {
      message: 'Request Failed',
    };

  mock.onGet('/api/auth/logout').reply(200, {});
  mock.onGet('/api/auth/profile').reply(200, loggedIn);

  mock.onPost('/api/auth/login').reply(200, loggedIn);
  mock.onPost('/api/auth/getinvite').reply(200, {
    email: 'test@test.com',
    inviteCode: 'code',
  });
  mock.onPost('/api/auth/register').reply(200, {});

  mock.onPost('/api/auth/requestresetpassword').reply(400, failedRequest);
  mock.onPost('/api/auth/requestresetpassword').reply(400, failedRequest);
  mock.onPost('/api/auth/resetpassword').reply(400, failedRequest);
  mock.onPost('/api/auth/deleteuser').reply(400, failedRequest);
  mock.onPost('/api/auth/changepassword').reply(200, {});
  mock.onPost('/api/auth/changename').reply(200, {});
}
