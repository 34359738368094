<template>
  <FilterableMenu :opt="opt" @close="close"> </FilterableMenu>
</template>
<script>
import { App } from '@/namespace';
import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
import { deleteSprint, setSprintUnfinished } from '@/models/sprintHandler';

import ViewFinishSprint from '@/pages/viewfinishsprint/ViewFinishSprint.vue';
import EditSprintPopover from '@/popovers/EditSprintPopover';
import CreateSprintPopover from '@/popovers/CreateSprintPopover';

export default {
  props: ['sprint'],
  data() {
    const boardTerm = App.vm.currentProject.boardTerm,
      isScrum = App.vm.currentProject.type === 'scrum';
    return {
      opt: {
        items: [
          {
            text: `Edit ${boardTerm}`,
            icon: 'icon-pen6',
            disabled: this.sprint.backlog,
            select: () =>
              App.openPopover(EditSprintPopover, {
                sprint: this.sprint,
              }),
          },
          {
            text: 'Finish Sprint',
            disabled: !isScrum || this.sprint.finished || this.sprint.backlog,
            icon: 'icon-racing',
            select: () => {
              App.closePopover();
              App.openPopup(ViewFinishSprint, { sprint: this.sprint });
            },
          },
          {
            text: 'Restart Sprint',
            disabled: !isScrum || !this.sprint.finished || this.sprint.backlog,
            icon: '-',
            select: () => {
              setSprintUnfinished(this.sprint);
              this.sprint.save();
            },
          },
          {
            text: `View all ${boardTerm}s`,
            icon: 'empty',
            url: '/' + App.vm.currentProject.id + '/sprints',
          },
          {
            text: `Create New ${boardTerm}`,
            icon: 'icon-plus3',
            select: () =>
              App.openPopover(CreateSprintPopover, {
                onCreate: sprint => {
                  App.router.navigate(sprint.url);
                },
              }),
          },
        ].filter(menuItem => !menuItem.disabled),
        footerItems: [
          {
            text: `Delete ${boardTerm}`,
            disabled: this.sprint.backlog,
            icon: 'icon-bin',
            select: () => {
              return deleteSprint(this.sprint).then(() => {
                App.router.navigateBack();
              });
            },
          },
        ].filter(menuItem => !menuItem.disabled),
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
