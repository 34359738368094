import { getChildren } from './containerUtils';
export default {
  methods: {
    setDragStatus(name, value) {
      this.wrapper.dragStatus[name] = value;
    },
    getListElements() {
      return getChildren(this);
    },
    getModelList() {
      return this.wrapper.issues;
    },
    getModel() {
      return this.wrapper;
    },
  },
};
