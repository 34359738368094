import { App, Model } from '@/namespace';
import _ from 'lodash';
import initDefaultModels from '@/models/initDefaultModels';
import Subscription from '@/models/billing/Subscription';
import ConfirmModal from '@/components/confirmmodal/ConfirmModal.vue';

_.assign(App, {
  clearCache() {
    Model.Issue.clearCache();
    Model.Member.clearCache();
    Model.Sprint.clearCache();
    Model.Project.clearCache();
    Model.Notification.clearCache();
    Subscription.clearCache();
    initDefaultModels();
  },
  openConfirmDialog(pageOptions) {
    App.openPopover(ConfirmModal, pageOptions, { type: 'confirmmodal' });
  },
  clearCurrentProjectId() {
    App.vm.currentProject = null;
  },
  setCurrentProject(project) {
    App.vm.currentProject = project;
  },

  isSafariStandalone() {
    var result = navigator.standalone && /iphone|ipod|ipad/gi.test(navigator.platform);
    return result;
  },

  handleError(error) {
    var message = _.isString(error) ? error : error.message || 'An error occured';
    App.displayError(message);
  },

  displayError(error) {
    console.error(error);
    App.displayMessage(error, 'error');
  },
  displayInfo(info) {
    App.displayMessage(info, 'info');
  },
  displayWarning(info) {
    App.displayMessage(info, 'warning');
  },

  logActivity() {
    App.lastActivity = new Date();
  },
  getIdleTimeInMinutes() {
    var time = new Date().getTime() - App.lastActivity.getTime();
    time = Math.floor(time / 60000);
    return time;
  },

  closeError() {
    App.closeMessage();
  },

  registerError(error) {
    App.registeredError = error;
    setTimeout(function() {
      // TODO use $.timer instead, so multiple errors are not shown
      if (App.registeredError != null) App.displayError(App.registeredError);
    }, 50);
  },
  currentProjectMember() {
    var profile = App.vm.profile;
    var currentMember = _.find(App.vm.currentProject.members, member => {
      return member.userId === profile.userId;
    });

    return currentMember;
  },
});
