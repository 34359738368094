import _ from 'lodash';

const models = {
  Member: [
    {
      id: 'd1',
      displayName: 'test1@test.com',
      name: 'Test1',
      email: 'test1@test.se',
      currentUser: true,
      projectId: 'p1',
      color: 'red',
      initials: 'T1',
      roleId: 'admin',
      userId: 'u1',
    },
    {
      id: 'd2',
      color: 'blue',
      displayName: 'test2@test.com',
      name: 'Test2',
      email: 'test2@test.se',
      projectId: 'p1',
      initials: 'T2',
      roleId: 'admin',
      userId: 'u2',
    },
  ],
  Sprint: [
    {
      id: 's1',
      title: 'Sprint 1',
      projectId: 'p1',
      createdAt: new Date(1000),
      numeral: 1,
      priority: 100,
    },
    {
      id: 's2',
      title: 'Sprint 2',
      projectId: 'p1',
      createdAt: new Date(2000),
      numeral: 2,
      memberId: 'd2',
      priority: 200,
    },
    {
      id: 's3',
      title: 'Sprint 3',
      projectId: 'p1',
      createdAt: new Date(3000),
      numeral: 3,
      priority: 300,
    },
    {
      id: 's4',
      title: 'Sprint 4',
      projectId: 'p1',
      createdAt: new Date(4000),
      numeral: 4,
      priority: 400,
    },
    {
      id: 's5',
      title: 'Sprint 5',
      projectId: 'p1',
      createdAt: new Date(5000),
      numeral: 5,
      priority: 500,
    },
  ],
  Project: [
    {
      id: 'p1',
      title: 'Project A',
    },
  ],
  Status: [
    {
      key: 'archived',
      name: 'Archived',
      active: true,
      priority: 10000,
      fixed: true,
      color: 'green',
      icon: 'e9d8',
      numeral: 3,
    },
    {
      key: 'todo',
      name: 'ToDo',
      active: true,
      fixed: true,
      priority: 0,
      color: 'white',
      iconText: '',
      numeral: 0,
    },
    {
      key: 'inprogress',
      name: 'In Progress',
      active: true,
      fixed: true,
      priority: 100,
      color: 'yellow',
      icon: 'eb27',
      numeral: 1,
    },
    {
      key: 'done',
      name: 'Done',
      active: true,
      fixed: true,
      priority: 300,
      color: 'green',
      icon: 'ed6f',
      numeral: 2,
    },
    {
      key: 'test',
      name: 'Test',
      active: false,
      priority: 200,
      color: 'yellow',
      iconText: 'T',
    },
    {
      key: 'accepted',
      name: 'Accepted',
      active: false,
      priority: 400,
      color: 'green',
      iconText: 'A',
    },
  ],
  Tag: [
    {
      title: 'Backend',
      id: 't1',
      projectId: 'p1',
      color: 'green',
    },
    {
      title: 'Frontend',
      id: 't2',
      projectId: 'p1',
      color: 'red',
    },
  ],
  Issue: [
    {
      id: 'i1',
      key: 1,
      projectId: 'p1',
      parentId: 'p1',
      sprintId: 's1',
      priorityMap: {
        _: 100,
      },
      tags: ['t1', 't2'],
      title: 'Issue 1',
      description: '',
      statusId: 'todo',
      explodeChildren: {
        s1: false,
      },
    },
    {
      id: 'i11',
      key: 11,
      projectId: 'p1',
      issueType: 'bug',
      parentId: 'i1',
      sprintId: 's1',
      description: '',
      priorityMap: {
        _: 100,
      },
      statusId: 'todo',
      title: 'Issue 11',
    },
    {
      id: 'i12',
      key: 12,
      projectId: 'p1',
      parentId: 'i1',
      sprintId: 's1',
      description: '',
      priorityMap: {
        _: 200,
      },
      statusId: 'todo',
      title: 'Issue 12',

      comments: [
        {
          id: 'C-1',
          text: 'a comment',
          member: 'd1',
          createdAt: new Date(),
        },
      ],
    },
    {
      id: 'i2',
      key: 2,
      projectId: 'p1',
      statusId: 'todo',
      parentId: 'p1',
      sprintId: 's1',
      priorityMap: {
        _: 100,
      },

      title: 'Issue 2',
      description: 'For per@1 @d1 or @d2 to look at @11',
    },

    {
      id: 'i3',
      key: 3,
      projectId: 'p1',
      parentId: 'p1',
      sprintId: 's3',
      statusId: 'todo',
      title:
        'Issue 3 long title long title long title long title long title long title long title long title long title long title long title long title',

      priorityMap: {
        _: 500,
      },
    },
    {
      id: 'i31',
      key: 31,
      projectId: 'p1',
      parentId: 'i3',
      statusId: 'done',
      sprintId: 's3',
      title: 'Issue 31',
      priorityMap: {
        _: 100,
      },
    },
    {
      id: 'i32',
      key: 32,
      projectId: 'p1',
      parentId: 'i3',
      sprintId: 's3',
      title: 'Issue 32',
      statusId: 'todo',
      priorityMap: {
        _: 200,
      },
    },
    {
      id: 'i321',
      key: 321,
      projectId: 'p1',
      parentId: 'i32',
      statusId: 'todo',
      sprintId: 's3',
      title: 'Issue 321',
      priorityMap: {
        _: 100,
      },
      estimate: 5,
    },
    {
      id: 'i322',
      key: 322,
      projectId: 'p1',
      parentId: 'i32',
      statusId: 'done',
      sprintId: 's3',
      title: 'Issue 322',
      priorityMap: {
        _: 200,
      },
      estimate: 7,
    },
    {
      id: 'i4',
      key: 4,
      projectId: 'p1',
      parentId: 'p1',
      sprintId: 's4',
      statusId: 'todo',
      issueType: 'feature',
      title: 'Issue 4',
      priorityMap: {
        _: 100,
      },
    },
    {
      id: 'i41',
      key: 41,
      projectId: 'p1',
      parentId: 'i4',
      statusId: 'todo',
      sprintId: 'backlog',
      title: 'Issue 41',
      priorityMap: {
        _: 100,
        '4': 300,
      },
    },
    {
      id: 'i411',
      key: 411,
      projectId: 'p1',
      parentId: 'i41',
      statusId: 'done',
      sprintId: 's4',
      title: 'Issue 411',
      issueType: 'bug',
      priorityMap: {
        _: 100,
      },
    },
    {
      id: 'i412',
      key: 412,
      projectId: 'p1',
      parentId: 'i41',
      statusId: 'done',
      sprintId: 's4',
      title: 'Issue 412',
      priorityMap: {
        _: 200,
      },
    },
    {
      id: 'i413',
      key: 413,
      projectId: 'p1',
      parentId: 'i41',
      statusId: 'todo',
      sprintId: 'backlog',
      title: 'Issue 413',
    },
    {
      id: 'i42',
      key: 42,
      projectId: 'p1',
      parentId: 'i4',
      title: 'Issue 42',
      statusId: 'todo',
      sprintId: 'backlog',
      priorityMap: {
        _: 200,
      },
    },
    {
      id: 'i421',
      key: 421,
      projectId: 'p1',
      parentId: 'i42',
      statusId: 'todo',
      sprintId: 's4',
      title: 'Issue 421',
      priorityMap: {
        _: 100,
      },
    },
    {
      id: 'i422',
      key: 422,
      projectId: 'p1',
      parentId: 'i42',
      statusId: 'done',
      sprintId: 's4',
      title: 'Issue 422',
      priorityMap: {
        _: 200,
      },
    },
    {
      id: 'i423',
      key: 423,
      projectId: 'p1',
      parentId: 'i42',
      statusId: 'todo',
      sprintId: 'backlog',
      title: 'Issue 423',
    },
    {
      id: 'i43',
      key: 43,
      projectId: 'p1',
      parentId: 'i4',
      title: 'Issue 43',
      statusId: 'todo',
      sprintId: 's4',
      priorityMap: {
        _: 300,
      },
    },
    {
      id: 'i431',
      key: 43,
      projectId: 'p1',
      parentId: 'i43',
      sprintId: 's4',
      title: 'Issue 431',
      statusId: 'todo',
      priorityMap: {
        _: 100,
      },
    },
    {
      id: 'i5',
      key: 5,
      projectId: 'p1',
      parentId: 'p1',
      statusId: 'todo',
      issueType: 'story',
      sprintId: 's3',
      title: 'Issue 5',
      priorityMap: {
        _: 600,
      },
    },
    {
      id: 'i6',
      key: 2,
      projectId: 'p1',
      statusId: 'todo',
      parentId: 'p1',
      sprintId: 's3',
      priorityMap: {
        _: 250,
      },

      title: 'Issue 6',
    },
  ],
};

_.each(models.Status, function(s) {
  s.id = 'p1' + '_' + s.key;
  s.projectId = 'p1';
});

export default {
  id: 'p1',
  models: models,
};
