<template>
  <div class="HelpCenterFooter">
    <a :href="'/help/' + prevPage.subpage" class="prevPage" v-if="prevPage">
      <i class="icon-arrow-left2"></i>
      {{ prevPage.title }}
    </a>
    <a :href="'/help/' + nextPage.subpage" class="nextPage" v-if="nextPage">
      {{ nextPage.title }}
      <i class="icon-arrow-right2"></i>
    </a>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: ['pages', 'helppage'],
  computed: {
    currentIndex() {
      const index = _.findIndex(this.pages, page => page.subpage === this.helppage);
      return index < 0 ? 0 : index;
    },
    prevPage() {
      return this.pages[this.currentIndex - 1];
    },
    nextPage() {
      return this.pages[this.currentIndex + 1];
    },
  },
};
</script>
<style lang="scss">
.HelpCenterFooter {
  display: flex;
  padding: 0 40px;
  .nextPage {
    margin-left: auto;
  }
  a {
    display: block;
    font-size: 14px;
    i {
      font-size: 12px;
    }
    line-height: 1.8;

    color: $brand-accent-color;
  }
}
</style>
