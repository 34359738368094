import { App, Util } from '@';
import { nextTick } from '@/utils';
import Keys from '@/common/keys';

export default {
  props: {
    opt: null,
  },

  data() {
    return {
      items: this.opt.items,
      headerItems: this.opt.headerItems || [],
      footerItems: this.opt.footerItems || [],
      text: null,
      selectedItem: null,
      autoFocus: !Util.isTouchDevice(),
    };
  },
  methods: {
    url(item) {
      return this.opt.url && this.opt.url(item);
    },
    cssClasses(item) {
      return {
        selected: item === this.selectedItem,
        active: this.opt.isActive && this.opt.isActive(item),
      };
    },
    clickItem(item) {
      this.select(item);
    },
    input() {
      const text = this.text,
        opt = this.opt,
        re = new RegExp(text.trim(), 'i');

      const filterFN = item => item.text.search(re) != -1;

      if (text.length >= 1) {
        this.items = opt.items.filter(filterFN);
        if (opt.footerItems) {
          this.footerItems = opt.footerItems.filter(filterFN);
        }

        if (this.opt.createMenuItem) {
          this.items.pushFirst(this.opt.createMenuItem(text));
        }
      } else {
        this.items = this.opt.items;
      }

      nextTick(() => {
        this.$el.triggerCustomEvent('height-change');
      });
    },

    select(item) {
      const { opt } = this;
      if (item.isModel && opt.select) {
        opt.select(item);
      } else if (item.select) {
        item.select(this.text);
      } else if (item.url) {
        App.router.navigate(item.url);
      } else {
        console.warn('Could not find proper select action');
      }
      if (opt.closeOnSelect !== false) {
        this.$emit('close');
      } else {
        this.$refs.input.focus();
      }
    },

    keydown(ev) {
      switch (ev.keyCode) {
        case Keys.DOWN:
        case Keys.TAB:
          this.markSelected(1);
          ev.stop();
          break;
        case Keys.UP:
          this.markSelected(-1);
          ev.stop();
          break;
        case Keys.ESCAPE:
          this.$emit('close');
          ev.stop();
          break;
        case Keys.ENTER:
          this.selectedItem && this.select(this.selectedItem);
          ev.stop();
      }
    },
    markSelected(step) {
      const { items, footerItems, headerItems } = this;
      const visibleItems = [...headerItems, ...items, ...footerItems];
      let index = visibleItems.findIndex(item => item === this.selectedItem),
        newIndex;

      if (index < 0) {
        newIndex = step === 1 ? 0 : visibleItems.length - 1;
      } else {
        newIndex = (index + visibleItems.length + step) % visibleItems.length;
      }

      this.selectedItem = visibleItems[newIndex];
    },
  },
};
