<template>
  <div class="HelpCenterMenu">
    <a
      :href="'/help/' + page.subpage"
      v-for="page in pages"
      :key="page.subpage"
      :class="{ active: helppage === page.subpage }"
      >{{ page.title }}</a
    >
  </div>
</template>
<script>
export default {
  props: ['pages', 'helppage'],
};
</script>
<style lang="scss">
.HelpCenterMenu-section {
  height: 0;
  position: relative;
  margin: 0;

  @media (max-width: 1400px) {
    display: none;
  }
}
.HelpCenterMenu {
  position: absolute;
  left: -180px;
  padding-top: 80px;

  a {
    display: block;
    font-size: 14px;
    font-weight: 300 !important;
    line-height: 1.8;

    &.active {
      color: $brand-accent-color;
    }
  }
}
</style>
