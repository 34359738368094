import { App } from '@';
import _ from 'lodash';
export default {
  props: ['pageKey'],
  computed: {
    isPageActive() {
      const activePage = App.vm.activePage;
      return activePage && activePage.key === this.pageKey;
    },
  },
};
