<template>
  <section>
    <h1>Github Integration</h1>
    <p>
      By connecting to a Github Project, you can link commits, branches and pull requests to work
      items in Agiloo.
    </p>
    <p>To Connect a Github project, use <i>Connect to Github</i> in Project Settings.</p>
    <img src="/static/images/help/addgithub.png" />
    <p>
      You will be redirected to Github and asked to authorize Agiloo to connect to your Github
      account. You then pick which Github project you want connected to your Agiloo project.
    </p>
    <p>
      To link a commit to a work item, enter the work item key as part of the commit message, or as
      part of the name of the branch / pull request.
    </p>
    <img src="/static/images/help/github-key.png" />
    <img src="/static/images/help/github-commitmessage.png" />
    <p>
      Commits, branches and pull request will now be visible in the item view.
    </p>
    <img src="/static/images/help/github-viewitem.png" />
  </section>
</template>
<script>
export default {};
</script>
