import { cleanupLiveRemove } from '@/common/disable-live-remove';
import { disableLiveRemove } from './dragUtils';
import { initContainers, isCloneInsideContainer } from './containerUtils';
import initScrollHandler from './scrollHandler';
import initContainerHandler from './containerHandler';
import { dragWithinCurrentContainer } from './dragWithinContainer';

import _ from 'lodash';
import { App } from '@';
import { reactive } from 'vue';
import { isTouchStart, touchEventWrapper, CloneStyle, getTotalChange } from './dragUtils';

export default class DragSession {
  constructor({ ev, dragItem, cloneHandler }) {
    ev.stopPropagation();

    const isTouch = isTouchStart(ev);
    const startEv = isTouch ? touchEventWrapper(ev) : ev;

    _.assign(this, {
      destroyCalls: [],
      dragItem,
      dragModel: dragItem.getModel(),
      startEv,
      lastMoveEv: startEv,
      isTouch,
      cloneStyle: reactive(new CloneStyle()),
      cloneHandler,
      direction: {},

      containers: initContainers(dragItem),
    });

    this.on(dragItem.$el, 'click', ev => {
      if (this.started || this.hasBeenDragged) {
        ev.stopPropagation();
        ev.preventDefault();
      }
    });

    this.timer = setTimeout(
      () => {
        if (!this.hasBeenDragged) {
          this.doStart();
        }
      },
      isTouch ? 500 : 300
    );

    this.setWindowEvents();
  }

  doStart() {
    const { dragItem } = this;
    const useContainers = dragItem.wrapper != null;
    _.assign(this, {
      scrollHandler: initScrollHandler(
        dragItem,
        () => {
          this.moveDragItem();
        },
        dragItem.$el.closest('.Board') != null
      ),
      containerHandler: useContainers && initContainerHandler(this),
    });

    this.started = true;
    this.initClone();

    dragItem.setDragStatus('dragTarget', true);

    disableLiveRemove(dragItem);
    App.scrolling.disable();
  }

  initClone() {
    const { dragItem } = this;
    var dragitemOffset = dragItem.$el.boxOffset();

    this.cloneHandler.start(dragItem);
    this.cloneStyle.setStart(dragitemOffset.left, dragitemOffset.top);

    this.cloneStyle.setWidth(dragItem.$el.offsetWidth);
  }

  onMove(ev) {
    if (this.finished) {
      return;
    }
    if (this.started) {
      this.doMove(ev);
      ev.stopPropagation();
      ev.preventDefault();
      return false;
    }

    if (this.isTouch && getTotalChange(ev, this.startEv) > 10) {
      this.hasBeenDragged = true;
    }
    return true;
  }

  doMove(ev) {
    const { startEv, cloneStyle, direction } = this;
    this.setDirection(ev);
    cloneStyle.setTranslate3d(ev.pageX - startEv.pageX, ev.pageY - startEv.pageY);
    this.cloneEl = this.cloneHandler.getClone();
    this.scrollHandler.onMove(ev, direction);
    this.moveDragItem();
  }

  moveDragItem() {
    const { dropTargetHandler, containerHandler, cloneEl, containers } = this;
    dropTargetHandler && dropTargetHandler.clearHoldTimer();
    const cloneOffset = cloneEl.boxOffset();

    if (isCloneInsideContainer(cloneOffset, containers.current.$el)) {
      dropTargetHandler && dropTargetHandler.clear();
      dragWithinCurrentContainer(this);
    } else {
      containerHandler && containerHandler.dragToNewContainer();
    }
  }

  setDirection(ev) {
    const { lastMoveEv, direction } = this;

    if (ev.pageY != lastMoveEv.pageY) {
      direction.up = ev.pageY < lastMoveEv.pageY;
    }
    if (ev.pageX != lastMoveEv.pageX) {
      direction.left = ev.pageX < lastMoveEv.pageX;
    }
    this.lastMoveEv = ev;
  }

  onEnd(ev) {
    this.finished = true;
    if (this.started) {
      this.doEnd(ev);

      ev.stopPropagation();
      ev.preventDefault();
    } else {
      clearTimeout(this.timer);
    }
    App.scrolling.enable();
    setTimeout(() => {
      this.destroy();
    }, 10);
  }

  doEnd() {
    this.scrollHandler.onEnd();
    if (this.containerHandler) {
      this.containerHandler.onEnd();
    } else {
      this.dragModel.save();
      this.dragItem.clearDragStatus();
    }

    this.cloneHandler.stop();
    cleanupLiveRemove();
  }

  setWindowEvents() {
    this.on(window, 'mousemove', ev => {
      this.onMove(ev);
      return false;
    });
    this.on(document.body, 'touchmove', ev => {
      return this.onMove(touchEventWrapper(ev));
    });
    this.on(window, 'dragstart', ev => {
      ev.stopPropagation();
      ev.preventDefault();
    });
    this.on(window, 'mouseup', ev => {
      this.onEnd(ev);
    });
    this.on(document.body, 'touchend', ev => {
      this.onEnd(touchEventWrapper(ev));
      return true;
    });
  }

  on(target, ev, handler) {
    target.addEventListener(ev, handler);
    this.destroyCalls.push(() => {
      target.removeEventListener(ev, handler);
    });
  }

  destroy() {
    _.each(this.destroyCalls, c => {
      c();
    });
  }
}
