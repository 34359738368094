import EventEmitter from 'events';

export default {
  eventHandler() {
    if (this._eventHandler == null) {
      this._eventHandler = new EventEmitter();
    }
    return this._eventHandler;
  },

  emit(eventName, data) {
    this.eventHandler().emit(eventName, data);
  },

  on(eventName, cb) {
    this.eventHandler().on(eventName, cb);
  },
  off(eventName, cb) {
    this.eventHandler().removeListener(eventName, cb);
  },
};
