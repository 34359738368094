<template>
  <div class="page-content viewtag wide">
    <section class="flex">
      <a class="tag big" :tag-id="tag.id" @click="editTag">
        {{ tag.title }}
      </a>
    </section>
    <section class="item-collection full-width" active-view="list">
      <div class="mw-wide item-collection-settings-section">
        <item-collection-settings
          :collectionSettings="collectionSettings"
        ></item-collection-settings>
      </div>
      <IssueSections :collectionSettings="collectionSettings" ref="issueSections"></IssueSections>
    </section>

    <section v-if="showArchivedLinks">
      <div class="archived-links">
        <a-btn :href="projectUrl('?archived=true')" color="primary" flat>View archived Items</a-btn>
        <a-btn :href="projectUrl('?archived=all')" color="primary">View all Items</a-btn>
      </div>
    </section>
    <teleport to="#navbar" v-if="isPageActive">
      <q-btn @click="createNew" rounded>
        <i class="icon-plus3"></i>
        <span>New Item</span>
      </q-btn>
      <q-btn @click="editTag" rounded>
        <i class="icon-pen6"></i>
        <span>Edit Tag</span>
      </q-btn>
    </teleport>
  </div>
</template>
<script>
import { App, Model, Page } from '@/namespace';
import { pageMixin, eventMixin } from '@/common';
import { CreateIssue } from '@/pages/modals';
import createTopWrapper from '@/models/issuewrapper/createTopWrapper';
import EditTag from '@/pages/edittag/EditTag.vue';
export default {
  menu: 'items',
  props: ['project', 'archived', 'tag'],
  route: '/:projectId/tag/:tagId',
  mixins: [eventMixin, pageMixin],
  data: function () {
    var collectionSettings = {
      filterQuery: null,
      statusStandardRule: null,
      includeArchived: false,
      issue: this.project.issue,
      tag: this.tag,
    };
    if (this.archived === 'true') {
      collectionSettings.statusStandardRule = Model.Status.getByKey(this.project.id, 'archived');
    }
    if (this.archived === 'all') {
      collectionSettings.includeArchived = true;
    }
    return { collectionSettings };
  },
  getData(props) {
    let resolveProject = Model.Project.find(props.projectId);
    return {
      tag: resolveProject.then(project => {
        App.vm.currentProject = project;
        return Model.Tag.get(props.tagId);
      }),
      project: resolveProject,
    };
  },
  created() {
    App.vm.currentProject = this.project;
  },
  computed: {
    showArchivedLinks() {
      console.log('showArchivedLinks');
      return this.totalWrapper.statusMap.containsKey('archived') && !this.archived;
    },
    totalWrapper() {
      var wrapper = createTopWrapper(
        this.project.issue,
        {
          tag: this.tag,
          includeArchived: true,
        },
        { force: true }
      ).bindChangeEvent(this);

      return wrapper;
    },
  },

  methods: {
    save() {
      this.project.save();
    },

    projectUrl(appender) {
      var url = this.tag.url + appender;
      return url;
    },

    createNew() {
      const attributes = {
        parent: this.project.issue,
        sprintId: 'backlog',
        tags: [this.tag],
      };

      App.openPopup(CreateIssue, {
        attributes,
        title: 'Create Item',
      });
    },
    editTag() {
      App.openPopup(EditTag, { tag: this.tag });
    },
  },
};
</script>
