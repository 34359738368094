<template>
  <div class="page-content CreateProject">
    <section>
      <text-input
        v-model="project.title"
        class="bordered main-title"
        v-focus
        placeholder="Project Title"
      ></text-input>

      <SelectProjectType v-model="project.type" />
    </section>
    <footer>
      <a-btn color="primary" @click="save">Save</a-btn>
    </footer>
  </div>
</template>
<script>
import { Model, Page } from '@/namespace';

import SelectProjectType from '@/pages/EditProjectTypeModal/SelectProjectType.vue';

export default {
  smallModal: true,

  props: ['onCreate'],
  components: { SelectProjectType },
  data: function () {
    return { project: Model.Project.createNew() };
  },
  methods: {
    save: function () {
      return this.project.save().then(() => {
        this.onCreate && this.onCreate(this.project);
        this.$emit('close');
      });
    },
    cancel(ev) {
      ev.preventDefault();
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.CreateProject {
  .input.main-title {
    padding: 10px 0;
  }
}
</style>
