<template>
  <div class="page-content medium">
    <section>
      <h1>Test Page</h1>
      <div v-for="todo in project.todos" :key="todo.name">
        <div>{{ todo.name }}</div>
        <div>
          children: <span v-for="child in todo.children" :key="child.id">{{ child.name }}</span>
        </div>
      </div>
      <button @click="change">CHANGE</button>
    </section>
  </div>
</template>
<script>
import { reactive } from 'vue';
class Todo {
  name;
  id;
  constructor(name) {
    this.name = name;
    this.id = name;
  }
}

const newTodo = name => reactive(new Todo(name));
const project = {
  todos: [
    { name: '1', id: '1', children: [newTodo('4'), newTodo('5')] },
    { name: '2', id: '2', children: [] },
  ],
};

export default {
  components: {},
  route: '/testpage',
  props: [],
  data() {
    return {
      project,
    };
  },
  mounted() {},

  methods: {
    change() {
      console.log(this.project.todos);
      project.todos[0].children[0].name = 'X3';
    },
  },
};
</script>
