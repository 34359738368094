import { Util } from '@/namespace';
export default function(el) {
  if (Util.isTouchDevice()) {
    const scroller = el.closest('.page-content');
    const offset = getOffset(el);
    const fromBottom = scroller.height() - offset.top;

    const THRESHOLD = 300;
    if (fromBottom < THRESHOLD) {
      scroller.scrollTop = scroller.scrollTop + THRESHOLD - fromBottom;
    }
    console.log(`focus touch: fromBottom: ${fromBottom} scrollTop: ${scroller.scrollTop}`);
  }
}

function getOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}
