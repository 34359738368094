<template>
  <div class="TipTapEditor" @keydown="keydown" v-click-outside="clickOutside">
    <TipTapEditorMenu
      :editor="editor"
      @openBubble="openBubble"
      :active="active"
      v-if="showMenu && editor"
    />

    <editor-content :editor="editor" :spellcheck="active" />
    <footer v-if="showFooter && active">
      <q-btn @click="cancel" flat>Cancel</q-btn>
      <q-btn color="primary" @click="save">Save</q-btn>
    </footer>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';

import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import PasteAndDropImage from './PasteAndDropImage';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import './editor-content.scss';
import TipTapEditorMenu from './TipTapEditorMenu.vue';
import Placeholder from '@tiptap/extension-placeholder';
import mention from './mention/mention';
import * as _ from 'lodash';
//import Bubble from './bubble/Bubble.vue';

export default {
  components: {
    EditorContent,
    TipTapEditorMenu,
    //Bubble,
  },

  props: {
    modelValue: {
      type: String,
    },
    parentModel: null,
    showMenu: {
      default: true,
    },
    showFooter: {
      default: false,
    },
    placeholder: {
      default: 'Add description',
    },
    editable: {
      default: true,
    },
    autofocus: {
      default: false,
    },
  },

  data() {
    return {
      editor: null,
      active: false,
    };
  },

  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }
      this.editor.commands.setContent(this.modelValue, false);
    },
    editable: function (editable) {
      this.editor.setOptions({
        editable,
      });
      this.editor.focus();
    },
  },

  mounted() {
    const component = this;
    this.editor = new Editor({
      content: this.modelValue,
      editable: this.editable,
      autofocus: this.autofocus,
      extensions: [
        StarterKit,
        Table,
        TableRow,
        TableCell,
        TableHeader,
        Link,
        Image,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        PasteAndDropImage.configure({
          parentModel: this.parentModel,
        }),

        // BubbleMenu.configure({
        //   element: document.querySelector('.bubble-menu'),
        //   shouldShow: ({ editor, view, state, oldState, from, to }) => {
        //     // only show the bubble menu for images and links
        //     console.log('show show ' + link);
        //     const link = editor.isActive('link');

        //     return editor.isActive('link');
        //   },
        // }),
        mention,
      ],

      onUpdate: () => {
        console.log('update');
      },
      onFocus: () => {
        if (!this.editable) {
          return;
        }
        component.active = true;
      },
      onBlur: () => {
        console.log('onBlur');
        setTimeout(() => {
          if (this.editor.isFocused) {
            console.log('editor is still focused');
            //component.save();
          }
        }, 3000);
      },
    });
    this.editor.executeRun = action => {
      this.editor.chain().focus()[action].call().run();
    };
    this.editor.focus = action => {
      this.editor.chain().focus().run();
    };
  },

  beforeUnmount() {
    this.editor.destroy();
  },

  methods: {
    focus() {
      this.editor.focus();
    },
    keydown(ev) {
      if ((ev.ctrlKey || ev.metaKey) && ev.which === 83) {
        this.save();
        ev.preventDefault();
      }
    },
    save() {
      const newValue = this.editor.getHTML();
      const oldValue = this.modelValue;
      const unchanged = newValue === oldValue || (_.isEmpty(oldValue) && newValue === '<p></p>');
      if (!unchanged) {
        this.$emit('update:modelValue', newValue);
        this.$emit('save');
      }
      this.editor.chain().blur().run();
      this.active = false;
    },
    cancel() {
      this.editor.commands.setContent(this.modelValue, false);
      this.$emit('cancel');
      this.active = false;
    },
    clickOutside() {
      if (this.showFooter) {
        return;
      }
      const editor = this.editor;
      if (!editor.isFocused) {
        this.save();
      }
    },
    openBubble() {},
  },
};
</script>
<style lang="scss">
.TipTapEditor {
  position: relative;

  footer {
    display: flex;
    justify-content: flex-end;
    button + button {
      margin-left: 10px;
    }
  }
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
  }
}
</style>
