import { createApp } from 'vue';

//import "./registerServiceWorker";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import VueGtag from 'vue-gtag-next';
import VTooltip from 'v-tooltip';
import './css/base.scss';
import './css/components.scss';
import './css/pages.scss';
import MainApp from './MainApp.vue';
import initRecaptcha from './recaptcha';
import { initGlobalComponents } from '@/components';

const app = createApp(MainApp);

app.use(VueGtag, {
  property: {
    id: 'UA-119197943-1',
    params: {
      send_page_view: false,
      linker: {
        domain: ['agiloo.com'],
      },
    },
  },
});
initGlobalComponents(app);
initRecaptcha(app);

app
  .use(Quasar, quasarUserOptions)
  .use(VTooltip, {
    defaultDelay: { show: 500, hide: 0 },
    defaultOffset: 5,
  })
  .mount('#app');
