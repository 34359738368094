<template>
  <FilterableMenu :opt="opt" @close="close"> </FilterableMenu>
</template>

<script>
import { App } from '@/namespace';
import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
import { pickUploadFile } from '@/apphandlers/filehandler/filehandler';

export default {
  props: ['document'],
  data() {
    return {
      opt: {
        items: [
          {
            text: 'Add Attachment',
            icon: 'icon-cloud-upload',
            select: () => pickUploadFile(this.document),
          },
        ],
        footerItems: [
          {
            text: 'Delete',
            icon: 'icon-bin',
            select: () => {
              const { document } = this;
              App.openConfirmDialog({
                title: 'Delete Document',
                text: 'This will delete the document',
                onConfirm() {
                  document.destroy().then(() => {
                    App.router.navigateBack();
                  });
                },
              });
            },
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
