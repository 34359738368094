<template>
  <div v-if="profile.authenticated" class="Navbar standard">
    <div class="left">
      <q-btn
        id="navigate-back"
        round
        v-bind:class="{ disabled: !vm.showBackLink }"
        v-if="!vm.hideTopbar"
        @click="navigateBack"
        size="small"
      >
        <i class="icon-arrow-left3"></i>
      </q-btn>
    </div>
    <div class="center" v-if="!vm.hideTopbar">
      <div class="page-options-bar" id="navbar" />

      <MainSearch class="search" />
    </div>
    <div class="right">
      <q-btn class="app-notifications" @click="showNotifications" flat round>
        <div class="count" :count="notifications.unseenCount" v-if="notifications"></div>
        <i class="icon-bell2"></i>
      </q-btn>
      <q-btn id="mobile-menu" @click="openProfileMenu" round>
        <i class="icon-menu7"></i>
      </q-btn>
      <a class="button profile" @click.prevent="openProfileMenu">{{ profile.initials }}</a>
    </div>
  </div>
  <div v-else class="Navbar logged-out">
    <div class="left">
      <q-btn
        id="navigate-back"
        class="mobile-visible"
        round
        v-bind:class="{ disabled: !vm.showBackLink }"
        type="secondary"
        @click="navigateBack"
      >
        <i class="icon-arrow-left3"></i>
      </q-btn>
    </div>
    <div class="center"></div>
    <div class="right">
      <a-btn color="primary" href="/login" class="login" :unelevated="false">Login</a-btn>
      <a-btn color="primary" href="/register" class="register" :unelevated="false">Register</a-btn>
    </div>
  </div>
</template>
<script>
import { App, Vue } from '@';
import MainSearch from '@/components/mainsearch/MainSearch.vue';
import ProfileMenu from '@/pages/MobileMenu/MobileMenu.vue';

import ViewNotifications from '@/pages/viewnotifications/ViewNotifications.vue';
import './Navbar.scss';
import store from '@/store';
export default {
  components: {
    MainSearch,
  },
  methods: {
    openSelector(ev) {
      App.openSelector({
        property: ev.currentTarget.getAttribute('selector'),
        targetElement: ev.target,
      });
    },
    navigateBack(ev) {
      ev.preventDefault();
      window.history.back();
    },
    openProfileMenu(ev) {
      App.openPopover(
        ProfileMenu,
        {},
        {
          type: 'menu',
          alignToTop: this.$el,
          target: ev.target,
        }
      );
    },
    showNotifications(ev) {
      App.openPopover(
        ViewNotifications,
        {},
        {
          type: 'menu',
          alignToTop: this.$el,
          target: ev.target,
        }
      );
    },
  },

  computed: {
    currentProject() {
      return App.vm.currentProject;
    },
    profile() {
      return App.vm.profile;
    },
    vm() {
      return App.vm;
    },
    notifications() {
      return store.notifications;
    },
  },
};
</script>
