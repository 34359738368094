import _ from 'lodash';
import getNewPriority from './getNewPriority';
export function setNewPriority(issue, sprint, status, newPriority) {
  issue.set('priorityMap', getNewPriorityMap(issue, sprint, status, newPriority));
}

export function nextHigherPriority(list) {
  var priority;
  _.each(list, function(issue) {
    _.each(issue.priorityMap, function(p) {
      if (priority == null) {
        priority = p;
      }
      if (p > priority) {
        priority = p;
      }
    });
  });
  if (priority == null) {
    return 0;
  }
  return priority + 100;
}

export function nextLowerPriority(list) {
  var priority;
  _.each(list, function(issue) {
    _.each(issue.priorityMap, function(p) {
      if (priority == null) {
        priority = p;
      }
      if (p < priority) {
        priority = p;
      }
    });
  });
  if (priority == null) {
    return 0;
  }
  return priority - 100;
}

export function initNewPriority(issue, sprint, direction = true) {
  var siblings = issuesWithSprint(issue.parent.issues, sprint);
  var newPriority = direction ? nextHigherPriority(siblings) : nextLowerPriority(siblings);
  issue.set('priorityMap', getNewPriorityMap(issue, sprint, null, newPriority));
}

function issuesWithSprint(issues, sprint) {
  return issues.filter(is => is.sprintMap.containsValue(sprint));
}

export function buildPriorityKeys(wrapperContext) {
  var keys = [],
    status = wrapperContext.status,
    sprint = wrapperContext.sprint;

  if (status != null && !status.key !== 'todo') {
    if (sprint != null && !sprint.backlog) {
      keys.push(sprint.numeral + ':' + status.numeral);
    }
    keys.push(':' + status.numeral);
  }
  if (sprint != null && !sprint.backlog) {
    keys.push('' + sprint.numeral);
  }

  keys.push('_');
  return keys;
}

function getNewPriorityMap(issue, sprint, status, newPriority) {
  var key = '',
    priorityMap = _.assign({}, issue.priorityMap);
  if (status != null && status.key !== 'todo') {
    key = ':' + status.numeral;
  }
  if (sprint != null && !sprint.backlog) {
    if (issue.sprintMap.length() > 1 || priorityMap[sprint.numeral + key] != null) {
      key = sprint.numeral + key;
    }
  }
  if (_.isEmpty(key)) {
    key = '_';
  }

  priorityMap[key] = newPriority;

  return priorityMap;
}

export function cleanupPriorityMap(issue) {
  var sprints = issue.sprintMap.toArray(),
    statuses = issue.statusMap.toArray(),
    priorityMap = _.assign({}, issue.priorityMap);

  _.each(_.keys(issue.priorityMap), function(key) {
    if (key === '_') {
      return;
    }
    var parts = key.split(':'),
      sprintPart = parts[0],
      statusPart = parts[1];
    if (!_.isEmpty(sprintPart)) {
      if (!hasNumeral(sprints, sprintPart)) {
        delete priorityMap[key];
      }
    }
    if (!_.isEmpty(statusPart)) {
      if (statusPart === '1') {
        if (!hasNumeral(statuses, statusPart) && issue.statusMap.getProgress() !== 'mixed') {
          delete priorityMap[key];
        }
      } else if (!hasNumeral(statuses, statusPart)) {
        delete priorityMap[key];
      }
    }
  });
  issue.set('priorityMap', priorityMap);
}

export { getNewPriority };

function hasNumeral(models, numeralStr) {
  const numeral = parseInt(numeralStr);
  return _.some(models, function(m) {
    return m.numeral === numeral;
  });
}
