import StatusMap from '@/models/propertymap/StatusMap';
import MemberMap from '@/models/propertymap/MemberMap';
import SprintMap from '@/models/propertymap/SprintMap';
import { buildTree } from './changeEventHandler';
import { _, Model } from '@/index';
function doCalculateProperty(issue, MAP, ID, PropertyMap) {
  var valueId = issue.closestProperty(ID),
    newKeys = {};

  if (ID === 'statusId' && issue[ID]) {
    // Alternative solution: to not do it on sprint and member could be changed. If the parent sprintId is changed when the last child of the original sprint is changed.
    newKeys[issue[ID]] = true;
  }

  if (issue.issues.length == 0) {
    newKeys[valueId] = true;
  } else {
    _.each(issue.issues, function(is) {
      _.assign(newKeys, is[MAP].getKeys());
    });
  }

  var existing = issue[MAP];
  if (!existing) {
    issue.set(MAP, PropertyMap.make(newKeys, issue.projectId));
    return false;
  }
  if (existing.isEqualKeys(newKeys)) {
    return false;
  }
  issue.set(MAP, PropertyMap.make(newKeys, issue.projectId));
  return true;
}

const definitions = {
  status: {
    ID: 'statusId',
    MAP: 'statusMap',
    PropertyMap: StatusMap,
  },
  member: {
    ID: 'memberId',
    MAP: 'memberMap',
    PropertyMap: MemberMap,
  },
  sprint: {
    ID: 'sprintId',
    MAP: 'sprintMap',
    PropertyMap: SprintMap,
  },
};

function calcProp(property) {
  const { ID, MAP, PropertyMap } = definitions[property];
  return function(issue) {
    return doCalculateProperty(issue, MAP, ID, PropertyMap);
  };
}

const calculateStatus = calcProp('status');
const calculateMember = calcProp('member');
const calculateSprint = calcProp('sprint');

function calculateEstimate(issue) {
  var est = issue.estimate || 0,
    subs = _.sumBy(issue.issues, is => is.totalEstimate);
  if (subs > est) {
    est = subs;
  }
  issue.totalEstimate = est;
}

export function initialCalculation(issue, changeMap) {
  _.each(issue.issues, is => {
    initialCalculation(is, changeMap);
  });

  var changed = calculateStatus(issue);
  changed = calculateMember(issue) || changed;
  changed = calculateSprint(issue) || changed;

  if (changed) {
    changeMap[issue.id] = true;
  }

  calculateEstimate(issue);
}

function calculateUp(issue) {
  if (issue == null) {
    return;
  }
  calculateStatus(issue);
  calculateMember(issue);
  calculateSprint(issue);
  calculateEstimate(issue);
  calculateUp(issue.parent);
}

function calculateForChangedParent(issue) {
  var backup = issue.getBackup(),
    oldParent,
    newParent = issue.parent;
  if (backup == null || backup.parentId === issue.parent.id) {
    return;
  }
  oldParent = Model.Issue.store.get(backup.parentId);

  if (oldParent == null) {
    return;
  }

  oldParent.issues.remove(issue);
  calculateUp(oldParent);

  newParent.issues.add(issue);

  triggerChanges(issue, {}, { parent: oldParent });
}

function triggerChanges(topIssue, changeMap, opt) {
  const tree = buildTree(topIssue, changeMap, opt);
  tree.emitChange();
}

export function calculateAndTriggerChanged(issue, opt = {}) {
  calculateForChangedParent(issue);
  var changeMap = {};
  changeMap[issue.id] = issue;

  initialCalculation(issue, changeMap);
  calculateUp(issue.parent);
  triggerChanges(issue, changeMap, opt);
}

export function calculateProperty(issue, property) {
  return calcProp(property)(issue);
}
