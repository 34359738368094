<template>
  <div
    class="BoardColumn issue-wrapper DragContainer top"
    :status="wrapper.wrapperContext.status.key"
    :class="cssClass"
  >
    <header>
      <span class="status-icon" :status-id="wrapper.wrapperContext.status.id">
        <i class="icon-"></i>
      </span>
      <span class="text">{{ wrapper.wrapperContext.status.name }}</span>
    </header>

    <template v-for="child in wrapper.children">
      <BoardCard
        v-if="child.issue"
        :wrapper="child"
        :key="child.id"
        :ref="child.id"
        :class="{ 'no-0': wrapper.issues.length === 0, target: child.dragTarget }"
        @createSiblingIssue="createSiblingIssue"
      ></BoardCard>
      <CreateIssueCard v-else :parentWrapper="wrapper"></CreateIssueCard>
    </template>

    <a-btn v-if="isDone" class="board-column-button" @click="archiveItems">
      Archive Items {{ wrapper.wrapperContext.status.name }}
    </a-btn>
    <a-btn v-if="isTodo" class="board-column-button" @click="openCreateIssue">
      <i class="icon-plus3"></i>
      <span>New Item</span>
    </a-btn>
  </div>
</template>
<script src="./BoardColumn.js"></script>
<style lang="scss">
.BoardColumn {
  flex: 1;
  transition: background-color 0.2s;
  height: auto;
  padding-bottom: 40px;
  max-width: 410px;
  min-width: 300px;

  > header {
    padding: 10px 10px 0;

    display: flex;
    align-items: center;

    .status-icon {
      margin-right: 5px;
    }

    .text {
      //color: var(--gray-text);
      font-size: 16px;
    }
  }

  .board-column-button {
    margin: 15px auto 0;
    display: block;
  }

  .BoardCard.main {
    margin: 10px 10px;
  }

  &:first-child {
    margin-left: 5px;
  }
}

.BoardColumn + .BoardColumn {
  border-left: 1px solid #e8e8e8;
}
</style>
