<template>
  <div>
    <div class="page-content CreateIssue with-footer">
      <section class>
        <div class="top">
          <a selector="issueType" class="nosave issueType" @click="openSelector">
            <div class="issue-type standard" :type="issueType">
              <div class="inner"></div>
            </div>
          </a>
          <span class="parent-label">Parent:</span>
          <IssueParentPath :issue="issue" @click.prevent="changeParent" :includeProject="true" />
        </div>

        <text-input
          v-model="issue.title"
          v-focus
          class="main-title"
          placeholder="Title"
        ></text-input>

        <TipTapEditor v-model="issue.description" :issue="issue" :hideable="true" />

        <div class="ItemContext">
          <a class="selector status nosave" selector="status" @click="openSelector">
            <div class="status-icon large" :status-id="issue.statusMap.calculatedValue().id">
              <i class="icon-"></i>
            </div>
            <div class="name">{{ issue.statusMap.calculatedValue().name }}</div>
          </a>

          <a class="selector member nosave" href="#" selector="member" @click="openSelector">
            <div class="member-icon large" :member-id="issue.memberMap.firstValue().id">
              <i class="icon-"></i>
            </div>

            <div class="name">{{ issue.memberMap.firstValue().displayName }}</div>
          </a>

          <a class="selector sprint nosave" selector="sprint" @click="openSelector">
            <svg-icon icon="board" class="board-icon" />
            <div class="name">{{ issue.sprintMap.combinedTitle() }}</div>
          </a>

          <div class="estimate-box">
            <div class="main-estimate">
              <label for="estimate-input">Estimate</label>
              <input
                ref="estimateInput"
                type="text"
                class="input estimate"
                id="estimate-input"
                v-model.number="issue.estimate"
              />
            </div>
          </div>
        </div>

        <div class="tags" style>
          <a class="tag" v-for="tag in issue.tags" :tag-id="tag.id">{{ tag.title }}</a>
        </div>
        <q-btn flat rounded color="accent" @click="addTag">
          <i class="icon-plus3"></i>
          <span>Add Tag</span>
        </q-btn>
      </section>
    </div>

    <footer class="space-between">
      <div class="create-more-container" @click="toggleCreateMore">
        <checkbox :selected="createOneMore"></checkbox>
        <span>Create one more</span>
      </div>

      <a-btn id="save" color="primary" @click="save">Save</a-btn>
    </footer>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';
import AddTag from '@/pages/addtag/AddTag.vue';
import { ChangeIssueParent } from '@/pages/modals';
import '@/pages/viewissue/IssueParentPath.vue';
export default {
  props: ['attributes'],

  data: function() {
    return { createOneMore: false, issue: Model.Issue.createNew(this.attributes) };
  },
  computed: {
    issueType() {
      return this.issue.issueType || 'item';
    },
  },
  methods: {
    openSelector: function(ev) {
      ev.preventDefault();
      App.openSelector({
        property: ev.currentTarget.getAttribute('selector'),
        issue: this.issue,
        targetElement: ev.currentTarget,
        noSave: true,
      });
    },
    save: function() {
      this.$el.find('.input').blur();

      var issue = this.issue;

      setTimeout(() => {
        issue.save().then(() => {
          if (this.createOneMore) {
            this.issue = Model.Issue.createNew({
              parent: issue.parent,
              issueType: issue.issueType,
              sprintId: issue.sprintId,
              tags: issue.tags,
              memberId: issue.memberId,
              statusId: issue.statusId,
            });
          } else {
            this.$emit('close');
          }
        });
      }, 300);
    },
    addTag(ev) {
      ev.preventDefault();

      App.openPopover(
        AddTag,
        { issue: this.issue, noSave: true },
        {
          type: 'menu',
          target: ev.currentTarget,
        }
      );
    },
    toggleCreateMore() {
      this.createOneMore = !this.createOneMore;
    },
    changeParent(ev) {
      ev.preventDefault();

      App.openPopover(
        ChangeIssueParent,
        {
          issue: this.issue,
          noSave: true,
        },
        {
          target: ev.currentTarget,
        }
      );
    },
  },
  components: {},
};
</script>
<style lang="scss" src="./CreateIssue.scss"></style>
