import { App, Model } from '@/namespace';

import _ from 'lodash';
import RULES from '@/models/issuewrapper/filterRules';
import ConfirmPropagateDownModal from '@/actions/saveProperty/ConfirmPropagateDownModal.vue';
Array.prototype.lastElement = function() {
  return this[this.length - 1];
};
Array.prototype.firstElement = function() {
  return this[0];
};

function withoutPropertyId(property, value) {
  return issue => {
    var propertyMap = issue.getPropertyMap(property);

    if (!propertyMap.contains(value)) {
      return true;
    }
    if (propertyMap.length() === 1) {
      return false;
    }
  };
}

const RULE_MAP = {
  status: ['lowerStatus', 'notEqualProperty'],
  sprint: ['unfinished', 'notEqualProperty'],
  member: ['unfinished', 'notEqualProperty'],
};

function getRules(property) {
  // should not use rule 'lowerStatus' when drag on status board
  return RULE_MAP[property];
}

function getStoredStatus(issue) {
  issue = issue.asIssue();
  return Model.Status.getByKey(issue.projectId, issue.closestProperty('statusId'));
}

export default function confirmPropagateDown(wrapper, property, value, opt) {
  var confirms = opt.confirms || {};

  if (opt.propagateDown === true) {
    return Promise.resolve(wrapper);
  }

  var propertyMap = wrapper.getPropertyMap(property);
  if (propertyMap.length() <= 1 && property !== 'status') {
    return Promise.resolve(wrapper);
  }

  var baseFilterRule = withoutPropertyId(property, value);

  if (property === 'status') {
    var currentStatus = wrapper.partial ? wrapper.statusMap.calcValue() : getStoredStatus(wrapper);
    if (value.priority > currentStatus.priority) {
      baseFilterRule = RULES.lowerStatus('status', value);
    }
  }
  var filteredWrapper = wrapper.copy().filterByRule(baseFilterRule);
  if (!filteredWrapper || filteredWrapper.issues.length === 0) {
    return Promise.resolve(false);
  }
  var ruleName, rule;
  _.each(getRules(property), _ruleName => {
    filteredWrapper = wrapper.copy().filterByRule(baseFilterRule);
    ruleName = _ruleName;
    rule = RULES[ruleName](property, value);
    filteredWrapper = filteredWrapper.filterByRule(rule);
    if (filteredWrapper) {
      return false;
    }
  });

  if (!filteredWrapper) {
    return Promise.resolve(false);
  }

  if (opt.confirmAll || confirms[ruleName] === true) {
    return Promise.resolve(filteredWrapper);
  }

  return openConfirmDialog().then(confirmed => (confirmed ? filteredWrapper : false));

  function openConfirmDialog() {
    return new Promise(function(resolve) {
      App.openPopover(
        ConfirmPropagateDownModal,
        {
          wrapper: filteredWrapper,
          property: property,
          value: value,
          ruleName: ruleName,
          resolve: resolve,
        },
        { type: 'confirmmodal' }
      );
    });
  }
}
