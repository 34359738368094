<template>
  <div class="CreateSprintPopover">
    <label>Edit Sprint</label>
    <text-input
      v-model="sprint.title"
      v-focus-last
      class="bordered"
      placeholder="Sprint Title"
      @save="save"
      @close="close"
    ></text-input>
    <footer>
      <q-btn color="primary" @click="save">Save</q-btn>
    </footer>
  </div>
</template>

<script>
export default {
  props: ['sprint'],

  methods: {
    save() {
      this.sprint.save();
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
