<template>
  <div class="page-content login-and-register flex Register">
    <AgilooLogo class="white" />
    <section class="center">
      <q-form ref="form" class="form large white" @submit="register" v-if="!finished">
        <q-input
          v-model="credentials.name"
          label="Name"
          dark
          :rules="rules.name"
          lazy-rules
          class="large"
        />
        <q-input
          v-model="credentials.email"
          label="Email"
          type="email"
          dark
          :rules="rules.email"
          lazy-rules
          class="large"
        />

        <q-input
          v-model="credentials.password"
          label="Password"
          type="password"
          :rules="rules.password"
          dark
          lazy-rules
          class="large"
        />

        <q-input
          v-model="credentials.confirmPassword"
          label="Confirm Password"
          type="password"
          :rules="rules.confirmPassword"
          dark
          lazy-rules
          class="large"
        />

        <div class="row">
          <div class="col col-md-auto">
            <q-checkbox v-model="termsAccepted" color="primary" dark />
          </div>

          <div class="terms-text col col-10">
            I agree to
            <a class="navigate-slide" href="/termsconditions">Terms and Conditions</a>,
            <a class="navigate-slide" href="/privacypolicy">Privacy Policy</a>
            and the use of
            <a href="/cookiepolicy" class="navigate-slide">cookies</a>
          </div>
        </div>

        <div v-if="error" class="error-box">{{ error }}</div>

        <a-btn size="large" color="primary" type="submit">Register</a-btn>
      </q-form>
      <div class="finished-overlay" v-else>
        Thank you for registering! An email has been sent to you with a link to confirm your email.
      </div>
    </section>

    <page-footer />
  </div>
</template>
<script>
import { App, Model } from '@';
import { requiredValue, requiredEmail } from '@/models/validation';
export default {
  route: '/register(/:inviteCode)',
  noAuthentication: true,

  props: ['inviteCredentials'],
  data: function () {
    return {
      finished: false,
      isLoading: false,
      termsAccepted: false,
      error: null,
      credentials: new Model.Credentials(this.inviteCredentials),
    };
  },
  getData: function (props) {
    return {
      inviteCredentials: props.inviteCode
        ? Model.Credentials.getInvite({ passwordResetToken: props.inviteCode })
        : {},
    };
  },
  computed: {
    rules() {
      return {
        name: [requiredValue('Name')],
        email: [requiredEmail()],
        password: [
          val => {
            return (
              (val != null && val.length >= 8) || 'The password must be at least 8 characters long'
            );
          },
        ],
        confirmPassword: [
          val => {
            return val === this.credentials.password || 'Confirm password does not mach password';
          },
        ],
      };
    },
  },

  methods: {
    async register() {
      if (this.isLoading) {
        return;
      }

      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }
      if (!this.termsAccepted) {
        this.error = 'Terms and conditions must be accepted';
        return;
      }
      this.isLoading = true;

      const recaptcha = await this.$recaptcha('register');

      return this.credentials
        .register(recaptcha)
        .then(profile => {
          if (profile.authenticated) {
            App.router.navigate('/projects', {});
          } else {
            this.finished = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.Register .termsAccepted {
  margin-top: 20px;
}

.Register {
  .terms-text {
    color: white;

    a {
      color: #e0e0e0;
      text-decoration: underline;
    }
  }
}
</style>
