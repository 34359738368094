<template>
  <FilterableMenu @close="close" :opt="opt" class="MobileMenu">
    <template slot="projectSelector">
      <q-btn class="projectSelector" color="primary" rounded unelevated>
        <span v-if="currentProject">{{ currentProject.title }}</span>
        <span v-else>Select Project</span>
      </q-btn>
    </template>
  </FilterableMenu>
</template>
<script>
import { App, Model, Util } from '@';

import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
import ProjectSelector from '@/components/selectors/ProjectSelector.vue';

export default {
  props: [],

  data: function() {
    const currentProject = App.vm.currentProject;
    const projectItems = () => {
      const projectSelectorLink = {
        slotName: 'projectSelector',
        select: () => App.openPopover(ProjectSelector),
      };
      if (!Util.isTabletOrSmaller()) {
        return [];
      }
      if (!currentProject) {
        return [projectSelectorLink];
      }
      return [
        projectSelectorLink,
        {
          text: 'Work Items',
          icon: 'icon-files-empty',
          url: currentProject.projectUrl('/project'),
        },
        {
          text: 'Board',
          icon: 'icon-spinner11',
          url: currentProject.projectUrl('/sprint/' + currentProject.sprints[0].id),
        },
        {
          text: 'Documents',
          icon: 'icon-file-text2',
          url: currentProject.projectUrl('/documents'),
        },
        {
          text: 'Team',
          icon: 'icon-users',
          url: currentProject.projectUrl('/members'),
        },
        {
          text: 'Project Settings',
          icon: 'icon-cog',
          url: currentProject.projectUrl('/projectsettings'),
        },
      ];
    };

    const menuItems = [
      {
        text: 'User Profile',
        url: '/usersettings',
      },
      {
        text: 'Premium Plan',
        url: '/premiumplan',
      },
      {
        text: 'About Agiloo',
        url: '/aboutagiloo',
      },
      {
        text: 'Help Center',
        url: '/help/getstarted',
      },
      {
        text: 'Feedback',
        url: '/feedback',
      },
    ];

    return {
      currentProject,
      opt: {
        items: [...projectItems(), ...menuItems],
        footerItems: [
          {
            text: 'Logout',
            icon: 'icon-exit3',
            select: () => {
              Model.Profile.logout().then(() => {
                App.router.navigate('/login', {});
              });
            },
          },
        ],
      },
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
<style lang="scss">
.MobileMenu {
  .projectSelector {
    width: 100%;
  }
}
</style>
