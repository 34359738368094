<template>
  <FilterableMenu @close="close" :opt="opt">
    <template v-slot:default="{ item }">
      <div class="issue-type standard" :type="item.id">
        <div class="inner"></div>
      </div>
    </template>
  </FilterableMenu>
</template>
<script>
import { Model } from '@';
import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';

export default {
  props: ['issue', 'noSave', 'issueTypes'],
  data: function() {
    return {
      opt: {
        isActive: type => type.id === this.issue.asIssue().issueType,
        items: this.issueTypes,
        select: type => {
          const issue = this.issue.asIssue();
          issue.set('issueType', type);
          if (!this.noSave) {
            issue.save();
          }
        },
      },
    };
  },
  getData() {
    return {
      issueTypes: Model.IssueType.findAll(),
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
