import _ from 'lodash';
import BaseModel from './BaseModel';
import Store from './Store';
import initModel from '@/common/model/initmodel';
import eventHandler from './eventHandler';
import { convertModelMap } from '@/common/model/convertmodels';
import { Util } from '@/namespace';
import { handleNewActivity } from '@/models/activityHandler';
class StoreModel extends BaseModel {
  defineModels() {
    return {};
  }

  serializedProperties() {
    return [];
  }

  backup() {
    this.constructor.store.setBackup(this);
  }
  getBackup() {
    return this.constructor.store.getBackup(this.id);
  }

  restore() {
    var backup = this.getBackup();
    this.setAttributes(backup);
    this.initModel();
  }

  set(name, value) {
    var d = this.constructor.define[name];
    if (d && d.set) {
      value = d.set.call(this, value);
    }
    this[name] = value;
  }

  initModel() {
    return initModel.call(this);
  }
  fullInitModel(attributes) {
    this.setAttributes(attributes);
    this.constructor.store.addReference(this);
    this.initModel();
    this.postInitModel && this.postInitModel();
    this.backup();
    return this;
  }

  async fill() {
    if (this.filled) {
      return Promise.resolve(this);
    }
    this.filled = true;
    const response = await this.constructor.fill(this.id);
    this.postFill(response);
    this.backup();
    return this;
  }

  serialize() {
    var result = {};
    _.forOwn(this.constructor.define, (attrDef, attrName) => {
      var value = this[attrName];

      if (attrDef == null) {
        result[attrName] = value;
        return;
      }

      if (attrDef.serialize === false) {
        return;
      }
      if (attrDef.serialize === undefined) {
        if (attrDef.model) {
          result[attrName] = toIds(value);
        } else {
          result[attrName] = value;
        }

        return;
      }

      result[attrName] = attrDef.serialize(value);
      return result;
    });
    return result;
  }

  serializedChanges() {
    var serialized = this.serialize();
    var backup = this.getBackup() || {};

    _.forOwn(serialized, function (val, a) {
      if (_.isEqual(backup[a], serialized[a])) {
        delete serialized[a];
      }
    });
    if (serialized.description != null) {
      console.log('desc changed');
    }
    return serialized;
  }

  isNew() {
    return this.id == null;
  }

  getKey() {
    return this.id;
  }

  save(opt = {}) {
    if (this.isNew()) {
      return this.constructor.create(this.serialize()).then(data => {
        if (data.originModel) {
          this.setAttributes(data.originModel);
        } else {
          this.setAttributes(data);
        }

        this.constructor.store.addReference(this);

        if (opt.postCreateHook) {
          opt.postCreateHook();
        }

        this.initModel();
        this.postInitModel && this.postInitModel();
        this.backup();
        if (data.models) {
          convertModelMap(data.models);
        }
        handleNewActivity(data);
      });
    }
    var changes = this.serializedChanges();

    if (_.isEmpty(changes)) {
      return Promise.resolve();
    }
    return this.constructor.update(this, changes).then(data => {
      data = data || {};
      if (data.models) {
        convertModelMap(data.models);
      }
      if (data.originModel) {
        _.assign(this, data.originModel);
      } else {
        _.assign(this, data);
      }
      console.log('save ' + this.constructor.NAME);

      this.initModel();
      this.postInitModel && this.postInitModel();
      this.backup();

      handleNewActivity(data);
    });
  }

  destroy() {
    console.log('destroy');
    return this.constructor.destroy(this).then(data => {
      this.deleted = true;
      this.constructor.store.remove(this.id);
      this.postDestroy && this.postDestroy();

      if (data.models) {
        convertModelMap(data.models);
      }
    });
  }

  static create(data) {
    return Util.jsonajax({
      url: '/' + this.NAME.toLowerCase(),
      data: data,
      method: 'POST',
    });
  }

  static update(model, data) {
    return Util.jsonajax({
      url: '/' + this.NAME.toLowerCase() + '/' + model.id,
      data: data,
      method: 'PUT',
    });
  }

  static destroy(model) {
    return Util.jsonajax({
      url: '/' + this.NAME.toLowerCase() + '/' + model.id,
      data: {
        id: model.id,
      },
      method: 'delete',
    });
  }

  static get(id) {
    return this.store.get(id);
  }

  static get store() {
    if (this._store == null) {
      this._store = new Store();
    }
    return this._store;
  }

  static clearCache() {
    return this.store.clear();
  }

  static model(data) {
    return this.createModel(data);
  }

  static createModel(data) {
    var model = this.store.get(data.id);
    if (model != null) {
      model.setAttributes(data);
    } else {
      model = new this(data);
      model = this.store.addReference(model);
    }

    return model;
  }

  static createAndInitModel(data) {
    var m = this.createModel(data);
    m.initModel();
    m.postInitModel && m.postInitModel();
    m.backup();
    return m;
  }

  static find(id) {
    return Promise.resolve().then(() => {
      var m = this.store.get(id);
      if (m) {
        return m;
      }
      return this.load(id).then(p => {
        if (p.id == null) {
          p.id = id;
        }

        m = this.createAndInitModel(p);
        // initModel ??
        return m;
      });
    });
  }

  static getAll() {
    return this.store.get('_all');
  }

  static findAll() {
    return Promise.resolve().then(() => {
      var all = this.store.get('_all');
      if (all) {
        return all;
      }
      return this.loadAll().then(all => {
        var models = _.map(all, m => {
          return this.createAndInitModel(m);
        });
        this.store.setReference('_all', models);
        return models;
      });
    });
  }
}
_.assign(StoreModel.prototype, eventHandler);

function toIds(value) {
  if (!value) {
    return value;
  }
  if (_.isArray(value)) {
    return _.map(value, v => v.id);
  } else {
    return value.id;
  }
}

export default StoreModel;
