<template>
  <div class="page-content narrow billing">
    <PremiumSignup v-if="showSignup" :subscription="subscription" />
    <section v-else>
      <h1>Premium Plan</h1>

      <div class="split-section" v-if="!subscription.active">
        <div v-if="subscription.premiumProvider">
          You are on a Premium Plan provided by {{ subscription.premiumProvider.email }}
        </div>
        <div v-else>
          <p v-if="subscription.statusError">{{ subscription.statusError }}</p>
          <div>You are now on a free plan. Switch to premium to access all features of Agiloo.</div>
          <div class="flex">
            <a-btn class="right" @click="startSignup" color="primary">Sign up for Premium</a-btn>
          </div>
        </div>
      </div>

      <SubscriptionStatus
        :subscription="subscription"
        v-if="subscription.active"
      ></SubscriptionStatus>

      <PremiumUsers :subscription="subscription" v-if="subscription.premiumUsers" />
      <PastInvoices :subscription="subscription" v-if="subscription.activated"></PastInvoices>

      <div class="split-section" v-if="subscription.fullyActive">
        <aside>
          <h3>Cancel Subscription</h3>
        </aside>
        <div>
          When canceling the premium subscription you will not be billed further. The premium status
          for you and users paid for will end on
          <span class="highlight">{{ formatDate(subscription.currentPeriodEnd) }}</span>
          <div class="flex">
            <a-btn class="right" color="red" @click="cancelPremium">Cancel Premium</a-btn>
          </div>
        </div>
      </div>
    </section>

    <section class="filler"></section>

    <page-footer />
  </div>
</template>
<script>
import { loadSubscription } from '@/models/billing/billingService';

import './BillingPage.scss';

import SubscriptionStatus from './SubscriptionStatus.vue';
import PremiumUsers from './PremiumUsers.vue';
import PastInvoices from './PastInvoices.vue';
import PremiumSignup from './PremiumSignup.vue';

export default {
  route: '/premiumplan',
  props: ['subscription'],
  data() {
    return {
      showSignup: false,
    };
  },
  getData() {
    return { subscription: loadSubscription() };
  },

  methods: {
    startSignup() {
      this.showSignup = true;
    },

    cancelPremium() {
      return this.subscription.cancelPremium();
    },
  },
  components: { PremiumSignup, PastInvoices, PremiumUsers, SubscriptionStatus },
};
</script>
