import { render } from "./MemberRow.vue?vue&type=template&id=449083de"
import script from "./MemberRow.vue?vue&type=script&lang=js"
export * from "./MemberRow.vue?vue&type=script&lang=js"

import "./MemberRow.vue?vue&type=style&index=0&id=449083de&lang=scss"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
