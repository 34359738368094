<template>
  <div class="ModalHandler">
    <div class="modal-overlay" :class="{ visible: pages.length > 0 }" @click="close"></div>

    <transition name="modal">
      <div class="modal" v-if="pages.length > 0" :class="{ small: modalOptions.small }">
        <transition name="fade">
          <a class="left" v-if="pages.length > 1" @click="goBack">
            <i class="icon-arrow-left2"></i>
          </a>
        </transition>
        <a class="right close" @click="close">
          <i class="icon-cross2"></i>
        </a>
        <transition-group
          :name="pageTransition"
          tag="div"
          class="pages"
          :class="{ afterInit: afterInit }"
          v-on:after-enter="afterInit = true"
        >
          <div class="page" :key="page.key" :class="pageClass(index)" v-for="(page, index) in pages">
            <div v-if="page.loadingText" class="loadingText">{{ page.loadingText }}</div>
            <component :ref="page.key" :is="page.component" v-bind="page.data" @close="closePage" v-else></component>
          </div>
        </transition-group>
      </div>
    </transition>
  </div>
</template>
<script>
import { Util, App, _ } from '@/index';

import { nextTick } from '@/utils';
export default {
  data: function () {
    return { pages: [], afterInit: false, modalOptions: {}, pageTransition: 'pageslide' };
  },
  created() {
    var self = this;
    App.openPopup = App.openModal = function (Page, data) {
      self.openPage(Page, data);
    };
  },
  methods: {
    close: function () {
      this.pages = [];
    },
    closePage() {
      if (this.pages.length > 1) {
        this.goBack();
      } else {
        setTimeout(() => {
          this.close();
        }, 0);
      }
    },
    pageClass(index) {
      var currentIndex = this.pages.length - 1;
      if (!this.afterInit) {
        //return 'firstpage'
      }
      if (index === currentIndex) {
        return 'center';
      }
      if (index > currentIndex) {
        return 'right';
      }
      if (index === currentIndex - 1) {
        return 'left';
      }
      return 'left-far';
    },

    goBack() {
      this.pages.removeLast();
    },

    openPage(component, data) {
      var newPage = {
        component,
        key: nextKey(),
      };
      let loadingPage;

      const componentDef = component;

      this.modalOptions = {
        small: !!componentDef.smallModal,
      };

      const resolveData = componentDef.getData ? Util.resolveMap(componentDef.getData(data)) : Promise.resolve({});

      if (this.pages.length === 0) {
        this.afterInit = false;
      }
      if (componentDef.loadingText) {
        loadingPage = { loadingText: componentDef.loadingText, key: 'loader' };
        this.pages.push(loadingPage);
      }
      const pages = this.pages;

      resolveData.then(resolvedData => {
        newPage.data = _.assign({}, resolvedData, data);
        const loadingPageIndex = pages.indexOf(loadingPage);
        if (loadingPageIndex >= 0) {
          this.pageTransition = 'none';
          nextTick(() => {
            this.pageTransition = 'pageslide';
          });
          this.pages.splice(loadingPageIndex, 1, newPage);
        } else {
          this.pages.push(newPage);
        }
      });
    },
  },
};

var _nextKey = 0;
function nextKey() {
  _nextKey++;
  return 'p' + _nextKey;
}
</script>
<style lang="scss">
.ModalHandler {
  .loadingText {
    display: flex;
    justify-content: center;
    padding-top: 20%;
  }
}
</style>
