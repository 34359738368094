import StoreModel from './base/StoreModel';
import _ from 'lodash';
import cssRule from '@/common/cssRule';
import { Model } from '@/namespace';

class Tag extends StoreModel {
  static NAME = 'Tag';
  static define = {
    color: {
      model: 'Color',
    },
    title: {},
    projectId: null,
  };

  static createNew(project, title) {
    var tag = new Model.Tag({
      title: title,
      projectId: project.id,
      color: newTagColor(project.tags),
    });
    tag.initModel();
    return tag;
  }

  postInitModel() {
    Model.Project.get(this.projectId).tags.add(this);
    initStyle(this);
  }

  get url() {
    return '/' + this.projectId + '/tag/' + this.id;
  }

  get text() {
    return this.title;
  }

  postDestroy() {
    Model.Project.get(this.projectId).tags.remove(this);
  }
}

Model.Tag = Tag;
export default Tag;

function initStyle(tag) {
  const color = tag.color,
    id = tag.id;
  if (color) {
    cssRule(`.tag[tag-id="${id}"]`, {
      'background-color': '#' + color.code,
    });
    if (color.darkText) {
      cssRule(`.tag[tag-id="${id}"]`, {
        color: '#606060',
        'box-shadow': 'none',
        outline: '1px solid #e8e8e8',
      });
    }
  }
}

function newColorMap() {
  return {
    blue: 0,
    green: 0,
    orange: 0,
    purple: 0,
    indigo: 0,
    yellow: 0,
    red: 0,
  };
}

function pickLeastUsedColor(colorMap) {
  var least = {
    color: 'blue',
    count: 10000,
  };
  _.each(colorMap, (count, color) => {
    if (count < least.count) {
      least = {
        color: color,
        count: count,
      };
    }
  });
  return least.color;
}

function countColors(tags) {
  var colorMap = newColorMap();
  _.each(tags, tag => {
    if (tag.color == null) {
      return;
    }
    var colorId = tag.color.id;
    if (colorMap[colorId] == null) {
      return;
    }
    colorMap[colorId] += 1;
  });
  return colorMap;
}

function newTagColor(tags) {
  var colorMap = countColors(tags);
  return pickLeastUsedColor(colorMap);
}
