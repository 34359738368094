<template>
  <div
    class="item-list-item selectissuerow issue dragitem issue-wrapper"
    :class="{ partial: wrapper.partial, 'no-0': wrapper.children.length === 0 }"
  >
    <div class="row" @click="toggleCheckbox">
      <IssueCount :wrapper="wrapper" />
      <StatusIcon :wrapper="wrapper" :preventClicks="true" />
      <checkbox :selected="wrapper.sprintselected">
        <i class="icon-"></i>
      </checkbox>

      <div class="title">
        <div class="issue-type small" v-if="wrapper.issueType" :type="wrapper.issueType"></div>
        <span class="title-text">{{ wrapper.issue.title }}</span>
      </div>
    </div>
    <div v-if="wrapper.open" class="sub-items children">
      <select-issue-row
        v-for="wrapper in wrapper.children"
        :wrapper="wrapper"
        :key="wrapper.id"
        @toggleChild="toggleChild"
      ></select-issue-row>
    </div>
  </div>
</template>
<script>
import { StatusIcon, IssueCount } from '@/components/issue-collection';
export default {
  props: ['wrapper'],
  name: 'SelectIssueRow',
  methods: {
    toggleCheckbox(ev) {
      ev.stop();
      if (ev.target.closest('.IssueCount')) {
        return;
      }
      var selected = this.wrapper.sprintselected || 'false';

      selected = selected === 'true' || selected === 'semi' ? 'false' : 'true';
      this.wrapper.set('sprintselected', selected);
      propagateSprintSelectedToChildren(this.wrapper, selected);
      this.$emit('toggleChild', this.wrapper);
    },
    toggleChild() {
      var result = null;
      this.wrapper.issues.forEach(function (is) {
        if (result === null) {
          result = is.sprintselected;
          return;
        }
        if (result !== is.sprintselected) {
          result = 'semi';
          return false;
        }
      });

      if (this.wrapper.sprintselected !== result) {
        this.wrapper.set('sprintselected', result);
        this.$emit('toggleChild');
      }
    },
  },
  components: { StatusIcon, IssueCount },
};

function propagateSprintSelectedToChildren(issueWrapper, value) {
  if (!issueWrapper.transformedToWrappers) {
    issueWrapper.transformIssuesToWrappers();
  }
  issueWrapper.issues.forEach(is => {
    is.set('sprintselected', value);
    propagateSprintSelectedToChildren(is, value);
  });
}
</script>
<style lang="scss">
.SelectIssueRow {
  .issue-type {
    left: 115px;
    top: 1px;
  }
}
</style>
