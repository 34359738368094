<template>
  <a class="member-link" :href="member.url">
    <div class="member-icon" :member-id="member.id">
      <i class="icon-"></i>
    </div>
    <div class="name">
      {{ member.displayName }}
    </div>
  </a>
</template>

<script>
import { Model } from '@/namespace';
export default {
  props: ['id'],

  computed: {
    member() {
      return Model.Member.get(this.id);
    },
  },
};
</script>
