<template>
  <q-btn @click.stop="doClick" :size="qSize" :loading="showSpinner" v-bind="$attrs" :unelevated="unelevated" rounded>
    <slot />
  </q-btn>
</template>
<script>
import { App } from '@/namespace';
export default {
  emits: ['click'],
  props: {
    unelevated: {
      default: () => true,
    },
    size: String,
    href: String,
  },
  data() {
    return {
      loading: false,
      showSpinner: false,
    };
  },
  computed: {
    qSize() {
      if (this.size === 'large') {
        return '18px';
      }
      return this.size;
    },
  },
  methods: {
    async doClick() {
      if (this.loading) {
        return;
      }

      if (this.href) {
        App.router.navigate(this.href);
        return;
      }

      this.loading = true;
      const loadingTimer = setTimeout(() => {
        this.showSpinner = true;
      }, 500);

      const action = this.$attrs.onAction;

      try {
        action ? await action() : this.$emit('click');
      } finally {
        clearTimeout(loadingTimer);
        this.loading = false;
        this.showSpinner = false;
      }
    },
  },
};
</script>
