<template>
  <section>
    <h1>View Work Items</h1>
    <p>The View Item Page displays all relevant information about a work item.</p>
    <div class="image-with-helpkeys viewitem">
      <img src="/static/images/help/viewitem.png" class="bordered" />
      <helpkey id="m1">1</helpkey>
      <helpkey id="m2">2</helpkey>
      <helpkey id="m3">3</helpkey>
      <helpkey id="m4">4</helpkey>
      <helpkey id="m5">5</helpkey>
      <helpkey id="m6">6</helpkey>
      <helpkey id="m7">7</helpkey>
    </div>
    <div class>
      <ul>
        <li>
          <helpkey>1</helpkey>The title is editable. Edit the text and save by pressing
          <span class="keyboard">ENTER</span>
          or cancel your changes by pressing
          <span class="keyboard">ESC</span>
        </li>
        <li><helpkey>2</helpkey>You can add a description to a work item.</li>
        <li>
          <helpkey>3</helpkey>The status icon shows the current status, change status by clicking
          it.
        </li>
        <li>
          <helpkey>4</helpkey>The team member assigned to the work item is shown here. If the work
          item has sub items with different members assigned to them, all team members assigned are
          shown.
        </li>
        <li>
          <helpkey>5</helpkey>By default, when creating a new item, the item is put on the backlog.
          To move a item to a sprint, click to change sprint.
        </li>
        <li>
          <helpkey>6</helpkey>All of the sub items are shown in a list. If the items are assigned to
          different work boards, the work board name is shown as the list title. Navigate into the
          work board by clicking the list title.
        </li>
        <li>
          <helpkey>7</helpkey>This part of the top bar contains actions related to the item. In the
          more menu, you have the option to delete the item, change the item parent or add an
          attachment or a tag.
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
export default {};
</script>
