<template>
  <div class="NotificationHandler">
    <div class="notification" v-if="notification">
      <div class="title" v-html="notification.text"></div>
      <a href="" @click="close" id="close">
        <i class="icon-cross2"></i>
      </a>
    </div>
  </div>
</template>
<script>
import { Util, App } from '@/namespace';

import { addNotification } from '@/models/notificationService';

export default {
  data: function() {
    return { notification: null };
  },

  created() {
    App.displayNotification = this.displayNotification;
    this.closeTimer = Util.timer(() => {
      this.notification = null;
    }, 20000);
  },

  methods: {
    close(ev) {
      ev.stop();
      this.notification = null;
    },
    displayNotification(notification) {
      this.notification = notification;
      addNotification(notification);
      this.closeTimer.start();
    },
  },
};
</script>
<style lang="scss">
.NotificationHandler {
  .notification {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 13500;
    top: 50px;
    right: 15px;
    padding: 10px 30px 10px 20px;
    min-width: 240px;
    max-width: 300px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
  }

  #close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: #888;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
  }
}
</style>
