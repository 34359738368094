<template>
  <FilterableMenu :opt="opt" @close="close"> </FilterableMenu>
</template>

<script>
import { App } from '@/namespace';
import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
import { deleteIssue } from '@/actions';
import { pickUploadFile } from '@/apphandlers/filehandler/filehandler';

import ChangeIssueParent from '@/pages/ChangeIssueParent/ChangeIssueParent';

export default {
  props: ['issue'],
  data() {
    return {
      opt: {
        items: [
          {
            text: 'Change Parent',
            icon: 'icon-tree7',
            select: () =>
              App.openPopover(ChangeIssueParent, {
                issue: this.issue,
              }),
          },
          {
            text: 'Add Attachment',
            icon: 'icon-cloud-upload',
            select: () => pickUploadFile(this.issue),
          },
          {
            text: 'Add Tag',
            icon: 'icon-price-tag2',
            select: () =>
              App.openSelector({
                issue: this.issue,
                name: 'AddTag',
                type: 'menu',
              }),
          },
        ],
        footerItems: [
          {
            text: 'Delete',
            icon: 'icon-bin',
            select: () =>
              deleteIssue(this.issue).then(() => {
                App.router.navigateBack();
              }),
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
