<template>
  <a
    class="item-list-item issue dragitem issue-wrapper DragContainer navigate-slide"
    :class="cssClass"
    :href="url"
    @mousedown="mousedown"
    @touchstart="mousedown"
  >
    <div class="row">
      <IssueCount :wrapper="wrapper" />

      <StatusIcon :wrapper="wrapper" />

      <div class="title">
        <div class="issue-type small" v-if="wrapper.issueType" :type="wrapper.issueType"></div>
        <span class="title-text">{{ wrapper.issue.title }}</span>
      </div>

      <div class="fillable" v-if="wrapper.issue.fillable" v-tooltip="tooltips.fillable">
        <i class="icon-file-text2"></i>
      </div>
      <div class="fillable" v-if="wrapper.issue.additions.github" v-tooltip="tooltips.github">
        <i class="icon-github"></i>
      </div>
      <div v-for="tag in wrapper.issue.tags" class="tag" :tag-id="tag.id">
        {{ tag.title }}
      </div>

      <estimate-bar-inlist v-if="wrapper.estimateMap.est" :wrapper="wrapper"></estimate-bar-inlist>

      <MemberIcon :wrapper="wrapper" />

      <div class="more-menu" @click.prevent="openMoreMenu">
        <i class="icon-more2"></i>
      </div>
    </div>
    <div v-if="wrapper.open" class="sub-items children">
      <template v-for="child in wrapper.children">
        <IssueRow
          v-if="child.issue"
          :wrapper="child"
          :key="child.id"
          :ref="child.id"
          @createSiblingIssue="createSiblingIssue"
        />
        <CreateIssueRow v-else :parentWrapper="wrapper" />
      </template>
    </div>
  </a>
</template>
<script>
import { App, _, Model } from '@';
import tooltips from '../tooltips';
import dragContainerMixin from '@/components/drag/issueDragContainerMixin';
import { MemberIcon, StatusIcon, IssueCount } from '@/components/issue-collection';
import CreateIssueRow from './CreateIssueRow.vue';
import MoreMenu from '../MoreMenu.vue';
export default {
  name: 'IssueRow',
  props: ['wrapper'],
  mixins: [dragContainerMixin],
  data() {
    return {
      showCreateSubIssue: false,
    };
  },
  methods: {
    mousedown(ev) {
      App.startDragSession({ ev, dragItem: this });
    },

    setDragStatus(name, value) {
      this.wrapper.setDragStatus(name, value);
    },

    createSubIssue(data) {
      if (_.isEmpty(data.title)) {
        this.showCreateSubIssue = false;
        return;
      }
      const newIssue = Model.Issue.createNew({
        parent: this.wrapper.issue,
        title: data.title,
      });
      this.wrapper.applyChange(newIssue);
      newIssue.save().then(() => {
        this.showCreateSubIssue = false;
      });
    },
    createSiblingIssue({ sibling }) {
      this.wrapper.addCreateIssue({ sibling });
    },
    openMoreMenu(ev) {
      App.openPopover(
        MoreMenu,
        {
          issue: this.wrapper.issue,
          wrapper: this.wrapper,
          handleCreateSubIssue: () => {
            this.wrapper.showSubIssues();
            this.wrapper.issues.push({ isCreateIssue: true });
          },
          handleCreateSiblingIssue: () => {
            this.$emit('createSiblingIssue', { sibling: this.wrapper });
          },
        },
        {
          type: 'menu',
          target: ev.currentTarget,
        }
      );
    },
  },
  computed: {
    url: function () {
      return this.wrapper.issue.getUrl();
    },
    cssClass() {
      return this.wrapper.dragStatus;
    },
    tooltips() {
      return tooltips;
    },
  },
  components: { MemberIcon, StatusIcon, IssueCount, CreateIssueRow },
};
</script>
<style lang="scss">
.item-list-item {
  display: block;
  position: relative;

  &.hidden {
    display: none;
  }

  &.create-issue {
    .text-input {
      margin-left: 35px;
      font-size: 15px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    min-height: 44px;
    margin-left: 32px;
    border-bottom: 1px solid $gray-lighter;
    position: relative;
    padding-left: 10px;

    > * {
      margin-left: 10px;
    }
    > .IssueCount {
      margin-left: -41px;
    }
    > .StatusIcon {
      margin-left: 0;
    }
    padding-right: 10px;

    > .title {
      flex: 1;
      cursor: pointer;
      position: relative;
      min-height: 44px;
      padding-top: 12px;
      .title-text {
        font-size: 15px;
        line-height: 1.4em;
        font-weight: 300;
      }
      .issue-type {
        left: 0;
        position: absolute;
        top: 1px;
      }
    }

    > .additional {
      padding-right: 10px;
    }

    .more-menu {
      color: #b8b8b8;

      font-size: 18px;
      position: absolute;
      right: -30px;
      height: 42px;
      width: 30px;
      text-align: center;
      line-height: 46px;
      opacity: 0;
      &:hover {
        opacity: 1;
      }

      @media (max-width: 580px) {
        display: none;
      }
    }
    &:hover .more-menu {
      opacity: 1;
    }
  }

  .fillable {
    color: #888;

    i {
      position: relative;
      top: 1px;
    }
  }

  .tag + .tag {
    margin-left: 5px;
  }

  .sub-items {
    margin-left: 30px;
  }

  .checkbox {
    background-color: #f0f0f0;
    align-self: flex-start;
    margin-top: 10px;
    border: 1px solid #e0e0e0;
  }
  @media (max-width: 580px) {
    .row {
      margin-left: 15px;
    }

    .IssueCount {
      .arrow {
        display: none;
      }
      left: -7px;
    }
  }
}
</style>
