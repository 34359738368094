const MAX_FILE_SIZE = 1024 * 1024 * 5;

class FileUpload {
  constructor(file, fileParent) {
    this.file = file;
    this.parent = fileParent;
  }

  getName() {
    return this.filename || this.file.name || 'Image';
  }

  getBlob() {
    return this.file.slice ? this.file.slice() : this.file;
  }

  isImage() {
    return this.file.type.indexOf('image') > -1;
  }

  validate() {
    if (this.file.size > MAX_FILE_SIZE) {
      throw new Error('The maximum size for a file is 5 mb');
    }
  }

  ready() {
    return Promise.resolve()
      .then(() => {
        if (this.resolveFileName) {
          return this.resolveFileName.then(name => {
            this.filename = name;
          });
        }
      })
      .then(() => {
        return this.validate();
      });
  }
}

export default FileUpload;
