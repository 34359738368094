<template>
  <div class="create-comment">
    <div class="comment">
      <div class="left"></div>
      <div class="right">
        <TipTapEditor
          ref="editor"
          v-model="text"
          :autoSave="false"
          :showMenu="false"
          :showFooter="true"
          :autofocus="true"
          :parentModel="issue"
          placeholder="comment"
          @cancel="editorCancel"
          @save="editorSave"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['issue'],
  data: function () {
    return { text: null };
  },

  methods: {
    editorCancel() {
      this.$emit('change', null);
    },
    editorSave() {
      this.$emit('change', this.text);
    },
  },
};
</script>
