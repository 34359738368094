import { Util } from '@/namespace';

export function signFile(fileUpload) {
  var data = {
    filename: fileUpload.getName(),
    filetype: fileUpload.file.type,
    filesize: fileUpload.file.size,
    parentId: fileUpload.parent.id,
    parentType: fileUpload.parent.constructor.NAME,
  };
  if (fileUpload.thumb) {
    data.thumbsize = fileUpload.thumb.size;
    data.thumbtype = fileUpload.thumb.type;
  }

  return Util.jsonajax({ url: '/file/signupload', data: data });
}

export function uploadToAmazon(signed_request, file) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();

    xhr.open('PUT', signed_request);
    xhr.setRequestHeader('x-amz-acl', 'private');
    xhr.onload = function () {
      if (xhr.status === 200) {
        resolve();
      }
    };
    xhr.onerror = function () {
      console.error('Could not upload file');
      reject();
    };

    xhr.send(file);
  });
}
