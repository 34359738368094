<template>
  <div class="CookiePolicyMessage">
    <div class="message-container" v-if="!hideMessage">
      <div class="message">
        We use cookies to give you the best possible experience on our website. By continuing to
        browse this site, you give consent for cookies to be used. For more details, please read our
        <a href="/cookiepolicy">Cookie Policy</a>.
      </div>
      <button @click="close"><i class="icon-cross2"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['profile'],
  data() {
    return {
      doHide: false,
    };
  },
  computed: {
    hideMessage() {
      return this.profile.authenticated || localStorage.getItem('hideCookieMessage') || this.doHide;
    },
  },
  methods: {
    close() {
      this.doHide = true;
      localStorage.setItem('hideCookieMessage', true);
    },
  },
};
</script>
<style lang="scss">
.CookiePolicyMessage {
  position: absolute;
  bottom: 0;
  left: 0;

  z-index: 20000;

  .message-container {
    position: relative;
    bottom: 10px;
    left: 10px;
    max-width: 540px;
    border-radius: 4px;
    background: #222b37;
    padding: 10px 20px 10px 10px;
    @media (max-width: 580px) {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      bottom: 0;
      left: 0;
    }
  }
  .message {
    color: white;
    font-size: 12px;
    a {
      color: white;
      text-decoration: underline;
    }
  }

  button {
    position: absolute;
    top: 3px;
    right: 0;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    i {
      font-size: 12px;
    }
  }
}
</style>
