<template>
  <div id="message-handler" class="MessageHandler">
    <div class="message" v-if="text" :type="type" :class="type">
      <div class="text">{{ text }}</div>
      <a href="#" class="close-notification close" @click="close">
        <i class="icon-cross2"></i>
      </a>
    </div>
  </div>
</template>
<script>
import { App } from '@/namespace';

export default {
  data: function () {
    return { text: null, type: 'info' };
  },

  created() {
    App.displayMessage = this.displayMessage;
    App.closeMessage = this.closeMessage;
  },

  methods: {
    close(ev) {
      ev.stop();
      this.text = null;
    },
    closeMessage() {
      this.text = null;
    },
    displayMessage(text, type) {
      this.text = text;
      this.type = type;
    },
  },
};
</script>
<style lang="scss">
.MessageHandler {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20000;

  .message {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      text-align: center;
      font-size: 14px;
      color: inherit;
      height: auto;
      line-height: inherit;
      color: #fff;
      text-align: center;
      padding: 10px 0;
    }

    .close-notification {
      position: absolute;
      right: 0;
      height: 100%;
      width: 40px;
      top: 0;
      line-height: 50px;
      text-align: center;
      color: white;
    }

    &.info {
      background-color: $green;
    }

    &.error {
      background-color: $red;
    }

    &.warning {
      background-color: $yellow;
    }
  }
}
</style>
