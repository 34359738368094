<template>
  <a class="sprint-link" :href="sprint.url">
    <svg-icon icon="board" class="board-icon" />

    <div class="name">
      {{ sprint.title }}
    </div>
  </a>
</template>

<script>
import { Model } from '@/namespace';
export default {
  props: ['id'],

  computed: {
    sprint() {
      return Model.Sprint.get(this.id);
    },
  },
};
</script>
