<template>
  <FilterableMenu @close="close" :opt="opt">
    <template v-slot:default="{ item }">
      <div class="text">{{ item.name }}</div>
    </template>
  </FilterableMenu>
</template>
<script>
import { Model } from '@';

import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
export default {
  props: ['member', 'onSelect'],
  data: function() {
    return {
      opt: {
        isActive: item => this.member.role === item,
        items: Model.Role.getAll(),
        select: role => {
          this.member.setRole(role);
        },
      },
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
