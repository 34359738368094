export default function(mock) {
  let newId = 10000;

  mock.onPut(/\/comment\/\w+/).reply(200, {});
  mock.onDelete(/\/comment\/\w+/).reply(200, {});
  mock.onPost('/comment').reply(function(data) {
    var id = ++newId;
    return [
      200,
      {
        id: 'c' + id,
        member: 'd1',
        createdAt: new Date(),
      },
    ];
  });
  mock.onPost('/issue/fill').reply(function() {
    return [200, {}];
  });
}
