import Util from '@/common/util';
import { Activity, Project } from '@/models';
import DateMap from '@/common/model/DateMap';
export async function loadProjectActivityMap(projectId) {
  await Project.find(projectId);
  const data = await Util.jsonajax({
    url: `/activity/project/${projectId}`,
    method: 'GET',
  });
  const activities = data.map(json => new Activity(json));
  return DateMap.createNew(activities, true);
}
