<template>
  <section class="main">
    <h1>Sub Items</h1>

    <div class="with-svg">
      <div class="text">
        <p>
          A core feature in Agiloo is the ability to divide Items into smaller pieces, into sub
          items. This can be done in any amount of levels necessary. By dividing into smaller items
          you can better define what needs to be done.
        </p>
        <p>
          An item can be assigned to a team member, moved to a sprint or having a status set. In
          Agiloo this is not limited only to top items, but you can do that for any work item on any
          level.
        </p>
      </div>
      <div class="svg">
        <object data="/static/images/help/project.svg" type="image/svg+xml" />
      </div>
    </div>

    <div class="with-svg">
      <div class="text">
        <p>
          Another core functionality in Agiloo is aggregation of information. A work item's status
          is defined out of it's sub items. When all sub items are done, the parent item is also
          considered done.
        </p>
        <p>
          In the example to the right, the items C11 and C12 are both done. That in turn means that
          their parent, C1, is done.
        </p>
        <p>
          Item C has one item done and one in progress, and is therefor considered to be in
          progress.
        </p>
      </div>
      <div class="svg">
        <object data="/static/images/help/status.svg" type="image/svg+xml" />
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
