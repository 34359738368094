import { Model, _ } from '@/index';
import { nextHigherPriority } from '@/models/priority/priorityCalculation';
export default {
  createNew(attributes) {
    var item = new Model.Issue();

    var defaults = {
      statusId: 'todo',
      title: '',
      description: '',
    };
    attributes = _.extend(defaults, attributes);

    attributes.parentId = attributes.parent.id;
    attributes.projectId = attributes.parent.projectId;

    if (!attributes.sprintId) {
      attributes.sprintId = attributes.parent.sprintMap.calcValue();
      if (attributes.sprintId.finished) {
        attributes.sprintId = Model.Sprint.backlog;
      }
    }
    if (!attributes.memberId) {
      attributes.memberId = attributes.parent.memberMap.calcValue();
    }

    attributes.priorityMap = {
      _: nextHigherPriority(attributes.parent.issues),
    };

    item.setAttributes(attributes);

    item.initModel();
    item.initialCalculation();

    return item;
  },
};
