<template>
  <div>
    <a class="status-icon" :status-id="item.id">
      <i class="icon-"></i>
    </a>
    <div class="text">{{ item.name }}</div>
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
