import StoreModel from '@/models/base/StoreModel';

import _ from 'lodash';
import { Util } from '@/namespace';

const statusTexts = {
  trialing: 'Trialing',
  incomplete: 'There was a problem with the last payment',
  incomplete_expired: 'There was a problem with the last payment',
  past_due: 'There was a problem with the last payment',
  canceled: 'The subscription has been canceled',
  unpaid: 'There was a problem with the last payment',
};

class Subscription extends StoreModel {
  static NAME = 'Subscription';
  static define = {
    cardToken: null,
    cardLast4: null,
    canceled: null,
    active: null,
    currentPeriodEnd: null,

    premiumUsers: {
      serialize(users) {
        if (!users) {
          return users;
        }
        return users.map(user => user.id);
      },
    },
  };
  static load() {
    return Util.jsonajax({
      url: '/billing/subscription',
      data: {},
      method: 'GET',
    });
  }

  addUsers(users) {
    return Util.jsonajax({
      url: '/billing/addusers',
      data: {
        users,
      },
    }).then(data => {
      _.assign(this, data);
    });
  }

  removeUser(user) {
    return Util.jsonajax({
      url: '/billing/removeuser',
      data: {
        user: user.id,
      },
    }).then(data => {
      _.assign(this, data);
    });
  }

  cancelPremium() {
    return Util.jsonajax({ url: '/billing/cancelpremium' }).then(data => {
      _.assign(this, data);
      console.log('done');
    });
  }

  get statusError() {
    if (this.status) {
      return statusTexts[this.status];
    }
    return null;
  }

  get calcQuantity() {
    return this.quantity || this.premiumUsers.length;
  }

  get totalPrice() {
    return this.calcQuantity * this.price;
  }

  reactivatePremium() {
    return Util.jsonajax({ url: '/billing/reactivatepremium' }).then(data => {
      _.assign(this, data);
      console.log('done');
    });
  }

  get fullyActive() {
    return this.active && !this.canceled;
  }

  static update(id, data) {
    return Util.jsonajax({
      url: '/billing/subscription',
      data: data,
      method: 'PUT',
    });
  }

  static find() {
    return super.find('1');
  }
  static get() {
    return super.get('1');
  }
}

export default Subscription;
