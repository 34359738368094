import { pasteFile, dropFile } from './filehandler';
import { App } from '@/namespace';
export default {
  '.page-content drop': function(ev) {
    ev.stop();
    if (this.fileParent == null) {
      App.displayError('It is not possible to upload attachments on the current page');
      return;
    }
    dropFile(ev, this.fileParent);
  },
  '.page-content drag': function(ev) {
    ev.stop();
  },
  '.page-content dragstart': function(ev) {
    ev.stop();
  },
  '.page-content dragend': function(ev) {
    ev.stop();
  },
  '.page-content dragover': function(ev) {
    ev.stop();
  },
  '.page-content dragenter': function(ev) {
    ev.stop();
  },
  '.page-content dragleave': function(ev) {
    ev.stop();
  },
  '.page-content paste': function(ev) {
    console.log('paste');
    if (this.fileParent == null) {
      App.displayError('It is not possible to upload attachments on the current page');
      return;
    }
    pasteFile(ev, this.fileParent);
  },
};
