<template>
  <div class="page-content narrow info-pages">
    <AgilooLogo />
    <section class="header">
      <h1>Privacy Policy</h1>
    </section>

    <section class="legal-text">
      <h2>Highlights</h2>
      <p>
        Agiloo is a supplier of a service for agile project management. We understand that you are
        careful of your personal information and this privacy policy sets out how we collect and use
        that information to provide you with the services you have requested.
      </p>
      <h2>1. GENERAL</h2>
      <p>
        <span>1.1</span>
        <span>
          This privacy policy (“Privacy Policy”) applies when Agiloo Digital AB, reg. no.
          556847-1600, with address Ölandsgatan 49B, 116 63 Stockholm, Sweden, e-mail:
          info@agiloo.com, (“Agiloo”, “we”, “us”) supply the Agiloo website www.agiloo.com (the
          “Website”) and supply the service as defined in Agiloo’s Terms, see link
          <a href="/termsconditions">here</a>, (the “Service”).
        </span>
      </p>

      <p>
        <span>1.2</span>
        <span>
          We are responsible for the processing of your personal data for which we decide the
          purposes and the means of processing. With “Personal Data” we mean information, which is
          directly, or indirectly referable to a natural living person, e.g. name, email-address and
          IP addresses. We may collect the information set out below, which may include your
          Personal Data.
        </span>
      </p>
      <p>
        <span>1.3</span>
        <span>
          By creating a user account at our Website you acknowledge that we collect and process your
          Personal Data in accordance with this Privacy Policy.
        </span>
      </p>
      <h2>2. HOW AND WHY WE COLLECT AND USE YOUR PERSONAL DATA</h2>
      <p>
        <span>2.1</span>
        <span>
          We collect and use the following Personal Data from you when you create your Agiloo
          account on the legal basis to provide you with our service (the performance of a
          contract):
          <ul>
            <li>Full name and</li>
            <li>E-mail address.</li>
          </ul>
        </span>
      </p>
      <p>
        <span>2.2</span>
        <span>
          We process this Personal Data for the following main purposes:
          <ul>
            <li>
              Providing, managing, developing and customising the Service and the Website and their
              functionalities to you.
            </li>
            <li>
              Managing the custom relationship with you. This includes, but is not limited to,
              answering support e-mails and troubleshooting the Service.
            </li>
            <li>
              Processing your personal data in this regard is necessary for the performance of a
              contract. Without registering your account, we will be unable to provide you the
              Service.
            </li>
          </ul>
        </span>
      </p>
      <p>
        <span>2.3</span>
        <span>
          When you use the Service, certain information will be collected automatically by us,
          including;
          <ul>
            <li>Information about your use of the Services; and</li>
            <li>
              We collect information through technology like [IP addresses, device location],
              cookies, pixels and local storage (like on your browser or device)site and other usage
              information about the use of our web. Technical data, which may include the URL you
              are accessing the Website from, your IP address, network and computer performance,
              browser type, language and identifying information and operating system. The technical
              data is used to maintain and operate the service and Website which is required to
              provide you with the Service.
            </li>
            <li>
              We use your IP address to help diagnose problems with our server and to administer our
              website, analyse trends, track visitor movements and gather broad demographic
              information that assists us in identifying visitor preferences. Processing your
              personal data in this regard is necessary for our legitimate interest to provide our
              customers with a functional service.
            </li>
            <li>
              We also use the technical information mentioned here to help us design our Website to
              better suit our user’s need. The legal basis for this activity is our legitimate
              interest to develop and improve our Website and services. For information about how we
              use system technologies and analytic tools and how you can prevent the use of cookies,
              please see our Cookie Policy (available <a href="/cookiepolicy">here</a>).
            </li>
          </ul>
        </span>
      </p>
      <p>
        <span>2.4</span>
        <span>
          Information you upload to the Service
          <ul>
            <li>
              When you use our Service, you are able to upload and submit any information into the
              Service. Processing your uploaded Personal Data in this regard is necessary for the
              performance of our contract with you.
            </li>
          </ul>
        </span>
      </p>
      <p>
        <span>2.5</span>
        <span>
          We use Personal Data in order to protect the security of our services, to detect and
          prevent fraud, resolve disputes and to enforce our agreements.
          <ul>
            <li>
              Processing your personal data in this regard is necessary for the legitimate interests
              of Agiloo to protect our users, systems and yourself from harm, or to comply with a
              legal obligation.
            </li>
          </ul>
        </span>
      </p>
      <p>
        <span>2.6</span>
        <span>
          We process your Personal Data for direct marketing purposes,
          <ul>
            <li>
              so Agiloo is able to send you information about Agiloo, e.g. new services provided by
              Agiloo or other information about Agiloo to your e-mail. Processing your personal data
              in this regard is necessary for our legitimate interests to promote our services. If
              you do not want us to process your Personal Data for the purpose of us sending you
              information about Agiloo, direct marketing, you can give written notice to us and
              opt-out from receiving more marketing communication from us.
            </li>
          </ul>
        </span>
      </p>
      <h2>3. DATA SUBJECT RIGHTS</h2>
      <p>
        <span>3.1</span>
        <span>
          You have the right to, free of charge, request a registry extract and access a copy of
          your Personal Data, as well as correction and, under certain circumstances, deletion of
          your Personal Data. A request to delete your Personal Data will not affect the lawfulness
          of the processing prior to the request.
        </span>
      </p>
      <p>
        <span>3.2</span>
        <span>
          You have the right to request restriction of our processing of your Personal Data, even if
          we have legitimate reasons to do so.
        </span>
      </p>
      <p>
        <span>3.3</span>
        <span>
          You have the right to make a complaint to a data protection supervisory authority.
          Datainspektionen is the authority in Sweden that supervises our compliance with relevant
          privacy legislation. You can contact them on www.datainspektionen.se.
        </span>
      </p>
      <p>
        <span>3.4</span>
        <span>
          You have the right to request that we transfer your Personal Data to another organisation
          (Data Controller) responsible for handling your Personal Data. This applies when our right
          to process your Personal Data is based on the performance of a contract we have entered
          into with you.
        </span>
      </p>
      <p>
        <span>3.5</span>
        <span>
          You will be provided with reasonable access to your Personal Data by sending a request to
          <a mailto="info@agiloo.com">info@agiloo.com</a>. If we are unable to provide you with your
          Personal Data within a reasonable time frame, we will specify a date by which we will be
          able to do so. If such a request is denied, you will be provided with an explanation.
        </span>
      </p>
      <h2>4. DATA PROCESSORS AND THIRD PARTY PAYMENT SUPPLIER</h2>
      <p>
        <span>4.1</span>
        <span>
          Agiloo is responsible (the data controller) of personal data you provide to enter into an
          agreement with Agiloo and to create and administrer a user account. As such, Agiloo is
          responsible for that the use of your Personal Data is lawful, fair and transparent.
        </span>
      </p>
      <p>
        <span>4.2</span>
        <span>
          Agiloo may process personal data on your behalf, e.g. if you entrust us with the
          assignment to store and manage any of your uploaded documents and content. You are the
          data controller and responsible for the processing of such personal data which means that
          you should always review what type of data you submit and whether you have the relevant
          permissions to use such data.
        </span>
      </p>
      <p>
        <span>4.3</span>
        <span>
          Agiloo can use a sub-processor on our behalf to assist us in performing the services in
          accordance with this agreement, such as IT suppliers, server suppliers, suppliers of
          digital data, analysing tools (“Data Processors”). Such sub-processor will have the same
          obligations regarding the processing of Personal Data as Agiloo has towards you.
        </span>
      </p>
      <p>
        <span>4.4</span>
        <span>
          Due to that Data Processors can be geographically located both within and outside the
          EU/EEA your Personal Data may be transferred to or stored in a country outside the EU/EEA.
          Personal Data will only be transmitted to a country outside the EU/EEA if the country
          ensures an adequate level of protection, the EU Model Clauses are used or the Data
          Processor is certified with Privacy Shield. Your user name may be disclosed to other users
          of the Service. By creating a user account to the Service, the user agrees that user&#39;s
          Personal Data may be transferred and stored outside of the EU/EEA.
        </span>
      </p>
      <h2>5. WHEN AND HOW WE SHARE INFORMATION WITH OTHERS</h2>
      <p>
        <span>5.1</span>
        <span
          >We share your personal data as necessary for the completion of a transaction, for the
          performance of a contract, when we have legitimate business reasons for doing so, or, we
          will ask for your consent.</span
        >
      </p>
      <p>
        <span>5.2</span>
        <span
          >We may share and disclose your Personal Data to our partners and suppliers that process
          Personal Data on behalf of Agiloo, for the above stated purposes.</span
        >
      </p>
      <p>
        <span>5.3</span>
        <span
          >Agiloo uses a third party supplier, Stripe, for administering any payments made by you
          for using the Service. Agiloo does not process any of your payment information, such as
          credit card info. Please note that any information that you supply to an integrated third
          party’s website or service will be shared with the owner of that third party. Your
          information will be subject to the privacy polies as well as the terms of use, of such
          third party’s website or services, which you may find here.</span
        >
      </p>
      <p>
        <span>5.4</span>
        <span
          >Personal Data may also be disclosed to third parties if we have good reasons to believe
          that access, use, disclosure or retention of such information is reasonable necessary to
          <ul>
            <li>with applicable legal requirements or requirements from authorities,</li>
            <li>enforce and preserve our legal interests and agreements,</li>
            <li>
              manage and maintain the security of our services, systems, network and protect the
              rights, property or safety of Agiloo, its customers, or other, in order to detect,
              prevent, or pay attention to fraud and other security or technical issues.
            </li>
          </ul>
        </span>
      </p>
      <p>
        <span>5.5</span>
        <span
          >If the ownership of our business changes due to a share acquisition, we may transfer your
          information to the new owners so they can continue supplying the Services. The new owner
          will still have to honour the commitments we have made in this Privacy Policy.</span
        >
      </p>
      <h2>6. DATA STORAGE AND ERASURE</h2>
      <p>
        <span>6.1</span>
        <span
          >Personal data managed by Agiloo is stored and processed in the region in which you live,
          in Sweden or in other countries in the European Union and in the EEA region where Agiloo
          or its affiliates, partners or suppliers are active.</span
        >
      </p>
      <p>
        <span>6.2</span>
        <span
          >Agiloo retain your data for as long as it is necessary to be able to supply you with the
          Service you have requested from us and to fulfil the purposes stated in section 3 above.
          Different categories of data may be stored for different time periods, due to certain
          criteras.</span
        >
      </p>
      <p>
        <span>6.3</span>
        <span
          >We store your Personal Data for a period up to [12 months] after our customer
          relationship has ended, alternatively until you choose to delete your user account or
          request to have your Personal Data deleted. All data will be erased within [30 – 180 days]
          from that you delete your user account or request us to delete your Personal Data.</span
        >
      </p>
      <p>
        <span>6.4</span>
        <span
          >Some types of personal information must be stored longer than usual for purposes such as
          accounting reasons, government orders to preserve data relevant to investigations, or
          information that must be kept in order to solve potential disputes.</span
        >
      </p>
      <h2>7. DATA SECURITY</h2>
      <p>
        <span>7.1</span>
        <span
          >We maintain physical, technical and administrative safeguards to protect the
          confidentiality, integrity, availability and privacy of your personal data. We update and
          test our security technology on an ongoing basis.</span
        >
      </p>
      <p>
        <span>7.2</span>
        <span
          >We restrict access to your personal data to our employees on a need-to-know basis. We
          commit to taking the appropriate disciplinary measures to enforce our employees’ privacy
          responsibilities.</span
        >
      </p>
      <h2>8. EXCLUSIONS</h2>
      <p>
        <span>8.1</span>
        <span
          >Aggregated data is collected and processed to follow and evaluate user trends of the
          Service. This means that information about your use of the Service is collected and then
          anonymised in a way that means that the information cannot be linked back to you any
          longer. We use this anonymous information about how our services and websites are used for
          statistics, service improvement and product development. This type of information is
          anonymous and does not constitute Personal Data. Such information may therefore be stored
          for longer time periods than your personal information.</span
        >
      </p>
      <p>
        <span>8.2</span>
        <span
          >Third Party Links: This Privacy Policy does not apply to any personal information that
          you provide to another user through the website or through any other means. Any third
          party links you click on via our website may be subject to such third parties’ privacy
          policies, terms or other rules. Please make sure that you read the respective privacy
          information for each third party whose links you click on, to keep yourself up to date
          about the processing of your personal data.</span
        >
      </p>
      <h2>9. CHANGES OF THE PRIVACY POLICY</h2>
      <p>
        <span>9.1</span>
        <span
          >If we make changes to this Privacy Policy we will notify you by publication here. If the
          changes are material, we will provide you additional, prominent notice as appropriate
          under the circumstances and, where required under applicable law, ask for your
          consent.</span
        >
      </p>
      <p>
        <span>9.2</span>
        <span>You terminate your agreement with Agiloo by deleting your Agiloo account.</span>
      </p>
      <h2>10. QUESTIONS, CONCERNS OR COMPLAINTS</h2>
      <p>
        <span>10.1</span>
        <span
          >Agiloo Digital AB is the responsible data controller
          <ul class="address">
            <li>Agiloo Digital AB</li>
            <li>Ölandsgatan 49B</li>
            <li>11663 Stockholm</li>
            <li>info@agiloo.com</li>
          </ul>
        </span>
      </p>
      <p>
        <span>10.2</span>
        <span
          >These Terms were written in English (UK). To the extent any translated version of these
          Terms conflicts with the English version, the English version shall prevail.</span
        >
      </p>
      <p>
        <span>10.3</span>
        <span
          >Do not hesitate to contact us if you have any questions about how your Personal Data is
          processed or if you would like to request a transcript. You will find our contact details
          in section 1.</span
        >
      </p>
      <h2>Change History</h2>
      <p>
        September 2019: Clarifications due to updates and improvements to the Service. The updated
        privacy policy will automatically enter into force for all existing customers and users on
        the day of publication on this website. Your continued use of our services from that date
        will be subject to the new privacy policy. The privacy policy has also been revised to be
        concise, clear and easier to understand.
      </p>
    </section>
    <section class="filler"></section>
    <page-footer />
  </div>
</template>
<script>
export default {
  route: '/privacypolicy',
  noAuthentication: true,
};
</script>
