import { render } from "./TipTapEditorMenu.vue?vue&type=template&id=44170db2"
import script from "./TipTapEditorMenu.vue?vue&type=script&lang=js"
export * from "./TipTapEditorMenu.vue?vue&type=script&lang=js"

import "./TipTapEditorMenu.vue?vue&type=style&index=0&id=44170db2&lang=scss"
script.render = render

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QBtn,QItem,QField});
