<template>
  <div>
    <div class="tag" :tag-id="item.id">{{ item.title }}</div>
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
