<template>
  <div>
    <div class="page-content with-footer less-top-padding createstatus CreateStatus">
      <section>
        <div class="status-icon large" :status-id="status.id">
          <i class="icon-"></i>
        </div>
      </section>
      <section class="main-attributes">
        <div class="row">
          <q-input v-model="status.name" label="Name" class="name" autocorrect="off" />
          <q-input v-model="status.iconText" label="Icon Text" class="iconText" autocorrect="off" />
        </div>
        <color-picker :color="status.color" @select="selectColor"></color-picker>
      </section>
    </div>
    <footer>
      <a-btn color="primary" @click="save">Save</a-btn>
    </footer>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  props: ['status'],
  smallModal: true,

  methods: {
    selectColor(color) {
      this.status.color = color;
    },
    save() {
      return this.status.save().then(() => {
        this.$emit('close');
      });
    },
    changeName() {
      var status = this.status;
      if (_.isEmpty(status.iconText) && !status.fixed && !_.isEmpty(status.name)) {
        status.iconText = status.name.substring(0, 1).toUpperCase();
      }
    },
  },
};
</script>
<style lang="scss">
.CreateStatus {
  .row {
    display: flex;
    align-items: flex-end;

    .name {
      flex: 1;
    }
    .iconText {
      flex: 0 1 80px;

      margin-left: 10px;
    }
  }
}
</style>
