<template>
  <div class="box github GithubSettings">
    <template v-if="project.githubAccess">
      <div class="info">
        The project is connected to Github Repos
      </div>
      <div v-if="project.githubSettings.loadingState">
        {{ project.githubSettings.loadingState }}
      </div>
      <div class="button-row">
        <a-btn color="primary" @click="selectGithubRepos">Edit Github Repos</a-btn>

        <a-btn @click="removeGithubConnection">Remove Github Access</a-btn>
      </div>
    </template>
    <template v-else>
      <div>Connect to Github</div>
      <div class="button-row">
        <a-btn color="primary" @click="connectGithub">Connect to Github</a-btn>
      </div>
    </template>
  </div>
</template>
<script>
import SelectGithubReposModal from '@/components/github/SelectGithubRepos.vue';
import { handleModelResponse } from '@/common/util';
import { App, Util } from '@';
export default {
  props: ['project'],
  methods: {
    async connectGithub() {
      const { url } = await Util.jsonajax({
        url: '/github/connect',
        data: { projectId: this.project.id },
      });

      window.open(url, '_self');
    },
    selectGithubRepos() {
      App.openModal(SelectGithubReposModal);
    },
    removeGithubConnection() {
      const projectId = this.project.id;
      App.openConfirmDialog({
        title: 'Remove Github Access',
        text: 'This will remove Github access',
        onConfirm() {
          Util.jsonajax({
            url: '/github/removeconnection',
            data: { projectId },
          }).then(handleModelResponse);
        },
      });
    },
  },
};
</script>
<style lang="scss">
.GithubSettings {
  .info {
    padding-bottom: 10px;
  }

  button + button {
    margin-left: 10px;
  }
}
</style>
