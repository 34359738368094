<template>
  <a
    class="ViewSprintsRow list-row sprint navigate-slide"
    :href="sprint.url"
    :class="cssClass"
    @mousedown="mousedown"
    @touchstart="mousedown"
  >
    <div class="title text">{{ sprint.title }}</div>

    <q-btn round flat @click.prevent="edit" ref="edit"><i class="icon-pen6"></i></q-btn>
    <q-btn round flat @click.prevent="destroy"> <i class="icon-bin"></i></q-btn>
  </a>
</template>
<script>
import { App } from '@/namespace';

import { deleteSprint } from '@/models/sprintHandler';
import EditSprintPopover from '@/popovers/EditSprintPopover';
export default {
  props: ['sprint'],
  data() {
    return {
      dragStatus: {},
    };
  },
  computed: {
    cssClass() {
      return { ...this.dragStatus };
    },
  },
  methods: {
    destroy() {
      deleteSprint(this.sprint);
    },
    mousedown(ev) {
      App.startDragSession({ ev, dragItem: this });
    },
    getModel() {
      return this.sprint;
    },
    clearDragStatus() {
      this.dragStatus = {};
    },
    setDragStatus(name, value) {
      this.dragStatus[name] = value;
    },
    edit() {
      const sprint = this.sprint;
      App.openPopover(EditSprintPopover, { sprint }, { target: this.$refs.edit.$el });
    },
  },
};
</script>
<style lang="scss">
.ViewSprintsRow {
  padding-right: 10px;
  .q-btn {
    margin-right: 0;
  }
}
</style>
