<template>
  <div class="page-content page_projects wide">
    <section class="">
      <div class="main-title">Projects</div>

      <div class="projects">
        <a
          v-for="project in projects"
          class="project navigate-slide"
          :href="project.url"
          :key="project.id"
        >
          <div class="title">{{ project.title }}</div>
          <warning-link v-if="project.premiumRequired && !profile.premium" href="/premiumplan"
            >Premium Required
          </warning-link>
          <div class="footer">
            <div class="items">
              <template v-if="project.issueCount">{{ project.issueCount }} items</template>
            </div>
            <div class="update-date" v-tooltip="updateDateTooltip(project.updated)">
              {{ formatUpdateTime(project.updated) }}
            </div>
          </div>
        </a>

        <a
          v-if="!profile.userSettings.hideCreateSampleProject"
          class="project"
          @click="createSampleProject"
        >
          <div class="title">Sample Project</div>
        </a>

        <a class="project" @click="createNew" id="create-project" v-if="showCreateProject">
          <div class="title">
            <i class="icon-plus3"></i>
            <span>New Project</span>
          </div>
        </a>
      </div>
    </section>

    <teleport to="#navbar" v-if="isPageActive">
      <q-btn @click="createNew" rounded>
        <i class="icon-plus3"></i>
        <span>Create Project</span>
      </q-btn>
    </teleport>
  </div>
</template>
<script>
import { App, Model, Page } from '@/namespace';

import { pageMixin } from '@/common';
import CreateProject from '@/pages/createproject/CreateProject.vue';
import { format } from 'date-fns';
import { formatUpdateTime } from '@/utils';
export default {
  mixins: [pageMixin],
  route: '/projects',
  props: ['projects', 'profile'],
  getData() {
    return { projects: Model.Project.findAll(), profile: Model.Profile.get() };
  },
  computed: {
    showCreateProject() {
      return this.projects.length < 2;
    },
  },
  methods: {
    formatUpdateTime,
    createNew(ev) {
      ev.preventDefault();

      App.openPopup(CreateProject, {});
    },
    createSampleProject(ev) {
      var el = ev.currentTarget;
      var dfd = Model.Project.createSampleProject().then(project => {
        this.profile.userSettings.hideCreateSampleProject = true;
        this.profile.save();
        App.router.navigate(project.url);
        el.preloader(dfd);
      });
    },
    updateDateTooltip(date) {
      if (date == null) {
        return null;
      }
      return 'The project was last updated ' + format(date, 'do MMMM yyyy HH:mm');
    },
  },
};
</script>
<style lang="scss">
.page_projects {
  .projects {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    width: calc(100% + 10px);
  }

  .project {
    width: calc(25% - 20px);
    display: flex;
    flex-direction: column;
    @media (max-width: 1100px) {
      width: calc(33.3% - 20px);
    }
    @media (max-width: 580px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 400px) {
      width: calc(100% - 20px);
    }

    background-color: white;
    //border: 1px solid $gray-border;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    min-height: 90px;
    margin: 10px 10px;

    cursor: pointer;
    border-radius: 2px;
    &#create-project {
      background-color: #eee;
      i {
        font-size: 11px;
        padding-right: 5px;
      }
    }
    .title {
      font-size: 16px;
      padding: 20px 20px 0 20px;
    }

    .footer {
      margin-top: auto;
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      align-items: center;
      background: #f0f0f0;
      padding: 5px 10px 5px 20px;
    }
  }
}
</style>
