<template>
  <div class="ItemContext">
    <a class="selector status" selector="status" @click="openSelector">
      <div class="status-icon large" :status-id="issueWrapper.statusMap.calculatedValue().id">
        <i class="icon-"></i>
      </div>
      <div class="name">
        {{ issueWrapper.statusMap.calculatedValue().name }}
      </div>
    </a>

    <div v-if="tags && tags.length" class="tags" style="">
      <TagView v-for="tag in tags" :key="tag.id" :tag="tag" :issue="issueWrapper.issue" />
    </div>
    <a
      v-if="issueWrapper.memberMap.isSingle()"
      class="selector member"
      href="#"
      selector="member"
      @click="openSelector"
    >
      <div class="member-icon large" :member-id="issueWrapper.memberMap.firstValue().id">
        <i class="icon-"></i>
      </div>
      <div class="name">{{ issueWrapper.memberMap.firstValue().displayName }}</div>
    </a>
    <a v-else class="selector member multiple" selector="member" @click="openSelector">
      <div
        v-for="member in issueWrapper.memberMap.toArray()"
        class="member-icon large"
        :member-id="member.id"
      >
        <i class="icon-"></i>
      </div>
    </a>

    <a class="selector sprint" selector="sprint" @click="openSelector">
      <svg-icon icon="board" class="board-icon" />
      <div class="name">
        {{ issueWrapper.sprintMap.combinedTitle() }}
      </div>
    </a>

    <div
      v-if="estimateActive || issue.totalEstimate > 0"
      class="estimate-box"
      :used-estimate="usedEstimate"
    >
      <div class="main-estimate">
        <label :for="'estimate' + issue.id">Estimate</label>
        <input
          ref="estimateInput"
          type="text"
          class="input estimate"
          :id="'estimate' + issue.id"
          v-focus="estimateAutofocus"
          v-model.number="issue.estimate"
          @blur="blurEstimate"
          @focus="focusEstimate"
          @keyup.enter="$event.target.blur"
        />
      </div>

      <template v-if="issueWrapper.estimateMap.hasSubs">
        <div class="sub-items-estimate">
          <div>Sub Items Estimate</div>
          <div class="value">
            {{ issueWrapper.estimateMap.done }} <span class="slash">/</span
            >{{ issueWrapper.estimateMap.subsEst }}
          </div>
        </div>
        <estimate-bar :estimateMap="issueWrapper.estimateMap"></estimate-bar>
      </template>
    </div>

    <a id="toggle-archived" v-if="containsArchived" @click="toggleArchived">
      <checkbox :selected="collectionSettings.includeArchived"></checkbox>
      <span>Show Archived Items</span>
    </a>

    <footer>
      <a @click="addTag">
        Add Tag
      </a>

      <a id="set-estimate" @click="activateEstimate">
        Set Estimate
      </a>
    </footer>
  </div>
</template>
<script>
import { App } from '@/namespace';
import AddTag from '@/pages/addtag/AddTag.vue';

import createTopWrapper from '@/models/issuewrapper/createTopWrapper';
import eventMixin from '@/common/eventMixin';
import TagView from '@/components/TagView';

export default {
  props: ['issue', 'collectionSettings'],
  data: function() {
    return {
      issueWrapper: createTopWrapper(this.issue, {
        includeArchived: true,
      }).bindChangeEvent(this),
      estimateActive: false,
      estimateAutofocus: false,
    };
  },
  mixins: [eventMixin],
  computed: {
    tags() {
      return this.issueWrapper.issue.tags;
    },
    containsArchived() {
      return this.issue.statusMap.containsArchived();
    },
    usedEstimate() {
      const estimateMap = this.issueWrapper.estimateMap;
      return this.issue.estimate >= estimateMap.est ? 'main-estimate' : 'sub-estimate';
    },
  },
  methods: {
    activateEstimate() {
      this.estimateAutofocus = true;
      this.estimateActive = true;
    },
    focusEstimate() {
      this.estimateActive = true;
      this.$refs.estimateInput.select();
    },
    blurEstimate() {
      this.estimateActive = false;
      this.issue.save();
    },
    openSelector: function(ev) {
      ev.stop();
      App.openSelector({
        property: ev.currentTarget.getAttribute('selector'),
        issue: this.issueWrapper,
        targetElement: ev.target,
      });
    },
    toggleArchived(ev) {
      ev.stop();
      this.collectionSettings.includeArchived = !this.collectionSettings.includeArchived;
    },
    addTag(ev) {
      var el = ev.currentTarget;
      App.openPopover(
        AddTag,
        { issue: this.issue },
        {
          type: 'menu',
          target: el,
        }
      );
    },
  },
  components: { TagView },
};
</script>
<style lang="scss" src="./ItemContext.scss"></style>
