import { render } from "./EditTag.vue?vue&type=template&id=83cfc1d8"
import script from "./EditTag.vue?vue&type=script&lang=js"
export * from "./EditTag.vue?vue&type=script&lang=js"

import "./EditTag.vue?vue&type=style&index=0&id=83cfc1d8&lang=scss"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput});
