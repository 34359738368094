<template>
  <div class="PremiumUsers split-section">
    <aside>
      <h3>Premium Users</h3>
    </aside>
    <div class="list">
      <a class="list-row" v-for="user in subscription.premiumUsers">
        <user-icon :user="user"></user-icon>
        <div class="title">
          {{ user.name }}
        </div>

        <div class="fixed-width email mobile-hidden">
          {{ user.email }}
        </div>

        <a
          class=" red-text"
          @click.prevent="removePremium(user)"
          href="#"
          v-if="subscription.fullyActive"
        >
          Remove
        </a>
      </a>

      <footer>
        <a class="left" @click.prevent="addUsers" v-show="subscription.fullyActive">
          <i class="icon-plus3"></i>
          <span>Add Premium Users</span>
        </a>
        <div></div>
        <div class="right cost">
          <div>
            Cost: {{ subscription.calcQuantity }} * ${{ subscription.price }} = ${{
              subscription.totalPrice
            }}
            / month
          </div>
          <div class="vat">(VAT added where applicable)</div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import AddPremiumUsersModal from './AddPremiumUsersModal.vue';
import { App } from '@/namespace';
export default {
  props: ['subscription'],

  methods: {
    removePremium(user) {
      const subscription = this.subscription;
      App.openConfirmDialog({
        title: 'Remove Premium User',
        text: 'This will remove the User as Premium. You will not be charged for the User.',
        onConfirm() {
          subscription.removeUser(user);
        },
      });
    },
    addUsers() {
      App.openModal(AddPremiumUsersModal, { subscription: this.subscription });
    },
  },
  computed: {
    totalPrice() {
      return this.subscription.quantity * this.subscription.price;
    },
  },
};
</script>
