<template>
  <span>
    <template v-if="templateType === 'standardText'">
      <span> {{ text }} </span>
    </template>
    <template v-else-if="templateType === 'sprint'">
      <span> added the item to </span>
      <SprintLink :id="activity.to" />
    </template>
    <template v-else-if="templateType === 'status'">
      <span> moved the item to </span>
      <StatusLink :id="activity.to" />
    </template>
    <template v-else-if="templateType === 'parent'">
      <span>
        Changed parent to
        <issue-link :id="activity.to" :projectId="activity.projectId"></issue-link>
      </span>
    </template>
    <template v-else-if="templateType === 'member'">
      <template v-if="activity.to === 'unassigned'">
        <span> set the item to </span>
        <member-link :id="activity.to"></member-link>
      </template>
      <template v-else>
        <span> assigned the item to </span>
        <MemberLink :id="activity.to" class="navigate-slide" />
      </template>
    </template>
  </span>
</template>
<script>
import { getModelById, Issue, Document, Sprint } from '@/models';
import {
  MemberLink,
  IssueLink,
  SprintLink,
  StatusLink,
  DocumentLink,
} from '@/components/propertylinks';
export default {
  props: ['activity'],
  computed: {
    templateType() {
      const { type } = this.activity;

      if (standardTexts[type]) {
        return 'standardText';
      }
      return type;
    },
    targetTemplate() {
      const { id } = this.activity;
      const model = getModelById(id);
      if (model instanceof Issue) {
        return IssueLink;
      }
      if (model instanceof Document) {
        return DocumentLink;
      }
      if (model instanceof Sprint) {
        return SprintLink;
      }
      return IssueLink;
    },
    text() {
      return standardTexts[this.activity.type];
    },
  },
  components: { MemberLink, IssueLink, SprintLink, StatusLink },
};

const standardTexts = {
  created: ' created the item',
  description: ' updated the description',
  title: ' changed the title',
  comment: ' added comment',
  estimate: ' changed the estimate',
  text: ' updated text',
};
</script>
