import StoreModel from './base/StoreModel';

import cssRule from '@/common/cssRule';
import { Model } from '@/namespace';

const types = [
  {
    id: 'item',
    color: 'white',
    name: 'Item',
  },
  {
    id: 'epic',
    color: 'blue',
    name: 'Epic',
  },
  {
    id: 'feature',
    color: 'indigo',
    name: 'Feature',
  },
  {
    id: 'story',
    color: 'green',
    name: 'Story',
  },
  {
    id: 'bug',
    color: 'red',
    name: 'Bug',
  },
];

class IssueType extends StoreModel {
  static NAME = 'IssueType';
  static loadAll() {
    return Promise.resolve(types);
  }

  static define = {
    color: {
      model: 'Color',
    },
  };

  postInitModel() {
    initStyle(this);
  }
}

function initStyle(type) {
  const { color, id, name } = type;
  let styles = {
    color: '#' + color.code,
  };
  if (color.darkText) {
    styles = {
      ...styles,

      color: '#ccc',
    };
  }
  cssRule(`.issue-type[type="${id}"]`, styles);

  let textStyle = {
    content: '"' + name + '"',
  };
  if (status.darkText) {
    textStyle.color = '#606060';
  }
  cssRule(`.issue-type[type="${id}"]:before`, textStyle);
}

Model.IssueType = IssueType;
export default IssueType;
