<template>
  <div class="page-content narrow info-pages pricing">
    <AgilooLogo />
    <section class="header">
      <h1>Pricing</h1>
    </section>
    <section class="pricing-boxes">
      <div class="box free">
        <header>Free</header>
        <div class="price-box"></div>
        <ul>
          <li>Unlimited Projects and Work Items</li>
          <li>Up to 4 members per project</li>
        </ul>
      </div>
      <div class="box premium">
        <header>Premium</header>
        <div class="price-box">
          <div class="price">$5</div>
          <div>
            <div class="price-details">per user, per month</div>
            <div class="vat">(VAT added where applicable)</div>
          </div>
        </div>

        <ul>
          <li>Unlimited Projects and Work Items</li>
          <li>Unlimited Members</li>
          <li>Billed Monthly</li>
        </ul>
      </div>
    </section>

    <page-footer />
  </div>
</template>
<script>
export default {
  route: '/pricing',
  noAuthentication: true,
};
</script>
<style lang="scss">
.pricing {
  .pricing-boxes {
    display: flex;

    @media (max-width: 580px) {
      display: block;
    }
  }

  .box {
    flex: 1 0 50%;
    margin: 10px;
    padding: 20px;

    &.free {
      background: #f4f4f4;
    }
    &.premium {
      background: $blue-d1;
      color: white;
    }
  }
  .price-box {
    display: flex;
    justify-content: center;
  }
  .price-details {
    font-size: 13px;
  }
  .vat {
    font-size: 11px;
  }
  .price {
    font-size: 24px;
    margin-right: 10px;
  }

  header {
    font-size: 24px;
    text-align: center;
  }
}
</style>
