<template>
  <div class="page-content EnterPaymentInfoModal billing">
    <section>
      <div class="payment-form">
        <PaymentForm :paymentInfo="paymentInfo" ref="form" />
        <div v-if="validationError" class="validation-error">
          {{ validationError }}
        </div>
        <div class="flex">
          <a-btn class="right" color="primary" @click="save">Update Billing Info</a-btn>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import PaymentForm from './PaymentForm/PaymentForm.vue';
import { toErrorMessage } from './paymentError';
import { findPaymentInfo, updatePaymentInfo } from '@/models/billing/billingService';

export default {
  props: ['subscription', 'countries', 'paymentInfo'],
  getData() {
    return { paymentInfo: findPaymentInfo() };
  },
  data: function() {
    return { validationError: null };
  },
  methods: {
    async save() {
      const valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }

      var paymentInfo = this.paymentInfo.serialize();
      this.validationError = null;
      updatePaymentInfo(paymentInfo)
        .then(() => {
          this.$emit('close');
        })
        .catch(error => {
          this.validationError = toErrorMessage(error);
        });
    },
  },
  components: { PaymentForm },
};
</script>
<style lang="scss">
.EnterPaymentInfoModal {
  background-color: white;

  button.right {
    margin: 0 0 0 auto;
  }

  .cardDetails {
    padding: 5px;

    a {
      padding: 5px;
      color: var(--blue);
    }
  }
}
</style>
