import { Util } from '@/namespace';
function getScrollSpeed(topOffset) {
  if (topOffset < 25) {
    return { timeout: 5, factor: 5 };
  }
  if (topOffset < 50) {
    return { timeout: 10, factor: 5 };
  }
  if (topOffset < 75) {
    return { timeout: 15, factor: 3 };
  }
  if (topOffset < 100) {
    return { timeout: 20, factor: 2 };
  }
}

export default function initScrollHandler(board, onScroll) {
  var scrollContainer = board,
    scrollTimeout,
    scrollSpeed = {
      timeout: 20,
      factor: 1,
    },
    scrollDirection,
    LEFT_OFFSET = Util.isTabletOrSmaller() ? 0 : 170,
    RIGHT_OFFSET = board.clientWidth;

  function stopScroll() {
    clearTimeout(scrollTimeout);
    scrollTimeout = null;
  }

  function doScroll() {
    var lastScrollLeft = scrollContainer.scrollLeft;

    scrollContainer.scrollLeft = scrollContainer.scrollLeft + scrollDirection * scrollSpeed.factor;
    if (lastScrollLeft === scrollContainer.scrollLeft) {
      return;
    }

    onScroll();
    scrollTimeout = setTimeout(doScroll, scrollSpeed.timeout);
  }

  return {
    isOnScroll: function() {
      return scrollTimeout != null;
    },
    onMove: function(ev, dragUpwards) {
      var fromLeft = ev.pageX - LEFT_OFFSET,
        fromRight = RIGHT_OFFSET - ev.pageX;

      if (fromLeft < 100) {
        scrollSpeed = getScrollSpeed(fromLeft);
        scrollDirection = -1;
        if (scrollTimeout == null && dragUpwards) {
          doScroll();
        }
      } else if (fromRight < 100) {
        scrollSpeed = getScrollSpeed(fromRight);
        scrollDirection = 1;
        if (scrollTimeout == null && !dragUpwards) {
          doScroll();
        }
      } else {
        if (scrollTimeout != null) {
          stopScroll();
        }
      }
    },
    onEnd: function() {
      stopScroll();
    },
  };
}
