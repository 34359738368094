<template>
  <FilterableMenu @close="close" :opt="opt"> </FilterableMenu>
</template>
<script>
import { App } from '@';

import EditTag from '@/pages/edittag/EditTag.vue';
import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
export default {
  props: ['tag', 'issue', 'showDelete'],
  data: function() {
    return {
      opt: {
        items: [
          {
            text: 'View Tag',
            icon: 'icon-price-tag2',
            url: this.tag.url,
          },
          {
            text: 'Remove Tag from Item',
            icon: 'icon-bin',
            disabled: this.issue == null,
            select: () => {
              this.issue.removeTag(this.tag);
              this.issue.save();
            },
          },
          {
            text: 'Edit Tag',
            icon: 'icon-pen6',
            select: () => App.openPopup(EditTag, { tag: this.tag }),
          },
        ].filter(m => !m.disabled),

        footerItems: [
          {
            text: 'Delete Tag',
            disabled: !this.showDelete,
            icon: 'icon-bin',
            select: () =>
              App.openConfirmDialog({
                title: 'Delete Tag',
                text: 'This will delete the tag and remove it from all Items',
                onConfirm: () => {
                  this.tag.destroy();
                },
              }),
          },
        ].filter(m => !m.disabled),
      },
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
