import {
  findCurrentTopContainer,
  findClosestCurrentDragContainer,
  buildContainerTree,
  executeOnPageComponents,
  buildWrapperIssueTree,
  getChildren,
} from './containerUtils';

import { shouldExplode } from '@/models/issuewrapper/explodes';
import { dropIn, removeDragModel, addDragModel } from './wrapperUtils';

import { doSorting } from './dragWithinContainer';
import initDropTargetHandler from './dropTargetHandler';
import { nextTick } from '@/utils';

export default function (dragSession) {
  const { containers } = dragSession;

  let dropTargetHandler = initDropTargetHandler(dragSession);
  dragSession.dropTargetHandler = dropTargetHandler;
  setCurrentContainer(containers.start);

  function dragOutFromCurrent() {
    const { dragItem } = dragSession;

    const tree = [...containers.currentTree];

    removeItem(dragItem);

    function removeItem(child) {
      if (tree.length === 0) {
        return;
      }
      const container = tree.pop();
      removeDragModel(container.wrapper, child.wrapper);
      if (isEmptyAndPartial(container.wrapper)) {
        removeItem(container);
      }
    }
  }

  function isEmptyAndPartial(wrapper) {
    // TODO clean this up. on Board, wrapper is partial even though it shouldnt be (because of archivedRule)
    // If the partial is set correctly, we could look only on the issues length and partial attribute
    return wrapper.partial && wrapper.issues.length === 0;
  }

  function dragInPreserveLevel(dragModel, toTopContainer, fromContainer) {
    let upwards = isAfter();

    const wrapperIssueTree = buildWrapperIssueTree(toTopContainer.wrapper, dragModel);
    if (wrapperIssueTree.length === 0) {
      console.error('wrapper tree is empty');
      return;
    }

    let newDragContainer;
    addChild(toTopContainer);
    function addChild(container) {
      newDragContainer = container;
      let nextIssue = wrapperIssueTree.shift();
      while (shouldExplode(nextIssue, dragModel.wrapperContext) && wrapperIssueTree.length > 0) {
        nextIssue = wrapperIssueTree.shift();
      }
      const parentWrapper = container.wrapper;
      var childWrapper = parentWrapper.findById(nextIssue.id);

      if (wrapperIssueTree.length == 0) {
        addDragModel(parentWrapper, nextIssue, upwards);
        setCurrentContainer(newDragContainer);
        return;
      }

      if (childWrapper == null) {
        childWrapper = parentWrapper.createEmptyChildWrapper(nextIssue);
        childWrapper.open = true;
        childWrapper.partial = true;
        addDragModel(parentWrapper, childWrapper, upwards);
      }

      nextTick(() => {
        const childContainer = getChildren(container).find(c => {
          return c.wrapper && c.wrapper.id === nextIssue.id;
        });

        addChild(childContainer);
      });
    }

    function isAfter() {
      if (fromContainer.$el.is('.BoardColumn')) {
        return fromContainer.$el.boxOffset().left < toTopContainer.$el.boxOffset().left;
      } else {
        return fromContainer.$el.boxOffset().top > toTopContainer.$el.boxOffset().top;
      }
    }
  }

  function waitForDragIntoNewParentElement(newContainer) {
    if (containers.start === newContainer) {
      dragIntoNewParentElement(newContainer);
      return;
    }

    if (containers.startTop !== containers.currentTop) {
      return;
    }

    dropTargetHandler.initHoldTimer(newContainer, () => {
      dragIntoNewParentElement(newContainer);
      dropTargetHandler.clear();
    });
  }

  function dragIntoNewParentElement(newParentItem) {
    const { dragModel } = dragSession;
    dragOutFromCurrent();
    newParentItem.wrapper.add(dragModel, { direction: dragSession.direction.up });

    dragModel.newParent = newParentItem.wrapper.issue;
    dragModel.level = newParentItem.wrapper.level + 1;
    newParentItem.wrapper.showSubIssues();
    nextTick(() => {
      setCurrentContainer(newParentItem);
      doSorting(dragSession);
    });
  }

  function dragToNewContainer() {
    var newTopContainer = findCurrentTopContainer(dragSession);

    if (newTopContainer == null) {
      return;
    }
    if (newTopContainer !== containers.currentTop) {
      dragIntoNewTopContainer(newTopContainer);
    } else {
      const parent = findClosestCurrentDragContainer(dragSession, newTopContainer);
      waitForDragIntoNewParentElement(parent);
    }
  }

  function dragIntoNewTopContainer(newTopContainer) {
    const { dragModel } = dragSession;
    dropTargetHandler.clear();

    dragOutFromCurrent();

    dragInPreserveLevel(dragModel, newTopContainer, containers.current);
  }

  function setCurrentContainer(container) {
    if (!container.$el.is('.DragContainer')) {
      console.error('not DragContainer!!!');
      return;
    }

    containers.currentTop && containers.currentTop.setDragStatus('topDropTarget', false);
    containers.currentTree = buildContainerTree(container);
    containers.currentTop = containers.currentTree[0];

    containers.current = container;
    containers.currentTop.setDragStatus('topDropTarget', true);
  }

  function onEnd() {
    const { dragModel, dragItem } = dragSession;
    dropTargetHandler.clearHoldTimer();
    if (dragModel.parent() !== dragModel.issue.parent) {
      dragModel.issue.set('parent', dragModel.parent());
      delete dragModel.newParent;
      //dragModel.issue.save()
    }

    if (containers.current.wrapper !== containers.start.wrapper) {
      dropIn(containers.current.wrapper, dragModel, dragSession.direction.up);
    } else {
      dragModel.setNewPriorityOnIssue();
      dragModel.issue.save();
    }

    executeOnPageComponents(dragItem, item => {
      if (item.wrapper) {
        item.wrapper.postInit();
        item.wrapper.clearDragStatus();
      }
    });

    dropTargetHandler.clear();
  }

  return {
    waitForDragIntoNewParentElement,
    dragToNewContainer,
    onEnd,
  };
}
