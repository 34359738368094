<template>
  <div class="ViewActivity">
    <div class="activity-list" v-if="activityMap">
      <div v-for="dateHolder in activityMap.dates">
        <h3 class="date-header">{{ formatDate(dateHolder.date) }}</h3>

        <div class="activity" v-for="activity in dateHolder">
          <member-link :id="activity.by"></member-link>

          <ActivityRow :activity="activity" />
          <div class="date">
            {{ formatTime(activity.at) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './activity-list.scss';

import ActivityRow from './ActivityRow';
export default {
  props: ['issue'],
  data() {
    return {
      activityMap: null,
    };
  },
  watch: {},
  async mounted() {
    this.activityMap = await this.issue.fillActivityMap();
  },
  components: { ActivityRow },
};
</script>
<style lang="scss">
.ViewActivity {
  .activity-list {
    margin-left: 20px;

    .date-header {
      margin: 20px 0 0 0;
      border-bottom: 1px solid #e8e8e8;
      font-size: 14px;
      padding-left: 10px;
    }
  }
}
</style>
