<template>
  <div class="issue item-list-item">
    <div class="row">
      <IssueCount :wrapper="item" :preventClicks="true" />
      <StatusIcon :wrapper="item" :preventClicks="true" />
      <div class="title">
        <div class="issue-type small" v-if="item.issueType" :type="item.issueType"></div>
        <span class="title-text">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { StatusIcon, IssueCount } from '@/components/issue-collection';
export default {
  props: ['item'],
  components: { StatusIcon, IssueCount },
};
</script>
