import WarningLink from './warning-link.vue';
import helpkey from './helpkey.vue';
import ABtn from './a-btn';
import TextInput from './text-input.vue';
import SvgIcon from './svg-icon.vue';

export function initElements(app) {
  app.component('WarningLink', WarningLink);
  app.component('helpkey', helpkey);
  app.component('ABtn', ABtn);
  app.component('TextInput', TextInput);
  app.component('SvgIcon', SvgIcon);
}
