<template>
  <a class="item-list-item issue create-issue">
    <div class="row">
      <text-input
        v-model="title"
        v-focus
        class="title text"
        placeholder="Item Title"
        @change="change"
      ></text-input>
    </div>
  </a>
</template>
<script>
import { _, Model } from '@';

import { getNewPriority, setNewPriority } from '@/models/priority/priorityCalculation';
export default {
  props: ['parentWrapper'],
  data: function() {
    return { title: '' };
  },

  methods: {
    change() {
      const { parentWrapper, title } = this;
      if (_.isEmpty(title)) {
        this.parentWrapper.cleanupCreateIssue();
        return;
      }
      var newIssue = Model.Issue.createNew({
        parent: parentWrapper.issue,
        title: title,
      });
      parentWrapper.applyChange(newIssue);
      setInitialPriority(newIssue, parentWrapper);
      const postCreateHook = () => {
        parentWrapper.cleanupCreateIssue();
      };
      newIssue.save({ postCreateHook });
    },
  },
};

function setInitialPriority(issue, parentWrapper) {
  const sortIndex = parentWrapper.issues.findIndex(is => is.isCreateIssue);
  const priority = getNewPriority(parentWrapper.issues, sortIndex);
  setNewPriority(issue, null, null, priority);
}
</script>
