<template>
  <div class="page-content narrow info-pages">
    <AgilooLogo />
    <section class="header">
      <h1>Terms and Conditions</h1>
    </section>
    <section class="legal-text">
      <h2>1. GENERAL</h2>
      <p>
        <span>1.1</span>
        <span>
          These terms and conditions (“Terms”) applies when [Agiloo Digital AB] reg. no.
          556847-1600, address Ölandsgatan 49B, 116 63 Stockholm, Sweden, e-mail: info@agiloo.com,
          (“Agiloo”) supplies the Agiloo website www.agiloo.com (the “Website”) and the Service, as
          defined below.
        </span>
      </p>
      <p>
        <span>1.2</span>
        <span>
          The Service is a web based service with which one can digitally manage and control
          projects of various types online (the “Service”). For example, a user may create different
          work items and sub-items to be dealt with and to allocate roles and responsibilities for
          their performance amongst participants in a project. The user may also invite others to
          become users of this Service and to participate and contribute to projects. Users may be
          also be able to upload various types of material that is relevant for the project, such as
          documents, pictures and presentations to the Service.
        </span>
      </p>
      <p>
        <span>1.3</span>
        <span>
          In order to be eligible to use the Service you must be at least 18 years of age, complete
          the registration of a user account and accept these Terms and our Privacy Policy available
          <a href="/termsconditions">here</a>.
        </span>
      </p>
      <p>
        <span>1.4</span>
        <span>
          By creating an account and by using the Service by clicking on the “I Agree” button when
          you create your account you hereby acknowledge and agree to these Terms.
        </span>
      </p>

      <h2>2. REGISTRATION AND ACCOUNT CREATION</h2>

      <p>
        <span>2.1</span>
        <span>
          In order to use the Service, you will have to create a user account. You may do this in
          two ways:
          <ul class="list-style-none">
            <li>a) Either directly via the Website where you register a new user account, or:</li>
            <li>
              b) By receiving an e-mail invitation to start using the Service from someone who is
              already a user including a link to the Website where you register a new user account.
            </li>
          </ul>
        </span>
      </p>
      <p>
        <span>2.2</span>
        <span>
          The registration process includes submitting your e-mail address, choosing a user name and
          choosing a password. You may thereafter sign in to your user account for the Service.
        </span>
      </p>
      <p>
        <span>2.3</span>
        <span>
          You agree that all registration information that you provide is accurate and up to date.
          In our sole discretion, we reserve the right to refuse any user name that impersonates
          some one else, is protected by trademark or other proprietary right law, or is offensive.
        </span>
      </p>
      <p>
        <span>2.4</span>
        <span>
          When creating your user account, you will be asked to submit certain information about
          yourself which will be used to provide you with the Service, such as:
          <ul>
            <li>Full name,</li>
            <li>E-mail-address, and</li>
            <li>Password</li>
          </ul>
          Premium users will also be required to provide their payment information to the third
          party payment service provider we use. For further information regarding how we process
          this information and protect your privacy, see our privacy policy
          <a href="/privacypolicy">here</a>.
        </span>
      </p>
      <p>
        <span>2.5</span>
        <span>
          The use of personal information you supply or communicate to the Service will be processed
          in compliance with Agiloo’s privacy policy, which you can find
          <a href="/privacypolicy">here</a>.
        </span>
      </p>
      <p>
        <span>2.6</span>
        <span>
          Agiloo does not claim ownership of any user generated content uploaded on or to the
          Service. With exception of Clause 5.2.
        </span>
      </p>
      <p>
        <span>2.7</span>
        <span>
          The Agiloo name and logo are pending trademarks of Agiloo, and may not be copied, imitated
          or used, in whole or in part, without the prior written permission of Agiloo.
        </span>
      </p>

      <h2>3. SUBSCRIPTION</h2>
      <p>
        <span>3.1</span>
        <span>
          The Service is provided in two different versions, the Basic version and the Premium
          version.
        </span>
      </p>
      <p>
        <span>3.2</span>
        <span>
          The Basic version is a free of charge subscription to the Service including specified
          functions and features as published on the Website, for link to specifics
          <a href="/pricing">click here</a>.
        </span>
      </p>
      <p>
        <span>3.3</span>
        <span>
          The Premium version is a monthly subscription to the Service including all features of the
          Service. For link to specifics features and price information
          <a href="/pricing">click here</a>.
        </span>
      </p>
      <p>
        <span>3.4</span>
        <span>
          Agiloo may also provide users with new, additional services or features to the Service.
          Such new services or features can be associated with particular conditions. If this is the
          case, Agiloo will present these conditions on the Webpage and, should it be required by
          substantive law, request your consent. If the user wants to activate the new service user
          must accept the special conditions applicable to that service.
        </span>
      </p>
      <p>
        <span>3.5</span>
        <span>
          You acknowledge that your user account and password are individual and private information
          and agree not to share this information with other people or companies. You are solely
          responsible to keep your password to yourself.
        </span>
      </p>

      <h2>4. PAYMENTS, CANCELLATION AND REFUNDS</h2>

      <p>
        <span>4.1</span>
        <span>
          Your subscription to the Service runs for an initial period of 30 days (“Subscription
          Period”). Your subscription will automatically renew at the end of each Subscription
          Period, unless you cancel your subscription before the current Subscription Period has
          lapsed. The cancellation will take effect the day after the current Subscription Period
          has lapsed.
        </span>
      </p>
      <p>
        <span>4.2</span>
        <span>
          Agiloo uses a third party payment supplier, Stripe, for management of all payments for the
          Service. This means that if you want to use the Premium version of Agiloo, and therefore
          make a payment to Agiloo, you will have to enter an agreement seperately with Stripe
          regarding terms and conditions for your payment, that exclusively will regulate your
          payment to Agiloo. Please see our privacy policy here for further information.
        </span>
      </p>
      <p>
        <span>4.3</span>
        <span>
          Payment for the Service will be charged in advance on the first day of each Subscription
          Period.
        </span>
      </p>
      <p>
        <span>4.4</span>
        <span>
          Agiloo may change the price or features of the different subscriptions from time to time,
          and will communicate any such changes to you in advance and, if applicable, how to accept
          the changes.
        </span>
      </p>

      <p>
        <span>4.5</span>
        <span>
          Price changes for subscriptions will take effect at the start of the next Subscription
          Period following the date of the price change. As permitted by local law, you accept the
          new price by continuing to use the Service after the price change takes effect. If you do
          not agree with the price changes, you have the right to reject the change by unsubscribing
          to the service prior to the change enters into effect. Please make sure that you read any
          such notification of price changes carefully.
        </span>
      </p>

      <h2>5. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        <span>5.1</span>
        <span>
          The Website and the Service is protected by copyright law, trademark law, other
          intellectual property law and other regulations under Swedish law, and the law of other
          countries. You hereby confirm and agree to that the Website and the Service and all
          related intellectual property rights exclusively belongs to Agiloo, Agiloo’s licensees and
          Agiloo’s licensors. You may not delete, change, distribute, distort any type of copyright-
          trademark or other intellectual property right that is available on or in connection to
          the Service and the Website.
        </span>
      </p>
      <p>
        <span>5.2</span>
        <span>
          Agiloo is free to use any feedback, comments, or suggestions the user may send, or
          otherwise provide to Agiloo, concerning the Service and the Website, and use it to improve
          the Service and Website, or otherwise how Agiloo see fit, without any obligation of
          specific compensation or other obligation to the user. More information regarding the
          protection of your privacy can be found in our privacy policy
          <a href="/privaciypolicy">here</a>.
        </span>
      </p>
      <p>
        <span>5.3</span>
        <span>
          Agiloo does not claim ownership of any user generated content uploaded on or to the
          Service. With exception of Clause 5.2
        </span>
      </p>
      <h2>6. PERMITTED USE</h2>
      <p>
        <span>6.1</span>
        <span>
          It is not permitted to reproduce, copy, modify, distribute, sell, trade, resell or exploit
          the Service provided by Agiloo. For personal use, it is allowed to download, copy and save
          your account information from Agiloo. It is not allowed to unduly exploit Agiloo or
          otherwise abuse the Service.
        </span>
      </p>
      <p>
        <span>6.2</span>
        <span>
          By accepting these Terms, you warrant that:
          <ul class="list-style-none">
            <li>
              a) You are solely responsible for any messages, notes and other content you upload,
              publish or display to users of the Service;
            </li>
            <li>
              b) The content that you upload or put into the Service (including any messages to
              other users of the Service) does not infringe on someone else's rights or otherwise
              conflict with the law or the rights of others.
            </li>
            <li>
              c) You have the right to dispose of your external credentials and content, and that
              you have obtained the requisite consents to the extent required to use the Service and
              for Agiloo to have the right to provide you with its Services.
            </li>
            <li>
              d) You will not use the Service to upload, post, transmit, share, store or otherwise
              make available any content that we deem to be harmful, threatening, unlawful,
              defamatory, infringing, abusive, inflammatory, harassing, vulgar, obscene, fraudulent,
              invasive of privacy or publicity rights, hateful, or racially, ethnically or otherwise
              objectionable.
            </li>
            <li>
              e) You are not to upload, post, transmit, share or otherwise make available any
              unsolicited or unauthorized advertising, solicitations, promotional material, “junk
              mail”, “spam”, chain letter”, “pyramid schemes” or any other form of solicitation.
            </li>
          </ul>
        </span>
      </p>
      <p>
        <span>6.3</span>
        <span>
          You agree that you are responsible for all data charges you incur through the use of the
          Service.
        </span>
      </p>
      <h2>7. BREACH OF CONTRACT AND LIMITATION OF LIABILITY</h2>
      <p>
        <span>7.1</span>
        <span>
          Agiloo has the right at any time to cancel a user account and close an account if the user
          violates these Terms or otherwise use the Service in a way that may cause Agiloo or third
          parties any harm.
        </span>
      </p>
      <p>
        <span>7.2</span>
        <span>
          Agiloo intends that all information in the Service is accurate and as up to date as
          possible. Agiloo cannot guarantee the availability of the Service or the reliability or
          accuracy of the information that is presented in the Service or the Website. Agiloo cannot
          be held accountable if the Service is inaccessible or if any information or functionality
          does not work as expected.
        </span>
      </p>
      <p>
        <span>7.3</span>
        <span>
          Agiloo is under no circumstances liable for any loss or damages of any kind that are
          directly or indirectly related to: (i) the Service or the Website; (ii) the content and
          user generated content of the Service and the Website; (iii) your use of, or inability to
          use or the performance of the Service, or; (iv) any action taken in connection with
          copyright or other intellectual property owners.
        </span>
      </p>
      <p>
        <span>7.4</span>
        <span>
          As stated in Clause 4.2, Agiloo uses a third party payment supplier, Stripe, for
          management of all payment for the Service made by you as a user. The agreement between you
          and Stripe exclusively regulates your payment to Agiloo. Therefore Agiloo is under no
          circumstances liable for any loss or damages of any kind that are directly or indirectly
          related to your payment of the Service. Any claims related to the payment shall be delt
          with accordingly to the agreement between you and Stripe.
        </span>
      </p>
      <h2>8. THIRD PARTY WEBPAGES</h2>
      <p>
        <span>8.1</span>
        <span>
          In order to provide you with a user-friendly Service, we use services from third parties
          for e.g. to host the Service internal communication, analysis of user data, payment
          services and customer support. Please note that any information that you enter on a third
          party website (either integrated with Agiloo or accessible via digital or otherwise online
          redirection ), will be shared with the owner of that third party. Your information may be
          subject to the privacy policies as well as the terms of use of such third party sites.
        </span>
      </p>
      <h2>9. FORCE MAJEURE</h2>
      <p>
        <span>9.1</span>
        <span>
          A Party is not responsible for any delay or loss due to circumstances that the party has
          no control of and which significantly hamper the fulfilment of the obligation in question,
          or that causes the fulfilment of the obligation not to be economically justifiable.
        </span>
      </p>
      <h2>10. MODIFICATION OF THESE TERMS</h2>
      <p>
        <span>10.1</span>
        <span>
          Agiloo has the right at any time to change or modify the Service, change the Terms or to
          stop providing the Service. If the changes are material, we will provide you additional,
          prominent notice as appropriate under the circumstances and, where required under
          applicable law. Agiloo If you do not accept the amended terms, you have the right to
          terminate the agreement with Agiloo before the amended terms become effective.
        </span>
      </p>
      <h2>11. TERMINATE AN ACCOUNT</h2>
      <p>
        <span>11.1</span>
        <span>
          You may at any time terminate your account and your use of the Service by selecting the
          "Deactivate Account" or similar function in the Service or by contacting Agiloo by other
          means, such as e.g. e-mail.
        </span>
      </p>
      <p>
        <span>11.2</span>
        <span>
          Upon termination, Agiloo will delete the user account and your log-in information. If you
          are the only user of a project, the uploaded information to Agiloo will be deleted
          automatically upon termination of the account. The same applies when a project as a whole
          has been deleted. If a project has several users, the information you have uploaded to
          Agiloo will not be automatically deleted.
        </span>
      </p>
      <p>
        <span>11.3</span>
        <span>
          Agiloo and its Service always gives the user an opportunity to delete uploaded
          information.
        </span>
      </p>
      <h2>12. INDEMNITY</h2>
      <p>
        <span>12.1</span>
        <span>
          You will indemnify and hold Agiloo (and its officers, directors, agents, subsidiaries,
          joint ventures and employees) harmless from any claim or demand, including reasonable
          attorneys' fees, made by any third party due to or arising out of your breach of these
          Terms, or your violation of any law or the rights of a third party.
        </span>
      </p>
      <h2>13. MISCELLANEOUS</h2>
      <p>
        <span>13.1</span>
        <span>
          Agiloo has the right to assign its rights and obligations under these Terms to any third
          party. Furthermore, Agiloo has the right to subcontract the performance of our duties
          under this Agreement.
        </span>
      </p>
      <p>
        <span>13.2</span>
        <span>
          If the ownership of Agiloo’s business changes, Agiloo may transfer Your information,
          subscription and user account to another legal entity so that they can continue to provide
          You with the Service. This new legal entity that will hold Your information, subscription
          and user account will still have to honor the commitments Agiloo and You have made in
          these Terms and in the Privacy Policy.
        </span>
      </p>
      <p>
        <span>13.3</span>
        <span>
          These Terms were written in English (UK). To the extent any translated version of these
          Terms conflicts with the English version, the English version shall prevail.
        </span>
      </p>
      <p>
        <span>13.4</span>
        <span>
          You acknowledge and agree to that no agency, partnership, joint venture,
          employee-employer, franchiser-franchisee relationship, etc. is intended or created between
          you and Agiloo by these Terms.
        </span>
      </p>
      <p>
        <span>13.5</span>
        <span>
          These Terms shall be governed by and construed in accordance with Swedish laws, without
          regard to its conflict of law rules.
        </span>
      </p>
      <p>
        <span>13.6</span>
        <span>
          Disputes arising out of or in connection with these Terms or your use of the Service, or
          the breach, termination or invalidity thereof, shall be finally settled by the Swedish
          courts, with the District Court of Stockholm as the first instance, unless otherwise
          provided by mandatory law.
        </span>
      </p>
      <h2>Change History</h2>
      <p>
        September 2019: Updated terms due to updates to the Service. The terms have been revised for
        clarity. The updated terms will automatically enter into force for all existing customers
        and users on the day of publication on this website. Your continued use of our services from
        that date will be subject to the new terns.
      </p>
    </section>

    <page-footer />
  </div>
</template>
<script>
export default {
  route: '/termsconditions',
  noAuthentication: true,
};
</script>
