<template>
  <a class="list-row InvoiceRow">
    <div class="date">
      {{ formatDate(invoice.date) }}
    </div>
    <div class="amount">${{ invoice.total }}</div>
    <div class="status" :class="statusText">
      {{ statusText }}
    </div>
    <a class="download-invoice" v-if="invoiceUrl" :href="invoiceUrl" target="_blank">
      <i class="icon-cloud-download"></i>
    </a>
  </a>
</template>
<script>
export default {
  props: ['invoice'],

  methods: {},
  computed: {
    statusText() {
      return this.invoice.paid ? 'Paid' : 'Pending';
    },
    invoiceUrl() {
      return this.invoice.invoiceUrl;
    },
  },
};
</script>
