import BaseModel from './base/BaseModel';

import { Model, Util } from '@/namespace';

class File extends BaseModel {
  static defaults = {
    thumburl: null,
    filename: null,
    fileurl: null,
  };

  delete() {
    return Util.jsonajax({
      url: '/file/delete',
      data: {
        filename: this.filename,
        parentId: this.parent.id,
        parentType: this.parent.constructor.NAME,
      },
    });
  }
  get iconClass() {
    var filename = this.get('filename');
    if (filename == null) {
      return 'icon-file-empty';
    }
    var filetype = filename
      .toLowerCase()
      .split('.')
      .pop();
    switch (filetype) {
      case 'png':
      case 'jpeg':
      case 'jpg':
      case 'img':
      case 'gif':
        return 'icon-file-picture';

      case 'txt':
      case 'doc':
      case 'docx':
        return 'icon-file-text2';

      default:
        return 'icon-file-empty';
    }
  }

  waitForUploadDone() {
    if (this.fileUploadPromise) {
      return this.fileUploadPromise;
    }
    return Promise.resolve();
  }
}

Model.File = File;
export default File;
