<template>
  <a class="issue-link" :href="document.url">
    <i class="icon-file-text2 document-icon"></i>

    <div class="name">
      {{ document.title }}
    </div>
  </a>
</template>

<script>
import { Model } from '@/namespace';
export default {
  props: ['id'],

  computed: {
    document() {
      return Model.Document.get(this.id);
    },
  },
};
</script>
