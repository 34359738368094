import { App, Model } from '@/namespace';
import CreateIssueCard from './CreateIssueCard.vue';
import BoardCard from './BoardCard.vue';
import dragContainerMixin from '@/components/drag/issueDragContainerMixin';
export default {
  mixins: [dragContainerMixin],
  data: function() {
    return { createNewIssue: false };
  },
  computed: {
    isDone() {
      return this.wrapper.wrapperContext.status.isDone() && this.wrapper.issues.length > 0;
    },
    isTodo() {
      return this.wrapper.wrapperContext.status.key === 'todo';
    },
    cssClass() {
      return this.wrapper.dragStatus;
    },
  },
  props: ['wrapper'],
  methods: {
    archiveItems() {
      var wrapper = this.wrapper,
        archived = Model.Status.getByKey(wrapper.issue.projectId, 'archived');

      App.openConfirmDialog({
        title: 'Archive Items',
        text: 'This will archive all Items ' + wrapper.wrapperContext.status.name,
        onConfirm: function() {
          wrapper.saveProperty('status', archived, {
            propagateDown: true,
          });
        },
      });
    },
    createSiblingIssue({ sibling }) {
      this.wrapper.addCreateIssue({ sibling });
    },
    openCreateIssue() {
      this.wrapper.addCreateIssue();
    },
  },
  components: { CreateIssueCard, BoardCard },
};
