import { Model, Util } from '@/namespace';
import _ from 'lodash';
import BaseModel from './base/BaseModel';
class GithubData extends BaseModel {
  constructor(attributes) {
    super(attributes);

    _.each(this.commits, c => {
      c.date = new Date(c.date);
    });
    _.each(this.pullRequests, c => {
      c.createdAt = new Date(c.createdAt);
    });
  }

  get size() {
    return this.commits.length + this.pullRequests.length + this.branches.length;
  }
}

Model.GithubData = GithubData;
export default GithubData;
