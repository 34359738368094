<template>
  <div class="LeftBar">
    <a class="select-project" color="primary" @click="openSelectProject">
      <span v-if="currentProject">{{ currentProject.title }}</span>
      <span v-else>Select Project</span>
      <i class="icon-arrow-down4"></i>
    </a>
    <div class="menu main" v-bind:class="{ disabled: !currentProject }">
      <a
        class="menulink submenu items"
        :href="projectLink('project')"
        :class="{ active: isActive('items') }"
      >
        <i class="icon-files-empty"></i>
        <span>Work Items</span>
      </a>
      <a
        class="menulink submenu sprints"
        :href="boardLink"
        :class="{ active: isActive('sprints') }"
      >
        <svg-icon icon="board" class="board-icon" />
        <span>Board</span>
      </a>
      <a
        class="menulink submenu"
        :href="projectLink('documents')"
        :class="{ active: isActive('documents') }"
      >
        <i class="icon-file-text2"></i>
        <span>Documents</span>
      </a>

      <a
        class="menulink navigate submenu"
        :href="projectLink('members')"
        :class="{ active: isActive('members') }"
      >
        <i class="icon-users"></i>
        <span>Team</span>
      </a>

      <a
        class="menulink navigate submenu"
        :href="projectLink('projectsettings')"
        :class="{ active: isActive('projectsettings') }"
      >
        <i class="icon-cog"></i>
        <span>Project Settings</span>
      </a>
      <a
        class="menulink navigate submenu"
        :href="projectLink('activity')"
        :class="{ active: isActive('activity') }"
      >
        <i class="icon-pulse2"></i>
        <span>Activity</span>
        <span
          class="details"
          v-if="currentProject && currentProject.updated"
          v-tooltip="updateTooltip"
          >{{ formatUpdateTime(currentProject.updated) }}</span
        >
      </a>
      <div class="divider"></div>

      <a class="menulink submenu" :href="helpurl">
        <span>Help Center</span>
      </a>
    </div>
  </div>
</template>
<script>
import { format } from 'date-fns';
import { App } from '@/namespace';
import { formatUpdateTime } from '@/utils';
export default {
  computed: {
    currentProject: function () {
      return App.vm.currentProject;
    },
    vm() {
      return App.vm;
    },
    profile() {
      return App.vm.profile;
    },
    boardLink() {
      if (!this.currentProject) {
        return null;
      }

      return '/' + this.currentProject.id + '/sprint/' + this.currentProject.sprints[0].id;
    },
    updateTooltip() {
      return (
        'The project was last updated ' + format(this.currentProject.updated, 'do MMMM yyyy HH:mm')
      );
    },
    helpurl() {
      const activePage = App.vm.activePage;

      return (activePage && activePage.component.helpurl) || '/help/getstarted';
    },
  },
  methods: {
    formatUpdateTime,
    projectLink: function (appender) {
      if (!this.currentProject) {
        return null;
      }
      return '/' + this.currentProject.id + '/' + appender;
    },

    isActive(menuItem) {
      const activePage = App.vm.activePage;

      return activePage && menuItem === activePage.component.menu;
    },

    openSelectProject(ev) {
      App.openSelector({
        property: 'project',
        targetElement: ev.target,
      });
    },
  },
};
</script>
<style lang="scss" src="./LeftBar.scss"></style>
