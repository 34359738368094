<template>
  <transition-group
    name="pageslide"
    tag="div"
    class="pages"
    :class="cssClasses"
    v-on:after-enter="afterInit = true"
  >
    <div class="page" :key="page.key" :class="pageClass(index)" v-for="(page, index) in pages">
      <component :ref="page.key" :is="page.component" v-bind="page.data"></component>
    </div>
  </transition-group>
</template>
<script>
import { Util, App, _ } from '@';
import { ref, shallowReactive, shallowRef } from 'vue';
import { initRoutesByPages, findMatch } from './routemap';
import pages from '@/pages/pages';

export default {
  setup() {
    return {
      pages: shallowReactive([]),
    };
  },
  data: function () {
    return { afterInit: false, transitionType: 'fade' };
  },
  created() {
    App.router = this;

    this.pageConfig = {};
    initRoutesByPages(pages);
    window.addEventListener('popstate', ev => {
      this.onUrlChange();
    });

    window.addEventListener('click', ev => {
      if (ev.defaultPrevented) {
        return;
      }
      let link = ev.target.href ? ev.target : ev.target.closest('[href]');
      if (link) {
        if (link.target === '_blank') {
          return;
        }

        if (link && link.classList.contains('navigate-slide')) {
          this.pageConfig.slide = true;
        }

        this.pushState(link.href);
        return ev.preventDefault();
      }
    });

    const url = getUrl();
    // if (url === '' && App.vm.profile.authenticated) {
    //   this.navigate('/projects');
    //   return;
    // }
    const authenticated = App.vm.profile.authenticated;
    Util.log(`init first page ${url}: authenticated = ${authenticated}`);
    Util.log(Util.getBrowser());
    if (url === 'standalone') {
      if (authenticated) {
        const storedPath = localStorage.getItem('currentPath');
        localStorage.setItem('currentPath', null);
        if (storedPath) {
          this.pushState(storedPath);
        } else {
          this.navigate('/projects');
        }
      } else {
        this.navigate('/');
      }
      return;
    }
    if (url === '' || url === '/') {
      if (authenticated) {
        this.navigate('/projects');
        return;
      }
    }

    this.onUrlChange();
  },
  computed: {
    cssClasses() {
      const { afterInit } = this;
      const classes = { afterInit };
      classes[this.transitionType] = true;
      return classes;
    },
  },
  methods: {
    navigateBack() {
      window.history.back();
    },
    navigate(url, config) {
      if (config) {
        this.pageConfig = config;
      }

      this.pushState(url);
    },
    clearHistory() {
      console.log('should clear history');
    },
    pushState(href) {
      history.pushState(null, null, href);
      this.onUrlChange();
    },
    updateUrl(url) {
      history.replaceState(null, null, url);
    },
    onUrlChange() {
      const url = getUrl(),
        lastPage = this.pages.secondLast();
      this.transitionType = 'fade';

      if (lastPage && lastPage.url === url) {
        if (this.pages.last().slide) {
          this.transitionType = 'slide';
        }

        this.goBack();
        return;
      }
      if (this.pageConfig.slide) {
        this.transitionType = 'slide';
      }
      const pageHit = findMatch(url);
      if (!pageHit) {
        console.error('could not find page for ' + url);
        return;
      }
      this.openPage(pageHit);
    },

    pageClass(index) {
      var currentIndex = this.pages.length - 1;
      if (!this.afterInit) {
        return 'firstpage';
      }
      if (index === currentIndex) {
        return 'center';
      }
      if (index > currentIndex) {
        return 'right';
      }
      if (index === currentIndex - 1) {
        return 'left';
      }
      return 'left-far';
    },

    initCurrentPage(clearBackLink) {
      const page = this.pages.last();

      App.vm.activePage = {
        key: page.key,
        component: {
          helpurl: page.component.helpurl,
          menu: page.component.menu,
        },
      };

      App.vm.showBackLink = !clearBackLink && this.pages.length > 1;

      //this.$ga.page({ page: page.component.route }); @TODO
      App.closeMessage();
      App.closePopover();
    },
    goBack() {
      this.pages.removeLast();
      this.initCurrentPage();
      storeCurrentPath();
    },

    clearOldPages() {
      console.log('clearOldPages');
      this.pages.splice(0, this.pages.length - 1);
    },

    reloadPage() {
      this.navigate(getUrl());
    },

    openPage({ component, props, url }) {
      App.timer.clear();

      if (!App.vm.profile.authenticated && !component.noAuthentication) {
        App.router.navigate('/login');
        return;
      }

      var newPage = {
        component,
        url,
        key: nextKey(),
      };

      const resolveData = component.getData
        ? resolveDataMap(component.getData(props))
        : Promise.resolve({});
      const slide = this.pageConfig.slide;
      const removeOldPages = !slide;
      this.pageConfig = {};

      resolveData.then(data => {
        App.timer.print('prerender ' + component.route);
        newPage.data = _.assign({ pageKey: newPage.key }, data, props);
        newPage.slide = slide;
        this.pages.push(newPage);
        this.initCurrentPage(removeOldPages);

        App.eventBus.emit('pageloaded');
        storeCurrentPath();
        setTimeout(() => {
          App.timer.print('postrender ' + component.route);
        }, 1);
        setTimeout(() => {
          removeOldPages && this.clearOldPages();
        }, 1);
      });
    },
  },
};

function resolveDataMap(data) {
  return Promise.resolve()
    .then(() => {
      return data;
    })
    .then(dataMap => {
      return Util.resolveMap(dataMap);
    });
}

var _nextKey = 0;
function nextKey() {
  _nextKey++;
  return 'p' + _nextKey;
}

function storeCurrentPath() {
  localStorage.setItem('currentPath', location.pathname);
}

function getUrl() {
  return location.pathname;
}
</script>
