import { doSorting } from './dragWithinContainer';
export default function(dragSession) {
  let currentDropTarget;
  let onHold = false;
  var holdTimer;
  function initHoldTimer(newContainer, callback) {
    clearHoldTimer();
    mark(newContainer);
    holdTimer = setTimeout(() => {
      callback();
    }, 1000);
  }
  function clearHoldTimer() {
    clearTimeout(holdTimer);
  }

  function setOnHold() {
    onHold = true;
  }

  function clearOnHold() {
    onHold = false;
    doSorting(dragSession);
  }

  function isOnHold() {
    return onHold;
  }

  function clear() {
    if (currentDropTarget == null) {
      return;
    }

    dragSession.cloneHandler.setMarkDropTarget(false);
    currentDropTarget.setDragStatus('dropTarget', false);
    currentDropTarget = null;
  }

  function mark(item) {
    if (currentDropTarget === item) {
      return;
    }
    currentDropTarget && currentDropTarget.setDragStatus('dropTarget', false);
    item.setDragStatus('dropTarget', true);
    currentDropTarget = item;

    dragSession.cloneHandler.setMarkDropTarget(true);
  }

  const handler = {
    clear,

    clearHoldTimer,
    initHoldTimer,
    clearOnHold,
    isOnHold,
    setOnHold,
  };

  return handler;
}
