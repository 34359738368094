<template>
  <div class="edittag">
    <div class="page-content with-footer less-top-padding">
      <section>
        <div class="tags">
          <a class="tag" :tag-id="tag.id" :style="{ 'background-color': '#' + tag.color.code }">{{ tag.title }}</a>
        </div>
      </section>
      <section>
        <q-input v-model="tag.title" :autofocus="true" label="Tag Name" class="large" />

        <color-picker :color="tag.color" @select="selectColor"></color-picker>
      </section>
    </div>

    <footer>
      <a-btn color="primary" @click="save">Save</a-btn>
    </footer>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';

export default {
  smallModal: true,
  props: {
    tag: {
      default: function () {
        return Model.Tag.createNew(App.vm.currentProject);
      },
    },
  },
  methods: {
    save() {
      return this.tag.save().then(() => {
        if (this.createOneMore) {
          this.tag = Model.Tag.createNew(App.vm.currentProject);
        } else {
          this.saved = true;
          this.$emit('close');
        }
      });
    },
    selectColor(color) {
      this.tag.color = color;
    },
  },
  unmounted() {
    if (!this.saved) {
      this.tag.restore();
    }
  },
};
</script>
<style lang="scss">
.edittag {
  section:first-child {
    margin-top: 0;
  }
  .tags {
    display: flex;

    .tag {
      cursor: text;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      padding: 0 15px;
    }
  }
}
</style>
