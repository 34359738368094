<template>
  <div>
    <div class="page-content content">
      <section>
        <template v-if="edit">
          <input class="input white" placeholder="Name" type="text" v-model="member.name" />

          <input class="input white" placeholder="Email" type="email" v-model="member.email" />
        </template>
        <template v-else>
          <h3>Enter email to add a member</h3>
          <q-input label="Email" type="email" v-model="member.email" :autofocus="true" />
        </template>
      </section>
    </div>
    <footer>
      <a-btn @click="save" color="primary">Save</a-btn>
    </footer>
  </div>
</template>
<script>
export default {
  props: ['member'],
  smallModal: true,
  data: function() {
    return {
      edit: this.member.id != null,
    };
  },
  methods: {
    async save() {
      return this.member.save().then(() => {
        this.$emit('close');
      });
    },
  },
};
</script>
