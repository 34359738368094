export default function(mock) {
  let newId = 10000;

  mock.onPut(/\/api\/member\/\w+/).reply(200, {});
  mock.onDelete(/\/api\/member\/\w+/).reply(200, {});
  mock.onPost(/\/api\/member/).reply(function() {
    var id = ++newId;
    return [
      200,
      {
        id: 'd' + id,
        numeral: id,
        userId: 'u' + id,
      },
    ];
  });
}
