import { Util } from '@';
export function initDirectives(app) {
  app.directive('focus', {
    mounted: function (el, binding) {
      const useAutofocus = binding.value !== false;
      if (useAutofocus) {
        Util.scrollInputIntoView(el);

        el.focus();
      }
    },
  });
  app.directive('select', {
    mounted: function (el, binding) {
      const useAutofocus = binding.value !== false;
      if (useAutofocus) {
        el.select();
      }
    },
  });

  app.directive('focus-last', {
    mounted: function (el, binding) {
      const useAutofocus = binding.value !== false;
      if (useAutofocus) {
        focusLast(el);
      }
    },
  });
}
function focusLast(el) {
  el.focus();

  var textNode = el.firstChild;
  if (textNode == null) {
    return;
  }
  var pos = textNode.nodeValue.length;
  var range = document.createRange();
  range.setStart(textNode, pos);
  range.collapse(true);

  var selection = window.getSelection(); //get the selection object (allows you to change selection)
  selection.removeAllRanges(); //remove any selections already made
  selection.addRange(range);
}
