import axios from 'axios';
import log from './log';
import { App } from '@/namespace';
import _ from 'lodash';
const axiosInstance = axios.create({
  //baseURL: 'https://agilooapp-test.herokuapp.com',
  //baseURL: window.location.origin,
  //withCredentials: true,
});

const ajaxCalls = {
  //get: axiosInstance.get,
  get: function(url, data, opt) {
    log('GET ' + url + ' ' + _.get(navigator, 'serviceWorker.controller.state'));

    const promise = axiosInstance.get(url, { params: data, ...opt });
    promise.then(response => {
      if (url.indexOf('api/profile') > -1) {
        const authenticated = _.get(response, 'data.authenticated');
        log('GET ' + url + ' response authenticated = ' + authenticated);
      }
    });
    return promise;
  },
  post: axiosInstance.post,
  patch: axiosInstance.patch,
  put: axiosInstance.put,
  // delete: function(URL, data, config) {
  //   return axiosInstance(
  //     URL,
  //     {
  //       data,
  //     },
  //     config
  //   );
  // },
};

async function jsonajax({ url, method, data, handleError, headers = {} }) {
  method = method || 'post';

  var ajaxCall = ajaxCalls[method.toLowerCase()],
    config = {};

  config.headers = headers;
  if (App.socketId) {
    config.headers.socketid = App.socketId; // doesnt seem to get sent with GET requests
  }

  const call = ajaxCall
    ? ajaxCall('/api' + url, data, config)
    : axiosInstance({ method, data, url: '/api' + url, headers });

  return call
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (!handleError) {
        handleJsonError(error.response.data);
      }

      throw error.response.data;
    });
}

function handleJsonError(data) {
  if (data && data.code && handleJsonErrorByCode(data)) {
    return;
  }

  let errorMessage;
  try {
    errorMessage = data.message;
  } catch (err) {
    console.error(err);
  }
  errorMessage = errorMessage || 'An error occured with the request';

  App.displayError(errorMessage);
}

function handleJsonErrorByCode(data) {
  switch (data.code) {
    case 'login-required':
      App.router.navigate('/login');
      setTimeout(() => {
        App.displayError('Your session has timed out. Please login again.');
      }, 100);

      return true;
    default:
      return false;
  }
}
export { jsonajax, axiosInstance as axios };
