import StoreModel from './base/StoreModel';
import _ from 'lodash';
import { Model, Util } from '@/namespace';
import { convertModelMap } from '@/common/model/convertmodels';
import { fillActivityMap } from './activityHandler';
class Document extends StoreModel {
  static NAME = 'Document';
  static define = {
    text: null,
    title: null,
    projectId: null,
    priority: null,
    documents: {
      value() {
        return [];
      },
    },
    parent: {
      model: 'Document',
      serialize(parent) {
        return parent && parent.id;
      },
    },
    files: {
      serialize: false,
    },

    comments: {
      model: 'Comment',
      value() {
        return [];
      },
      serialize: false,
    },
    activity: {
      type: Array,
    },
  };

  static fill(id) {
    return Util.jsonajax({
      url: '/document/fill/' + id,
      method: 'GET',
    });
  }

  fillFiles() {
    var files = new Model.FileList({ parent: this });
    this.set('files', files);

    this.files.fetch();
  }

  addFile(file) {
    if (this.files == null) {
      var files = new Model.FileList({ parent: this });
      this.set('files', files);
    }
    return this.files.add(file);
  }

  postInitModel() {
    const parent = this.parent || Model.Project.get(this.projectId);
    parent.documents.add(this);
  }

  postFill(response) {
    this.setAttributes(response.originModel);
    this.initModel();
    if (this.noFiles) {
      this.fillFiles();
    }
  }

  get url() {
    const { id, projectId } = this;
    return `/${projectId}/document/${id}`;
  }

  setPriority(priority) {
    this.priority = priority;
  }

  postDestroy() {
    const parent = this.parent || Model.Project.get(this.projectId);
    parent.documents.remove(this);
  }
}
_.assign(Document.prototype, {
  fillActivityMap,
});
Model.Document = Document;
export default Document;
