import { signFile, uploadToAmazon } from './uploadutils';
import FileUpload from './fileupload';
import createThumbnail from './createthumbnail';
import toFileUploadsFromPaste from './pastehandler';
import _ from 'lodash';
export function pickUploadFile(fileParent) {
  var fileInput = initInputElement();
  fileInput.click();
  fileInput.on('change', onChange);

  function onChange(ev) {
    var fileUploads = _.map(ev.target.files, file => new FileUpload(file, fileParent));

    startUploadFiles(fileUploads);
  }

  function initInputElement() {
    var body = document.querySelector('body'),
      input = body.querySelector('input#file-input');
    input && input.remove();
    body.insertAdjacentHTML('beforeend', '<input type="file" id="file-input"/>');

    return body.querySelector('input#file-input');
  }
}

function uploadFile(fileUpload) {
  return fileUpload
    .ready()
    .then(function() {
      return signFile(fileUpload);
    })
    .then(function(signResponse) {
      var file = fileUpload.parent.addFile(_.omit(signResponse.file, ['fileurl', 'thumburl']));

      file.fileUploadPromise = uploadToAmazon(signResponse.signedFileurl, fileUpload.file).then(
        () => {
          file.set('fileurl', signResponse.file.fileurl);
        }
      );
      if (signResponse.signedThumburl) {
        file.thumbUploadPromise = createThumbnail(fileUpload.file).then(thumbFile => {
          return uploadToAmazon(signResponse.signedThumburl, thumbFile).then(() => {
            file.set('thumburl', signResponse.file.thumburl);
          });
        });
      }

      return file;
    });
}

function startUploadFiles(fileUploads) {
  return Promise.all(
    _.map(fileUploads, function(fileUpload) {
      return uploadFile(fileUpload);
    })
  ).then(function(result) {
    console.log(result);
    return result;
  });
}

export function dropFile(ev, fileParent) {
  var fileUploads = _.map(ev.dataTransfer.files, file => new FileUpload(file, fileParent));
  return startUploadFiles(fileUploads);
}

export function pasteFile(ev, fileParent) {
  var fileUploads = toFileUploadsFromPaste(ev, fileParent);
  return startUploadFiles(fileUploads);
}
