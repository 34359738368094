<template>
  <section class="main">
    <h1>Work Items</h1>

    <p>
      A Work Item is the main building block in Agiloo and represents one piece of work. Nomatter
      how big it is, if it's considered a feature, a task or an epic, in Agiloo it is represented as
      a Work Item.
    </p>

    <div class="image-with-helpkeys workitem1">
      <img src="/static/images/help/workitems1.png" />
    </div>
    <p>
      The items shown under backlog are the project's top level work items. You can add a new item
      to the backlog by using the button <i>New Item</i>. This lets you create an item quickly by
      just setting the item title. After entering the title, Press
      <span class="keyboard">ENTER</span>
      to save the new item or
      <span class="keyboard">ESC</span>
      to cancel.
    </p>

    <img src="/static/images/help/newitem-modal.png" />
    <p>
      If you need more more options when creating a new item, you can use the <i>New Item</i> button
      in the top bar. This will open up a modal where you can set more properties, e.g. assign a
      team member, add description or add tags.
    </p>
    <p class="divider"></p>
    <div class="image-with-helpkeys itemrow">
      <img src="/static/images/help/itemrow.png" />
      <helpkey id="m1">1</helpkey>
      <helpkey id="m2">2</helpkey>
      <helpkey id="m3">3</helpkey>
      <helpkey id="m4">4</helpkey>
      <helpkey id="m5">5</helpkey>
    </div>
    <ul>
      <li>
        <helpkey>1</helpkey>By clicking the item title you navigate into the item page which gives
        you are <a href="/help/viewitem">more detailed view</a>. This is also where you can edit any
        of the item's properties.
      </li>
      <li>
        <helpkey>2</helpkey>If a work items has sub items, the number of items is shown to the left.
        Clicking shows the sub items.
      </li>
      <li>
        <helpkey>3</helpkey>The item list is sortable and the topmost item is considered to have the
        highest priority. You can change the priority by dragging an item to a new position.
      </li>
      <li><helpkey>4</helpkey>The status is shown to the left and is editable.</li>
      <li>
        <helpkey>5</helpkey>The assigned team member and any added tags or markers are shown to the
        right
      </li>
    </ul>
  </section>
</template>
<script>
export default {};
</script>
