<template>
  <div class="CustomSearch">
    <div class="searchbody">
      <input
        type="text"
        class="input"
        :placeholder="placeholder"
        v-focus
        autocomplete="off"
        @focus="focus"
        @keydown="keydown"
        @input="input"
        @blur="blur"
        v-model="text"
      />
      <div class="search-result">
        <div
          v-for="item in searchResult"
          class="searchelement"
          :class="{ active: isActive(item) }"
          @click.prevent="selectItem(item)"
        >
          <component :is="'Result' + item.constructor.NAME" :item="item"></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from '@/utils';
import { findSearchResult, TextItem } from './searchutil';
import handleKeydown from './handleKeydown';

export default {
  props: ['sourceOptions', 'placeholder', 'models'],
  data: function () {
    return {
      active: false,
      searchResult: this.sourceOptions.defaultSearchResult,
      activeItem: null,
      text: '',
      filters: [],
    };
  },
  methods: {
    showCreateTag() {
      return this.sourceOptions.showCreateTag && this.text.length > 0;
    },
    close() {
      this.active = false;
    },
    focus() {
      this.active = true;
      this.$el.find('input').select();
    },
    blur() {
      setTimeout(() => {
        this.close();
      }, 100);
    },

    input() {
      this.active = true;

      var text = this.text;
      if (text.length >= 1) {
        var searchResult = findSearchResult(text, this.sourceOptions);
        if (this.showCreateTag()) {
          searchResult.pushFirst(
            new TextItem({
              searchText: text,
              html: '<b>' + text + '</b> <span> (Create new tag) </span>',
            })
          );
        }
      } else {
        searchResult = this.sourceOptions.defaultSearchResult;
      }

      this.searchResult = searchResult;
      nextTick(() => {
        this.$el.triggerCustomEvent('height-change');
      });
    },
    selectItem(m) {
      this.$emit('select', m);
    },
    isActive(item) {
      return item === this.activeItem;
    },
    keydown: handleKeydown({
      selectFirstOnEnter: true,
      handleClose: function () {
        this.$emit('close');
      },
    }),
  },
};
</script>
