import { App } from '@/namespace';
export default function() {
  const project = App.vm.currentProject;
  if (!project) {
    return [];
  }
  const members = project.members
    .filter(member => !member.unassigned)
    .map(member => {
      return {
        id: 'm:' + member.id,
        name: member.name,
      };
    });
  const items = allChildIssues(project.issue);
  return [...members, ...items];
}

function allChildIssues(issue) {
  const result = [];

  appendIssueAndChildren(issue);

  function appendIssueAndChildren(issue) {
    result.push({
      id: 'i:' + issue.id,
      name: issue.title,
    });
    issue.issues.forEach(function(child) {
      appendIssueAndChildren(child);
    });
  }
  return result;
}
