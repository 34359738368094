<template>
  <div class="page-content narrow">
    <section>
      <h1>Feedback</h1>
      <p>
        Feedback from you is very important for us in order to continously make Agiloo better. If
        you have any thoughts or suggestions about how to make Agiloo a better service, please let
        us know.
      </p>
    </section>
    <section class="settings-split">
      <div class="left">
        <h2>Send Feedback</h2>
      </div>

      <div class="right">
        <div class="input-form">
          <text-input
            v-model="textDocument.subject"
            class="subject white"
            placeholder="Subject"
          ></text-input>
          <textarea class="input white" v-model="textDocument.text" placeholder="Message" />
          <a-btn @click="save" color="primary">Send</a-btn>
        </div>
      </div>
    </section>

    <page-footer />
  </div>
</template>
<script>
import { App, Util } from '@/namespace';

export default {
  route: '/feedback',
  data: function() {
    return {
      textDocument: {
        text: '',
        subject: '',
      },
    };
  },
  methods: {
    save() {
      return this.postMessage().then(function() {
        App.displayInfo('Thank you for your feedback');
      });
    },
    postMessage: function() {
      return Util.jsonajax({ url: '/sendfeedback', data: this.textDocument });
    },
  },
};
</script>
