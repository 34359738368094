<template>
  <div class="ItemCollectionSettings">
    <FilterSearch @filterchange="filterchange" />

    <template v-if="onBoard">
      <q-btn
        round
        unelevated
        @click="toggleView"
        size="small"
        v-tooltip="'View as list'"
        :color="collectionSettings.activeView === 'list' ? 'primary' : 'white'"
        ><i class="icon-paragraph-justify3"></i
      ></q-btn>
      <q-btn
        :color="collectionSettings.activeView === 'board' ? 'primary' : 'white'"
        round
        unelevated
        size="small"
        v-tooltip="'View as board'"
        @click="toggleView"
      >
        <svg-icon icon="board" />
      </q-btn>
    </template>
  </div>
</template>
<script>
import FilterSearch from '@/components/mainsearch/FilterSearch.vue';

export default {
  props: ['collectionSettings', 'onBoard'],

  methods: {
    filterchange: function (filterQuery) {
      this.$emit('filterchange', filterQuery);
      this.collectionSettings.filterQuery = filterQuery;
    },
    toggleView() {
      if (this.collectionSettings.activeView === 'board') {
        this.collectionSettings.activeView = 'list';
      } else {
        this.collectionSettings.activeView = 'board';
      }
    },
  },
  components: { FilterSearch },
};
</script>
<style lang="scss">
.item-collection-settings-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
.ItemCollectionSettings {
  display: flex;
  align-items: center;

  @media (max-width: 580px) {
    display: none;
  }
  .q-btn {
    margin-left: 5px;
  }

  .q-btn:first-child {
    margin-left: 10px;
  }
}
</style>
