<template>
  <div
    id="app"
    v-if="ready"
    @click="clicked"
    :class="{ preventScroll: appVm.preventScroll, touch: isTouch, loggedIn: profile.authenticated }"
  >
    <div class="views">
      <div class="view view-main">
        <aside class="leftbar" v-if="profile.authenticated">
          <LeftBar />
        </aside>
        <div class="right">
          <Navbar />
          <AppRouter />
        </div>
      </div>
    </div>
    <CookiePolicyMessage :profile="profile" />
    <ModalHandler />
    <PopoverHandler />
    <MessageHandler />
    <NotificationHandler />
    <DragCloneHandler ref="dragCloneHandler" />
    <SvgSprite />
  </div>
</template>
<script>
import { App, Model, Util } from '@/namespace';

import '@/common/util';

import '@/models';
import '@/models/fixtures';
import SvgSprite from '@/common/SvgSprite.vue';
import './appMethods';
import Navbar from '@/components/navbar/Navbar.vue';
import LeftBar from '@/components/leftbar/LeftBar.vue';
import DragCloneHandler from '@/components/drag/DragCloneHandler.vue';
import ModalHandler from '@/routing/ModalHandler.vue';
import AppRouter from '@/routing/AppRouter.vue';
import MessageHandler from '@/routing/MessageHandler.vue';
import NotificationHandler from '@/routing/NotificationHandler.vue';
import CookiePolicyMessage from '@/routing/CookiePolicyMessage.vue';
import PopoverHandler from '@/routing/PopoverHandler.vue';
import socketHandler from '@/apphandlers/sockethandler';
import initPreventTouchScrolling from '@/common/initPreventTouchScrolling';
import EventEmitter from 'events';
import timer from './common/timer';
import { findNotifications } from '@/models/notificationService';
import store from '@/store';
window.Model = Model;
window.App = App;
App.timer = timer();
App.eventBus = new EventEmitter();
App.scrolling = initPreventTouchScrolling();
export default {
  name: 'MainApp',
  created() {
    App.socketHandler = socketHandler();
    App.logActivity();
  },
  data: function () {
    return {
      appVm: {
        profile: null,
        currentProject: null,
        pageNavbar: null,
        showBackLink: false,
        activeMenu: 'items',
        helpurl: null,
        hideTopbar: false,
        activePage: null,
      },

      isTouch: Util.isTouchDevice(),
    };
  },

  async mounted() {
    App.vm = this.appVm;

    App.eventBus.on('profileupdate', function () {
      onProfileUpdate();
      App.router.clearHistory();
    });
    Model.Profile.find().then(profile => {
      onProfileUpdate();
    });
    await Model.Color.findAll();
    await Model.IssueType.findAll();
    await Model.Role.findAll();
  },
  computed: {
    ready() {
      return this.profile != null;
    },
    profile() {
      return this.appVm.profile;
    },
  },
  methods: {
    clicked() {
      // For some reason, without this clicked listener, quill-editor doesnt close on ios devices
    },
  },
  components: {
    DragCloneHandler,
    ModalHandler,
    AppRouter,
    MessageHandler,
    NotificationHandler,
    PopoverHandler,
    SvgSprite,
    CookiePolicyMessage,
    Navbar,
    LeftBar,
  },
};

async function onProfileUpdate() {
  App.clearCache();
  App.clearCurrentProjectId();

  App.vm.profile = Model.Profile.get();

  if (App.vm.profile.authenticated) {
    App.eventBus.once('pageloaded', () => {
      App.socketHandler.init();
    });
  }
  store.notifications = App.vm.profile.authenticated ? await findNotifications() : null;
}

App.socketHandler = {
  init() {},
};
</script>
