<template>
  <a class="tag" :tag-id="tag.id" @click.prevent="editTagMenu">{{ tag.title }}</a>
</template>
<script>
import EditTagMenu from '@/popovers/EditTagMenu';
import { App } from '@/namespace';
export default {
  props: ['tag', 'issue', 'showDelete'],
  methods: {
    editTagMenu() {
      const el = this.$el;
      App.openPopover(
        EditTagMenu,
        {
          tag: this.tag,
          issue: this.issue,
          showDelete: this.showDelete,
        },
        {
          type: 'menu',
          alignToTop: el,
          target: el,
        }
      );
    },
  },
};
</script>
