import { App, Model, Util } from '@/namespace';

export async function deleteSprint(sprint) {
  const project = Model.Project.get(sprint.projectId);
  return new Promise((resolve, reject) => {
    if (!project.issue.sprintMap.containsValue(sprint)) {
      resolve(sprint.destroy());
    } else {
      App.openConfirmDialog({
        title: 'Delete Sprint',
        text:
          'This will delete the sprint. The items assigned to the sprint will be moved to the backlog.',
        onConfirm: function() {
          resolve(sprint.destroy());
        },
        onClose() {
          reject();
        },
      });
    }
  });
}

export function getSuggestedSprintTitle(project) {
  var REGEXP = /(\d+)[^\d]*$/;

  if (project.type !== 'scrum') {
    return '';
  }

  var validSprints = project.sprints.filter(sprint => sprint.unfinished);
  if (validSprints == null) {
    validSprints = project.sprints.filter(sprint => sprint.finished);
  }
  var lastSprint = validSprints[validSprints.length - 1];

  if (lastSprint == null) {
    return 'Sprint 1';
  } else {
    var ex = REGEXP.exec(lastSprint.title);
    if (ex && ex.index >= 0) {
      var number = Number(lastSprint.title.substring(ex.index));
      if (number >= 0) {
        var base = lastSprint.title.substring(0, ex.index);
        return '' + base + (number + 1);
      }
    }
  }
  return '';
}

export function setSprintFinished(sprint) {
  const project = Model.Project.get(sprint.projectId);
  sprint.priority = previousPriority(finishedSprints(project));
  sprint.finished = new Date();
}

export function setSprintUnfinished(sprint) {
  const project = Model.Project.get(sprint.projectId);
  sprint.priority = previousPriority(unfinishedSprints(project));
  sprint.finished = null;
}

export function createSprint(project, attributes) {
  const priority = nextPriority(unfinishedSprints(project));
  return new Model.Sprint({ ...attributes, projectId: project.id, priority });
}

function nextPriority(list) {
  const last = list.last();
  return last && last.priority != null ? last.priority + 100 : 100;
}

function previousPriority(list) {
  const first = list[0];
  return first && first.priority != null ? first.priority - 100 : 100;
}

function unfinishedSprints(project) {
  return project.sprints.filter(sprint => sprint.unfinished).sorted(Util.priorityComparator);
}

function finishedSprints(project) {
  return project.sprints.filter(sprint => sprint.finished).sorted(Util.priorityComparator);
}
