<template>
  <section class="ViewIssueAdditionalViews">
    <div class="tab-menu">
      <a
        href=""
        @click.prevent.stop="showAdditionalView('comments')"
        :class="{ active: additionalView === 'comments' }"
        >Comments</a
      >
      <a
        href=""
        @click.prevent.stop="showAdditionalView('activity')"
        :class="{ active: additionalView === 'activity' }"
        >Activity</a
      >
      <a
        href=""
        @click.prevent.stop="showAdditionalView('github')"
        :class="{ active: additionalView === 'github' }"
        v-if="project.githubAccess && showGithub"
        >Github
        <span class="number-of-commits" v-if="issue.githubData">{{
          issue.githubData.size
        }}</span></a
      >
    </div>

    <view-comments v-if="additionalView === 'comments'" :issue="issue"></view-comments>

    <view-activity v-if="additionalView === 'activity'" :issue="issue"></view-activity>
    <ViewGithub v-if="additionalView === 'github'" :issue="issue"></ViewGithub>
  </section>
</template>
<script>
import ViewGithub from './ViewGithub.vue';
import ViewActivity from '@/components/ViewActivity/ViewActivity.vue';
export default {
  props: {
    issue: null,
    project: null,
    showGithub: {
      default: true,
    },
  },
  data() {
    return {
      additionalView: 'comments',
    };
  },
  methods: {
    showAdditionalView(view) {
      this.additionalView = view;
    },
  },
  components: {
    ViewGithub,
    ViewActivity,
  },
};
</script>
<style lang="scss">
.ViewIssueAdditionalViews {
  .tab-menu {
    display: flex;
    padding-left: 20px;
    border-bottom: 1px solid $gray-border;
    a {
      font-size: 16px;
      padding: 10px 30px 10px 0;

      &.active {
        color: $orange-400;
        font-weight: 400;
      }
    }
  }

  .date-header {
    margin: 20px 0 0 30px;
    border-bottom: 1px solid $gray-border;
    font-size: 14px;
    padding-left: 10px;
    @media (max-width: 580px) {
      margin: 20px 10px 5px;
    }
  }

  .number-of-commits {
    background-color: white;
    font-size: 9px;
    line-height: 20px;
    height: 18px;
    width: 18px;
    display: inline-block;
    position: relative;
    top: -5px;
    text-align: center;
    border-radius: 9px;
    line-height: 20px;
    background-color: $orange-400;
    color: white;
    font-weight: 400;
  }

  a.active .number-of-commits {
    //background-color: #e8e8e8;
  }
}
</style>
