<template>
  <div class="FilterableMenu" :class="{ showInput: opt.showInput }">
    <input
      type="text"
      class="input"
      ref="input"
      v-select="autoFocus"
      autocomplete="off"
      @keydown="keydown"
      v-model="text"
      @input="input"
      placeholder="Filter"
    />
    <header v-if="headerItems.length">
      <a
        v-for="item in headerItems"
        :class="cssClasses(item)"
        class="item"
        @click.prevent="clickItem(item)"
      >
        <slot :item="item" v-if="item.isModel"></slot>
        <template v-else
          ><i :class="item.icon" v-if="item.icon"></i
          ><span class="text">{{ item.text }}</span></template
        >
      </a>
    </header>
    <main>
      <a
        v-for="item in items"
        :class="cssClasses(item)"
        class="item"
        @click.prevent="clickItem(item)"
      >
        <slot :item="item" v-if="item.isModel"></slot>
        <slot v-else-if="item.slotName" :item="item" :text="text" :name="item.slotName"></slot>
        <template v-else
          ><i :class="item.icon" v-if="item.icon"></i
          ><span class="text">{{ item.text }}</span></template
        >
      </a>
    </main>
    <footer v-if="footerItems.length">
      <a
        v-for="item in footerItems"
        :class="cssClasses(item)"
        class="item"
        @click.prevent="clickItem(item)"
      >
        <slot :item="item" v-if="item.isModel"></slot>
        <template v-else
          ><i :class="item.icon" v-if="item.icon"></i
          ><span class="text">{{ item.text }}</span></template
        >
      </a>
    </footer>
  </div>
</template>
<script src="./FilterableMenu.js" />
<style lang="scss" src="./FilterableMenu.scss"></style>
