<template>
  <div class="page-content login-and-register flex">
    <section class="center">
      <q-form :model="credentials" ref="form" class="form" @submit="resetPassword">
        <q-input
          v-model="credentials.password"
          label="Password"
          type="password"
          :rules="rules.password"
          dark
          class="large"
          lazy-rules
        />

        <q-input
          v-model="credentials.confirmPassword"
          label="Confirm Password"
          type="password"
          :rules="rules.confirmPassword"
          dark
          class="large"
          lazy-rules
        />

        <a-btn size="large" color="primary" type="submit">Reset Password</a-btn>
      </q-form>
    </section>

    <page-footer />
  </div>
</template>
<script>
import { App } from '@';

import Credentials from '@/models/Credentials';

export default {
  route: '/resetpassword/:passwordResetToken',

  noAuthentication: true,
  props: ['passwordResetToken'],

  data() {
    return {
      isLoading: false,
      credentials: new Credentials(),
    };
  },
  getData: function (props) {
    return {
      passwordResetToken: props.passwordResetToken,
    };
  },
  computed: {
    rules() {
      return {
        password: [
          val => {
            return (
              (val != null && val.length >= 8) || 'The password must be at least 8 characters long'
            );
          },
        ],
        confirmPassword: [
          val => {
            return val === this.credentials.password || 'Confirm password does not mach password';
          },
        ],
      };
    },
  },
  methods: {
    async resetPassword() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }
      this.credentials.passwordResetToken = this.passwordResetToken;
      return this.credentials.resetPassword().then(function () {
        App.router.navigate('/projects');
      });
    },
  },
};
</script>
