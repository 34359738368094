import { render } from "./ResetPassword.vue?vue&type=template&id=524a045c"
import script from "./ResetPassword.vue?vue&type=script&lang=js"
export * from "./ResetPassword.vue?vue&type=script&lang=js"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput});
