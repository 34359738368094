<template>
  <div class="item-list-item issue dragitem issue-wrapper">
    <div class="row ">
      <IssueCount :wrapper="wrapper" />

      <StatusIcon :wrapper="wrapper" :preventClicks="preventSelector" />
      <div class="title">
        <div class="issue-type small" v-if="wrapper.issueType" :type="wrapper.issueType"></div>
        <span class="title-text">{{ wrapper.issue.title }}</span>
      </div>
    </div>
    <div v-if="wrapper.open" class="sub-items children">
      <SimpleIssueRow
        v-for="wrapper in wrapper.children"
        :wrapper="wrapper"
        :key="wrapper.id"
        :preventSelector="preventSelector"
      />
    </div>
  </div>
</template>
<script>
import { StatusIcon, IssueCount } from '@/components/issue-collection';
export default {
  props: ['wrapper', 'preventSelector'],
  name: 'SimpleIssueRow',
  methods: {
    toggleShowSubIssues: function() {
      return this.wrapper.toggleShowSubIssues();
    },
  },
  components: { StatusIcon, IssueCount },
};
</script>
