import _ from 'lodash';
import Route from 'route-parser';
import { Util } from '@/namespace';
const compiledRoutes = [];

export function findMatch(url) {
  let pageHit,
    urlParams = getUrlParameters();

  for (var i = 0; i < compiledRoutes.length; i++) {
    var compiledRoute = compiledRoutes[i];
    var match = compiledRoute.route.match(url);

    if (!match) {
      continue;
    }
    if (pageHit == null || size(match) < size(pageHit.props)) {
      pageHit = {
        component: compiledRoute.component,
        props: match,
        url: url,
      };
    }
  }

  if (!pageHit) {
    return;
  }
  if (urlParams) {
    _.assign(pageHit.props, urlParams);
  }

  return pageHit;
}

function size(o) {
  return _.keys(o).length;
}

function getUrlParameters() {
  let query = document.location.search.substring(1);

  var match,
    urlParams,
    pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function(s) {
      return decodeURIComponent(s.replace(pl, ' '));
    };

  urlParams = {};
  while ((match = search.exec(query))) {
    urlParams[decode(match[1])] = decode(match[2]);
  }

  return urlParams;
}

export function initRoutesByPages(pages) {
  _.forEach(pages, function(component) {
    Util.assert(component.route != null);
    compiledRoutes.push({
      route: new Route(component.route),
      component,
    });
  });
}
