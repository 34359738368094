export default function(mock) {
  const notifications = [
    {
      id: 'n1',
      time: '2017-06-05T17:06:49.472Z',
      data: {
        userId: 'u1',
        projectId: 'p1',
        issueId: 'i3',
        type: 'issueassign',
      },
    },
  ];

  mock.onGet('/api/notification/findall').reply(200, notifications);
}
