<template>
  <div class="EnterPaymentInfo">
    <h3>Enter Billing Information</h3>

    <div class="payment-form">
      <PaymentForm :paymentInfo="paymentInfo" ref="form" />

      <div v-if="validationError" class="validation-error">
        {{ validationError }}
      </div>
      <div class="flex">
        <a-btn class="right" @click="save" color="primary">Continue to Card to payment</a-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { createSubscription } from '@/models/billing/billingService';
import PaymentInfo from '@/models/billing/PaymentInfo';
import PaymentForm from './PaymentForm/PaymentForm.vue';
import { toErrorMessage } from './paymentError';
export default {
  props: ['subscription'],
  data() {
    return { validationError: null, paymentInfo: PaymentInfo.make() };
  },

  components: {
    PaymentForm,
  },
  methods: {
    async save() {
      const valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }

      const subscription = this.subscription.serialize();
      const paymentInfo = this.paymentInfo.serialize();
      this.validationError = null;
      return createSubscription({
        premiumUsers: subscription.premiumUsers,
        paymentInfo,
      })
        .then(session => {
          console.log('use session');
          console.log(session);
        })
        .catch(error => {
          this.validationError = toErrorMessage(error);
        });
    },
  },
};
</script>
<style lang="scss">
.EnterPaymentInfo {
  h3 {
    margin-bottom: 20px;
  }
  button.right {
    margin-left: auto;
  }
}
</style>
