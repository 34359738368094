<template>
  <div class="page-content page_view_members medium">
    <section class="">
      <div class="main-title">Team</div>
      <a v-if="hasPremiumRequired" class="warning-box" href="/premiumplan">
        <h2>Premium Required</h2>
        For projects with more than <strong>4</strong> members, Premium membership is required.
        Users without Premium Membership will not be able to access the project. Add premium
        membership in <a href="/premiumplan">Premium Settings </a>.
      </a>
      <div class="list members">
        <MemberRow
          :member="member"
          :project="project"
          v-for="member in unassignedMembers"
          @select="selectMember"
          :key="member.id"
        />

        <footer>
          <a-btn @click="openCreateMember" flat>
            <i class="icon-plus3"></i>
            <span>Add Member</span>
          </a-btn>
        </footer>
      </div>
    </section>
    <teleport to="#navbar" v-if="isPageActive">
      <q-btn @click="openCreateMember" rounded>
        <i class="icon-plus3"></i>
        <span>Add Member</span>
      </q-btn>
    </teleport>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';
import { pageMixin } from '@/common';
import CreateMember from '@/pages/createmember/CreateMember.vue';
import MemberRow from './MemberRow.vue';
export default {
  route: '/:projectId/members',

  menu: 'members',
  mixins: [pageMixin],
  props: ['project', 'onSelect'],
  components: { MemberRow },
  getData: function (props) {
    return {
      project: Model.Project.find(props.projectId || App.vm.currentProject.id),
    };
  },
  created() {
    App.setCurrentProject(this.project);
  },
  computed: {
    hasPremiumRequired() {
      return (
        this.project.premiumRequired &&
        this.project.members.filter(member => !member.premium && !member.unassigned).length > 0
      );
    },
    unassignedMembers() {
      return this.project.members.filter(member => !member.unassigned);
    },
  },
  methods: {
    openCreateMember() {
      var member = Model.Member.createNew({ projectId: this.project.id });
      App.openPopup(CreateMember, { member: member });
    },

    selectMember(member, ev) {
      if (this.onSelect) {
        ev.preventDefault();
        this.onSelect(member);
        this.$emit('close');
      }
    },
  },
};
</script>
