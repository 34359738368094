<template>
  <div class="CreateSprintPopover">
    <label>Create {{ boardTerm }}</label>
    <text-input
      v-model="title"
      v-focus-last
      :saveOnBlur="false"
      class="title text bordered"
      :placeholder="placeholder"
      @save="save"
      @close="close"
    ></text-input>
    <footer>
      <a-btn @click="save" color="primary">Save</a-btn>
    </footer>
  </div>
</template>

<script>
import { Model, App } from '@';
import { getSuggestedSprintTitle } from '@/models/sprintHandler';
import './CreateSprintPopover.scss';
export default {
  props: ['onCreate'],
  data() {
    return {
      title: getSuggestedSprintTitle(App.vm.currentProject),
      boardTerm: App.vm.currentProject.boardTerm,
      placeholder: App.vm.currentProject.boardTerm + ' title',
    };
  },
  methods: {
    save() {
      const newSprint = Model.Sprint.createNew(App.vm.currentProject, {
        title: this.title,
      });
      newSprint.save().then(() => {
        this.onCreate && this.onCreate(newSprint);
        this.close();
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
