<template>
  <div class="page-content narrow info-pages">
    <AgilooLogo />
    <section class="header">
      <h1>Cookie Policy</h1>
    </section>
    <section class="legal-text">
      <h2>1. GENERAL</h2>
      <p>
        <span>1.1</span>
        <span>
          This cookie policy describes how Agiloo Digital AB reg. no. 556847-1600 with address
          Ölandsgatan 49B 3tr, 116 63 Stockholm (“Agiloo”, ”we” or ”us”) email: info@agiloo.com,
          uses cookies on www.agiloo.com (the ”Website”).
        </span>
      </p>
      <p>
        <span>1.2</span>
        <span>
          You accept our use of cookies by clicking the “I Accept” – button in the banner at the top
          of the Website, and/or by using the Website.
        </span>
      </p>
      <h2>2. COOKIES</h2>
      <p>
        <span>2.1</span>
        <span>
          A cookie is a small piece of data sent from a website and stored in a user's web browser,
          mobile phone, or other device while the user is browsing that website. Agiloo safeguards
          our users’ integrity and recognizes that our users’ trust is our most valuable asset. In
          this document we briefly explain how we use cookies and how we protect our users’
          integrity in connection therewith.
        </span>
      </p>
      <h2>3. OUR USE OF COOKIES</h2>
      <p>
        <span>3.1</span>
        <span>
          In order to provide you, as a Agiloo account holder, with the best possible service and
          user experience we use cookies and similar technologies on our website and mobile
          applications. We use the following cookies for the purposes set out below:
        </span>
      </p>
      <p>
        <span>3.2</span>
        <span>
          Functional cookies.
          <p>
            3.2.1 We use functional cookies to operate certain functions of the Website in
            accordance with your choices, meaning that when you continue to use or come back to the
            Website, the site will be provided as you have previously requested, e.g. remembering
            your username and customization of the Website.
          </p>
        </span>
      </p>
      <p>
        <span>3.3</span>
        <span>
          Analytic cookies
          <p>
            3.3.1 We use analytic cookies to analyse how the Website is accessed, used or is
            performing. We use the information to maintain, operate and improve the Website.
          </p>
        </span>
      </p>
      <p>
        <span>3.4</span>
        <span>
          Third party-cookies
          <p>
            3.4.1 We may allow third party-cookies on the Website for the purposes set out above. We
            may also allow service providers that operates on our behalf to use cookies for the
            purposes set out above.
          </p>
        </span>
      </p>
      <h2>4. MANAGE YOUR COOKIES</h2>
      <p>
        <span>4.1</span>
        <span>
          If you do not wish us to use cookies to improve your user experience you can easily turn
          it off.
        </span>
      </p>
      <p>
        <span>4.2</span>
        <span>
          Your web browser allows you to manage your cookie preferences. You can set your browser to
          refuse cookies, or delete certain cookies. If you choose to block cookies, the Agiloo
          Service may not function as intended on most web browsers.
        </span>
      </p>
      <p>
        <span>4.3</span>
        <span>
          If you wish, you can in most browser settings choose to just turn off tracking, which
          means that we do not co-process the data from our own and our partners’ web pages. To do
          the same thing on your mobile phone you can for iOS go in and choose to limit tracking
          under the privacy and advertising preferences. For Android, you do this in Google settings
          and ads.
        </span>
      </p>
    </section>
    <section class="filler"></section>
    <page-footer />
  </div>
</template>
<script>
export default {
  route: '/cookiepolicy',
  noAuthentication: true,
};
</script>
