<template>
  <div class="page-content login-and-register flex">
    <AgilooLogo class="white" />
    <section class="center">
      <q-form ref="form" class="form white large" @submit="login">
        <q-input
          v-model="credentials.email"
          label="Email"
          type="email"
          dark
          :rules="rules.email"
          lazy-rules
          class="large"
        />
        <q-input
          v-model="credentials.password"
          label="Password"
          type="password"
          :rules="rules.password"
          dark
          class="large"
          lazy-rules
        />

        <a-btn size="large" color="primary" type="submit">Login</a-btn>

        <a href="#" id="forgot-password" @click.prevent="openForgotPassword">Forgot Password</a>
      </q-form>
    </section>

    <page-footer />
  </div>
</template>
<script>
import { App, Model } from '@';
import { requiredValue, requiredEmail } from '@/models/validation';
import Credentials from '@/models/LoginCredentials';

export default {
  route: '/login',

  noAuthentication: true,
  data: function () {
    return {
      profile: App.vm.profile,
      credentials: new Credentials(),
      isLoading: false,
    };
  },

  computed: {
    rules() {
      return {
        email: [requiredEmail()],
        password: [requiredValue('Password')],
      };
    },
  },

  methods: {
    async login() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return false;
      }

      const recaptcha = await this.$recaptcha('login');

      return this.credentials.login(recaptcha).then(() => {
        App.router.navigate('/projects');
      });
    },
    openForgotPassword() {
      var credentials = this.credentials;
      App.openConfirmDialog({
        title: 'Reset Password',
        text: 'Request to reset password will be sent to',
        inputType: 'email',
        inputValue: credentials.email,
        onConfirm(email) {
          Model.Credentials.requestResetPassword({ email: email })
            .then(function () {
              App.displayInfo('Password Reset Sent');
            })
            .catch(App.handleError);
        },
      });
    },
  },
};
</script>
