import BaseModel from './base/BaseModel';
import { Model, App } from '@';
export function findNotifications() {
  return Model.Notification.findAll().then(list => {
    return new Notifications({ list });
  });
}

export function addNotification(notification) {
  Model.Notification.store.addAllReference(notification);
}

class Notifications extends BaseModel {
  constructor(attributes) {
    super(attributes);
    this.list.sort(Model.Notification.comparator);
  }
  get unseenCount() {
    var profile = App.vm.profile;

    return this.list.filter(notification => {
      return notification.time > profile.lastNotificationReadDate;
    }).length;
  }
}
