<template>
  <div>
    <div class="page-content with-footer">
      <section>
        <div class="">Select Work Items from backlog to be part of {{ sprint.title }}</div>
      </section>
      <section>
        <div class="item-list hide-last-row-border box">
          <select-issue-row
            v-for="wrapper in issueWrapper.children"
            :wrapper="wrapper"
            :key="wrapper.id"
          ></select-issue-row>
        </div>
      </section>
    </div>
    <footer>
      <a-btn color="primary" @click="finish">Add Selected Items</a-btn>
    </footer>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';

import createTopWrapper from '@/models/issuewrapper/createTopWrapper';
import SelectIssueRow from './SelectIssueRow.vue';

export default {
  props: ['sprint', 'project'],
  getData: function() {
    return { project: App.vm.currentProject };
  },
  data: function() {
    var wrapperContext = {
      sprint: Model.Sprint.backlog,
      openLevel: 0,
      wrapperDefaults: {
        sprintselected: 'false',
      },
    };

    return {
      issueWrapper: createTopWrapper(this.project.issue, wrapperContext),
    };
  },
  methods: {
    finish() {
      saveSprintToChildren(this.issueWrapper, this.sprint);
      setTimeout(() => {
        this.$emit('close');
      }, 0);
    },
  },
  components: { SelectIssueRow },
};

function saveSprintToChildren(issueWrapper, sprint) {
  // REFACTOR rewrite: first use "selected"-filter to get all selected issues, then save

  issueWrapper.issues.forEach(function(is) {
    if (is.sprintselected === 'true') {
      is.saveProperty('sprint', sprint);
    } else if (is.sprintselected === 'semi') {
      saveSprintToChildren(is, sprint);
    }
  });
}
</script>
