import { nextTick } from '@/utils';
export default function stickyOnScroll(scrollContainer, target, targetContainer) {
  let storedPosition = null;
  const NAVBAR_HEIGHT = 10,
    MENU_OFFSET = 25;

  scrollContainer.on('scroll', onScroll);
  onScroll();

  function setSticky() {
    if (storedPosition == null) {
      console.log('setSticky');

      storedPosition = {
        top: target.style.top,
        left: target.style.left,
      };

      nextTick(() => {
        Object.assign(target.style, {
          position: 'fixed',
          top: NAVBAR_HEIGHT + 'px',
          left: target.offset().left - scrollContainer.offset().left + 'px',
        });
      });
    }
  }

  function removeSticky() {
    if (storedPosition != null) {
      const stored = storedPosition;
      storedPosition = null;

      Object.assign(target.style, {
        position: 'absolute',
        top: stored.top,
        left: stored.left,
      });
    }
  }

  function onScroll() {
    const scrollTop = scrollContainer.scrollTop + NAVBAR_HEIGHT,
      containerTop = targetContainer.offsetTop - MENU_OFFSET,
      containerBottom = containerTop + targetContainer.clientHeight;

    if (scrollTop > containerTop && scrollTop < containerBottom) {
      setSticky();
    } else {
      removeSticky();
    }
  }

  function destroy() {
    scrollContainer.off('scroll', onScroll);
    removeSticky();
  }

  return { destroy };
}
