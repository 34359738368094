import { Util } from '@/namespace';
export default function(popover, options, targetBox) {
  const body = document.querySelector('body');
  let { type } = options;

  const popoverBox = popover.getBoundingClientRect();

  let caretCss,
    cssClass,
    css = {};
  type = type || 'menu';

  if (Util.isMobile() && (type === 'selector' || type === 'menu')) {
    asMobileMenu();
  } else if (type === 'selector') {
    asSelector();
  } else if (type === 'menu') {
    asSelector(options.alignToTop);
    caretCss = null;
  } else if (type === 'confirmmodal') {
    asConfirmModal();
  }

  function asSelector(alignToTop) {
    caretCss = {};

    const windowWidth = body.outerWidth();

    if (popoverBox.width / 2 > centerX(targetBox)) {
      css.left = '10px';
      caretCss.left = targetBox.left - 5 + 'px';
    } else if (popoverBox.width / 2 + targetBox.left > windowWidth) {
      var left = windowWidth - popoverBox.width - 10;
      css.left = left + 'px';
      caretCss.left = targetBox.left - left + 5 + 'px';
    } else {
      css.left = centerX(targetBox) - popoverBox.width / 2 + 'px';
    }

    if (popoverBox.height + 20 > targetBox.top) {
      if (popoverBox.height + targetBox.top > body.outerHeight()) {
        css.height = body.outerHeight() - targetBox.top - 50 + 'px';
        css.maxHeight = body.outerHeight() - targetBox.top - 50;
      }
      css.top = targetBox.top + targetBox.height + 10 + 'px';
      caretCss.top = '-10px';
      caretCss.transform = 'rotate(180deg)';
    } else {
      css.top = targetBox.top - popoverBox.height - 10 + 'px';
      caretCss.bottom = '-10px';
    }

    if (alignToTop) {
      var top = alignToTop.offset().top + alignToTop.outerHeight();
      css.top = top + 'px';
    }
  }

  function asConfirmModal() {
    var left = (body.outerWidth() - popoverBox.width) / 2,
      top = (body.outerHeight() - popoverBox.height) / 2 - 50;
    css.left = left + 'px';
    css.top = top + 'px';
  }

  function asMobileMenu() {
    cssClass = 'mobile';

    var windowHeight = body.outerHeight();

    css = {
      width: '100%',
      left: 0,
    };
    if (popoverBox.height > windowHeight - 120) {
      css.height = windowHeight - 120 + 'px';
    }
  }

  return { css, caretCss, cssClass };
}

function centerX(box) {
  return box.left + box.width / 2;
}
