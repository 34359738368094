<template>
  <div class="filter-rule">
    {{ item.text }}
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
