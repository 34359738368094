<template>
  <section>
    <h1>Work Board</h1>
    <div class="image-with-helpkeys sprintboard">
      <img src="/static/images/help/sprintboard.png" />
      <helpkey id="m1">1</helpkey>
      <helpkey id="m2">2</helpkey>
    </div>
    <div class>
      <p>
        Items assigned to a sprint shows up on the Sprint Board. Both top level items and sub items
        are shown on the sprint board and can be moved to a status column.
      </p>

      <h3>Add items to the sprint</h3>
      <p>
        There are several ways to add items to a sprint. On the
        <a href="/help/viewitem">item page</a>
        the sprint selector allows you to set a sprint. You can also
        <a href="/help/sprints">drag items between sprint lists</a>.
      </p>
      <p>
        By using the add items button
        <helpkey>1</helpkey>you get a quick way to select which items that should be part of the
        sprint.
      </p>
      <p>
        When creating a new item on the sprint board
        <helpkey>2</helpkey>, the item gets the viewed sprint as default.
      </p>
    </div>
  </section>
</template>
<script>
export default {};
</script>
