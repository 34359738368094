<template>
  <div class="page-content helpcenter narrow">
    <AgilooLogo />
    <section class="HelpCenterMenu-section">
      <HelpCenterMenu :pages="pages" :helppage="helppage" />
    </section>
    <component :is="helppage"></component>

    <section>
      <HelpCenterFooter :pages="pages" :helppage="helppage" />
    </section>
    <section class="filler"></section>
    <page-footer />
  </div>
</template>
<script>
import GetStarted from './help-getstarted.vue';
import WorkItems from './help-workitems.vue';
import SubItems from './help-subitems.vue';
import WorkBoard from './help-workboard.vue';
import Sprints from './help-sprints.vue';
import ViewItem from './help-viewitem.vue';
import GithubIntegraton from './help-github.vue';
import HelpCenterMenu from './HelpCenterMenu.vue';
import HelpCenterFooter from './HelpCenterFooter.vue';
const pages = [
  {
    title: 'Get Started',
    component: GetStarted,
    subpage: 'getstarted',
  },
  {
    title: 'Work Items',
    component: WorkItems,
    subpage: 'workitems',
  },
  {
    title: 'Sub Items',
    component: SubItems,
    subpage: 'subitems',
  },
  {
    title: 'View Item',
    component: ViewItem,
    subpage: 'viewitem',
  },
  {
    title: 'Work Board',
    component: WorkBoard,
    subpage: 'workboard',
  },
  {
    title: 'Sprints',
    component: Sprints,
    subpage: 'sprints',
  },
  {
    title: 'Github Integration',
    component: GithubIntegraton,
    subpage: 'github',
  },
];

export default {
  route: '/help/:subpage',
  props: ['helppage'],
  noAuthentication: true,

  getData: function(props) {
    let helppage = props.subpage;
    if (!this.components[helppage]) {
      helppage = 'workitems';
    }
    return { helppage };
  },
  data() {
    return { pages };
  },

  components: {
    workitems: WorkItems,
    subitems: SubItems,
    getstarted: GetStarted,
    workboard: WorkBoard,
    sprints: Sprints,
    viewitem: ViewItem,
    github: GithubIntegraton,
    HelpCenterMenu,
    HelpCenterFooter,
  },
};
</script>
