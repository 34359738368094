<template>
  <div id="items-view">
    <div class="issue-list-sections Board" :class="cssClasses">
      <BoardColumn
        v-for="wrapper in issueSections.issues"
        :wrapper="wrapper"
        :key="wrapper.wrapperContext.status.id"
        :ref="wrapper.wrapperContext.status.id"
      ></BoardColumn>
    </div>
  </div>
</template>
<script src="./Board.js"></script>
<style lang="scss">
.Board {
  display: flex;
  position: relative;
  height: 100%;
  min-height: 300px; // For safari

  padding-right: 15px;

  border-top: 1px solid #e8e8e8;
  background-color: #f6f6f6;

  a#archive {
    margin: 10px 8px;
    background-color: rgba(0, 0, 0, 0);
  }
  &.overflowed {
    overflow-x: auto;
  }
  &:not(.overflowed) {
    justify-content: center;
    overflow-x: hidden;
  }
}
</style>
