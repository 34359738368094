<template>
  <div class="list-row MemberRow">
    <div class="member-icon" :member-id="member.id">
      <i class="icon-"></i>
    </div>

    <a class="title" :href="member.url" @click="select">
      {{ member.displayName }}
      <span class="invite-sent" v-if="member.unregistered && member.inviteSent"
        >(Invite to Agiloo Sent)</span
      >
    </a>

    <warning-link v-if="premiumRequired" href="/premiumPlan">Premium Required</warning-link>

    <template v-if="member.unregistered">
      <q-btn
        class="action-link"
        v-if="member.inviteSent"
        id="send-invite"
        @click.prevent="resendInvite"
        flat
        rounded
        >Resend invite</q-btn
      >
      <q-btn class="action-link" v-else id="edit-member" @click.prevent="editMember"
        >Edit and Invite to Project</q-btn
      >
    </template>

    <div class="fixed-width email mobile-hidden">
      {{ member.email }}
    </div>

    <q-btn class="role" :role-id="member.role.id" @click.prevent="selectRole" unelevated rounded>{{
      member.role.name
    }}</q-btn>

    <q-btn round flat @click.prevent="deleteMember">
      <i class="icon-bin"></i>
    </q-btn>
  </div>
</template>

<script>
import { App } from '@';
import CreateMember from '@/pages/createmember/CreateMember.vue';
import RoleSelector from '@/popovers/RoleSelector';
export default {
  props: ['member', 'project'],
  computed: {
    premiumRequired() {
      return this.project.premiumRequired && !this.member.premium;
    },
  },
  methods: {
    select(ev) {
      this.$emit('select', this.member, ev);
    },

    deleteMember() {
      App.openConfirmDialog({
        title: 'Remove Member',
        text: 'This will remove the member from the project',
        onConfirm: () => {
          this.member.destroy();
        },
      });
    },
    editMember() {
      App.openPopup(CreateMember, {
        member: this.member,
        edit: true,
      });
    },
    selectRole: function (ev) {
      App.openPopover(
        RoleSelector,
        {
          member: this.member,
        },
        {
          target: ev.currentTarget,
        }
      );
    },
    resendInvite() {
      this.member.resendInvite().then(function () {
        App.displayInfo('Invite Sent');
      });
    },
  },
};
</script>
<style lang="scss">
.MemberRow {
  .role {
    min-width: 82px;
  }

  .email {
    font-size: 12px;
    flex-shrink: 1;
    overflow: hidden;
  }

  .invite-sent {
    font-size: 12px;
  }

  .action-link {
    font-weight: 400;
  }
}
</style>
