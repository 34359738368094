<template>
  <div class="DocumentParentPath" v-show="parents.length">
    <a v-for="parent in parents" :href="parent.url" :key="parent.id">{{ parent.title }}</a>
  </div>
</template>

<script>
import { Model } from '@';
export default {
  props: ['document'],
  computed: {
    parents() {
      var result = [];
      function addParent(is) {
        var parent = is.parent;
        if (parent != null && parent instanceof Model.Document) {
          result.unshift(parent);
          addParent(parent);
        }
      }
      addParent(this.document);

      return result;
    },
  },
};
</script>
<style lang="scss">
.DocumentParentPath {
  padding-bottom: 10px;
  a {
    margin-right: 7px;
    font-size: 14px;
    font-weight: 300;
    &:after {
      content: '>';
      font-weight: 300;
      position: relative;
      left: 3px;
      text-decoration: none;
    }
  }
}
</style>
