export function toErrorMessage(error) {
  if (error.code === 'tax_id_invalid') {
    return 'The VAT entered is invalid';
  }

  if (error.code === 'parameter_missing') {
    if (error.param === 'address[line1]') {
      return 'Billing Address is missing';
    }
  }

  return 'An error has occured';
}
