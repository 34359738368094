import _ from 'lodash';
export function isTouchStart(ev) {
  return ev.type === 'touchstart';
}

export function disableLiveRemove(dragItem) {
  dragItem.$el.disableLiveRemove();
  _.each(dragItem.$el.parents('.issue-wrapper'), el => {
    el.disableLiveRemove();
  });
}

export function getTotalChange(moveEv, startEv) {
  var change = {
    x: moveEv.pageX - startEv.pageX,
    y: moveEv.pageY - startEv.pageY,
  };
  return Math.abs(change.x) + Math.abs(change.y);
}

export function touchEventWrapper(ev) {
  var touch = ev.touches[0] || ev.changedTouches[0];
  return {
    pageX: touch.pageX,
    pageY: touch.pageY,

    preventDefault() {
      ev.preventDefault();
    },
    stopPropagation() {
      ev.stopPropagation();
    },
  };
}

export class CloneStyle {
  constructor() {
    this.left = null;
    this.top = null;
    this.width = null;
    this.transform = 'translate3d(0,0,0)';
  }
  setStart(left, top) {
    this.left = left + 'px';
    this.top = top + 'px';
  }
  setTranslate3d(x, y) {
    this.transform = `translate3d(${x}px, ${y}px, 0)`;
  }
  setWidth(width) {
    this.width = width + 'px';
  }
}
