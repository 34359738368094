import _ from 'lodash';

Event.prototype.stop = function() {
  this.stopPropagation();
  this.preventDefault();
};

window.on = window.addEventListener;
window.off = window.removeEventListener;

_.assign(Element.prototype, {
  find(sel) {
    return this.querySelector(sel);
  },

  addClass(c) {
    return this.classList.add(c);
  },
  removeClass(c) {
    return this.classList.remove(c);
  },
  html(html) {
    this.innerHTML = html;
  },
  offset() {
    return this.getBoundingClientRect();
  },
  outerHeight() {
    return this.offsetHeight;
  },
  height() {
    //return $(this).height();
    return this.clientHeight;
  },

  outerWidth() {
    return this.offsetWidth;
  },

  triggerCustomEvent(eventName) {
    this.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
      })
    );
  },
  on(ev, handler) {
    return this.addEventListener(ev, handler);
  },
  off(ev, handler) {
    return this.removeEventListener(ev, handler);
  },
  disableLiveRemove() {
    this._disableLiveRemove = true;
  },
  parents(selector) {
    let result = [],
      element = this.parentNode;
    while (element) {
      if (element && element.matches && element.matches(selector)) {
        result.push(element);
      }

      element = element.parentNode;
    }
    return result;
  },

  is(sel) {
    return this.matches(sel);
  },

  boxOffset() {
    var offset = this.getBoundingClientRect();
    var height = this.offsetHeight;
    var width = this.offsetWidth;

    return {
      left: offset.left,
      right: offset.left + width,
      centerX: offset.left + width / 2,
      top: offset.top,
      bottom: offset.top + height,
      centerY: offset.top + height / 2,
    };
  },
  preloader() {
    console.log('init preloader');
  },
});
