import StoreModel from './base/StoreModel';

import { Model } from '@/index';

// https://coolors.co/009688-2196f3-ff9800-f44336-9c27b0 original material colors
// https://coolors.co/177c72-4998d8-e59c2d-db645c-893f96 same with less saturation

const colors = [
  {
    id: 'green',
    code: '4db6ac',
  },
  {
    id: 'blue',
    code: '40A2ED',
  },
  {
    id: 'indigo',
    code: '7E78E8',
  },
  {
    id: 'purple',
    code: 'C464EA',
  },
  {
    id: 'red',
    code: 'F95C79',
  },
  {
    id: 'orange',
    code: 'ffb74d',
  },
  {
    id: 'yellow',
    //code: 'fdd835', //amber400
    code: 'FCD31E',
  },
  {
    id: 'gray',
    code: 'c8c8c8',
  },
  {
    id: 'white',
    code: 'ffffff',
    darkText: true,
  },
];

class Color extends StoreModel {
  static loadAll() {
    return Promise.resolve(colors);
  }
}

Model.Color = Color;
export default Color;
