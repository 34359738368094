import { Plugin, PluginKey } from 'prosemirror-state';
import { Extension } from '@tiptap/core';
import { pasteFile, dropFile } from '@/apphandlers/filehandler/filehandler';

export default Extension.create({
  name: 'PasteAndDropImage',
  defaultOptions: {
    parentModel: null,
  },
  addProseMirrorPlugins() {
    const editor = this.editor;
    const parentModel = this.options.parentModel;
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop(view, event) {
              const files = event.dataTransfer.files;
              if (!files.length) {
                return false;
              }
              event.preventDefault();
              event.stopPropagation();
              dropFile(event, parentModel).then(files => {
                insertImages(files, editor);
              });
              return false;
            },
            paste(view, event) {
              const { clipboardData } = event;
              const hasFiles = clipboardData && clipboardData.files && clipboardData.files.length;

              if (!hasFiles) {
                return;
              }
              event.preventDefault();
              event.stopPropagation();
              pasteFile(event, parentModel).then(files => {
                insertImages(files, editor);
              });

              return false;
            },
          },
        },
      }),
    ];
  },
});

function insertImages(files, editor) {
  files.map(file => {
    file.waitForUploadDone().then(() => {
      editor.chain().focus().setImage({ src: file.fileurl }).run();
    });
  });
}
