<template>
  <div class="estimate-bar">
    <div class="progress" :style="{ width: progress + '%' }" :progress="progress"></div>
  </div>
</template>

<script>
export default {
  props: ['estimateMap'],
  computed: {
    progress() {
      return (100 * this.estimateMap.done) / this.estimateMap.est;
    },
  },
};
</script>
