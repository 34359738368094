import StoreModel from './base/StoreModel';
import _ from 'lodash';

import { Model, Util } from '@/namespace';
import { fill, fillWithData } from './project/fill';
import { loadProjectActivityMap } from './projectActivityService';
class Project extends StoreModel {
  static NAME = 'Project';
  static define = {
    title: null,
    description: null,
    type: {
      value() {
        return 'scrum';
      },
    },
    sprints: {
      value() {
        return [];
      },
      serialize: false,
    },
    members: {
      value() {
        return [];
      },
      serialize: false,
    },
    statuses: {
      value() {
        return [];
      },
      serialize: false,
    },
    tags: {
      value() {
        return [];
      },
      serialize: false,
    },
    documents: {
      value() {
        return [];
      },
    },
    premiumRequired: false,
    activityMap: {
      serialize: false,
      value() {
        return null;
      },
    },
    updated: {
      serialize: false,
      type: 'date',
    },
    issueCount: {
      serialize: false,
    },
  };

  static loadAll() {
    return Util.jsonajax({ url: '/project/findall', method: 'GET' });
  }

  static find(id) {
    return this.findAll()
      .then(() => {
        return this.store.get(id);
      })
      .then(project => {
        if (!project.filled) {
          return project.fill();
        }
        return project;
      });
  }

  async fillProjectActivity() {
    if (this.activityMap) {
      return this.activityMap;
    }
    this.activityMap = await loadProjectActivityMap(this.id);
    return this.activityMap;
  }

  postInitModel() {
    this.constructor.store.addAllReference(this);
  }

  postDestroy() {
    this.constructor.store.removeAllReference(this);
  }

  get githubAccess() {
    return this.githubSettings && this.githubSettings.active;
  }

  get activeStatuses() {
    var result = this.statuses.filter(Model.Status.filter.active);
    //result.sort(Util.priorityComparator)
    return result;
  }
  get unarchivedStatuses() {
    var result = this.statuses.filter(Model.Status.filter.unarchived);
    //result.sort(Util.priorityComparator)
    return result;
  }

  get issueTypes() {
    return Model.IssueType.getAll();
  }

  get text() {
    return this.title;
  }

  get boardTerm() {
    return this.type === 'scrum' ? 'Sprint' : 'Board';
  }

  findMember(id) {
    var result;
    _.each(this.members, function(member) {
      if (member.id === id) {
        result = member;
        return false;
      }
    });
    return result;
  }

  get url() {
    return this.projectUrl('/project');
  }

  projectUrl(appendix) {
    return '/' + this.id + appendix;
  }

  findIssueByKey(key) {
    key = Number(key);
    if (isNaN(key)) {
      return;
    }
    var result;
    _.each(this.issue.allIssues(), function(issue) {
      if (issue.key === key) {
        result = issue;
        return false;
      }
    });
    if (result == null) {
      console.error('could not find issue by key' + key);
    }

    return result;
  }
}

_.assign(Project, {
  createSampleProject() {
    return Util.jsonajax({
      url: '/project/createsampleproject',
      data: {},
      method: 'POST',
    }).then(response => {
      return Model.Project.createAndInitModel(response);
    });
  },
  createNew() {
    const project = new Project();
    project.initModel();
    return project;
  },
});

Project.prototype.fill = fill;
Project.prototype.fillWithData = fillWithData;
Model.Project = Project;
export default Project;
