<template>
  <div class="main-search">
    <div class="searchbody" :active="active">
      <i class="icon-search3"></i>
      <input
        type="text"
        class="input"
        autocomplete="off"
        @focus="focus"
        @keydown="keydown"
        @input="input"
        @blur="blur"
        v-model="text"
      />
      <div class="search-result">
        <div
          v-for="item in searchResult"
          class="searchelement"
          :class="{ active: isActive(item) }"
          @click="selectItem(item)"
        >
          <component :is="'Result' + item.constructor.NAME" :item="item"></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { App, Vue } from '@';

import { findSearchResult } from './searchutil';
import handleKeydown from './handleKeydown';
import Logger from '@/components/Logger.vue';
import './searchresults';
export default {
  data: function() {
    return { active: false, activeItem: null, searchResult: null, text: '' };
  },
  methods: {
    close() {
      this.active = false;
    },
    focus(ev) {
      this.active = true;
      ev.currentTarget.select();
    },
    blur() {
      if (this.text === 'oriolog') {
        App.openPopup(Logger);
      }
      setTimeout(() => {
        this.close();
      }, 100);
    },
    input() {
      this.active = true;

      var text = this.text;
      if (text.length >= 2) {
        var searchResult = findSearchResult(text, {
          models: ['tag', 'member', 'issue', 'sprint', 'document'],
        });
      } else {
        searchResult = null;
      }

      this.searchResult = searchResult;
    },
    selectItem(m) {
      App.router.navigate(m.url);
      this.$el.find('input').blur();
    },
    isActive(item) {
      return item === this.activeItem;
    },
    keydown: handleKeydown({ selectFirstOnEnter: true }),
  },
};
</script>
