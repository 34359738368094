import _ from 'lodash';

const KEYCODE = {
  ESC: 27,
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
};

export default function(options = {}) {
  return function(ev) {
    var component = this;
    switch (ev.keyCode) {
      case KEYCODE.DOWN:
      case KEYCODE.TAB:
        markActive(1);
        ev.stop();
        break;
      case KEYCODE.UP:
        markActive(-1);
        ev.stop();
        break;
      case KEYCODE.ENTER:
        if (component.activeItem == null && options.selectFirstOnEnter) {
          component.activeItem = component.searchResult[0];
        }
        if (component.activeItem == null) {
          component.close();
          ev.stop();
          return;
        }

        component.selectItem(component.activeItem);
        ev.stop();
        break;
      case KEYCODE.ESC:
        options.handleClose && options.handleClose.call(component);
        ev.stop();
        break;
      case KEYCODE.BACKSPACE:
        if (
          ev.target.selectionStart === 0 &&
          ev.target.selectionEnd === 0 &&
          options.handleBackspace
        ) {
          options.handleBackspace.call(component);
        }
        break;
      default:
    }

    function markActive(step) {
      var index = findCurrentIndex(),
        newIndex;
      if (index == null) {
        newIndex = 0;
      } else {
        newIndex = (index + component.searchResult.length + step) % component.searchResult.length;
      }

      component.activeItem = component.searchResult[newIndex];
    }

    function findCurrentIndex() {
      var result;

      _.each(component.searchResult, (item, index) => {
        if (item === component.activeItem) {
          result = index;
          return false;
        }
      });
      return result;
    }
  };
}
