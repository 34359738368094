import { Model } from '@/namespace';

export default function() {
  Model.Sprint.backlog = Model.Sprint.createModel({
    title: 'Backlog',
    id: 'backlog',
    backlog: true,
    createdAt: new Date(2100, 1, 1, 0, 0, 0, 0),
    priority: 1000000,
    finished: false,
  });

  Model.Member.unassigned = Model.Member.createModel({
    displayName: 'Unassigned',
    id: 'unassigned',
    initials: '',
    unassigned: true,
  });
}
