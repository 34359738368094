<template>
  <div class="ViewIssue page-content page_view_issue wide bottom-margin-on-touch">
    <section class="split2">
      <div class="main">
        <BreadCrumbs :issue="issue" />
        <text-input
          v-model="issue.title"
          class="main-title"
          placeholder="Item Title"
          @save="save"
        ></text-input>
        <TipTapEditor
          v-model="issue.description"
          @update:modelValue="save"
          :parentModel="issue"
          :hideable="true"
        ></TipTapEditor>
        <ItemFiles :parent="issue" />
      </div>
      <aside>
        <ItemContext :issue="issue" :collectionSettings="collectionSettings" />
      </aside>
    </section>

    <section
      class="item-collection full-width"
      active-view="list"
      :class="{ 'no-0': issue.issues.length === 0 }"
    >
      <div class="mw-wide item-collection-settings-section">
        <item-collection-settings
          :collectionSettings="collectionSettings"
        ></item-collection-settings>
      </div>

      <IssueSections :collectionSettings="collectionSettings" ref="issueSections"></IssueSections>
    </section>

    <section class="center" v-if="showArchivedLinks">
      <q-btn color="primary" flat @click="toggleArchived" rounded>View archived Items</q-btn>
    </section>

    <ViewIssueAdditionalViews :issue="issue" :project="project" />

    <teleport to="#navbar" v-if="isPageActive">
      <q-btn id="create" @click="createNew" rounded>
        <i class="icon-plus3"></i>
        <span>New Sub Item</span>
      </q-btn>
      <q-btn class="menu-more" @click.prevent="moremenu" rounded>
        <span>More</span>
        <i class="icon-more2"></i>
      </q-btn>
    </teleport>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';
import ItemContext from './ItemContext.vue';
import ItemFiles from './ItemFiles.vue';
import './BreadCrumbs.vue';
import fileEvents from '@/apphandlers/filehandler/fileeventsmixin';
import eventMixin from '@/common/eventMixin';
import { pageMixin } from '@/common';
import ViewIssueAdditionalViews from './ViewIssueAdditionalViews.vue';

import MoreMenu from './ViewIssueMoreMenu.vue';
import { CreateIssue } from '@/pages/modals';

export default {
  route: '/:projectId/item/:issueKey',
  helpurl: '/help/viewitem',
  menu: 'items',
  props: ['issue', 'project'],
  mixins: [eventMixin, pageMixin],
  data: function () {
    var collectionSettings = {
      filterQuery: null,
      includeArchived: false,
      issue: this.issue,
    };

    return {
      collectionSettings,
      fileParent: this.issue,
    };
  },

  getData: function ({ projectId, issueKey }) {
    const projectResolve = Model.Project.find(projectId);

    return {
      issue: projectResolve.then(project => {
        App.vm.currentProject = project;
        const issue =
          issueKey.length === 24 ? Model.Issue.get(issueKey) : project.findIssueByKey(issueKey);
        return issue.fill();
      }),
      project: projectResolve,
    };
  },
  events: fileEvents,
  methods: {
    save: function () {
      this.issue.save();
    },
    toggleArchived(ev) {
      ev.stop();
      this.collectionSettings.includeArchived = !this.collectionSettings.includeArchived;
    },
    moremenu(ev) {
      App.openPopover(
        MoreMenu,
        {
          issue: this.issue,
        },
        {
          type: 'menu',
          alignToTop: this.$el.closest('.navbar'),
          target: ev.currentTarget,
        }
      );
    },
    createNew(ev) {
      ev.preventDefault();
      const attributes = { parent: this.issue };

      App.openPopup(CreateIssue, { attributes });
    },
  },
  computed: {
    showArchivedLinks() {
      return (
        this.issue.statusMap.containsKey('archived') && !this.collectionSettings.includeArchived
      );
    },
  },

  components: {
    ItemContext,
    ViewIssueAdditionalViews,
    ItemFiles,
  },
};
</script>
<style lang="scss" src="./ViewIssue.scss" />
