import _ from 'lodash';
import { Model } from '@/namespace';

import createTopWrapper from './createTopWrapper';
import '@/models/issuewrapper/issuewrapper';

export default class SprintIssueSections {
  static createNew(issue, controller, wrapperContextBase = {}) {
    const result = new this();

    result.issue = issue;
    result.issues = [];
    result.wrapperContextBase = {
      ...wrapperContextBase,
      openLevel: wrapperContextBase.sprint ? 1 : 0,
      propertyToSave: 'sprint',
    };
    result.populate();
    result.bindChangeEvent(controller);

    return result;
  }

  getMissingSprintIds() {
    const missingSprints = _.extend({}, this.issue.sprintMap.getKeys());
    _.each(this.issues, function (wrapper) {
      delete missingSprints[wrapper.wrapperContext.sprint.id];
    });
    return _.keys(missingSprints);
  }

  isSingleSprint() {
    return this.wrapperContextBase.sprint != null;
  }

  addAndRemoveWrappers() {
    if (this.isSingleSprint()) {
      return;
    }
    const missingSprintIds = this.getMissingSprintIds();

    if (!_.isEmpty(missingSprintIds)) {
      _.each(missingSprintIds, sprintId => {
        this.addSprintList(Model.Sprint.store.get(sprintId));
      });
      this.sort();
    }

    _.each(this.issues, (wrapper, index) => {
      if (wrapper && wrapper.issues.length === 0 && !wrapper.permanent) {
        this.issues.splice(index, 1);
      }
    });
    if (this.issues.length === 0) {
      this.addSprintList(this.issue.sprintMap.calcValue(), true);
    }
  }

  bindChangeEvent(controller) {
    const self = this;
    controller.on(this.issue, 'issuechange', ([changeTree]) => {
      if (self.preventListenToChange) {
        return;
      }
      _.each(self.issues, wrapper => {
        wrapper.onIssueChange(changeTree);
      });
      // TODO PROBLEM - SprintIssueSections is turned to array after bound

      self.addAndRemoveWrappers();
    });
  }
  removeListenToChange() {
    // would be better to actually remove the binding of change event
    this.preventListenToChange = true;
  }

  comparator(listA, listB) {
    return Model.Sprint.comparator.call(
      null,
      listA.wrapperContext.sprint,
      listB.wrapperContext.sprint
    );
  }
  sort() {
    this.issues.sort(this.comparator);
  }
  populate() {
    if (this.isSingleSprint()) {
      this.addSprintList(this.wrapperContextBase.sprint);
    } else {
      const sprints = this.issue.sprintMap.toArray();

      if (this.issue.isProject()) {
        sprints.add(Model.Sprint.backlog);
      }

      _.each(sprints, sprint => {
        this.addSprintList(sprint);
      });

      if (this.issues.length === 0) {
        this.addSprintList(this.issue.sprintMap.calcValue(), true);
      }
      this.sort();
    }

    return this;
  }

  addSprintList(sprint, force) {
    if (sprint == null) {
      return;
    }

    const permanent =
      this.issue.isProject() && sprint.backlog && !isArchiveStatus(this.wrapperContextBase.status);

    const issueWrapper = createTopWrapper(
      this.issue,
      {
        ...this.wrapperContextBase,
        sprint: sprint,
      },
      {
        permanent: permanent,
        force: force,
      }
    );
    if (!issueWrapper) {
      return;
    }
    if (issueWrapper.issues.length === 0 && !permanent && !force) {
      return;
    }

    issueWrapper.url = '/' + this.issue.projectId + '/sprint/' + sprint.id;

    this.issues.push(issueWrapper);
  }
}

function isArchiveStatus(status) {
  return status != null && status.key === 'archived';
}
