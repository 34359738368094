<template>
  <div class="EditProjectStatuses list statuses">
    <DragContainer ref="dragContainer" :dragSession="dragSession" :modelList="statuses">
      <EditProjectStatusRow
        v-for="status in statuses"
        :key="status.id"
        :status="status"
        :ref="status.id"
      />
    </DragContainer>

    <footer>
      <a-btn flat @click="createStatus">
        <i class="icon-plus3"></i>
        <span>Add Status</span>
      </a-btn>
    </footer>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';
import DragContainer from '@/components/drag/DragContainer.vue';

import CreateStatus from '@/pages/createstatus/CreateStatus.vue';

import EditProjectStatusRow from './EditProjectStatusRow.vue';

export default {
  props: ['project'],
  data() {
    return {
      statuses: null,
      dragSession: null,
    };
  },
  watch: {
    'project.statuses': {
      immediate: true,
      handler() {
        this.statuses = this.project.unarchivedStatuses;
      },
    },
  },

  methods: {
    createStatus() {
      var statuses = this.statuses;
      var priority = statuses[statuses.length - 1].priority + 100;
      var status = new Model.Status({
        projectId: this.project.id,
        priority: priority,
        active: true,
        color: Model.Color.store.get('white'),
      });
      App.openPopup(CreateStatus, { status: status });
    },
  },
  components: {
    EditProjectStatusRow,
    DragContainer,
  },
};
</script>
<style lang="scss">
.EditProjectStatuses {
  .status.statusFixed .checkbox {
    background: none;
    border: none;
    cursor: default;
  }

  .status:not(.statusFixed) .title {
    cursor: pointer;
  }
}
</style>
