import _ from 'lodash';

export default {
  methods: {
    on: function (target, ev, listener) {
      target.on(ev, listener);
      addDestroyCall(this, () => {
        target.off(ev, listener);
      });
    },
  },
  mounted: function () {
    _.each(this.$options.events, (handler, eventDescription) => {
      addByEventDescription(this, eventDescription, handler);
    });
  },
  unmounted: function () {
    _.each(this.destroyCalls, c => {
      c();
    });
  },
};

function addByEventDescription(component, eventDescription, handler) {
  const { targetName, selector, eventName } = parseEventDescription(eventDescription);
  let target, topContainer;

  if (targetName == null) {
    target = component.$el;
    topContainer = target;
  } else if (targetName === 'window') {
    target = window;
  } else {
    target = component[targetName];
  }

  component.on(target, eventName, function (ev) {
    if (ev.cancelBubble) {
      return;
    }

    const currentTarget = selector ? closestWithinContainer(ev.target, selector, topContainer) : target;
    //const currentTarget = selector ? ev.target.closest(selector) : target;

    if (currentTarget) {
      handler.call(component, ev, currentTarget);
    }
  });
}

// Use special matches that ends on component element, see e.g. polyfill at https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
function closestWithinContainer(el, selector, topContainer) {
  if (el == null) {
    return null;
  }
  if (el.matches(selector)) {
    return el;
  }
  if (topContainer && el === topContainer) {
    return null;
  }
  return closestWithinContainer(el.parentElement, selector, topContainer);
}

function parseEventDescription(eventDescription) {
  let descriptionItems = eventDescription.split(' '),
    firstItem = descriptionItems[0],
    targetName,
    selector,
    eventName = descriptionItems[descriptionItems.length - 1];

  descriptionItems.removeLast();

  if (firstItem.indexOf('{') === 0) {
    targetName = firstItem.substring(1, firstItem.length - 1);
    descriptionItems.removeFirst();
  }

  if (descriptionItems.length > 0) {
    selector = descriptionItems.join(' ');
  }

  return { targetName, selector, eventName };
}

function addDestroyCall(component, call) {
  if (component.destroyCalls == null) {
    component.destroyCalls = [];
  }
  component.destroyCalls.push(call);
}
