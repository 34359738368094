function timer() {
  var logPoints = [],
    startTime = new Date().getTime(),
    lastTime = startTime;

  const service = {
    print(text = 'Total Time') {
      var time = new Date().getTime(),
        totalTime = time - startTime;
      service.log('remaining time');
      logPoints.push([text, time - startTime]);
      console.log(text + ' ' + totalTime);
      //console.table(logPoints);
    },
    clear() {
      startTime = lastTime = new Date().getTime();
      logPoints = [];
    },
    log(text) {
      var now = new Date().getTime(),
        time = now - lastTime;

      logPoints.push([text, time]);
      lastTime = now;
    },
  };
  return service;
}
export default timer;
