<template>
  <div class="SubscriptionStatus split-section">
    <aside>
      <h3>Payment Information</h3>
    </aside>

    <template v-if="!subscription.canceled">
      <div>
        <div>
          You are on a Premium Plan, paying subscription for
          <span class="highlight">{{ subscription.quantity }} users. </span>
        </div>
        <div>
          Next billing will occur on
          <span class="highlight">{{ formatDate(subscription.currentPeriodEnd) }}</span>
        </div>
        <div>
          Card used: **** **** ****
          <span class="highlight">{{ subscription.cardLast4 }}</span>
        </div>
        <div class="flex right">
          <a-btn class="" color="primary" @click="changeCard">Change Payment Card</a-btn>
          <a-btn class="q-ml-sm" color="primary" @click="updatePaymentInfo"
            >Update Billing Info</a-btn
          >
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        Your Premium Plan will end on
        <span class="highlight">{{ formatDate(subscription.currentPeriodEnd) }}</span
        >, you will not be charged with any more payments.

        <div class="flex">
          <a-btn class="right" color="primary" @click="reactivatePremium">Reactivate Premium</a-btn>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { App } from '@/namespace';
import { changeCard } from '@/models/billing/billingService';
import EnterPaymentInfoModal from './EnterPaymentInfoModal.vue';
export default {
  props: ['subscription'],

  methods: {
    reactivatePremium() {
      return this.subscription.reactivatePremium();
    },
    updatePaymentInfo() {
      App.openModal(EnterPaymentInfoModal, { subscription: this.subscription });
    },
    changeCard() {
      changeCard();
    },
  },
};
</script>

<style lang="scss">
.SubscriptionStatus {
  .flex.right {
    justify-content: flex-end;
    padding-top: 20px;
  }
}
</style>
