<template>
  <div class="page-content ViewDocuments medium">
    <section>
      <div class="main-title">Documents</div>
      <DocumentList :parent="project" :project="project" />
    </section>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';
import DocumentList from './DocumentList';

export default {
  menu: 'documents',
  props: ['project'],
  route: '/:projectId/documents',

  getData(props) {
    return {
      project: Model.Project.find(props.projectId),
    };
  },
  created() {
    App.vm.currentProject = this.project;
  },
  computed: {},

  methods: {},

  components: {
    DocumentList,
  },
};
</script>
<style lang="scss">
.ViewDocuments {
  .main-title {
    margin-bottom: 20px;
  }
}
</style>
