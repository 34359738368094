<template>
  <div class="confirmmodal confirm-property-modal">
    <div class="page-content with-footer">
      <h2>Set Parent Item to {{ status.name }}?</h2>

      <div class="text">All sub items of {{ parent.title }} are set to {{ status.name }}</div>
      <div class="item-list hide-last-row-border box">
        <SimpleIssueRow :wrapper="wrapper" />
      </div>
    </div>
    <footer>
      <a href="#" id="cancel" @click="cancel">No</a>
      <a href="#" id="confirm" @click="confirm">Set Item to {{ status.name }} </a>
    </footer>
  </div>
</template>
<script>
import createTopWrapper from '@/models/issuewrapper/createTopWrapper';
export default {
  props: ['status', 'parent', 'resolve'],
  data: function() {
    return {
      wrapper: createTopWrapper(this.parent, { includeArchived: true }),
    };
  },
  methods: {
    confirm: function(ev) {
      ev.stop();
      this.resolve(true);
      this.close();
    },
    cancel: function(ev) {
      ev.stop();
      this.close();
    },
    close: function() {
      // TODO make sure this is called when component is removed
      this.resolve(false);
      this.$emit('close');
    },
  },
};
</script>
