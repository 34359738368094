import { Model } from '@/namespace';
import { filterIssue } from './filterIssueWrapper';
import createWrapperContext from './wrapperContext';
import { breakOutExploded } from './explodes';
export default function createTopWrapper(issue, wrapperContext = {}, opt = {}) {
  wrapperContext = createWrapperContext(wrapperContext);

  let topIssue = issue;

  if (wrapperContext.statusBoard) {
    topIssue = Model.IssueWrapper.createNew({
      issue,
      issues: breakOutExploded(issue.issues, wrapperContext, 0),
      wrapperContext,
      level: 0,
    });
  }

  let wrapper = filterIssue(topIssue, wrapperContext, 0);
  if (!wrapper) {
    if (opt.permanent || opt.force) {
      wrapper = Model.IssueWrapper.createNew({
        issue,
        wrapperContext,
        level: 0,
      });
    } else {
      return false;
    }
  }

  if (!wrapper.isWrapper()) {
    wrapper = Model.IssueWrapper.createNew({
      issue,
      issues: issue.issues,
      wrapperContext,
      level: 0,
    });
  }
  if (opt.permanent) {
    wrapper.permanent = true;
  }
  wrapper.postInit();
  return wrapper;
}
