import { render } from "./ViewIssue.vue?vue&type=template&id=13223e04"
import script from "./ViewIssue.vue?vue&type=script&lang=js"
export * from "./ViewIssue.vue?vue&type=script&lang=js"

import "./ViewIssue.scss?vue&type=style&index=0&lang=scss"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
