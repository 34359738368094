import StoreModel from './base/StoreModel';
import _ from 'lodash';

import { Model, Util } from '@/namespace';

class Notification extends StoreModel {
  static NAME = 'Notification';
  static define = {
    time: {
      type: 'date',
    },
  };

  get text() {
    if (this.data == null) {
      return 'unknown text';
    }
    var issue, member;

    if (this.data.issueId) {
      issue = Model.Issue.store.get(this.data.issueId);
    }
    if (this.data.userId) {
      member = Model.Member.getByUserAndProjectId(this.data.userId, this.data.projectId);
    }

    var issueId = issue ? issue.key : this.data.issueId;
    let issueTitle, prefix;
    if (this.data.type == 'issuemention') {
      issueTitle = issue ? issue.title : 'an item';
      prefix = member ? member.displayName + ' mentioned you in' : 'You were mentioned in';
      return (
        prefix +
        ' <a href="/' +
        this.data.projectId +
        '/item/' +
        issueId +
        '" class="issue-link">' +
        issueTitle +
        '</a>'
      );
    }
    if (this.data.type == 'issueassign') {
      issueTitle = issue ? issue.title : 'an item';
      prefix = member ? member.displayName + ' assigned you to' : 'You were assigned to';
      return (
        prefix +
        ' <a href="/' +
        this.data.projectId +
        '/item/' +
        issueId +
        '" class="issue-link">' +
        issueTitle +
        '</a>'
      );
    }
    return 'notification ' + this.data.type;
  }
}

_.assign(Notification, {
  loadAll() {
    return Util.jsonajax({
      url: '/notification/findall',

      method: 'GET',
    });
  },
  comparator(a, b) {
    if (a === b) {
      return 0;
    }
    return a.time < b.time ? 1 : -1;
  },
});
Model.Notification = Notification;

export default Notification;
