import BaseModel from './base/BaseModel';
import _ from 'lodash';

import { Model, Util } from '@/namespace';

class Credentials extends BaseModel {
  serialize() {
    return {
      ...this,
    };
  }

  login() {
    const data = this.serialize();

    return Util.jsonajax({
      url: '/auth/login',
      data: data,
      method: 'POST',
    }).then(function (profile) {
      Model.Profile.setProfile(profile);
    });
  }

  register(recaptcha) {
    var data = this.serialize();

    return Util.jsonajax({
      url: '/auth/register',
      data: data,
      method: 'POST',
      headers: { recaptcha },
    }).then(function (profile) {
      Model.Profile.setProfile(profile);
      return profile;
    });
  }
  resetPassword() {
    var data = this.serialize();

    return Util.jsonajax({
      url: '/auth/resetpassword',
      data: data,
      method: 'POST',
    });
  }
  changePassword() {
    var data = this.serialize();

    return Util.jsonajax({
      url: '/auth/changepassword',
      data: data,
      method: 'POST',
    });
  }
}
_.assign(Credentials, {
  getInvite(data) {
    return Util.jsonajax({
      url: '/auth/getinvite',
      data: data,
      method: 'POST',
    }).then(function (response) {
      console.log(response);
      return response;
    });
  },

  requestResetPassword(data) {
    return Util.jsonajax({
      url: '/auth/requestresetpassword',
      data: data,
      method: 'POST',
    });
  },
});
Model.Credentials = Credentials;

export default Credentials;
