<template>
  <div id="popover-handler">
    <div class="popover-overlay" :class="{ visible: page != null }" @click="close"></div>

    <div class="popover" :style="positions.css" :class="positions.cssClass" v-if="page">
      <component :is="page.component" v-bind="page.data" v-on:close="close"></component>
      <i class="icon- caret" v-if="positions.caretCss" :style="positions.caretCss"></i>
    </div>
  </div>
</template>
<script>
import { Util, App } from '@/namespace';

import { nextTick } from '@/utils';
import _ from 'lodash';
import findPosition from './PopoverHandler-findposition';

export default {
  data: function () {
    return {
      page: null,
      positions: {
        css: null,
      },
    };
  },
  created() {
    var self = this;
    App.openPopover = function (Page, pageOptions, popoverOptions) {
      self.openPopover(Page, pageOptions, popoverOptions);
    };

    App.closePopover = function () {
      self.close();
    };
  },

  mounted: function () {
    // It is not possible to set max-height and have overflow: auto, so height must be set. If the child changes height, this must be taken care of. (#1631)
    this.$el.addEventListener('height-change', ev => {
      const css = this.positions.css;
      if (css.height) {
        const height = Math.min(css.maxHeight, ev.target.height());
        css.height = height + 'px';
      }
    });
  },

  methods: {
    close: function () {
      var popover = this.$el.querySelector('.popover');

      popover && popover.removeClass('in');

      this.page = null;
    },
    openPopover: function (Page, pageOptions, popoverOptions = {}) {
      const resolveData = Page.getData ? Util.resolveMap(Page.getData(pageOptions)) : Promise.resolve({});

      return resolveData.then(data => {
        this.page = {
          component: Page,
          data: {
            ...pageOptions,
            ...data,
          },
        };
        const { target } = popoverOptions;

        this.positions = {
          css: {},
        };

        const targetPosition = target ? target.getBoundingClientRect() : this.currentTargetPosition;

        nextTick(() => {
          var popover = this.$el.querySelector('.popover');
          this.positions = findPosition(popover, popoverOptions, targetPosition);
          this.currentTargetPosition = targetPosition;

          setTimeout(function () {
            popover.addClass('in');
          }, 0);
        });
      });
    },
  },
};
</script>
