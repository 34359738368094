import BadgeMember from './BadgeMember.vue';
import BadgeTag from './BadgeTag.vue';
import BadgeFilterRule from './BadgeFilterRule.vue';
import BadgeStatus from './BadgeStatus.vue';
import BadgeIssueType from './BadgeIssueType.vue';
export function initBadges(app) {
  app.component('BadgeMember', BadgeMember);
  app.component('BadgeTag', BadgeTag);
  app.component('BadgeFilterRule', BadgeFilterRule);
  app.component('BadgeStatus', BadgeStatus);
  app.component('BadgeIssueType', BadgeIssueType);
}
