import StoreModel from '@/models/base/StoreModel';
import _ from 'lodash';

class Address extends StoreModel {
  static define = {
    line1: null,
    line2: null,
    postal_code: null,
    city: null,
    state: null,
    country: null,
  };

  static make(data) {
    var result = new Address(data);

    return result;
  }

  serializedNotNull() {
    return pickNotNull(this.serialize());
  }

  validate(field, val) {
    const validate = this.constructor.validation[field];
    return validate && validate.call(this, val);
  }

  static validation = {
    line1(val) {
      return required(val, 'The Billing Address');
    },
    city(val) {
      return required(val, 'The City');
    },
    zip(val) {
      return required(val, 'The ZIP');
    },
    country(val) {
      return required(val, 'The Country');
    },
  };
}

function pickNotNull(obj) {
  return _.pickBy(obj, val => {
    return val != null;
  });
}

export default Address;

function required(val, fieldName) {
  if (_.isEmpty(val)) {
    return `${fieldName} must be entered`;
  }
}
