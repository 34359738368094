export default function(mock) {
  let newId = 10000;

  mock.onPut(/\/api\/issue\/\w+/).reply(200, {});
  mock.onDelete(/\/api\/issue\/\w+/).reply(200, {});
  mock.onPost('/api/issue').reply(function() {
    var id = ++newId;
    return [
      200,
      {
        id: 'i' + id,
        key: id,
      },
    ];
  });
  mock.onGet(/\/api\/issue\/fill\/\w+/).reply(function() {
    return [200, {}];
  });
}
