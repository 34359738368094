<template>
  <div class="page-content ViewSprint wide">
    <section class="mw-wide top-section">
      <a class="title selectSprint-v2" @click="selectSprint">
        <span>{{ sprint.title }}</span>
        <i class="icon-arrow-down4"></i>
      </a>
      <q-btn
        id="finish-sprint"
        @click="openFinishSprint"
        class="mobile-hidden"
        color="primary"
        v-if="showFinishSprint"
        rounded
        unelevated
        >Finish sprint</q-btn
      >
      <div
        class="sprint-estimate mobile-hidden"
        v-if="!sprint.backlog && sprintWrapper.estimateMap.est"
      >
        <div class="sub-items-estimate">
          <div>Total Estimate</div>
          <div class="total">
            {{ sprintWrapper.estimateMap.done }}
            <span class="slash">/</span>
            {{ sprintWrapper.estimateMap.est }}
          </div>
        </div>
        <estimate-bar :estimateMap="sprintWrapper.estimateMap"></estimate-bar>
      </div>
      <item-collection-settings
        :collectionSettings="collectionSettings"
        :onBoard="true"
      ></item-collection-settings>
    </section>

    <section class="item-collection full-width" :active-view="collectionSettings.activeView">
      <Board
        :collectionSettings="collectionSettings"
        v-if="collectionSettings.activeView === 'board'"
        ref="board"
      ></Board>
      <IssueSections
        :collectionSettings="collectionSettings"
        v-if="collectionSettings.activeView === 'list'"
        ref="issueSections"
      ></IssueSections>
    </section>
    <teleport to="#navbar" v-if="isPageActive">
      <q-btn @click="createIssue" rounded>
        <i class="icon-plus3"></i>
        <span>New Item</span>
      </q-btn>

      <q-btn v-if="!sprint.backlog" @click="addIssues" rounded>
        <span>Add Items</span>
      </q-btn>

      <q-btn class="menu-more" @click="moremenu" rounded>
        <span>More</span>
        <i class="icon-more2"></i>
      </q-btn>
    </teleport>
  </div>
</template>

<script>
import { App, Model } from '@/namespace';

import IssueSections from '@/components/issue-collection/IssueList/IssueSections.vue';
import Board from '@/components/issue-collection/Board/Board.vue';

import createTopWrapper from '@/models/issuewrapper/createTopWrapper';
import ViewFinishSprint from '@/pages/viewfinishsprint/ViewFinishSprint.vue';
import SelectSprintMenu from '@/popovers/SelectSprintMenu';

import MoreMenu from './ViewSprintMoreMenu.vue';
import SelectSprintIssues from '@/pages/selectsprintissues/SelectSprintIssues.vue';
import { eventMixin, pageMixin } from '@/common';
import { CreateIssue } from '@/pages/modals';
export default {
  route: '/:projectId/sprint/:sprintId',
  helpurl: '/help/workboard',
  menu: 'sprints',
  mixins: [eventMixin, pageMixin],
  props: ['sprint', 'project'],

  data: function () {
    return {
      collectionSettings: {
        activeView: 'board',
        filterQuery: null,
        sprint: this.sprint,
        issue: this.project.issue,
        views: ['list', 'board'],
      },
      sprintWrapper: createTopWrapper(
        this.project.issue,
        {
          sprint: this.sprint,
          includeArchived: true,
        },
        { permanent: true }
      ).bindChangeEvent(this),
    };
  },

  computed: {
    showFinishSprint() {
      const { sprintWrapper, sprint } = this;
      const statusMap = sprintWrapper.getPropertyMap('status');
      return statusMap.isDone() && !sprint.finished && !sprint.backlog;
    },
  },

  methods: {
    save() {
      this.sprint.save();
    },
    openFinishSprint() {
      App.openPopup(ViewFinishSprint, { sprint: this.sprint });
    },
    selectSprint(ev) {
      App.openPopover(
        SelectSprintMenu,
        {
          onSelect: sprint => {
            console.log(sprint);
            console.log('Should navigate to another sprint');
            App.router.navigate(sprint.url);
          },
          activeSprint: this.sprint,
        },
        {
          type: 'menu',
          target: ev.currentTarget,
        }
      );
    },
    moremenu(ev) {
      App.openPopover(
        MoreMenu,
        {
          sprint: this.sprint,
        },
        {
          type: 'menu',
          alignToTop: this.$el.closest('.navbar'),
          target: ev.currentTarget,
        }
      );
    },
    createIssue() {
      const attributes = {
        parent: App.vm.currentProject.issue,
        sprintId: this.sprint,
      };

      App.openPopup(CreateIssue, {
        attributes,
        title: 'Create Item',
      });
    },
    addIssues() {
      App.openPopup(SelectSprintIssues, { sprint: this.sprint });
    },
  },
  getData(props) {
    let resolveProject = Model.Project.find(props.projectId);
    return {
      sprint: resolveProject.then(project => {
        App.vm.currentProject = project;
        return Model.Sprint.get(props.sprintId);
      }),
      project: resolveProject,
    };
  },
  components: {
    Board,
    IssueSections,
  },
};
</script>
<style lang="scss">
.ViewSprint {
  .top-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    align-items: flex-end;

    > * + * {
      margin-left: 20px;
    }
  }

  .selectSprint-v2 {
    margin-right: auto;
    font-size: 28px;
    font-weight: 300;

    padding: 0 30px 5px 0;
    position: relative;
    line-height: 1;
    i {
      position: absolute;
      right: 5px;
      top: 7px;
      font-size: 13px;
      color: $gray-light;
    }
  }

  .sprint-estimate {
    width: 300px;
    .sub-items-estimate {
      display: flex;
      justify-content: space-between;
      padding: 0 5px;
    }
    .estimate-bar {
      margin-top: 3px;
    }
  }

  .IssueSection > .h2 {
    display: none;
  }
}

#app.touch .ViewSprint {
  .BoardColumn {
    padding-bottom: 120px;
  }
  section[active-view='list'] {
    padding-bottom: 160px;
  }
}
</style>
