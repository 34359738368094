function getScrollSpeed(topOffset) {
  if (topOffset < 25) {
    return { timeout: 5, factor: 5 };
  }
  if (topOffset < 50) {
    return { timeout: 10, factor: 5 };
  }
  if (topOffset < 75) {
    return { timeout: 15, factor: 3 };
  }
  if (topOffset < 100) {
    return { timeout: 20, factor: 2 };
  }
}

export default function initScrollHandler(page, onScroll) {
  var scrollContainer = page,
    scrollTimeout,
    scrollSpeed = {
      timeout: 20,
      factor: 1,
    },
    scrollDirection,
    TOP_OFFSET = 60,
    BOTTOM_OFFSET = document.querySelector('body').height();

  function stopScroll() {
    clearTimeout(scrollTimeout);
    scrollTimeout = null;
  }

  function doScroll() {
    var lastScrollTop = scrollContainer.scrollTop;

    scrollContainer.scrollTop = scrollContainer.scrollTop + scrollDirection * scrollSpeed.factor;
    if (lastScrollTop === scrollContainer.scrollTop) {
      return;
    }

    onScroll();
    scrollTimeout = setTimeout(doScroll, scrollSpeed.timeout);
  }

  return {
    isOnScroll: function() {
      return scrollTimeout != null;
    },
    onMove: function(ev, dragUpwards) {
      var fromTop = ev.pageY - TOP_OFFSET,
        fromBottom = BOTTOM_OFFSET - ev.pageY;

      if (fromTop < 100) {
        scrollSpeed = getScrollSpeed(fromTop);
        scrollDirection = -1;
        if (scrollTimeout == null && dragUpwards) {
          doScroll();
        }
      } else if (fromBottom < 100) {
        scrollSpeed = getScrollSpeed(fromBottom);
        scrollDirection = 1;
        if (scrollTimeout == null && !dragUpwards) {
          doScroll();
        }
      } else {
        if (scrollTimeout != null) {
          stopScroll();
        }
      }
    },
    onEnd: function() {
      stopScroll();
    },
  };
}
