<template>
  <div class="MentionSearch">
    <div class="suggestion-list">
      <div
        v-for="(item, index) in items"
        :key="item.id"
        class="suggestion-list__item"
        :class="{ 'is-selected': index === selectedIndex }"
        @click="selectItem(index)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    };
  },

  watch: {
    items() {
      this.selectedIndex = 0;
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        this.downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length;
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },

    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index) {
      const item = this.items[index];

      if (item) {
        this.command({ id: item.id, label: item.name });
      }
    },
  },
};
</script>

<style lang="scss">
.MentionSearch {
  position: relative;
  border-radius: 0.25rem;
  background: white;
  color: rgba(black, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);

  .suggestion-list {
    font-weight: 400;
    &__no-results {
      padding: 0.2rem 0.5rem;
    }
    &__item {
      padding: 0.2rem 0.5rem;
      margin-bottom: 0.2rem;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &.is-selected,
      &:hover {
        background-color: #e8e8e8;
      }
      &.is-empty {
        opacity: 0.5;
      }
    }
  }
}
</style>
