import StoreModel from './base/StoreModel';
import _ from 'lodash';

import { Model, App } from '@/namespace';

import { createSprint } from './sprintHandler';

class Sprint extends StoreModel {
  static NAME = 'Sprint';
  static define = {
    title: null,
    numeral: {
      serialize: false,
    },
    createdAt: {
      serialize: false,
      type: 'date',
    },
    finished: {
      type: 'date',
    },
    projectId: null,
    priority: null,
  };
  postInitModel() {
    var sprints = Model.Project.get(this.projectId).sprints;
    sprints.add(this);
    sprints.sort(Sprint.comparator);
  }
  postDestroy() {
    Model.Project.get(this.projectId).sprints.remove(this);
  }

  setPriority(priority) {
    this.priority = priority;
  }

  get url() {
    const projectId = this.projectId || App.vm.currentProject.id;
    return '/' + projectId + '/sprint/' + this.id;
  }
  get displayName() {
    return this.title;
  }

  get text() {
    return this.title;
  }

  get unfinished() {
    return !this.finished && !this.backlog;
  }

  static comparator(a, b) {
    var result;
    //console.log('compare sprints');

    if (a === b) {
      return 0;
    }
    if (a.finished) {
      result = b.finished ? a.priority > b.priority : true;
    } else {
      result = b.finished ? false : a.priority > b.priority;
    }
    return result ? 1 : -1;
  }
}

_.assign(Sprint, {
  createNew(project, attributes) {
    return createSprint(project, attributes);
  },
});

Model.Sprint = Sprint;
export default Sprint;
