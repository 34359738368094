<template>
  <div class="status-link" :key="status.id">
    <div class="status-icon" :status-id="status.id">
      <i class="icon-"></i>
    </div>
    <div class="name">
      {{ status.name }}
    </div>
  </div>
</template>

<script>
import { Model, App } from '@/namespace';
export default {
  props: ['id'],

  computed: {
    status() {
      return Model.Status.getByKey(App.vm.currentProject.id, this.id);
    },
  },
};
</script>
