import { App } from '@/namespace';
import SprintIssueSections from '@/models/issuewrapper/sprintissuesections';
import IssueSection from './IssueSection.vue';
import eventMixin from '@/common/eventMixin';
export default {
  props: ['collectionSettings'],
  data: function() {
    return { issueSections: createIssueSections(this) };
  },

  mixins: [eventMixin],
  mounted: function() {
    App.timer.log('mounted');
  },
  computed: {},
  watch: {
    'collectionSettings.filterQuery': function() {
      this.issueSections = createIssueSections(this);
    },
    'collectionSettings.includeArchived': function() {
      this.issueSections = createIssueSections(this);
    },
  },
  methods: {
    mousedown: function(ev) {
      // let dragitem = ev.target.closest('.dragitem');
      // if (dragitem) {
      //   return initDragSessionOnClick(dragitem, ev);
      // }
    },
  },
  components: {
    IssueSection,
  },
};

function createIssueSections(vm) {
  var collectionSettings = vm.collectionSettings;
  const result = SprintIssueSections.createNew(collectionSettings.issue, vm, {
    includeArchived: collectionSettings.includeArchived,
    tag: collectionSettings.tag,
    member: collectionSettings.member,
    status: collectionSettings.status,
    sprint: collectionSettings.sprint,
    filterQuery: collectionSettings.filterQuery,
    statusStandardRule: collectionSettings.statusStandardRule,
  });
  App.timer.log('createIssueSection');
  return result;
}
