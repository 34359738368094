import { filterIssue } from './filterIssueWrapper';
import { Model } from '@/namespace';
import _ from 'lodash';
import { Node } from '@/models/issue/changeEventHandler';
import { shouldExplode } from './explodes';

export default function onIssueChange(topWrapper, changeTree) {
  const wrapperContext = topWrapper.wrapperContext;

  const topNodes = breakOut(changeTree.nodes, wrapperContext);

  applyNodesChange(topWrapper, topNodes);

  function applyNodesChange(wrapper, nodes) {
    const nodesMap = asIdNodes(nodes);
    const removed = [];
    let sort = false;
    function remove(w) {
      w && removed.push(w);
    }
    if (!wrapper.isWrapper()) {
      return;
    }
    wrapper.issues.forEach(child => {
      const node = pop(nodesMap, child.id);
      if (!node) {
        return remove(testToRemoveExploded(child));
      }
      if (child.asIssue().deleted) {
        return remove(child);
      }

      const filtered = filterAndValidateNode(node, wrapper, wrapper.level + 1);
      if (!filtered) {
        return remove(child);
      }

      applyNodesChange(child, node.nodes);

      if (child.isWrapper()) {
        if (filtered.partial !== child.partial) {
          child.setPartial(filtered.partial);
          child.calculateProperties();
        }

        const newPriority = child.getPriority();
        if (newPriority !== child.priority) {
          child.priority = newPriority;
          sort = true;
        }
      }
    });
    removed.forEach(is => {
      wrapper.remove(is);
    });

    _.each(nodesMap, node => {
      const filtered = filterAndValidateNode(node, wrapper, wrapper.level + 1);
      addIssue(wrapper, filtered);
      sort = true;
    });

    if (wrapper.transformedToWrappers && sort) {
      wrapper.sort();
    }

    wrapper.calculateProperties();
  }

  function testToRemoveExploded(wrapper) {
    if (wrapper.exploded && !shouldExplode(wrapper.asIssue().parent, wrapperContext)) {
      return wrapper;
    }
  }

  function breakOut(nodes, wrapperContext) {
    const newNodes = [];
    _.each(nodes, node => {
      explodeNode(node);

      newNodes.push(node);
    });

    function explodeNode(node) {
      const issue = node.issue;
      if (shouldExplode(issue, wrapperContext)) {
        node.nodes.forEach(node => {
          newNodes.push(node);
          explodeNode(node);
        });

        issue.issues.forEach(is => {
          if (!containsNode(is.id)) {
            const newNode = new Node(is);
            newNodes.push(newNode);
            explodeNode(newNode);
          }
        });
      }
    }
    function containsNode(id) {
      return _.find(newNodes, n => n.id === id);
    }

    return newNodes;
  }

  function addIssue(wrapper, issue) {
    if (!issue) {
      return;
    }
    if (wrapper.transformedToWrappers) {
      if (issue instanceof Model.Issue) {
        issue = wrapper.createChildWrapper(issue);
      }
      issue.postInit();
    }

    wrapper.issues.push(issue);
  }

  function filterAndValidateNode(node, parentWrapper, level) {
    if (shouldExplode(node.issue, wrapperContext)) {
      return false;
    }
    const filtered = filterIssue(node.issue, wrapperContext, level);
    if (!filtered) {
      return false;
    }

    const parent = getClosestUnexplodedParent(filtered.asIssue());
    if (parent !== parentWrapper.asIssue()) {
      return false;
    }
    return filtered;
  }

  function getClosestUnexplodedParent(issue) {
    const parent = issue.parent;
    if (!parent) {
      return issue;
    }
    if (shouldExplode(parent, wrapperContext)) {
      return getClosestUnexplodedParent(parent.parent);
    }
    return parent;
  }

  function pop(nodesMap, id) {
    const node = nodesMap[id];
    delete nodesMap[id];
    return node;
  }

  function asIdNodes(nodes) {
    return _.reduce(
      nodes,
      (map, node) => {
        map[node.issue.id] = node;
        return map;
      },
      {}
    );
  }
}
