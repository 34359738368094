import PropertyMap from './PropertyMap';
import _ from 'lodash';
import { Model } from '@/namespace';

class MemberMap extends PropertyMap {
  toModel(id) {
    return Model.Member.store.get(id);
  }

  sort(array) {
    array.sort(function(a, b) {
      return Model.Sprint.comparator.call(null, a, b);
    });
  }

  calcValue() {
    if (this.length() === 1) {
      return this.toArray()[0];
    }
    return Model.Member.unassigned;
  }

  assigneeKey() {
    if (this.length() > 1) {
      return 'multiple';
    }

    var member = this.toArray()[0];
    if (member == null) {
      return 'unassigned';
    }
    return member.id;
  }
}

export default MemberMap;
