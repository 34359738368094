<template>
  <div class="page-content start">
    <AgilooLogo class="white" />
    <section class="top">
      <div class="inner">
        <h1>Agile Project Management. Made Simple.</h1>

        <img src="/static/images/start/start2.png" class="mobile-visible" />
        <img src="/static/images/start/workboard-fullpage.png" class="mobile-hidden" />
        <div class="info">
          With Agiloo you get the right tool to plan and manage your Scrum or Kanban project.
          Powerful enough to provide you with the help you need. Yet simple to use.
        </div>
      </div>
    </section>

    <section class="feature">
      <div class="inner">
        <div class="info">
          <h2>Work Boards</h2>
          <div class="text">
            Use the Kanban Board or represent each sprint with a Work Board. Large items can be
            spread out across different boards, giving you the possibility to split up large
            features across multiple sprints.
          </div>
        </div>
        <img src="/static/images/start/feature-board.png" />
      </div>
    </section>
    <section class="feature">
      <div class="inner">
        <div class="info">
          <h2>Divide Work Items</h2>
          <div class="text">
            A Work Item can be as large as en Epic or small as a minor task. Agiloo gives you the
            possibility to break down work items into smaller sub items, in any amount of levels
            necessary and without restrictions.
          </div>
        </div>

        <img src="/static/images/start/feature-divide2.png" />
      </div>
    </section>
    <section class="feature">
      <div class="inner">
        <div class="info">
          <h2>Fill with content</h2>
          <div class="text">
            Give your work items more meaning with descriptive texts and images. You can attach
            images with drag and drop and add comments to any work item.
          </div>
        </div>

        <img src="/static/images/start/feature-texts.png" />
      </div>
    </section>
    <section class="feature">
      <div class="inner">
        <div class="info">
          <h2>Flexible</h2>
          <div class="text">
            Start off with defining your large epics and features or begin working with whatever
            minor tasks you have upfront. You can always organize your smallest items into larger,
            and divide large features into fine grained tasks.
          </div>
        </div>

        <img src="/static/images/start/feature-flexible.png" />
      </div>
    </section>

    <section class="more-features">
      <div class="more-feature">
        <h3>Board and List View</h3>
        <div class="text">Work Items can be viewed both on Board View and List View</div>
      </div>

      <div class="more-feature">
        <h3>Tags</h3>
        <div class="text">Add tags to items and filter by text, tag or member</div>
      </div>
      <div class="more-feature">
        <h3>Instant Search</h3>
        <div class="text">Find any Work Item, Board or Member by instant search</div>
      </div>

      <div class="more-feature">
        <h3>Estimates</h3>
        <div class="text">
          Set estimates to items or sub items. Estimates are aggregated which gives you full view of
          the total estimate of a large feature.
        </div>
      </div>
      <div class="more-feature">
        <h3>Real time updates</h3>
        <div class="text">Updates are pushed to other project members in real time</div>
      </div>
      <div class="more-feature">
        <h3>Notifications</h3>
        <div class="text">
          Get notifications when you are assigned to an item or when you are mentioned in a text or
          comment
        </div>
      </div>
      <div class="more-feature">
        <h3>Customize Statuses</h3>
        <div class="text">
          By default, items can be set to status todo, in progress and done. Customize your own
          statuses when necessary
        </div>
      </div>

      <div class="more-feature">
        <h3>Archive</h3>
        <div class="text">
          By archiving finished Work Items, you can remove them from the current Work Board, but
          still access them for documentation
        </div>
      </div>

      <div class="more-feature">
        <h3>Comments</h3>
        <div class="text">You can add Comments to any Work Item</div>
      </div>
      <div class="more-feature">
        <h3>Github Integration</h3>
        <div class="text">Link your Work Items to Github commits or branches</div>
      </div>
      <div class="more-feature">
        <h3>PWA support</h3>
        <div class="text">Install Agiloo as PWA for a better mobile experience</div>
      </div>
      <div class="more-feature">
        <h3>Filters</h3>
        <div class="text">Filter your Work Items by title, tags or assigned members</div>
      </div>
      <!--
			<div class="more-feature">
			<h3>Mobile Support</h3>
			<div class="text">Add agiloo as PWA...</div>
		</div>
		<div class="more-feature">
			<h3>Finish a Sprint</h3>
			<div class="text">Add agiloo as PWA...</div>
		</div>
		<div class="more-feature">
			<h3>Filter</h3>
			<div class="text">Add agiloo as PWA...</div>
		</div>
      -->
    </section>
    <page-footer />
  </div>
</template>
<script>
import './start.scss';
export default {
  route: '/',
  noAuthentication: true,
};
</script>
