<template>
  <div class="ChangeIssueParent transparent">
    <CustomSearch
      :sourceOptions="sourceOptions"
      :placeholder="'Select Parent Item'"
      @select="select"
      @close="close"
    />
  </div>
</template>
<script>
import { App } from '@';
import CustomSearch from '@/components/mainsearch/CustomSearch.vue';
export default {
  props: ['issue', 'callback', 'noSave'],

  data: function() {
    const defaultSearchResult = [
      App.vm.currentProject.issue,
      ...App.vm.currentProject.issue.issues.filter(is => is !== this.issue),
    ];
    return {
      sourceOptions: {
        models: ['issue', 'projectissue'],
        defaultSearchResult,
        excludes: this.issue ? [this.issue] : null,
      },
    };
  },
  methods: {
    select(issue) {
      this.issue.set('parent', issue);
      if (!this.noSave) {
        this.issue.save();
      }

      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
  components: {
    CustomSearch,
  },
};
</script>
<style lang="scss">
.ChangeIssueParent {
  min-width: 400px;
  max-width: 600px;
  .search-result {
    background: white;
  }

  .CustomSearch input[type='text'] {
    border-width: 0 0 1px 0;
  }
}
</style>
