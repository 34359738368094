<template>
  <div class="page-content UserSettings medium">
    <section>
      <h1>User Profile</h1>
    </section>

    <section class="settings-split">
      <div class="left">
        <h2>User Details</h2>
      </div>
      <div class="right">
        <div class="names-container box white">
          <div class="">
            <div class="name">
              {{ profile.name }}
            </div>
            <div class="email">
              {{ profile.email }}
            </div>

            <div v-if="profile.premium">
              Premium Account
            </div>
          </div>

          <a class="button profile" id="update-details" @click="updateDetails">{{
            profile.initials
          }}</a>
        </div>

        <div class="buttons-container">
          <a href="#" id="change-password" @click="changePassword">Change Password</a>
          <a href="" id="update-details" @click="updateDetails">Update User Details</a>
        </div>
      </div>
    </section>

    <section class="settings-split">
      <div class="left">
        <h2>Delete User Account</h2>
        <div>This deletes the user account and all private projects</div>
      </div>
      <div class="right">
        <div class="">
          <a-btn color="red" @click="deleteUser">Delete User Account</a-btn>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';

import UpdateUserDetails from './UpdateUserDetails.vue';
import UpdatePassword from './UpdatePassword.vue';

export default {
  route: '/usersettings',
  props: ['profile'],
  getData() {
    return { profile: Model.Profile.find() };
  },
  methods: {
    changePassword(ev) {
      ev.stop();
      App.openPopup(UpdatePassword, {});
    },
    updateDetails(ev) {
      ev.stop();

      App.openPopup(UpdateUserDetails, {});
    },
    deleteUser() {
      App.openConfirmDialog({
        title: 'Delete User',
        text: 'This will delete the user, please enter password',
        inputType: 'password',
        onConfirm: function(password) {
          Model.Profile.deleteUser({ password: password })
            .then(function() {
              App.router.navigate('/login');
              App.displayInfo('User was deleted successfully');
            })
            .catch(App.handleError);
        },
      });
    },
  },
};
</script>
<style lang="scss">
.UserSettings {
  .names-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .name {
      font-size: 16px;
    }
  }

  .fullname {
    flex: 1;
  }

  .buttons-container {
    padding: 10px 5px 0 5px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
