<template>
  <a class="member-icon member" :member-id="item.id">
    <i class="icon-"></i>
  </a>
</template>
<script>
export default {
  props: ['item'],
};
</script>
