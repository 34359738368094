import StoreModel from '@/models/base/StoreModel';
import _ from 'lodash';

import Address from './Address';

class PaymentInfo extends StoreModel {
  static define = {
    name: null,
    vat: null,
    address: {
      serialize(address) {
        return address.serialize();
      },
      type: Address,
    },
  };

  serializedChanges() {
    var serialized = this.serialize();
    var backup = this.getBackup() || {};

    serialized = removeUnchanged(serialized, backup);
    if (serialized.address) {
      serialized.address = removeUnchanged(serialized.address, backup.address);
    }
    return serialized;
  }

  static make(data) {
    var result = new PaymentInfo(data);
    result.initModel();
    result.backup();
    return result;
  }
}

function removeUnchanged(serialized, backup) {
  _.forOwn(serialized, function(val, a) {
    if (_.isEqual(backup[a], serialized[a])) {
      delete serialized[a];
    }
  });
  return serialized;
}

export default PaymentInfo;
