<template>
  <div class="comment">
    <div class="actions" v-if="editable">
      <a @click="focusEditor">Edit</a>
      <a @click="deleteComment">Delete</a>
    </div>
    <div class="left">
      <div class="member-icon large" :member-id="comment.member.id">
        <i class="icon-"></i>
      </div>
    </div>
    <div class="right">
      <div class="comment-header">
        <div class="name">
          {{ comment.member.displayName }}
        </div>
        <div class="date">
          {{ formatTime(comment.created) }}
        </div>
        <div class="date updated-time" v-if="updatedText">{{ updatedText }}</div>
      </div>
      <TipTapEditor
        :showMenu="false"
        :showFooter="true"
        :autoSave="false"
        ref="editor"
        v-model="comment.text"
        :parentModel="comment.issue"
        placeholder="comment"
        :editable="textEditable"
        @save="editorSave"
        @cancel="editorCancel"
      />
    </div>
  </div>
</template>
<script>
import { nextTick } from '@/utils';
import { App } from '@/namespace';
import DateFns from '@/common/date-fns';

export default {
  props: ['comment'],
  data() {
    return { textEditable: false };
  },

  computed: {
    editable() {
      return (
        this.comment.member.userId === App.vm.profile.userId || App.currentProjectMember().isAdmin()
      );
    },
    updatedText() {
      const { comment } = this;
      if (comment.updated.getTime() === comment.created.getTime()) {
        return null;
      }
      const timeFormat = DateFns.isSameDay(comment.created, comment.updated)
        ? 'HH:mm'
        : 'do MMMM yyyy HH:mm';
      return 'updated on ' + DateFns.format(comment.updated, timeFormat);
    },
  },
  methods: {
    editorSave() {
      this.comment.save();
      this.textEditable = false;

      //this.$refs.editor.closeAndMakeUnEditable();
    },
    editorCancel() {
      this.comment.restore();
      this.textEditable = false;
    },
    deleteComment() {
      const self = this,
        comment = this.comment;
      App.openConfirmDialog({
        title: 'Delete Comment',
        text: 'This will delete the comment',
        onConfirm() {
          comment.destroy().then(() => {
            self.$emit('destroyed', comment);
          });
        },
      });
    },

    focusEditor() {
      this.textEditable = true;
      var editor = this.$refs.editor;
      nextTick(() => {
        editor.focus();
      });
    },
  },
};
</script>
