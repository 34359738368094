<template>
  <div class="ItemFiles" v-if="parent.files && parent.files.list.length">
    <h2 class="item-files-header">Attachments</h2>
    <div class="item-files-body">
      <div class="files-wrapper">
        <FileView v-for="file in parent.files.list" :file="file" @deleted="deleted" />
      </div>
    </div>
  </div>
</template>
<script>
import FileView from './FileView.vue';
export default {
  props: ['parent'],
  methods: {
    deleted(file) {
      this.parent.files.remove(file);
    },
  },
  components: {
    FileView,
  },
};
</script>
<style lang="scss">
.ItemFiles {
  .item-files-header {
    margin: 40px 10px 5px;
    font-size: 16px;
  }

  .item-files-body {
    border-top: 1px solid #ddd;
    padding-top: 10px;

    .files-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-right: -10px;
    }

    .file {
      margin: 0 10px 10px 0;
      box-shadow: $box-shadow;
      background-color: white;
      width: 175px;
      @media (max-width: 580px) {
        width: calc(50% - 10px);
      }
    }

    .cover {
      position: relative;
      height: 120px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;

      .thumbnail {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position: center;
      }

      .action-overlay {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        a {
          background-color: white;
          height: 24px;
          width: 24px;
          border-radius: 12px;
          line-height: 24px;
          text-align: center;
          margin: 5px 5px 0 0;

          i {
            font-size: 11px;
          }
        }
        visibility: hidden;
      }
    }

    .filename {
      font-size: 12px;
      text-align: center;
      padding: 5px 0;
    }

    .file-icon {
      text-align: center;
      font-size: 30px;
      color: #ccc;
    }

    .file:hover {
      .action-overlay {
        visibility: visible;
      }
    }
  }
}
</style>
