<template>
  <div class="DragContainer" :class="dragStatus">
    <slot />
  </div>
</template>
<script>
import { getChildren } from './containerUtils';
export default {
  props: ['modelList'],
  data() {
    return { dragStatus: {} };
  },

  methods: {
    setDragStatus(name, value) {
      this.dragStatus[name] = value;
    },
    getListElements() {
      return getChildren(this.$parent);
    },
    getModelList() {
      return this.modelList;
    },
    boxOffset() {
      return this.$el.boxOffset();
    },
  },
};
</script>
<style lang="scss">
.DragContainer {
  .dragTarget {
    opacity: 0.3;
  }
}
</style>
