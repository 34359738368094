import StoreModel from './base/StoreModel';
import _ from 'lodash';
import cssRule from '@/common/cssRule';
import { Model, Util } from '@/namespace';

class Status extends StoreModel {
  static NAME = 'Status';
  static PRIORITY_INPROGRESS = 100;
  static PRIORITY_DONE = 300;
  static PRIORITY_ARCHIVED = 1000000;
  static define = {
    color: {
      model: 'Color',
    },
    fixed: {
      serialize: false,
    },
    priority: null,
    projectId: null,
    name: null,
    active: null,
    iconText: null,
  };

  static update(model, data) {
    return Util.jsonajax({
      url: '/status',
      data: {
        ...data,
        id: model.id,
        key: model.key,
        projectId: model.projectId,
      },
      method: 'PUT',
    });
  }

  static destroy(model) {
    return Util.jsonajax({
      url: '/status',
      data: {
        id: model.id,
        key: model.key,
        projectId: model.projectId,
      },
      method: 'DELETE',
    });
  }

  static getByKey(projectId, key) {
    return this.store.get(this.toId(projectId, key));
  }
  static toId(projectId, key, id) {
    if (projectId == null) {
      console.error('projectId is null when getting status id');
    }
    if (key.length < 24) {
      return projectId + '_' + key;
    }
    return key;
  }

  postInitModel(onLoad = false) {
    const project = Model.Project.get(this.projectId);
    project.statuses.add(this);
    if (!onLoad) {
      project.statuses.sort(Model.Status.comparator);
    }

    initStyle(this);
  }

  postDestroy() {
    Model.Project.get(this.projectId).statuses.remove(this);
  }

  get displayName() {
    return this.name;
  }

  getKey() {
    return this.key;
  }

  isDone() {
    return this.priority >= 300;
  }

  lowerPriorityThan(status) {
    return this.priority < status.priority;
  }
  setPriority(priority) {
    this.set('priority', priority);
  }
}

function initStyle(status) {
  const color = status.color,
    statusId = status.id;
  let styles = {
    'background-color': '#' + color.code,
  };
  if (color.darkText) {
    styles = {
      ...styles,
      'box-shadow': 'none',
      border: '1px solid #e8e8e8',
      color: '#787878',
    };
  }
  cssRule(`.status-icon[status-id="${statusId}"]`, styles);

  if (status.icon) {
    const icon = '\\' + status.icon;
    cssRule(`.status-icon[status-id="${statusId}"] i:before`, {
      content: '"' + icon + '"',
    });
  } else {
    let textStyle = {
      content: '"' + status.iconText + '"',
    };
    if (status.darkText) {
      textStyle.color = '#606060';
    }
    cssRule(`.status-icon[status-id="${statusId}"]:before`, textStyle);
  }
}
Status.filter = {
  active(status) {
    return status.active && status.key !== 'archived';
  },
  unarchived(status) {
    return status.key !== 'archived';
  },
};
Status.comparator = function(a, b) {
  return Util.compare(a.priority, b.priority);
};

Model.Status = Status;
export default Status;
