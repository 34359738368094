import { reactive } from 'vue';
class Store {
  constructor() {
    this.map = reactive({});
    this.backup = {};
  }

  getBackup(id) {
    return this.backup[id];
  }

  setBackup(model) {
    this.backup[model.id] = model.serialize();
  }

  get(id) {
    return this.map[id];
  }

  remove(id) {
    delete this.map[id];
  }

  addAllReference(model) {
    var all = this.get('_all');
    if (all) {
      all.add(model);
    }
  }

  removeAllReference(model) {
    var all = this.get('_all');
    if (all) {
      all.remove(model);
    }
  }

  addReference(model) {
    this.map[model.id] = model;
    return this.map[model.id];
  }
  setReference(name, model) {
    this.map[name] = model;
  }
  clear() {
    this.map = reactive({});
    this.backup = {};
  }
}
export default Store;
