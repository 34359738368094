import { axios } from '@/common/jsonajax';
import MockAdapter from 'axios-mock-adapter';

import profile from './profile-fixture';
import project from './project-fixture';
import issue from './issue-fixture';
import comment from './comment-fixture';
import sprint from './sprint-fixture';
import tag from './tag-fixture';
import status from './status-fixture';
import member from './member-fixture';
import notification from './notification-fixture';

export default function(delayResponse) {
  var mock = new MockAdapter(axios, { delayResponse });
  profile(mock);
  project(mock);
  issue(mock);
  comment(mock);
  sprint(mock);
  tag(mock);
  status(mock);
  member(mock);
  notification(mock);
}
