<template>
  <div class="CreateIssuePopover">
    <text-input
      v-model="title"
      v-focus
      class="title text"
      placeholder="Item Title"
      @change="change"
    ></text-input>
  </div>
</template>

<script>
import { Model, App } from '@';
import _ from 'lodash';
export default {
  props: ['parentWrapper'],
  data() {
    return {
      title: null,
    };
  },
  methods: {
    change() {
      console.log('CHANGE');
      if (_.isEmpty(this.title)) {
        return;
      }
      var newIssue = Model.Issue.createNew({
        parent: this.parentWrapper.issue,
        title: this.title,
      });
      this.parentWrapper.applyChange(newIssue);

      newIssue.save();
      App.closePopover();
    },
  },
};
</script>
<style>
.CreateIssuePopover {
  padding: 10px 20px;
}
</style>
