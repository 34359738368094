import _ from 'lodash';
export class Node {
  constructor(issue, nodes = []) {
    this.issue = issue;
    this.nodes = nodes;
  }

  addNode(node) {
    node && this.nodes.push(node);
  }

  get id() {
    return this.issue.id;
  }

  getNode(issue) {
    return _.find(this.nodes, node => {
      return node.issue === issue;
    });
  }

  isEmpty() {
    return this.nodes.length == 0;
  }

  emitChange() {
    _.each(this.nodes, node => {
      node.emitChange();
    });
    this.issue.emit('issuechange', [this]);
  }
}

export function addParent(tree) {
  if (tree.issue.isProject()) {
    return tree;
  }
  var parentNode = new Node(tree.issue.parent, [tree]);
  return addParent(parentNode);
}

function buildNode(topIssue, changeMap) {
  return getTree(topIssue);

  function getTree(issue) {
    const tree = new Node(issue);

    _.each(issue.issues, is => {
      var childTree = getTree(is);
      tree.addNode(childTree);
    });
    if (tree.isEmpty() && issue !== topIssue && changeMap[issue.id] == null) {
      return false;
    }
    return tree;
  }
}

export function buildTree(topIssue, changeMap, opt = {}) {
  var node = buildNode(topIssue, changeMap, opt);
  if (opt.parent) {
    const parentNode = new Node(opt.parent);
    parentNode.addNode(node);
    node = parentNode;
  }
  return addParent(node);
}
