import BaseModel from '@/models/base/BaseModel';
import _ from 'lodash';
const rules = [
  {
    id: 'notdone',
    text: 'Items not Done',
    isValid(issue) {
      var progress = issue.getPropertyMap('status').getProgress();
      if (progress === 'todo') {
        return true;
      }
      if (progress === 'done') {
        return false;
      }
      return null;
    },
  },
  {
    id: 'archived-items',
    text: 'Archived Items',
    includeArchive: true,
    isValid(issue) {
      var map = issue.getPropertyMap('status');
      if (map.containsKey('archived')) {
        if (map.length() === 1) {
          return true;
        }
        return null;
      } else {
        return false;
      }
    },
  },
  {
    id: 'include-archived-items',
    text: 'Include Archived Items',
    includeArchive: true,
    isValid() {
      return true;
    },
  },
  {
    id: 'github-connected',
    text: 'With github data',
    isValid(issue, level) {
      if (level.current === 0) {
        return null;
      }
      if (issue.asIssue().additions.github) {
        return true;
      }
      if (issue.issues.length === 0) {
        return false;
      }
    },
  },
];

export default class FilterRule extends BaseModel {
  static NAME = 'FilterRule';
  static allRules = _.map(rules, rule => new FilterRule(rule));
}
