import _ from 'lodash';

class BaseModel {
  constructor(attributes, defaults = this.constructor.defaults) {
    if (defaults !== undefined) {
      _.assign(this, defaults);
    }
    _.assign(this, attributes);
  }

  setAttributes(attr) {
    _.each(attr, (val, key) => {
      this.set(key, val);
    });
    //  _.assign(this, attr);
  }

  get isModel() {
    return true;
  }

  set(name, value) {
    this[name] = value;
  }
  get(name) {
    return this[name];
  }
}

export default BaseModel;
