import _ from 'lodash';
import { Model } from '@/namespace';
import '@/models/issuewrapper/issuewrapper';
import filterRules from './filterRules';
import openPartialRules from './rules/openPartialRules';

function asPartialWrapper(issue, wrapperContext, level, filter, opt) {
  const validChildren = [];
  _.forEach(issue.issues, function (is) {
    is = filter(is, wrapperContext, {
      start: level.start,
      current: level.current + 1,
    });
    if (is) {
      validChildren.push(is);
    }
  });
  if (validChildren.length == 0 && !opt.archivedRule) {
    return false;
  }

  if (issue instanceof Model.Issue) {
    issue = Model.IssueWrapper.createNew({
      issue: issue,
      issues: validChildren,
      wrapperContext,
      level: level.current,
    });
  } else {
    issue.set('issues', validChildren);
  }
  if (!opt.archivedRule) {
    issue.setPartial(true);
  }

  return issue;
}

function ruleToFilter(rule, opt = {}) {
  const filter = function (issue, wrapperContext, level) {
    const valid = rule(issue, level);
    if (valid === true) {
      return issue;
    }
    if (valid === false) {
      return false;
    }

    const wrapper = asPartialWrapper(issue, wrapperContext, level, filter, opt);
    if (opt.openPartial && wrapper) {
      wrapper.open = true;
    }
    return wrapper;
  };
  return filter;
}

function getFilters(wrapperContext) {
  const ctx = wrapperContext,
    query = ctx.filterQuery;

  function getStandardRules() {
    const rules = [];

    ctx.sprint && rules.push(filterRules.withSprint(ctx.sprint));
    ctx.member && rules.push(filterRules.withMember(ctx.member));
    ctx.status && rules.push(filterRules.withStatus(ctx.status));
    ctx.statusStandardRule &&
      rules.push(filterRules.withStatusStandardRule(ctx.statusStandardRule));
    if (query) {
      query.members.length && rules.push(filterRules.withMembers(ctx.filterQuery.members));
      _.each(query.filterRules, filterRule => {
        rules.push(filterRule.isValid);
      });
      query.statuses.length && rules.push(filterRules.withStatuses(ctx.filterQuery.statuses));
    }

    if (ctx.rule) {
      rules.push(ctx.rule);
    }
    return rules;
  }

  function getOpenPartialRules() {
    const rules = [];
    ctx.tag && rules.push(openPartialRules.withTag(ctx.tag));

    if (ctx.filterQuery) {
      ctx.filterQuery.textRegex &&
        rules.push(openPartialRules.withTitle(ctx.filterQuery.textRegex));
      ctx.filterQuery.tags.length && rules.push(openPartialRules.withTags(ctx.filterQuery.tags));
      ctx.filterQuery.issueTypes.length &&
        rules.push(openPartialRules.withIssueTypes(ctx.filterQuery.issueTypes));
    }
    return rules;
  }

  const filters = [];
  _.each(getStandardRules(), rule => {
    filters.push(ruleToFilter(rule));
  });
  if (!wrapperContext.isIncludeArchived() && !ctx.status && !ctx.statusStandardRule) {
    filters.push(ruleToFilter(filterRules.withoutArchived(), { archivedRule: true }));
  }
  _.each(getOpenPartialRules(), rule => {
    filters.push(ruleToFilter(rule, { openPartial: true }));
  });

  return filters;
}

export function filterByRule(issue, wrapperContext, issueLevel, rule) {
  return doFilter(issue, wrapperContext, issueLevel, [ruleToFilter(rule)]);
}

export function filterIssue(issue, wrapperContext, issueLevel) {
  return doFilter(issue, wrapperContext, issueLevel, getFilters(wrapperContext));
}

function doFilter(issue, wrapperContext, issueLevel, filters) {
  //console.log('doFilter issue ' + issue.id + ' : level = ' + issueLevel);
  if (issue.deleted) {
    return false;
  }
  const levels = {
    start: issueLevel,
    current: issueLevel,
  };

  _.each(filters, filter => {
    issue = filter(issue, wrapperContext, levels);
    if (!issue) {
      return false;
    }
  });
  return issue;
}
