<template>
  <div class="IssueSection issue-wrapper DragContainer top" :class="cssClass">
    <header>
      <a :href="wrapper.wrapperContext.sprint.url" class="navigate-slide">
        {{ sprintTitle }}
      </a>
    </header>

    <div class="children">
      <template v-for="child in wrapper.children">
        <IssueRow
          v-if="child.issue"
          :wrapper="child"
          :key="child.id"
          :ref="child.id"
          @createSiblingIssue="createSiblingIssue"
        />
        <CreateIssueRow v-else :parentWrapper="wrapper" />
      </template>
      <footer>
        <div class="left"></div>
        <div class="center">
          <a-btn flat color="accent" @click="openCreateIssue">
            <i class="icon-plus3"></i>
            <span v-if="wrapper.issue.parent">New Sub Item</span>
            <span v-else>New Item</span>
          </a-btn>
        </div>

        <div class="right">
          <estimate-bar-inlist
            v-if="wrapper.estimateMap.est"
            :wrapper="wrapper"
          ></estimate-bar-inlist>
        </div>
      </footer>
    </div>
  </div>
</template>
<script src="./IssueSection.js" />
<style lang="scss">
.IssueSection {
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  background: #fff;

  header {
    border-bottom: 1px solid $gray-lighter;

    padding: 10px 10px;
    border-radius: 6px 6px 0 0;
    margin-left: 30px;
    a {
      //color: $orange-600;
      font-size: 1.125rem;
      line-height: 1.4em;
      font-weight: 300;
    }
    @media (max-width: 580px) {
      margin-left: 15px;
    }
  }

  footer {
    padding: 0;
    display: flex;

    a {
      .icon-plus3 {
        font-size: 12px;
        padding-right: 3px;
      }
    }

    .estimate-progress {
      margin: 5px 43px 0 auto;
    }

    .center {
      margin: 0 auto;
    }

    .left,
    .right {
      width: 100px;
    }
  }

  &.no-0 {
    &.filtered {
      display: none;
    }
    .children {
      background: none;
      border: none;
    }
    header {
      margin-left: 0;
      padding-left: 20px;
      //border-color: $orange-100;
    }
    box-shadow: none;
  }
}

.IssueSection + .IssueSection {
  margin-top: 40px;
}
</style>
