<template>
  <div class="page-content AddPremiumUsersModal">
    <section>
      <div class="list">
        <a
          class="list-row member "
          v-for="user in possibleUsers"
          @click.prevent="toggleSelected(user)"
        >
          <checkbox :selected="user.selected" />
          <user-icon :user="user"></user-icon>

          <div class="title">
            {{ user.name }}
          </div>

          <div class="fixed-width email mobile-hidden">
            {{ user.email }}
          </div>
        </a>

        <footer>
          <a-btn color="primary" @click="addUsers">
            Add Selected Users as Premium
          </a-btn>
        </footer>
      </div>
    </section>
  </div>
</template>
<script>
import { findPossibleUsers } from '@/models/billing/billingService';
export default {
  props: ['subscription', 'possibleUsers'],
  getData: function() {
    return { possibleUsers: findPossibleUsers() };
  },

  methods: {
    addUsers() {
      var users = this.possibleUsers.filter(user => user.selected).map(user => user.id);
      this.subscription.addUsers(users).then(() => {
        this.$emit('close');
      });
    },
    toggleSelected(user) {
      user.selected = !user.selected;
    },
  },
};
</script>
<style lang="scss">
.AddPremiumUsersModal {
  footer {
    padding: 5px 0;
  }
}
</style>
