import Mention from '@tiptap/extension-mention';
import { VueRenderer } from '@tiptap/vue-3';
import MentionList from './MentionList.vue';
import tippy from 'tippy.js';
import getMentionItems from './getMentionItems';

let mentionItems;

export default Mention.configure({
  HTMLAttributes: {
    class: 'mention',
  },

  suggestion: {
    items: query => {
      mentionItems = getMentionItems();
      return mentionItems
        .filter(item => item.name.toLowerCase().startsWith(query.toLowerCase()))
        .slice(0, 10);
    },
    render: () => {
      let component;
      let popup;

      return {
        onStart: props => {
          component = new VueRenderer(MentionList, {
            // using vue 2:
            // parent: this,
            // propsData: props,
            props,
            editor: props.editor,
          });

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          });
        },
        onUpdate(props) {
          component.updateProps(props);

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },
        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide();

            return true;
          }

          return component.ref?.onKeyDown(props);
        },
        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  },
});
