import DateMap from '@/common/model/DateMap';
import { getModelById, Activity, Project } from '@/models';
import { Util } from '@/namespace';

export async function fillActivityMap() {
  if (this.activityMap == null) {
    const activity = await loadActivity(this);

    this.activityMap = DateMap.createNew(activity);
  }
  return this.activityMap;
}

export function handleNewActivity(update) {
  const { activity, projectId, updated } = update;
  if (!activity || !projectId) {
    return;
  }
  const project = Project.get(projectId);
  if (!project) {
    return;
  }
  if (updated) {
    project.updated = new Date(updated);
  }
  activity.map(jsonActivity => {
    const model = getModelById(jsonActivity.id);
    if (!model) {
      return;
    }
    const act = new Activity(jsonActivity);
    model.activityMap && model.activityMap.add(act);
    project.activityMap && project.activityMap.add(act);
  });
}

async function loadActivity(model) {
  return Util.jsonajax({
    url: '/activity',
    data: {
      id: model.id,
    },
    method: 'POST',
  }).then(function(data) {
    return data.activity.map(json => new Activity(json));
  });
}
