import { App } from '@/namespace';

import StatusSelector from './StatusSelector.vue';
import MemberSelector from './MemberSelector.vue';
import SprintSelector from './SprintSelector.vue';
import AddTagSelector from './AddTagSelector.vue';

import ProjectSelector from './ProjectSelector.vue';
import IssueTypeSelector from './IssueTypeSelector.vue';

const selectors = {
  status: StatusSelector,
  member: MemberSelector,
  sprint: SprintSelector,
  project: ProjectSelector,
  issueType: IssueTypeSelector,
  AddTag: AddTagSelector,
};

App.openSelector = function({
  property,
  name,
  issue,
  targetModel,
  targetElement,
  noSave,
  type = 'selector',
}) {
  App.openPopover(
    selectors[property] || selectors[name],
    {
      targetModel: targetModel || issue,
      issue: issue,
      targetElement: targetElement,
      noSave,
    },
    {
      type,
      target: targetElement,
    }
  );
};
