<template>
  <div class="page-content viewmember wide">
    <section class="flex">
      <a class="member big" href="#">
        <div class="member-icon large" :member-id="member.id">
          <i class="icon-"></i>
        </div>
        <div class="name">{{ member.displayName }}</div>
      </a>
    </section>
    <section class="item-collection full-width" active-view="list">
      <div class="mw-wide item-collection-settings-section">
        <item-collection-settings
          :collectionSettings="collectionSettings"
        ></item-collection-settings>
      </div>
      <IssueSections :collectionSettings="collectionSettings" ref="issueSections"></IssueSections>
    </section>

    <section v-if="showArchivedLinks">
      <div class="archived-links">
        <a-btn :href="projectUrl('?archived=true')" color="primary" flat>View archived Items</a-btn>
        <a-btn :href="projectUrl('?archived=all')" color="primary">View all Items</a-btn>
      </div>
    </section>
    <teleport to="#navbar" v-if="isPageActive">
      <q-btn @click="createNew" rounded>
        <i class="icon-plus3"></i>
        <span>New Item</span>
      </q-btn>
    </teleport>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';
import { CreateIssue } from '@/pages/modals';
import { eventMixin, pageMixin } from '@/common';
import createTopWrapper from '@/models/issuewrapper/createTopWrapper';
export default {
  menu: 'items',
  props: ['project', 'archived', 'member'],
  route: '/:projectId/member/:memberId',
  mixins: [eventMixin, pageMixin],
  data: function () {
    var collectionSettings = {
      filterQuery: null,
      statusStandardRule: null,
      includeArchived: false,
      issue: this.project.issue,
      member: this.member,
    };
    if (this.archived === 'true') {
      collectionSettings.statusStandardRule = Model.Status.getByKey(this.project.id, 'archived');
    }
    if (this.archived === 'all') {
      collectionSettings.includeArchived = true;
    }
    return { collectionSettings };
  },
  getData(props) {
    let resolveProject = Model.Project.find(props.projectId);
    return {
      member: resolveProject.then(project => {
        App.vm.currentProject = project;
        return Model.Member.get(props.memberId);
      }),
      project: resolveProject,
    };
  },
  created() {
    App.vm.currentProject = this.project;
  },
  computed: {
    showArchivedLinks() {
      return this.totalWrapper.statusMap.containsKey('archived') && !this.archived;
    },
    totalWrapper() {
      return createTopWrapper(
        this.project.issue,
        {
          member: this.member,
          includeArchived: true,
        },
        { force: true }
      ).bindChangeEvent(this);
    },
  },

  methods: {
    save() {
      this.project.save();
    },

    projectUrl(appender) {
      var url = this.member.url + appender;
      return url;
    },
    createNew() {
      const attributes = {
        parent: this.project.issue,
        sprintId: 'backlog',
        memberId: this.member,
      };

      App.openPopup(CreateIssue, {
        attributes,
        title: 'Create Item',
      });
    },
  },
};
</script>
