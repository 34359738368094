<template>
  <div class="StatusIcon" :class="{ partial: wrapper.partial }" @click="openSelector">
    <div
      class="status-icon "
      v-tooltip="tooltip"
      :status-id="wrapper.statusMap.calculatedValue().id"
    >
      <i class="icon-"></i>
    </div>
  </div>
</template>
<script>
import { App } from '@/namespace';
export default {
  props: ['wrapper', 'preventClicks'],

  methods: {
    openSelector(ev) {
      if (this.preventClicks) {
        return;
      }

      ev.preventDefault();
      App.openSelector({
        property: 'status',
        issue: this.wrapper,
        targetElement: ev.target,
      });
    },
  },
  computed: {
    tooltip() {
      const { wrapper } = this;
      return {
        content: function() {
          var status = wrapper.statusMap.calcValue();
          if (wrapper.isPartial()) {
            var count = wrapper.issues.length;
            if (wrapper.wrapperContext.isOnlyPropertyInContext('sprint')) {
              return (
                'The item shown is partial, the item has ' +
                count +
                ' sub items in ' +
                wrapper.wrapperContext.sprint.title +
                '. The collected status is ' +
                status.name
              );
            }
            return (
              'The item shown is partial, ' +
              count +
              ' of its sub items are included. The collected status is ' +
              status.name
            );
          } else {
            return 'The item has status ' + status.name;
          }
        },
      };
    },
  },
};
</script>
<style lang="scss">
.StatusIcon {
  width: 35px;
  height: 44px;
  position: relative;
  overflow: hidden;
  align-self: start;
  .status-icon {
    position: absolute;
    right: 0;
    top: 10px;
  }
  &.partial {
    &:before {
      content: '\ec64';
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      right: 3px;
      color: #e0e0e0;
      font-size: 20px;
      top: 10px;
    }
    .status-icon {
      width: 14px;
      height: 14px;
      line-height: 16px;
      font-size: 9px;
      right: 0;
      top: 20px;

      i {
        line-height: 16px;
      }
    }
  }
}
</style>
