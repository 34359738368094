import FileUpload from './fileupload';

function toFileUploadsFromPaste(ev, fileParent) {
  console.log('toFileUploadsFromPaste');
  var result = [];
  var files = ev.clipboardData.files;
  if (files.length == 0) {
    return;
  }
  var file = files[0];
  if (file.type.match('^image/')) {
    var fileUpload = new FileUpload(file, fileParent);

    if (ev.clipboardData.items) {
      fileUpload.resolveFileName = resolveFileName(ev.clipboardData.items[0]);
    }
    result.push(fileUpload);
  }
  return result;
}

function resolveFileName(clipboardItem) {
  return new Promise(function(resolve) {
    if (
      clipboardItem &&
      clipboardItem.kind == 'string' &&
      clipboardItem.type.match('^text/plain')
    ) {
      clipboardItem.getAsString(function(s) {
        resolve(s);
      });
    } else {
      resolve();
    }
  });
}

export default toFileUploadsFromPaste;
