<template>
  <div class="BreadCrumbs">
    <a class="issue-type standard" :type="issueType" @click.prevent="openSelectIssueType"> </a>

    <IssueParentPath :issue="issue" />
    <div class="item-key">#{{ issue.key }}</div>
  </div>
</template>
<script>
import { App } from '@/namespace';

export default {
  props: ['issue'],

  computed: {
    issueType() {
      return this.issue.issueType || 'item';
    },
    issueParents() {
      var result = [];
      function addParent(is) {
        var parent = is.parent;
        if (parent != null && !parent.isProject()) {
          result.unshift(parent);
          addParent(parent);
        }
      }
      addParent(this.issue);
      return result;
    },
  },
  methods: {
    openSelectIssueType: function(ev) {
      App.openSelector({
        property: 'issueType',
        issue: this.issue,
        targetElement: ev.target,
      });
    },
  },
};
</script>
<style lang="scss">
.BreadCrumbs {
  display: flex;
  align-items: center;

  > a {
    margin-right: 7px;
  }
}
</style>
