import { render } from "./PaymentForm.vue?vue&type=template&id=0e8c5c8e"
import script from "./PaymentForm.vue?vue&type=script&lang=js"
export * from "./PaymentForm.vue?vue&type=script&lang=js"

import "./PaymentForm.vue?vue&type=style&index=0&id=0e8c5c8e&lang=scss"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QSelect});
