<template>
  <div class="filter-search">
    <div class="searchbody" :active="active">
      <div class="filters">
        <component
          v-for="item in filters"
          :is="'Badge' + item.constructor.NAME"
          :item="item"
        ></component>
      </div>

      <input
        type="text"
        class="input"
        placeholder="filter items"
        autocomplete="off"
        @focus="focus"
        @keydown="keydown"
        @input="input"
        @blur="blur"
        v-model="text"
      />
      <div class="search-result">
        <header v-if="queryEmpty">
          Filter items by title or member
        </header>

        <div
          v-for="item in searchResult"
          class="searchelement"
          :class="{ active: isActive(item) }"
          @click="selectItem(item)"
        >
          <component :is="'Result' + item.constructor.NAME" :item="item"></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import FilterQuery from '@/models/issuewrapper/filterquery';
import { findSearchResult, TextItem } from './searchutil';
import handleKeydown from './handleKeydown';

export default {
  data: function() {
    return {
      active: false,
      searchResult: null,
      activeItem: null,
      text: '',
      filters: [],
    };
  },
  computed: {
    queryEmpty: function() {
      return _.isEmpty(this.searchResult);
    },
  },
  methods: {
    close() {
      this.active = false;
    },
    focus() {
      this.active = true;
      this.$el.find('input').select();
    },
    blur() {
      setTimeout(() => {
        this.close();
      }, 100);
    },

    input() {
      this.active = true;

      var text = this.text;
      if (text.length >= 1) {
        var searchResult = findSearchResult(text, {
          models: ['member', 'tag', 'status', 'issueType', 'filterRule'],
        });
      } else {
        searchResult = [];
      }
      searchResult.pushFirst(
        new TextItem({
          html: 'Filter item titles by <b>' + text + '</b>',
        })
      );

      this.searchResult = searchResult;
    },
    selectItem(m) {
      if (m instanceof TextItem) {
        this.$el.find('input').blur();
      } else {
        this.filters.push(m);
        this.text = '';
      }
      this.searchResult = null;
      this.activeItem = null;
      this.triggerSearch();
    },
    triggerSearch: function() {
      var filterQuery = new FilterQuery();
      _.each(this.filters, item => {
        filterQuery.addModel(item);
      });
      if (!_.isEmpty(this.text)) {
        filterQuery.setText(this.text);
      }

      this.$emit('filterchange', filterQuery);
    },
    isActive(item) {
      return item === this.activeItem;
    },
    keydown: handleKeydown({
      selectFirstOnEnter: true,
      handleBackspace() {
        this.filters.removeLast();
        this.triggerSearch();
      },
    }),
  },
};
</script>
<style lang="scss">
.filter-search .searchbody {
  display: flex;
  align-items: center;

  border-radius: 17px;
  //border-bottom: 1px solid #e8e8e8;
  padding: 2px 10px;
  min-width: 300px;

  border-radius: 18px;
  border: 1px solid #e8e8e8;
  background-color: #fafafa;

  > i {
    position: absolute;
    right: 7px;
    top: 8px;
    color: rgba(255, 255, 255, 0.2);
    font-size: 13px;
  }

  input {
    height: 32px;
    flex: 1;
    border: none;
    &::-webkit-input-placeholder {
      font-style: italic;
      color: #aaa;
      font-weight: 300;
    }
  }

  &[empty='false'] {
    input::-webkit-input-placeholder {
      visibility: hidden;
    }
  }

  .filters {
    display: flex;

    > * {
      display: block;
      margin-left: 3px;
    }
  }

  .search-result {
    display: none;
    position: absolute;
    left: 0;
    top: 32px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 100%;

    z-index: 1;

    //max-width: 400px;
    header {
      font-size: 13px;
      text-align: center;
      padding: 5px;
    }
  }

  .issue-type {
    font-size: 14px;
    line-height: 25px;
  }

  &[active='true'] {
    .search-result {
      display: block;
    }
  }
}
</style>
