<template>
  <section>
    <h1>Get Started</h1>
    <p>
      Welcome to Agiloo! Whether you work with Scrum, Kanban or in an ad hoc project, Agiloo is a
      simple and intuitive tool that helps organize your project.
    </p>
    <p>
      Get started by creating a new Project and give it a name.
    </p>
    <img src="/static/images/help/newproject.png" />
    <p>
      When creating a Project you choose from one of 3 project types which controls how Boards are
      organized.
    </p>
    <ol>
      <li>
        With a plain project you will start with only a backlog, without any predefined boards.
      </li>
      <li>In a Kanban project, a Kanban board is set up.</li>
      <li>
        In a Scrum Project, each board is represented as a Sprint. While working with sprints, you
        have more features than with regular boards, e.g. finishing a sprint.
      </li>
    </ol>

    <p>
      After you have created a Project, add Work items and invite team members.
    </p>
  </section>
</template>
<script>
export default {};
</script>
