import BaseModel from '@/models/base/BaseModel';

import { Model } from '@/namespace';
import _ from 'lodash';
import calculateProperty from './calculateProperty';
import onIssueChange from './onIssueChange';
import saveProperty from '@/actions/saveProperty/saveProperty';
import { filterByRule } from '@/models/issuewrapper/filterIssueWrapper';
import { setNewPriority } from '@/models/priority/priorityCalculation';

import EstimateMap from '@/models/propertymap/EstimateMap';
import { shouldExplode } from './explodes';
import createWrapperContext from './wrapperContext';
import { reactive } from 'vue';
const calculateStatus = calculateProperty('status'),
  calculateMember = calculateProperty('member'),
  calculateSprint = calculateProperty('sprint');

class IssueWrapper extends BaseModel {
  static createNew(props) {
    return reactive(new IssueWrapper(props));
  }

  constructor(attrs) {
    super(attrs, {
      partial: false,
      dragStatus: {},
      open: null,
      exploded: false,
    });

    if (this.wrapperContext.wrapperDefaults) {
      _.assign(this, this.wrapperContext.wrapperDefaults);
    }

    this.issues = attrs.issues ? attrs.issues.slice(0) : [];
  }

  bindChangeEvent(controller) {
    controller.on(this.issue, 'issuechange', ([changeTree]) => {
      if (this.preventListenToChange) {
        return;
      }
      this.onIssueChange(changeTree);
    });
    return this;
  }
  removeListenToChange() {
    // would be better to actually remove the binding of change event
    this.preventListenToChange = true;
  }

  postInit() {
    if (this.isPostInited) {
      return;
    }
    this.isPostInited = true;

    if (this.issue.parent) {
      this.exploded = shouldExplode(this.issue.parent, this.wrapperContext);
    }
    if (this.open == null) {
      this.open = this.wrapperContext.isOpen(this.level);
    }

    if (this.open) {
      this.transformIssuesToWrappers();
    }

    this.calculateProperties();
    this.set('priority', this.getPriority());

    return this;
  }

  calculateProperties() {
    //this.calculatePartial();

    if (this.isPartial() && this.issues.length === 0) {
      this.sprintMap = null;
      this.statusMap = null;
      this.memberMap = null;
      return;
    }

    calculateStatus(this);
    calculateSprint(this);
    calculateMember(this);
    this.estimateMap = EstimateMap.make(this);
  }
  calculatePartial() {
    let partial = false;
    if (this.issues.length !== this.issue.issues.length) {
      partial = true;
    } else {
      _.each(this.issues, is => {
        if (is.partial) {
          partial = true;
          return false;
        }
      });
    }

    //console.log('calculatePartial on ' + this.id + ' ' + this._cid + ' : ' + partial)
    this.setPartial(partial);
  }

  isPartial() {
    return !!this.partial;
    //return this.issues.length !== this.issue.issues.length;
  }
  setPartial(partial) {
    this.set('partial', !!partial);
  }

  get id() {
    return this.issue.id;
  }
  get projectId() {
    return this.issue.projectId;
  }

  getPropertyMap(property) {
    const MAP = property + 'Map';
    if (this[MAP] == null) {
      calculateProperty(property)(this);
    }
    return this[MAP];
  }
  getEstimateMap() {
    if (this.estimateMap == null) {
      this.estimateMap = EstimateMap.make(this);
    }
    return this.estimateMap;
  }

  toggleShowSubIssues() {
    if (this.open) {
      this.open = false;
    } else {
      this.showSubIssues();
    }
  }

  showSubIssues() {
    this.open = true;
  }

  transformIssuesToWrappers() {
    _.each(this.issues, (is, index) => {
      if (is.isCreateIssue) {
        return;
      }
      if (is instanceof Model.IssueWrapper) {
        is.postInit();
      } else {
        const wrapper = this.createChildWrapper(is).postInit();
        this.issues.splice(index, 1, wrapper);
      }
    });
    this.sort();
    this.transformedToWrappers = true;
  }

  comparator(a, b) {
    return a.priority - b.priority;
  }
  sort() {
    this.issues.sort(this.comparator);
  }

  noIssuesDone() {
    let count = 0;
    const issues = this.get('issues');
    _.each(issues, function (is) {
      if (!is.isModel) {
        return;
      }
      if (is.get('statusMap').calcValue().isDone()) {
        count++;
      }
    });
    return count;
  }

  createChildWrapper(issue) {
    return Model.IssueWrapper.createNew({
      issue: issue,
      issues: issue.issues,
      wrapperContext: this.wrapperContext,
      level: this.level + 1,
    });
  }
  createEmptyChildWrapper(issue) {
    const result = Model.IssueWrapper.createNew({
      issue: issue,
      issues: [],
      wrapperContext: this.wrapperContext,
      level: this.level + 1,
    });
    result.calculateProperties();
    return result;
  }

  destroy() {
    if (this.isDestroyed) {
      console.warn(this._cid + ' is already supposed to be destroyed');
    }
    this.isDestroyed = true;

    console.log('destroy ' + this._cid);
    //this.attr('issues', null);
    this.dispatch('destroyed');
  }

  setWrapperContext(wrapperContext) {
    this.wrapperContext = wrapperContext;

    _.each(this.issues, function (sub) {
      if (sub.isWrapper()) {
        sub.setWrapperContext(wrapperContext);
      }
    });
  }

  debuginfo() {
    return 'IW ' + this._cid + ' ' + this.get('priority');
  }

  member() {
    return this.issue.get('member');
  }

  get children() {
    if (!this.transformedToWrappers) {
      this.transformIssuesToWrappers();
    }
    return this.issues;
  }

  get title() {
    return this.issue.get('title');
  }

  get issueType() {
    return this.issue.issueType;
  }

  hasSubIssues() {
    return this.issue.issues.length > 0;
  }

  softSave() {
    return this.issue.softSave();
  }
  setPriority(priority) {
    this.set('priority', priority);
  }
  savePriority(priority) {
    this.setPriority(priority);
    this.setNewPriorityOnIssue();
    return this.issue.save();
  }
  setNewPriorityOnIssue() {
    setNewPriority(
      this.issue,
      this.wrapperContext.sprint,
      this.wrapperContext.status,
      this.priority
    );
  }
  getPriority() {
    const priorityMap = this.issue.priorityMap;
    let priority;

    _.each(this.wrapperContext.getPriorityKeys(), priorityKey => {
      priority = priorityMap[priorityKey];
      if (priority != null) {
        return false;
      }
    });

    if (priority == null) {
      priority = 0;
    }
    return priority;
  }
  hasStatus(status) {
    let valid = false;
    this.issues.each(function (sub) {
      if (sub.status === status) {
        valid = true;
      }
    });
    return valid;
  }

  applyChange(issue) {
    const wrapperContext = this.wrapperContext;
    issue.setProperty(wrapperContext.propertyToSave, wrapperContext[wrapperContext.propertyToSave]);
    if (wrapperContext.tag) {
      issue.addTag(wrapperContext.tag);
    }
    if (wrapperContext.member) {
      issue.setProperty('member', wrapperContext.member);
    }
    if (wrapperContext.sprint) {
      issue.setProperty('sprint', wrapperContext.sprint);
    }
  }
  findById(id) {
    return this.issues.findById(id);
  }
  remove(issue) {
    const list = this.issues;
    if (issue == null) {
      return false;
    }

    const index = list.indexOfId(issue.id);
    if (index == -1) {
      return false;
    }
    list.splice(index, 1);
    return true;
  }
  add(item, opt) {
    opt = opt || {
      doSort: true,
    };

    const list = this.issues;
    if (item == null) {
      return false;
    }

    const existing = list.findById(item.id);
    if (existing != null) {
      return false;
    }

    if (item instanceof Model.Issue) {
      console.error('add issue to issuewrapper list, refactor this');
      if (this.transformedToWrappers) {
        item = this.createWrapper(item);
      }
    } else {
      if (item.wrapperContext !== this.wrapperContext) {
        item.setWrapperContext(this.wrapperContext);
      }
    }

    if (opt.direction != null) {
      // REFACTOR is used in dragContainer. Make special add function - addAndInitPriority instead of having the logic here
      if (list.length > 0) {
        let priority;

        if (opt.direction) {
          priority = list[list.length - 1].priority + 100;
        } else {
          priority = list[0].priority - 100;
        }

        item.setPriority(priority);
        opt.doSort = true;
      } else {
        item.setPriority(item.getPriority());
      }
    }
    list.push(item);
    if (opt.doSort) {
      this.sort();
    }
    return true;
  }

  addAll(items) {
    _.each(items, it => {
      this.add(it);
    });
  }

  parent() {
    if (this.newParent) {
      return this.newParent;
    }
    return this.issue.parent;
  }

  getParent() {
    return this.parent();
  }

  asIssue() {
    return this.issue;
  }
  isWrapper() {
    return true;
  }
  filterByRule(rule) {
    return filterByRule(this, this.wrapperContext, this.level, rule);
  }
  copy() {
    const copy = Model.IssueWrapper.createNew({
      issue: this.issue,
      wrapperContext: this.wrapperContext,
      level: this.level,
      partial: this.partial,
    });

    _.each(this.issues, is => {
      if (is instanceof Model.IssueWrapper) {
        is = is.copy();
      }
      copy.issues.push(is);
    });

    return copy;
  }

  saveProperty(property, value, opt) {
    return saveProperty(this, property, value, opt);
  }
  onIssueChange(changeTree) {
    return onIssueChange(this, changeTree);
  }

  addCreateIssue({ sibling, position } = {}) {
    if (sibling) {
      position = this.issues.findIndex(is => is === sibling);
    }
    if (position != null) {
      this.issues.splice(position, 0, { isCreateIssue: true });
      return;
    }

    this.issues.push({ isCreateIssue: true });
  }
  cleanupCreateIssue() {
    this.issues.forEach((is, index) => {
      if (is.isCreateIssue) {
        this.issues.splice(index, 1);
      }
    });
  }

  isExplodeApplicable() {
    const { sprint } = this.wrapperContext;
    return this.issues.length > 0 && this.level <= 1 && sprint != null && !sprint.backlog;
  }
  explodeChildren() {
    if (!this.isExplodeApplicable()) {
      return Promise.resolve();
    }
    const { issue, wrapperContext } = this;
    issue.setExplodeChildren(wrapperContext.sprint.id, true);

    return issue.save();
  }
  collectChildrenOnParent() {
    const { issue, wrapperContext } = this;
    const parent = issue.parent;

    parent.setExplodeChildren(wrapperContext.sprint.id, false);
    return parent.save();
  }

  setDragStatus(name, value) {
    this.dragStatus[name] = value;
  }
  clearDragStatus() {
    this.dragStatus = {};
  }
}
Model.IssueWrapper = IssueWrapper;
export default IssueWrapper;
