<template>
  <div>
    <div class="document-icon">
      <i class="icon-file-text2"></i>
    </div>
    <div class="text">{{ item.title }}</div>
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
