function makeTooltip(text) {
  return {
    content() {
      return text;
    },
  };
}

export default {
  fillable: makeTooltip('The item has description, comments or file attachments'),
  github: makeTooltip('The item has github data'),
};
