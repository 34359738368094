<template>
  <FilterableMenu :opt="opt" @close="close" class="AddTagSelector">
    <template v-slot:default="{ item }">
      <div class="tag" :tag-id="item.id">{{ item.title }}</div>
    </template>
    <template v-slot:createMenuItem="{ text, item }">
      <div class="createMenuItem">
        <b>{{ text }}</b
        ><span>(Create Tag)</span>
      </div>
    </template>
  </FilterableMenu>
</template>
<script>
import { App, Model } from '@';

import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
import './AddTagSelector.scss';
export default {
  props: ['issue', 'noSave'],
  data: function() {
    const issueTags = () => this.issue.asIssue().tags || [];
    return {
      opt: {
        items: App.vm.currentProject.tags,
        showInput: true,
        closeOnSelect: false,
        isActive: item => issueTags().contains(item),
        select: item => {
          const issue = this.issue.asIssue();
          if (issueTags().contains(item)) {
            issue.removeTag(item);
          } else {
            issue.addTag(item);
          }

          if (!this.noSave) {
            issue.save();
          }
        },
        createMenuItem: () => {
          return {
            slotName: 'createMenuItem',
            select: text => {
              this.createNewTag(text);
            },
          };
        },
      },
    };
  },

  methods: {
    createNewTag(title) {
      var tag = Model.Tag.createNew(App.vm.currentProject, title);

      tag.save().then(() => {
        var issue = this.issue.asIssue();
        issue.addTag(tag);
        if (!this.noSave) {
          issue.save();
        }
        this.$emit('close');
      });
    },

    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
