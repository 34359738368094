<template>
  <div>
    <div class="page-content UpdateUserDetails with-footer">
      <section>
        <div>
          <a class="button profile" :style="{ 'background-color': '#' + profile.color.code }">
            {{ profile.initials }}
          </a>
        </div>

        <q-form class="lined-form names-container">
          <q-input v-model="profile.name" label="Name" class="name" autocorrect="off" />
          <q-input v-model="profile.initials" label="Initials" class="initials" autocorrect="off" />
        </q-form>

        <color-picker :color="profile.color" @select="selectColor"></color-picker>
      </section>
    </div>
    <footer>
      <a-btn @click="save" color="primary">Save</a-btn>
    </footer>
  </div>
</template>

<script>
import { Model } from '@/namespace';

export default {
  smallModal: true,
  props: ['profile'],
  getData: function() {
    return { profile: Model.Profile.get() };
  },
  methods: {
    selectColor(color) {
      this.profile.set('color', color);
    },
    save() {
      return this.profile.save().then(() => {
        this.$emit('close');
      });
    },
  },
};
</script>
<style lang="scss">
.UpdateUserDetails {
  a.profile {
    margin: 0 0 10px auto;
  }

  .names-container {
    display: flex;
    .name {
      flex: 1;
    }
    .initials {
      flex: 0 0 60px;
      margin-left: 10px;
    }
  }
}
</style>
