import _ from 'lodash';

class PropertyMap {
  static make(keys, projectId) {
    var map = new this();
    map.keys = keys;
    map.projectId = projectId;
    return map;
  }
  length() {
    return _.keys(this.keys).length;
  }

  getKeys() {
    return this.keys;
  }

  keyArray() {
    return _.keys(this.keys);
  }
  isSingle() {
    return this.length() === 1;
  }

  toArray() {
    if (this.array == null) {
      var array = [];
      _.each(this.keys, (t, key) => {
        var m = this.toModel(key);
        m && array.push(m);
      });
      this.sort(array);
      this.array = array;
    }
    return this.array;
  }
  firstValue() {
    return this.toArray()[0];
  }
  lastValue() {
    var array = this.toArray();
    return array[array.length - 1];
  }

  contains(value) {
    return this.containsValue(value);
  }

  containsValue(value) {
    return this.keys[value.id];
  }

  containsAny(values) {
    return _.some(values, value => {
      return this.containsValue(value);
    });
  }

  containsKey(key) {
    return this.keys[key] === true;
  }

  isEqualKeys(keys) {
    return isEqualKeys(this.keys, keys);
  }

  toModel(key) {
    abstract();
  }

  sort(array) {
    abstract();
  }
}

function abstract() {
  throw new Error('abstract function');
}

function isEqualKeys(newVals, oldVals) {
  var newKeys = _.keys(newVals),
    oldKeys = _.keys(oldVals),
    changed = false;
  if (newKeys.length != oldKeys.length) {
    changed = true;
  } else {
    for (var key in oldVals) {
      if (newVals[key] == null) {
        changed = true;
        break;
      }
    }
  }
  return !changed;
}
export default PropertyMap;
