<template>
  <div class="DocumentList">
    <DragContainer
      class="list documents"
      :modelList="documents"
      :class="{ 'no-0': documents.length === 0 }"
    >
      <DocumentListItem v-for="doc in documents" :key="doc.id" :document="doc" :ref="doc.id" />

      <footer>
        <a-btn flat color="accent" @click="createDocument">
          <i class="icon-plus3"></i>
          <span>{{ addButtonText }}</span>
        </a-btn>
      </footer>
    </DragContainer>
  </div>
</template>
<script>
import DragContainer from '@/components/drag/DragContainer.vue';
import DocumentListItem from './DocumentListItem';
import { Model, App, Util } from '@/namespace';
export default {
  props: { parent: null, project: null, addButtonText: { default: 'New Document' } },
  components: {
    DocumentListItem,
    DragContainer,
  },
  computed: {
    documents() {
      const documents = this.parent.documents.slice();
      return documents.sorted(Util.priorityComparator);
    },
  },
  methods: {
    createDocument() {
      let url = `/${this.project.id}/document/new`;
      if (this.parent instanceof Model.Document) {
        url += '?parent=' + this.parent.id;
      }
      App.router.navigate(url);
    },
  },
};
</script>
<style lang="scss">
.DocumentList {
  .list {
    border: none;
  }
}
</style>
