<template>
  <a class="user-icon" :class="user.color">
    {{ user.initials }}
  </a>
</template>

<script>
export default {
  props: ['user'],
};
</script>
