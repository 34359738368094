<template>
  <FilterableMenu :opt="opt" @close="close" />
</template>
<script>
import { App } from '@/namespace';
import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
import { deleteIssue } from '@/actions';
import { ChangeIssueParent } from '@/pages/modals';
export default {
  props: ['issue', 'wrapper', 'handleCreateSubIssue', 'handleCreateSiblingIssue'],
  data() {
    const boardTerm = App.vm.currentProject.boardTerm;
    return {
      opt: {
        items: [
          {
            text: 'New Item',
            icon: 'icon-plus3',
            select: () => this.handleCreateSiblingIssue(),
          },
          {
            text: 'New Sub Item',
            icon: 'icon-tree5',
            select: () => this.handleCreateSubIssue(),
          },
          {
            text: `Change ${boardTerm}`,
            icon: 'icon-spinner11',
            select: () => App.openSelector({ issue: this.wrapper, property: 'sprint' }),
          },
          {
            text: 'Change Parent',
            icon: 'icon-tree7',
            select: () =>
              App.openPopover(ChangeIssueParent, {
                issue: this.issue,
              }),
          },
          {
            text: 'Change Item Type',
            icon: 'issue-type-icon',
            select: () =>
              App.openSelector({
                property: 'issueType',
                issue: this.wrapper,
              }),
          },
          {
            text: 'Add Tag',
            icon: 'icon-price-tag2',
            select: () => App.openSelector({ issue: this.wrapper, name: 'AddTag' }),
          },
          {
            text: 'Break Out Children',
            icon: 'icon-enlarge2',
            disabled: !this.wrapper.isExplodeApplicable(),
            select: () => {
              this.wrapper.explodeChildren();
            },
          },
          {
            text: 'Collect children to ' + this.wrapper.issue.parent.title,
            icon: 'icon-shrink2',
            disabled: !this.wrapper.exploded,
            select: () => {
              this.wrapper.collectChildrenOnParent();
            },
          },
        ].filter(menuItem => !menuItem.disabled),
        footerItems: [
          {
            text: 'Delete',
            icon: 'icon-bin',
            select: () => deleteIssue(this.issue),
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
