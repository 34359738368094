<template>
  <div style="overflow: auto; padding: 10px;">
    <div v-for="l in logs" style="padding-top: 5px; font-size: 10px;">{{ l }}</div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      logs: window.logs,
    };
  },
};
</script>
