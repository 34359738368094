import _ from 'lodash';
import { buildPriorityKeys } from '@/models/priority/priorityCalculation';

const defaultCtx = {
  openLevel: -1,
  includeArchived: false,
};
const filterProperties = ['status', 'tag', 'filterQuery', 'member', 'sprint'];

class WrapperContext {
  constructor(ctx) {
    _.assign(this, defaultCtx);
    _.assign(this, ctx);
    this.priorityKeys = buildPriorityKeys(ctx);
  }

  copyContext() {
    return {
      ...this,
    };
  }
  isOpen(level) {
    return level <= this.openLevel;
  }
  isIncludeArchived() {
    return this.includeArchived || (this.filterQuery && this.filterQuery.includeArchived());
  }
  getPriorityKeys() {
    return this.priorityKeys;
  }
  isOnlyPropertyInContext(prop) {
    const usedProperties = this.getUsedProperties();
    delete usedProperties[prop];
    return this[prop] != null && _.isEmpty(usedProperties);
  }

  getUsedProperties() {
    return _.reduce(
      filterProperties,
      (result, prop) => {
        if (this[prop]) {
          result[prop] = true;
        }
        return result;
      },
      {}
    );
  }
}

export default function createWrapperContext(ctx) {
  if (ctx instanceof WrapperContext) {
    return ctx;
  }
  return new WrapperContext(ctx);
}
