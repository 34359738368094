<template>
  <div class="page-content ViewSprints medium">
    <section class="">
      <div class="main-title">{{ project.boardTerm }}s</div>

      <DragContainer
        class="list sprints"
        :modelList="unfinishedSprints"
        :class="{ 'no-0': unfinishedSprints.length === 0 && !showCreateSprint }"
      >
        <ViewSprintsRow
          v-for="sprint in unfinishedSprints"
          :key="sprint.id"
          :sprint="sprint"
          :ref="sprint.id"
        />

        <CreateSprintRow
          v-if="showCreateSprint"
          :suggestedTitle="suggestedTitle"
          @change="createSprint"
        />

        <footer>
          <a-btn @click="openCreateSprint" color="accent" flat>
            <i class="icon-plus3"></i>
            <span>New {{ project.boardTerm }}</span>
          </a-btn>
        </footer>
      </DragContainer>
    </section>

    <section class="finished-sprints-section" :class="{ 'no-0': finishedSprints.length === 0 }">
      <div class="h2">Finished Sprints</div>
      <DragContainer class="list sprints" :modelList="finishedSprints">
        <ViewSprintsRow
          v-for="sprint in finishedSprints"
          :key="sprint.id"
          :sprint="sprint"
          :ref="sprint.id"
        />
      </DragContainer>
    </section>
  </div>
</template>
<script>
import _ from 'lodash';
import { App, Model, Util } from '@/namespace';
import ViewSprintsRow from './ViewSprintsRow';
import CreateSprintRow from './CreateSprintRow.vue';
import { getSuggestedSprintTitle } from '@/models/sprintHandler';
import DragContainer from '@/components/drag/DragContainer.vue';
export default {
  route: '/:projectId/sprints',
  helpurl: '/help/sprints',
  menu: 'sprints',
  props: ['project', 'onSelect'],
  data: function () {
    return { showCreateSprint: false, suggestedTitle: null };
  },
  getData: function (props) {
    return {
      project: Model.Project.find(props.projectId),
    };
  },
  created() {
    App.setCurrentProject(this.project);
  },
  computed: {
    unfinishedSprints() {
      return this.project.sprints
        .filter(sprint => sprint.unfinished)
        .sorted(Util.priorityComparator);
    },
    finishedSprints() {
      return this.project.sprints.filter(sprint => sprint.finished).sorted(Util.priorityComparator);
    },
  },
  methods: {
    openCreateSprint() {
      this.suggestedTitle = getSuggestedSprintTitle(this.project);
      this.showCreateSprint = true;
    },
    createSprint(data) {
      if (_.isEmpty(data.title)) {
        this.showCreateSprint = false;
        return;
      }

      var sprint = Model.Sprint.createNew(this.project, {
        ...data,
      });

      sprint.save().then(() => {
        this.showCreateSprint = false;
      });
    },
  },
  components: { ViewSprintsRow, DragContainer, CreateSprintRow },
};
</script>
<style lang="scss">
.ViewSprints {
  .finished-sprints-section.no-0 {
    display: none;
  }
  .list-row a {
    padding: 5px 5px;
  }
}
</style>
