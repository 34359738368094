import initScrollHandlerX from './scrollhandlerX';
import initScrollHandlerY from './scrollhandlerY';

export default function(dragItem, onScroll, useX) {
  const pageEl = dragItem.$el.closest('.page-content'),
    boardEl = dragItem.$el.closest('.Board');
  let handlerX = useX && initScrollHandlerX(boardEl, onScroll),
    handlerY = initScrollHandlerY(pageEl, onScroll);

  return {
    isOnScroll: function() {
      return handlerY.isOnScroll() || (handlerX && handlerX.isOnScroll());
    },
    onMove: function(ev, direction) {
      handlerY.onMove(ev, direction.up);
      handlerX && handlerX.onMove(ev, direction.left);
    },
    onEnd: function() {
      handlerY.onEnd();
      handlerX && handlerX.onEnd();
    },
  };
}
