<template>
  <div style="position: absolute; width: 0; height: 0;">
    <svg
      aria-hidden="true"
      style="position: absolute; width: 0; height: 0;"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <symbol id="icon-mail" viewBox="0 0 20 20">
          <title>Mail</title>
          <path
            d="M1.574 5.286l7.5 4.03c.252.135.578.198.906.198.328 0 .654-.064.906-.2l7.5-4.028c.49-.263.95-1.286.054-1.286H1.52c-.896 0-.434 1.023.054 1.286zm17.04 2.203c-.556.288-7.388 3.848-7.728 4.026s-.578.2-.906.2-.566-.022-.906-.2-7.133-3.74-7.688-4.028c-.39-.204-.386.035-.386.22V15c0 .42.566 1 1 1h16c.434 0 1-.58 1-1V7.708c0-.184.004-.423-.387-.22z"
          />
        </symbol>
        <symbol id="icon-board" viewBox="0 0 1024 1024">
          <title>Board</title>
          <path
            d="M976 96H48a48 48 0 0 0-48 48v736a48 48 0 0 0 48 48h928a48 48 0 0 0 48-48V144a48 48 0 0 0-48-48zM336 832H96V192h240v640z m304 0H384V192h256v640z m288 0H688V192h240v640z"
          />
        </symbol>
      </defs>
    </svg>
  </div>
</template>
<script>
export default {};
</script>
