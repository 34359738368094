<template>
  <section>
    <AddPremiumUsers
      :subscription="subscription"
      @select="setPremiumUsers"
      v-if="showAddPremiumUsers"
    ></AddPremiumUsers>
    <PremiumUsers :subscription="subscription" v-else />
    <EnterPaymentInfo :subscription="subscription" v-if="showPaymentInfo"></EnterPaymentInfo>
  </section>
</template>
<script>
import PremiumUsers from './PremiumUsers.vue';
import EnterPaymentInfo from './EnterPaymentInfo.vue';
import AddPremiumUsers from './AddPremiumUsers';
export default {
  props: ['subscription'],
  data() {
    return {
      showAddPremiumUsers: true,
      showPaymentInfo: false,
    };
  },
  methods: {
    setPremiumUsers(users) {
      this.subscription.premiumUsers = users;
      this.showAddPremiumUsers = false;
      this.showPaymentInfo = true;
    },
  },
  components: {
    PremiumUsers,
    EnterPaymentInfo,
    AddPremiumUsers,
  },
};
</script>
