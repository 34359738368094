import { Model } from '@/namespace';
import _ from 'lodash';
const rules = {
  withSprint(sprint) {
    return function withSprint(issue) {
      const sprintMap = issue.getPropertyMap('sprint');
      if (!sprintMap.contains(sprint)) {
        return false;
      }
      if (sprintMap.length() === 1) {
        return true;
      }
    };
  },
  withMember(member) {
    return issue => {
      const memberMap = issue.getPropertyMap('member');
      if (!memberMap.contains(member)) {
        return false;
      }
      if (memberMap.length() === 1) {
        return true;
      }
    };
  },
  withStatusStandardRule(status) {
    return issue => {
      const statusMap = issue.getPropertyMap('status');
      if (!statusMap.containsKey(status.key)) {
        return false;
      }
      if (statusMap.length() === 1) {
        return true;
      }
    };
  },

  withMembers(members) {
    return issue => {
      const memberMap = issue.getPropertyMap('member');

      if (!memberMap.containsAny(members)) {
        return false;
      }
      if (memberMap.length() === 1) {
        return true;
      }
    };
  },

  withStatuses(statuses) {
    return issue => {
      const statusMap = issue.getPropertyMap('status');

      if (!statusMap.containsAny(statuses)) {
        return false;
      }
      if (statusMap.length() === 1) {
        return true;
      }
    };
  },

  // should change name to BoardStatus, it is special logic for board
  withStatus(status) {
    return (issue, level) => {
      const statusMap = issue.getPropertyMap('status');

      switch (level.current) {
        case 0:
          return null;
        case 1:
          if (issue.issues.length == 0) {
            if (statusMap.calcValue() != status) {
              return false;
            } else {
              return true;
            }
          }
          return null;
        case 2:
          if (statusMap.calcValue() != status) {
            return false;
          } else {
            return true;
          }

        default:
          return true;
      }
    };
  },
  withoutArchived() {
    return issue => {
      const statusMap = issue.getPropertyMap('status');
      if (!statusMap.containsArchived()) {
        return true;
      }
      if (statusMap.length() === 1) {
        return false;
      }
    };
  },
};
const rulesUsedInConfirmPropagateDown = {
  lowerStatus(property, value) {
    return issue => {
      // todo check this one closer
      const statusMap = issue.getPropertyMap('status');
      const statusArray = statusMap.toSortedArray();
      const hasLower = statusArray.firstElement().priority < value.priority;
      const hasEqualOrHigher = statusArray.lastElement().priority >= value.priority;
      if (!hasLower) {
        return false;
      }
      if (!hasEqualOrHigher) {
        return true;
      }
    };
  },
  notEqualProperty(property, value) {
    return issue => {
      const propertyMap = issue.getPropertyMap(property);

      if (!propertyMap.contains(value)) {
        return true;
      }
      if (propertyMap.length() === 1) {
        return false;
      }
    };
  },
  unassigned() {
    return issue => {
      const propertyMap = issue.getPropertyMap('member');
      if (!propertyMap.containsKey('unassigned')) {
        return false;
      }
      if (propertyMap.length() === 1) {
        return true;
      }
    };
  },
  onBacklog() {
    return issue => {
      const propertyMap = issue.getPropertyMap('sprint');
      if (!propertyMap.containsKey('backlog')) {
        return false;
      }

      if (propertyMap.length() === 1) {
        return true;
      }
    };
  },
  unfinished() {
    // todo, check this one closer
    return issue => {
      const progress = issue.getPropertyMap('status').getProgress();

      if (progress === 'done') {
        return false;
      }
      if (progress === 'todo') {
        return true;
      }
    };
  },
  lowerStatusNOTUSED(status) {
    const project = Model.Project.store.get(status.projectId);
    const statusKeysUnder = _.reduce(
      project.statuses,
      (map, s) => {
        if (s.priority < status.priority) {
          map[s.key] = true;
        }
        return map;
      },
      {}
    );
    return issue => {
      let under, over;
      for (const k in issue.statusMap.vals) {
        if (statusKeysUnder[k]) {
          under = true;
        } else {
          over = true;
        }
      }
      if (under) {
        return over ? null : true;
      } else {
        return false;
      }
    };
  },
};
export default {
  ...rules,
  ...rulesUsedInConfirmPropagateDown,
};
