<template>
  <a
    class="BoardCard issue-wrapper issue dragitem DragContainer navigate-slide"
    :href="wrapper.issue.getUrl()"
    :class="cssClass"
    @mousedown="mousedown"
    @touchstart="mousedown"
  >
    <div class="body">
      <div class="top">
        <header>
          <div
            class="issue-type small"
            v-if="wrapper.issue.issueType"
            :type="wrapper.issue.issueType"
          >
            <div class="inner"></div>
          </div>
          <a v-if="wrapper.exploded" class="parent" :href="wrapper.issue.parent.url">{{
            wrapper.issue.parent.title
          }}</a>
        </header>
        <div class="title">{{ wrapper.issue.title }}</div>

        <MemberIcon :wrapper="wrapper" />
        <a class="more-menu" @click.prevent="openMoreMenu">
          <i class="icon-more2"></i>
        </a>
      </div>

      <div class="bottom">
        <div class="item-count" v-tooltip="tooltipNoSubs" v-if="wrapper.issues.length > 0">
          {{ wrapper.noIssuesDone() }}/{{ wrapper.issues.length }}
        </div>

        <div class="tag" :tag-id="tag.id" v-for="tag in wrapper.issue.tags">
          {{ tag.title }}
        </div>
        <div class="fillable" v-tooltip="tooltips.fillable" v-if="wrapper.issue.fillable">
          <i class="icon-file-text2 base-dark"></i>
        </div>
        <div class="fillable" v-if="wrapper.issue.additions.github" v-tooltip="tooltips.github">
          <i class="icon-github"></i>
        </div>
        <estimate-bar-inlist
          v-if="wrapper.estimateMap.est"
          :wrapper="wrapper"
        ></estimate-bar-inlist>
      </div>
    </div>

    <div class="sub-items children" v-if="isMain">
      <template v-for="child in wrapper.children">
        <BoardCard
          v-if="child.issue"
          :wrapper="child"
          :key="child.id"
          :ref="child.id"
          @createSiblingIssue="createSiblingIssue"
        ></BoardCard>
        <CreateIssueCard v-else :parentWrapper="wrapper"></CreateIssueCard>
      </template>
    </div>
  </a>
</template>
<script src="./BoardCard.js" />
<style lang="scss">
.BoardCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .body {
    a.more-menu {
      color: #b0b0b0;
      font-size: 18px;
      position: absolute;
      right: -20.5px;
      top: 5px;
      height: 24px;
      width: 20px;
      text-align: center;
      line-height: 28px;
      opacity: 0;
      //background:rgba(255,255,255, 0.5);

      text-align: center;
      &:hover {
        opacity: 1;
      }
      @media (max-width: 580px) {
        display: none;
      }
    }

    &:hover {
      a.more-menu {
        opacity: 1;
      }
    }
  }

  .top .title {
    flex: 1;
    padding: 7px 5px 3px 5px;
    font-size: 15px;
  }

  header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: top;
    width: 100%;
    .issue-type {
      font-size: 11px;
      line-height: 18px;
      margin-left: 10px;

      height: 15px;
    }

    .parent {
      font-size: 11px;
      line-height: 18px;
      margin-left: 10px;

      height: 15px;
      flex: 1;
      overflow: hidden;
    }

    .issue-type + .parent {
      margin-left: 5px;
    }
  }

  .member-icon {
    z-index: 1;
  }

  .top {
    padding: 5px;
    display: flex;
    align-items: flex-start;
  }

  &.main {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    background-color: white;
    min-height: 45px;

    border-radius: 4px;
    &.clone {
      margin: 0;
    }

    > .body > .bottom {
      .item-count {
        display: none;
      }
    }

    &[used-estimate='sub-estimate'] {
      > .body > .bottom > .estimate-progress {
        display: none;
      }
    }

    &.no-0 {
      > .body > .bottom .fillable {
        display: block;
      }
    }
  }

  &.sub {
    background-color: #fbfbfb;
    border-top: 1px solid #eee;
    //min-height: 45px;

    .title {
      font-size: 14px;
    }
  }

  &.exploded > .body {
    .title {
      padding-top: 15px;
    }
  }

  .sub-items {
    margin-left: 10px;
    border-left: 1px solid #eee;
  }

  .bottom {
    margin-top: auto;
    display: flex;
    align-items: center;
    margin-left: 5px;

    > div {
      margin-left: 5px;
      margin-bottom: 5px;
    }

    .estimate-progress {
      margin-left: auto;
      margin-right: 5px;
    }
  }

  &.main.oneMember {
    .sub-items .member-icon {
      opacity: 0.2;

      &[member-id='unassigned'] {
        opacity: 1;
      }
    }
  }
  &.main:not(.oneMember) {
    > .body .member-icon {
      opacity: 0.2;
    }
  }
}
</style>
