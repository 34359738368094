import StoreModel from './base/StoreModel';
import _ from 'lodash';
import cssRule from '@/common/cssRule';
import { Model, Util, App } from '@/namespace';
class Member extends StoreModel {
  static NAME = 'Member';
  static define = {
    color: {
      model: 'Color',
      serialize: false,
    },
    created: {
      serialize: false,
    },
    roleId: {
      value: 'normal',
    },
    role: {
      serialize: false,
      model: 'Role',
      modelId: 'roleId',
      set(role) {
        this.set('roleId', role ? role.id : null);
        return role;
      },
    },

    projectId: null,
    email: null,
    name: null,
    premium: null,
  };

  static async create(data) {
    const recaptcha = await App.$recaptcha('createMember');
    return Util.jsonajax({
      url: '/' + this.NAME.toLowerCase(),
      data: data,
      method: 'POST',
      headers: { recaptcha },
    });
  }

  static async update(model, data) {
    const recaptcha = await App.$recaptcha('updateMember');
    return Util.jsonajax({
      url: '/' + this.NAME.toLowerCase() + '/' + model.id,
      data: data,
      method: 'PUT',
      headers: { recaptcha },
    });
  }

  static async destroy(model) {
    console.log('destroy');
    const recaptcha = await App.$recaptcha('deleteMember');
    return Util.jsonajax({
      url: '/' + this.NAME.toLowerCase() + '/' + model.id,
      data: {
        id: model.id,
      },
      method: 'delete',
      headers: { recaptcha },
    });
  }

  postInitModel() {
    var project = Model.Project.get(this.projectId);
    if (!this.deletedMember) {
      project.members.add(this);
    }

    initStyle(this);
  }

  postDestroy() {
    var project = Model.Project.get(this.projectId);
    project.members.remove(this);
  }

  get url() {
    if (this.id === 'unassigned') {
      return '/' + App.vm.currentProject.id + '/member/' + this.id;
    }
    return '/' + this.projectId + '/member/' + this.id;
  }

  get text() {
    return this.displayName;
  }

  async resendInvite() {
    const recaptcha = await App.$recaptcha('resendInvite');

    return Util.jsonajax({
      url: '/member/resendinvite',
      data: {
        id: this.id,
      },
      method: 'POST',
      headers: { recaptcha },
    }).then(() => {
      this.set('invitesent', true);
    });
  }

  setRole(role) {
    this.set('role', role);
    this.save();
  }

  isAdmin() {
    return this.role.id == 'admin';
  }
}
_.assign(Member, {
  createNew(properties) {
    properties.roleId = 'normal';
    var result = new Model.Member(properties);
    result.initModel();
    return result;
  },
  getByUserAndProjectId(userId, projectId) {
    return _.find(this.store.map, member => {
      return member.userId === userId && member.projectId === projectId;
    });
  },
});

Member.comparator = function (a, b) {
  var result;

  if (a === b) {
    return 0;
  }
  if (a.unassigned || !a.id) {
    result = b.unassigned || !b.id ? a.displayName > b.displayName : true;
  } else {
    result = b.unassigned || !b.id ? false : a.displayName > b.displayName;
  }
  return result ? 1 : -1;
};

Model.Member = Member;

export default Member;

function initStyle(member) {
  const color = member.color,
    id = member.id;
  if (color) {
    cssRule(`.member-icon[member-id="${id}"]`, {
      'background-color': '#' + color.code,
      'border-color': '#' + color.code,
    });
  }

  cssRule(`.member-icon[member-id="${id}"]:before`, {
    content: '"' + member.initials + '"',
  });
}
