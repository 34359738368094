<template>
  <q-form ref="form" class="PaymentForm large">
    <q-input v-model="paymentInfo.name" label="Name" :rules="rules.required" lazy-rules />

    <div class="address-textbox">
      <q-input v-model="address.line1" label="Billing Address" :rules="rules.required" lazy-rules />
      <q-input v-model="address.line2" />
    </div>

    <div class="row q-col-gutter-md">
      <div class="col-6">
        <q-input v-model="address.postal_code" label="Postal Code" :rules="rules.required" />
      </div>
      <div class="col-6">
        <q-input v-model="address.city" label="City" :rules="rules.required" />
      </div>
    </div>
    <div class="row q-col-gutter-md">
      <div class="col-6">
        <q-input v-model="address.state" label="State/Province" />
      </div>
      <div class="col-6">
        <q-select
          v-if="countries"
          v-model="address.country"
          :options="countries"
          emit-value
          map-options
          flat
          label="Country"
          :rules="rules.required"
          popup-content-class="editormenu-select-heading"
        />
      </div>
    </div>

    <q-input v-model="paymentInfo.vat" label="VAT (optional)" />
  </q-form>
</template>
<script>
import _ from 'lodash';
import { findCountries } from '@/pages/billing/CountryService';

import { requiredValue } from '@/models/validation';
export default {
  props: ['paymentInfo'],

  computed: {
    address() {
      return this.paymentInfo.address;
    },
    rules() {
      return {
        name: [],
        required: [requiredValue()],
      };
    },
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
  data() {
    return { countries: null };
  },

  async mounted() {
    this.countries = await findCountries().then(countries =>
      countries.map(c => {
        return {
          label: c.name,
          value: c.code,
        };
      })
    );
  },
};
</script>
<style lang="scss">
.PaymentForm {
  .address-textbox {
    margin-bottom: 20px;
    border-radius: 3px;
  }
}
</style>
