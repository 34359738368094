function getNextPriority(list) {
  if (list.length === 0) {
    return null;
  } else {
    return list[list.length - 1].priority + 100;
  }
}

function findItemBefore(list, position) {
  const item = list[position];
  if (!item) {
    return null;
  }
  if (!item.isModel) {
    return list[position - 1];
  }
  return item;
}

function findItemAfter(list, position) {
  const item = list[position + 1];
  if (!item) {
    return null;
  }
  if (!item.isModel) {
    return list[position + 2];
  }
  return item;
}

export default function getNewPriority(list, position) {
  const itemBefore = findItemBefore(list, position);
  const itemAfter = findItemAfter(list, position);

  let goOver, goUnder, priority;
  if (itemBefore) {
    goOver = itemBefore.priority;
  }
  if (itemAfter) {
    goUnder = itemAfter.priority;
  }

  if (goOver != null && goUnder != null) {
    if (goOver === goUnder) {
      console.warn('sorting equal priorities, fix this better');
      itemBefore.setPriority(itemBefore.priority - 1);
      itemBefore.softSave(); // should save this operation, by using a soft save (which only stores to server, not doing anything else)
      goOver = itemBefore.priority;
    }
    priority = (goUnder - goOver) / 2 + goOver;
  } else if (goOver != null) {
    priority = goOver + 100;
  } else if (goUnder != null) {
    priority = goUnder - 100;
  } else {
    priority = getNextPriority(list);
  }

  //console.log('priority ' + issue.priority + " new priority " + priority);
  return priority;
}
