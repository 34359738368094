import _ from 'lodash';
import BaseModel from '@/models/base/BaseModel';
import { Model } from '@/index';
export default function() {
  _.each(this.constructor.define, (attrDef, attrName) => {
    var value = this[attrName];
    if (value === undefined) {
      this[attrName] = undefined;
    }
    if (attrDef == null) {
      return;
    }

    if (attrDef.model) {
      const M = Model[attrDef.model],
        modelId = attrDef.modelId ? this[attrDef.modelId] : this[attrName];

      if (modelId == null) {
        return;
      }
      if (_.isString(modelId)) {
        this[attrName] = M.get(modelId);
      } else if (_.isArray(modelId)) {
        this[attrName] = toModelList(M, modelId);
      } else if (modelId instanceof BaseModel) {
        this[attrName] = modelId;
      } else {
        this[attrName] = new M(modelId);
        console.warn('could not handle modelId conversion for ' + modelId);
      }
    }
    if (attrDef.value !== undefined && value === undefined) {
      this[attrName] = _.isFunction(attrDef.value) ? attrDef.value() : attrDef.value;
    }
    if (attrDef.type) {
      if (attrDef.type === 'date') {
        this[attrName] = toDate(this[attrName]);
      } else if (attrDef.type.make) {
        this[attrName] = attrDef.type.make(value);
      }
    }
  });
  return this;
}

function toDate(date) {
  if (!date) {
    return date;
  }
  return new Date(date);
}

function toModelList(M, ids) {
  return ids
    .map(id => {
      if (_.isString(id)) {
        return M.get(id);
      } else if (id instanceof BaseModel) {
        return id;
      } else {
        const m = new M(id);
        m.initModel && m.initModel();
        return m;
      }
    })
    .filter(m => m != null);
}
