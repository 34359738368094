import StoreModel from './base/StoreModel';
import _ from 'lodash';
import cssRule from '@/common/cssRule';
import { Model, Util, App } from '@/namespace';
import eventHandler from '@/models/base/eventHandler';

class Profile extends StoreModel {
  static NAME = 'Profile';
  static define = {
    color: {
      model: 'Color',
      serialize(color) {
        if (color instanceof Model.Color) {
          return color.id;
        }
        return color;
      },
    },
    name: null,
    initials: null,
    lastNotificationReadDate: {
      type: 'date',
    },
    userSettings: {
      value() {
        return {};
      },
      serialize(value) {
        return {
          ...value,
        };
      },
    },
    featureToggle: {
      value() {
        return {};
      },
      serialize: false,
    },
  };
  static async load() {
    try {
      return await Util.jsonajax({ url: '/auth/profile', data: {}, method: 'GET' });
    } catch (error) {
      return {
        authenticated: false,
      };
    }
  }

  static update(id, data) {
    return Util.jsonajax({ url: '/auth/profile', data: data, method: 'PUT' });
  }

  static find() {
    return super.find('1');
  }
  static get() {
    return super.get('1');
  }

  static async logout() {
    await Util.jsonajax({ url: '/auth/logout', data: {}, method: 'GET' });
    App.clearCache();

    delete window.localStorage.credentials;
    Model.Profile.setProfile({ authenticated: false });
  }

  static deleteUser(request) {
    delete window.localStorage.credentials;
    delete window.localStorage.loginEmail;
    return Util.jsonajax({
      url: '/auth/deleteuser',
      data: request,
      method: 'POST',
    }).then(function() {
      console.log('logged out');
      Model.Profile.setProfile({ authenticated: false });
    });
  }

  postInitModel() {
    if (this.color) {
      cssRule('.profile', {
        'background-color': '#' + this.color.code,
        //'border-color': '#' + this.color.code,
      });
    }
  }

  resetNotificationCount() {
    this.set('lastNotificationReadDate', new Date()); // todo user server date
  }
}

_.assign(Profile, {
  setProfile: function(profile) {
    profile.id = '1';
    this.createAndInitModel(profile);
    App.eventBus.emit('profileupdate');
  },
});

_.assign(Profile, eventHandler);
Model.Profile = Profile;
export default Profile;
