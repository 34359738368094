import _ from 'lodash';
import { Model } from '@/namespace';
import createTopWrapper from './createTopWrapper';
import '@/models/issuewrapper/issuewrapper';

export default class StatusIssueSections {
  static createNew(issue, controller, wrapperContextBase) {
    const result = new StatusIssueSections();

    result.issue = issue;
    result.issues = [];
    result.wrapperContextBase = {
      ...wrapperContextBase,
      openLevel: 1,
      propertyToSave: 'status',
      statusBoard: true,
    };
    result.bindChangeEvent(controller);
    result.populate();

    return result;
  }
  bindChangeEvent(controller) {
    controller.on(this.issue, 'issuechange', ([changeTree]) => {
      if (this.preventListenToChange) {
        return;
      }
      _.each(this.issues, wrapper => {
        wrapper.onIssueChange(changeTree);
      });
    });
  }
  removeListenToChange() {
    this.preventListenToChange = true;
  }
  populate() {
    const project = Model.Project.store.get(this.issue.projectId);
    _.each(project.activeStatuses, status => {
      const issueWrapper = createTopWrapper(
        this.issue,
        {
          ...this.wrapperContextBase,
          status: status,
        },
        { permanent: true }
      );

      this.issues.push(issueWrapper);
    });
    return this;
  }
}
