import BaseModel from './base/BaseModel';
import _ from 'lodash';

import { Model, Util } from '@/namespace';

class FileList extends BaseModel {
  constructor(attributes) {
    super(attributes);
    this.list = [];
  }

  fetch() {
    var parent = this.parent;

    for (var i = 0; i < parent.noFiles; i++) {
      this.list.push({ loading: true });
    }

    Util.jsonajax({
      url: '/file/listfiles',
      data: {
        parentId: parent.id,
        parentType: parent.constructor.NAME,
      },
    }).then(data => {
      this.list.splice(0);
      _.each(data, file => {
        this.add(file);
      });
    });
  }
  add(file) {
    if (!(file instanceof Model.File)) {
      file = new Model.File(file);
    }
    file.set('parent', this.parent);
    this.list.push(file);
    return file;
  }
  remove(file) {
    this.list.remove(file);
  }
}

Model.FileList = FileList;
export default FileList;
