<template>
  <FilterableMenu @close="close" :opt="opt">
    <template v-slot:default="{ item }">
      <div>{{ item.title }}</div>
    </template>
  </FilterableMenu>
</template>
<script>
import { App } from '@';

import saveProperty from '@/actions/saveProperty/saveProperty';

import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
import CreateSprintPopover from '@/popovers/CreateSprintPopover';
export default {
  props: ['issue', 'noSave'],
  data: function() {
    const mainSprint = this.issue.sprintMap.calcValue();

    const boardTerm = App.vm.currentProject.boardTerm;
    return {
      opt: {
        isActive: item => this.issue.sprintMap.containsValue(item),
        items: App.vm.currentProject.sprints,
        select: item => {
          if (this.noSave) {
            this.issue.setProperty('sprint', item);
          } else {
            saveProperty(this.issue, 'sprint', item);
          }
        },

        footerItems: [
          {
            text: 'View ' + mainSprint.title,
            url: mainSprint.url,
          },
          {
            text: `View all ${boardTerm}s`,
            url: App.vm.currentProject.projectUrl('/sprints'),
          },
          {
            text: `Create New ${boardTerm}`,
            select: () =>
              App.openPopover(CreateSprintPopover, {
                onCreate: sprint => {
                  saveProperty(this.issue, 'sprint', sprint);
                  this.close();
                },
              }),
          },
        ],
      },
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
