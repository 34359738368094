<template>
  <div class="confirmmodal confirm-property-modal">
    <div class="page-content with-footer">
      <top-info :value="value" :ruleName="ruleName" :count="count"></top-info>

      <div class="item-list hide-last-row-border box">
        <SimpleIssueRow
          v-for="wrapper in wrapper.children"
          :wrapper="wrapper"
          :key="wrapper.id"
          :preventSelector="true"
        />
      </div>
    </div>
    <footer>
      <a href="#" id="cancel" v-on:click.prevent.stop="cancel">No</a>
      <a href="#" id="confirm" v-on:click.prevent.stop="confirm">Change Sub Items</a>
    </footer>
  </div>
</template>
<script>
export default {
  created: function () {
    this.wrapper.calculateProperties();
    this.wrapper.showSubIssues();
  },
  props: ['wrapper', 'resolve', 'value', 'ruleName'],

  computed: {
    count: function () {
      return this.wrapper.issues.length;
    },
    heading: function () {
      return 'Change';
    },
    text: function () {
      return 'Change text';
    },
  },
  methods: {
    confirm: function () {
      this.resolve(true);
      this.close();
    },
    cancel: function () {
      this.close();
    },
    close: function () {
      // TODO make sure this is called when component is removed
      this.resolve(false);
      this.$emit('close');
    },
  },

  components: {
    // 'top-info': Vue.extend({ @TODO
    //   props: ['value', 'ruleName', 'count'],
    //   created() {
    //     const { text, heading } = RULE_TEMPLATES[this.value.constructor.NAME][this.ruleName];
    //     this.$options.template = `<div>
    //         <h2>${heading}</h2>
    //         <div class="text">${text}</div>
    //       </div>`;
    //   },
    // }),
  },
};

const RULE_TEMPLATES = {
  Status: {
    lowerStatus: {
      text: '{{count}} sub items are not yet set to {{value.name}}.',
      heading: 'Set Sub Items to {{value.name}}?',
    },
    notEqualProperty: {
      text: '{{count}} Sub Items are not set to {{value.name}}.',
      heading: 'Set All Sub Items to {{value.name}}?',
    },
  },
  Member: {
    unfinished: {
      text: 'There are {{count}} unfinished sub items not assigned to {{value.displayName}}',
      heading: 'Assign unfinished Sub Items to {{value.displayName}}?',
    },
    notEqualProperty: {
      text: 'There are {{count}} sub items not assigned to {{value.displayName}}',
      heading: 'Assign All Sub Items to {{value.displayName}}?',
    },
  },
  Sprint: {
    unfinished: {
      text: 'There are {{count}} unfinished sub items not in {{value.displayName}}',
      heading: 'Move unfinished Sub Items to {{value.displayName}}?',
    },
    notEqualProperty: {
      text: 'There are {{count}} sub items not in {{value.displayName}}',
      heading: 'Move All Sub Items to {{value.displayName}}?',
    },
  },
};
</script>
