import _ from 'lodash';

_.assign(Array.prototype, {
  pushAll: function(items) {
    _.each(items, item => {
      this.push(item);
    });
  },
  sorted: function(comparator) {
    this.sort(comparator);
    return this;
  },
  pushFirst(item) {
    this.splice(0, 0, item);
  },
  add: function(item) {
    if (this.indexOf(item) > -1) {
      return;
    }
    this.push(item);
  },
  addFirst: function(item) {
    if (this.indexOf(item) > -1) {
      return;
    }
    this.splice(0, 0, item);
  },
  remove: function(item) {
    if (item == null) {
      return false;
    }

    var index = this.indexOf(item);
    if (index == -1) {
      return false;
    }
    this.splice(index, 1);
    return true;
  },
  removeAll(items) {
    _.each(items, it => {
      this.remove(it);
    });
  },
  removeLast() {
    this.splice(-1, 1);
  },
  removeFirst() {
    this.splice(0, 1);
  },
  last() {
    return this[this.length - 1];
  },
  secondLast() {
    return this[this.length - 2];
  },
  findById: function(id) {
    for (var i = 0; i < this.length; i++) {
      if (this[i].id === id) {
        return this[i];
      }
    }
    return null;
  },
  indexOfId: function(id) {
    for (var i = 0; i < this.length; i++) {
      if (this[i].id === id) {
        return i;
      }
    }
    return -1;
  },
  containsAny(items) {
    var exists = false;
    _.each(this, function(i) {
      _.each(items, function(item) {
        if (i === item) {
          exists = true;
          return false;
        }
      });
    });
    return exists;
  },
  contains(item) {
    return this.indexOf(item) > -1;
  },
});
