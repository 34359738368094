<template>
  <a class="issue-link" :href="issue.url">
    <div class="name">
      {{ issue.title }}
    </div>
  </a>
</template>

<script>
import { Model } from '@/namespace';
export default {
  props: ['id'],

  computed: {
    issue() {
      return Model.Issue.get(this.id);
    },
  },
};
</script>
