<template>
  <div class="AddTag transparent">
    <CustomSearch
      :sourceOptions="sourceOptions"
      :placeholder="'filter tags'"
      @select="select"
      @close="close"
    />
  </div>
</template>
<script>
import { App, Model } from '@/namespace';

import CustomSearch from '@/components/mainsearch/CustomSearch.vue';

export default {
  props: ['issue', 'noSave'],

  data: function() {
    return {
      sourceOptions: {
        models: ['tag'],
        defaultSearchResult: App.vm.currentProject.tags.slice(),
        excludes: this.issue.tags,
        showCreateTag: true,
      },
    };
  },
  created() {
    this.sourceOptions.defaultSearchResult.removeAll(this.issue.tags);
  },
  methods: {
    select(tag) {
      var issue = this.issue;

      if (tag.constructor.NAME === 'TextItem') {
        this.createNewTag(tag.searchText);
        return;
      }

      issue.addTag(tag);
      if (!this.noSave) {
        issue.save();
      }

      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
    createNewTag(title) {
      var tag = Model.Tag.createNew(App.vm.currentProject, title);

      tag.save().then(() => {
        var issue = this.issue;
        issue.addTag(tag);
        if (!this.noSave) {
          issue.save();
        }
        this.$emit('close');
      });
    },
  },
  components: { CustomSearch },
};
</script>
<style lang="scss">
.AddTag {
  .search-result {
    background: white;
  }

  .CustomSearch input[type='text'] {
    border-width: 0 0 1px 0;
  }
}
</style>
