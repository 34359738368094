import './Color';
import './IssueType';
import './Profile';
import './Status';
import './Role';
import './Member';
import './Tag';
import './Comment';
import Sprint from './Sprint';
import Issue from './Issue';
export { default as Activity } from './Activity';
export { default as Project } from './Project';
import './Notification';
import './Credentials';
import './FileList';
import './File';
import Document from './Document';

export { Issue, Document, Sprint };

export function getModelById(id) {
  return Issue.get(id) || Document.get(id) || Sprint.get(id);
}
