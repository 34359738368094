<template>
  <div class="page-content ViewDocument medium">
    <section>
      <DocumentParentPath :document="document" />
      <text-input
        v-model="document.title"
        class="main-title"
        placeholder="Document Title"
        @save="save"
        ref="title"
      ></text-input>
      <TipTapEditor
        v-model="document.text"
        :parentModel="document"
        @update:modelValue="save"
        placeholder="Content"
      />
      <ItemFiles :parent="document" />
      <h2 v-if="document.documents.length" class="sub-document-header">Sub Documents</h2>
      <DocumentList
        :parent="document"
        :project="project"
        v-if="document.documents.length"
        addButtonText="Add Sub Document"
      />
    </section>
    <ViewIssueAdditionalViews :issue="document" :project="project" :showGithub="false" />
    <teleport to="#navbar" v-if="isPageActive">
      <q-btn id="create" @click="createNew" rounded>
        <i class="icon-plus3"></i>
        <span>Add Sub Document</span>
      </q-btn>
      <q-btn class="menu-more" @click.prevent="moremenu" rounded>
        <span>More</span>
        <i class="icon-more2"></i>
      </q-btn>
    </teleport>
  </div>
</template>
<script>
import _ from 'lodash';
import { App, Model } from '@/namespace';
import DocumentList from './DocumentList';
import MoreMenu from './ViewDocumentMoreMenu.vue';
import DocumentParentPath from './DocumentParentPath';
import fileEvents from '@/apphandlers/filehandler/fileeventsmixin';
import { eventMixin, pageMixin } from '@/common';
import ViewIssueAdditionalViews from '@/pages/viewissue/ViewIssueAdditionalViews.vue';
import ItemFiles from '@/pages/viewissue/ItemFiles.vue';
export default {
  menu: 'documents',
  props: ['document', 'project'],
  route: '/:projectId/document/:documentId',
  mixins: [eventMixin, pageMixin],
  async getData(props) {
    const { projectId, documentId } = props;
    const project = await Model.Project.find(props.projectId);

    const newParentId = new URL(window.location.href).searchParams.get('parent');
    const newParent = newParentId ? Model.Document.get(newParentId) : null;

    const document =
      documentId === 'new'
        ? new Model.Document({ projectId, parent: newParent }).initModel()
        : Model.Document.get(documentId).fill();

    return {
      project,
      document,
    };
  },

  data() {
    return {
      fileParent: this.document,
    };
  },
  mounted() {
    if (this.document.id == null) {
      //this.$refs.title.$el.focus();
    }
  },
  created() {
    App.vm.currentProject = this.project;
  },
  events: fileEvents,
  methods: {
    async save() {
      const { document, project } = this;
      const newDoc = document.id == null;
      if (newDoc) {
        const parent = document.parent || project;
        const lastDoc = parent.documents.last();
        document.priority = lastDoc && lastDoc.priority != null ? lastDoc.priority + 100 : 0;
      }

      if (_.isEmpty(document.title) && isEmptyText(document.text)) {
        return;
      }
      await this.document.save();
      console.log(this.document);
      if (newDoc) {
        App.router.updateUrl(this.document.url);
      }
    },
    moremenu(ev) {
      App.openPopover(
        MoreMenu,
        {
          document: this.document,
        },
        {
          type: 'menu',
          alignToTop: this.$el.closest('.navbar'),
          target: ev.currentTarget,
        }
      );
    },
    createNew() {
      let url = `/${this.project.id}/document/new`;

      url += '?parent=' + this.document.id;

      App.router.navigate(url);
    },
  },

  components: {
    DocumentList,
    DocumentParentPath,
    ViewIssueAdditionalViews,
    ItemFiles,
  },
};

function isEmptyText(text = '') {
  text = text.replace('<p>', '').replace('</p>', '');
  return _.isEmpty(text);
}
</script>
<style lang="scss">
.ViewDocument {
  .TipTapEditor {
    margin-bottom: 20px;
  }
  .DocumentList {
  }

  .sub-document-header {
    border-bottom: 1px solid #e8e8e8;
    line-height: 2;
  }
}
</style>
