import _ from 'lodash';
export function findParent(container, isValid) {
  if (container == null) {
    return null;
  }
  if (isValid(container)) {
    return container;
  }
  return findParent(container.$parent, isValid);
}

export function initContainers(dragItem) {
  const start = findParentDragContainer(dragItem);
  const tree = buildContainerTree(start);
  const top = tree[0];
  return {
    start,
    startTop: top,
    current: start,
    currentTop: top,
  };
}

function findParentDragContainer(dragItem) {
  return findParent(dragItem.$parent, container => container.$el.is('.DragContainer'));
}

export function findCurrentTopContainer(dragSession) {
  const topContainers = findTopContainers(dragSession.containers.start);
  const cloneOffset = dragSession.cloneEl.boxOffset();
  return _.find(topContainers, function (container) {
    return isCloneInsideContainer(cloneOffset, container.$el);
  });
}

export function findClosestCurrentDragContainer(dragSession) {
  const { containers, cloneEl } = dragSession;
  const cloneOffset = cloneEl.boxOffset();

  return getValidParent(containers.current.$parent, dragSession);
  function getValidParent(container) {
    if (container == null) {
      return null;
    }
    if (container.$el.is('.DragContainer') && isCloneInsideContainer(cloneOffset, container.$el)) {
      return container;
    }

    return getValidParent(container.$parent);
  }
}

export function executeOnPageComponents(dragItem, action) {
  const page = findParent(dragItem, container => container.$el.is('.page-content'));
  if (page == null) {
    return null;
  }
  executeOnChildComponents([page], action);
}

function findTopContainers(startContainer) {
  const topContainer = findParent(startContainer, container => container.$el.is('.page-content'));
  if (topContainer == null) {
    return null;
  }

  return findChildComponents([topContainer], container => {
    return container.$el.is && container.$el.is('.DragContainer.top');
  });
}

function executeOnChildComponents(children, action) {
  children.forEach(child => {
    action(child);
    executeOnChildComponents(getChildren(child), action);
  });
}

export function getChildren(comp) {
  return _.map(comp.$refs, child => child).filter(child => {
    return child != null && child.$el != null;
  });
}

function findChildComponents(children, valid) {
  return children.reduce((result, child) => {
    if (valid(child)) {
      result.push(child);
    }
    const childContainers = findChildComponents(getChildren(child), valid);
    return [...result, ...childContainers];
  }, []);
}

export function isCloneInsideContainer(cloneOffset, container, OFFSET = 0) {
  var containerOffset = container.boxOffset();

  return (
    cloneOffset.centerY > containerOffset.top - OFFSET &&
    cloneOffset.centerY < containerOffset.bottom + OFFSET &&
    cloneOffset.centerX > containerOffset.left &&
    cloneOffset.centerX < containerOffset.right
  );
}

export function buildContainerTree(container, tree = []) {
  if (container == null) {
    return tree;
  }
  tree = [container, ...tree];
  const parent = findParent(container.$parent, c => c.$el.is('.DragContainer'));

  return buildContainerTree(parent, tree);
}

export function buildWrapperIssueTree(topWrapper, issue, tree = []) {
  if (issue.id === topWrapper.id) {
    return tree;
  }
  tree = [issue, ...tree];

  return buildWrapperIssueTree(topWrapper, issue.getParent(), tree);
}
