import { Model, App } from '@/index';
import _ from 'lodash';

export function convertModelMap(modelsJsonMap, onLoad) {
  if (_.isEmpty(modelsJsonMap)) {
    return;
  }
  var modelMap = {};

  var modelNames = ['Status', 'Tag', 'Sprint', 'Member', 'Issue', 'Comment', 'Document', 'Project'];

  _.each(modelNames, modelName => {
    if (modelsJsonMap[modelName]) {
      if (modelName === 'Issue' && _.isArray(modelsJsonMap.Issue)) {
        cleanupIssues(modelsJsonMap.Issue);
      }
      modelMap[modelName] = convertModels(modelsJsonMap[modelName], Model[modelName], onLoad);
    }
  });

  return modelMap;
}

function destroyOnSynch(id, M) {
  var model = M.store.get(id);
  if (model == null) {
    return;
  }
  model.deleted = true;
  M.store.remove(model.id);

  model.postDestroy && model.postDestroy();
}

function cleanupIssues(issues) {
  _.each(issues, function(is) {
    if (is.sprintId) {
      if (!Model.Sprint.store.get(is.sprintId)) {
        is.sprintId = null;
      }
    }
    if (is.memberId) {
      if (!Model.Member.store.get(is.memberId)) {
        is.memberId = null;
      }
    }
    if (is.statusId && is.projectId) {
      if (!Model.Status.getByKey(is.projectId, is.statusId)) {
        is.statusId = null;
      }
    }
  });
}

export function convertModels(jsonArray, M, onLoad) {
  var models = [];

  if (!Array.isArray(jsonArray)) {
    jsonArray = [jsonArray];
  }

  _.each(jsonArray, function(m) {
    if (m.deleted) {
      destroyOnSynch(m.id, M);
    } else {
      if (m.children) {
        _.each(m.children, is => {
          M.createAndInitModel(is);
        });
        delete m.children;
      }
      m = M.createModel(m);
      models.push(m);
    }
  });

  App.timer.log('createModel ' + M.name);

  _.each(models, function(m) {
    m.initModel();
    //initModel.call(m);
    m.initDone = true;
  });

  _.each(models, function(m) {
    m.postInitModel && m.postInitModel(onLoad);

    m.backup();
  });

  App.timer.log('initModel ' + M.name);

  return models;
}
