import { App } from '@/namespace';
import StatusIssueSections from '@/models/issuewrapper/statusissuesections';
import BoardColumn from './BoardColumn.vue';

const MIN_COLUMN_WIDTH = 300;
import eventMixin from '@/common/eventMixin';
export default {
  props: ['collectionSettings'],
  data: function() {
    return { issueSections: createIssueSections(this), overflowed: false };
  },

  mixins: [eventMixin],
  mounted: function() {
    App.timer.log('mounted');

    const board = this.$el.find('.Board');

    this.overflowed = this.issueSections.issues.length * MIN_COLUMN_WIDTH > board.outerWidth();
  },
  computed: {
    cssClasses() {
      return { overflowed: this.overflowed };
    },
  },
  watch: {
    'collectionSettings.filterQuery'() {
      console.log('change filterQuery');
      this.issueSections = createIssueSections(this);
    },
  },
  components: { BoardColumn },
};

function createIssueSections(vm) {
  const collectionSettings = vm.collectionSettings;
  const result = StatusIssueSections.createNew(collectionSettings.issue, vm, {
    includeArchived: false,
    sprint: collectionSettings.sprint,
    filterQuery: collectionSettings.filterQuery,
  });
  App.timer.log('createIssueSection');
  return result;
}
