<template>
  <div class="estimate-progress" v-tooltip="tooltip">
    <div class="total">{{ estimateMap.est }}</div>
    <div class="estimate-bar small">
      <div class="progress" :style="{ width: progress + '%' }" :progress="progress"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['wrapper'],
  computed: {
    estimateMap() {
      return this.wrapper.estimateMap;
    },
    progress() {
      return (100 * this.estimateMap.done) / this.estimateMap.est;
    },
    tooltip() {
      const { wrapper } = this;
      return {
        content: function() {
          var result = 'The Estimate is ' + wrapper.estimateMap.est;
          if (
            wrapper.wrapperContext.sprint &&
            wrapper.issues.length != wrapper.issue.issues.length
          ) {
            result += ' for the sub items in ' + wrapper.wrapperContext.sprint.title;
          }
          return result;
        },
      };
    },
  },
};
</script>
