<template>
  <div>
    <div class="sprint-icon">
      <i class="icon-spinner11"></i>
    </div>
    <div class="text">{{ item.title }}</div>
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
