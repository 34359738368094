<template>
  <div
    class="list-row status dragitem"
    :class="cssClass"
    :active="status.active"
    @click="editStatus"
    @mousedown="mousedown"
    @touchstart="mousedown"
    :key="status.id"
  >
    <checkbox :selected="status.active" @toggle="toggleStatus"></checkbox>

    <span class="status-icon" :status-id="status.id">
      <i class="icon-"></i>
    </span>

    <div class="title">
      {{ status.name }}
    </div>

    <q-btn round @click.stop="deleteStatus" v-if="!status.fixed" flat>
      <i class="icon-bin"></i
    ></q-btn>
  </div>
</template>
<script>
import { App } from '@/namespace';
import CreateStatus from '@/pages/createstatus/CreateStatus.vue';
export default {
  props: ['status'],
  data() {
    return {
      dragStatus: {},
    };
  },
  computed: {
    cssClass() {
      return { ...this.dragStatus, statusFixed: this.status.fixed };
    },
  },
  methods: {
    deleteStatus() {
      var status = this.status;

      App.openConfirmDialog({
        title: 'Delete Status',
        text:
          'This will delete the status. Any items set to status ' +
          status.name +
          ' will get status ToDo',
        onConfirm: function () {
          status.destroy();
        },
      });
    },
    mousedown(ev) {
      if (this.status.fixed) {
        return;
      }
      App.startDragSession({ ev, dragItem: this });
    },
    editStatus() {
      var status = this.status;
      if (status.fixed) {
        return;
      }
      App.openPopup(CreateStatus, { status: status });
    },
    toggleStatus(ev) {
      ev.stopPropagation();

      var status = this.status;
      if (status.fixed) {
        return;
      }

      status.set('active', !status.active);
      status.save();
    },
    getModel() {
      return this.status;
    },
    setDragStatus(name, value) {
      this.dragStatus[name] = value;
    },
    clearDragStatus() {
      this.dragStatus = {};
    },
  },
};
</script>
