<template>
  <div class="TipTapEditorMenu">
    <div class="menu-bar" v-show="active">
      <q-select
        v-model="header"
        :options="headings"
        emit-value
        map-options
        flat
        borderless
        popup-content-class="editormenu-select-heading"
      />
      <q-btn :active="editor.isActive('bold')" flat @click="run('toggleBold')">
        <i class="icon-bold"></i>
      </q-btn>
      <q-btn :active="editor.isActive('italic')" flat @click="run('toggleItalic')">
        <i class="icon-italic"></i>
      </q-btn>
      <q-btn :active="editor.isActive('bulletList')" flat @click="run('toggleBulletList')">
        <i class="icon-list2"></i>
      </q-btn>
      <q-btn :active="editor.isActive('orderedList')" flat @click="run('toggleOrderedList')">
        <i class="icon-list-numbered"></i>
      </q-btn>

      <q-btn :active="editor.isActive('link')" flat @click="setLink" v-if="false">
        <i class="icon-link"></i>
      </q-btn>

      <q-btn :active="editor.isActive('mention')" flat @click="setMention">
        <span class="mention-icon">@</span>
      </q-btn>

      <q-btn :active="editor.isActive('table')" flat @click="insertTable" class="mobile-hidden">
        <i class="icon-table2"></i>
      </q-btn>

      <template v-if="editor.isActive('table')">
        <q-btn flat @click="run('deleteTable')" class="mobile-hidden delete-table">
          <i class="icon-table2"></i>
          <i class="icon-minus-circle2" />
        </q-btn>

        <q-btn flat @click="run('addRowAfter')" class="mobile-hidden">
          <TableIcon class="row-after" />
        </q-btn>

        <q-btn flat @click="run('addRowBefore')" class="mobile-hidden">
          <TableIcon class="row-before" />
        </q-btn>

        <q-btn flat @click="run('deleteRow')" class="mobile-hidden">
          <TableIcon class="row-delete" />
        </q-btn>

        <q-btn flat @click="run('addColumnAfter')" class="mobile-hidden">
          <TableIcon class="column-after" />
        </q-btn>

        <q-btn flat @click="run('addColumnBefore')" class="mobile-hidden">
          <TableIcon class="column-before" />
        </q-btn>

        <q-btn flat @click="run('deleteColumn')" class="mobile-hidden">
          <TableIcon class="column-delete" />
        </q-btn>
      </template>

      <div class="divider mobile-hidden"></div>

      <q-btn flat @click="run('undo')" class="mobile-hidden" :disabled="!editor.can().undo()">
        <i class="icon-undo"></i>
      </q-btn>
      <q-btn flat @click="run('redo')" class="mobile-hidden" :disabled="!editor.can().redo()">
        <i class="icon-redo"></i>
      </q-btn>
    </div>
  </div>
</template>
<script>
import stickyOnScroll from './stickyOnScroll';
import { Util, App } from '@';

import TableIcon from './TableIcon';
export default {
  props: ['editor', 'active'],
  data() {
    return {
      headings: [
        {
          value: null,
          label: 'Normal',
        },
        {
          value: 1,
          label: 'Heading 1',
        },
        {
          value: 2,
          label: 'Heading 2',
        },
        {
          value: 3,
          label: 'Heading 3',
        },
        {
          value: 4,
          label: 'Heading 4',
        },
      ],
    };
  },
  mounted() {
    console.log('mounted');
  },
  watch: {
    active: function (active) {
      App.vm.hideTopbar = active;
      if (active) {
        if (this.stickyMenu == null && !Util.isMobile()) {
          this.stickyMenu = stickyOnScroll(
            this.$el.closest('.page-content'),
            this.$el,
            this.$el.closest('.TipTapEditor')
          );
        }
      } else {
        this.stickyMenu && this.stickyMenu.destroy();
        this.stickyMenu = null;
      }
    },
  },
  components: { TableIcon },
  methods: {
    setMention() {
      const { view } = this.editor;
      const transaction = view.state.tr.insertText('@');
      view.dispatch(transaction);
    },
    setLink() {
      this.$emit('openBubble', { type: 'link' });
    },
    run(action) {
      this.editor.executeRun(action);
    },
    insertTable() {
      this.editor.chain().focus().insertTable({ rows: 2, cols: 2, withHeaderRow: false }).run();
    },
  },
  computed: {
    header: {
      get: function () {
        for (let h = 1; h <= 4; h++) {
          if (this.editor.isActive('heading', { level: h })) {
            return h;
          }
        }
        return null;
      },
      set: function (h) {
        if (h == null) {
          this.editor.executeRun('setParagraph');
        } else {
          this.editor.chain().focus().toggleHeading({ level: h }).run();
        }
      },
    },
  },
};
</script>
<style lang="scss">
.editormenu-select-heading {
  .q-item {
    min-width: 120px;
  }
}
.TipTapEditorMenu {
  z-index: 1;
  position: absolute;
  top: -35px;
  left: -15px;
  z-index: 1;
  .menu-bar {
    height: 40px;
    background-color: #f4f4f4;
    border-radius: 20px;
    display: flex;
    align-items: center;

    padding: 0 5px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
    @media (max-width: 580px) {
      left: -5px;
    }
  }

  .divider {
    height: 20px;
    border-left: 1px solid #c8c8c8;
    margin: 0 20px 0 20px;
  }

  .q-select {
    width: 80px;
    margin-left: 20px;
    .q-field__append {
      display: none;
    }
  }

  .q-btn {
    background-color: rgba(0, 0, 0, 0);
    &[active='true'] {
      background-color: white;
    }
    width: 40px;
  }

  .delete-table {
    .icon-minus-circle2 {
      font-size: 9px;
      position: absolute;
      top: 7px;
      right: 10px;
    }
  }
  .mention-icon {
    font-weight: 400;
    position: relative;
    top: -1px;
  }
}

.headings-dropdown {
  min-width: 140px !important;
  .el-select-dropdown__item {
    &.header-1 {
      font-weight: 300;
      font-size: 24px;
    }
    &.header-2 {
      font-weight: 300;
      font-size: 19px;
    }
    &.header-3 {
      font-weight: 400;
      font-size: 16px;
    }
    &.header-4 {
      font-weight: 400;
      font-size: 14px;
    }
  }
}
</style>
