import { render } from "./CreateStatus.vue?vue&type=template&id=2d1f6ee2"
import script from "./CreateStatus.vue?vue&type=script&lang=js"
export * from "./CreateStatus.vue?vue&type=script&lang=js"

import "./CreateStatus.vue?vue&type=style&index=0&id=2d1f6ee2&lang=scss"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput});
