<template>
  <div class="issue-type standard" :type="item.id">
    <div class="inner"></div>
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
