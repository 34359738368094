import { App, _ } from '@';

export default function(issue) {
  return new Promise((resolve, reject) => {
    let deleteInfo = null;
    if (issue.issues && issue.issues.length) {
      deleteInfo = 'This will delete the item and all sub items';
    } else if (issue.description || !_.isEmpty(issue.comments)) {
      deleteInfo = 'This will delete the item';
    }
    if (deleteInfo) {
      App.openConfirmDialog({
        title: 'Delete Item',
        text: deleteInfo,
        onConfirm() {
          resolve(issue.destroy());
        },
        onClose() {
          reject();
        },
      });
    } else {
      resolve(issue.destroy());
    }
  });
}
