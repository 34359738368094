function assertNoParentCycleInner(parent, child) {
  if (parent == null) {
    return;
  }
  if (parent === child) {
    throw new Error('Invalid parent cycle');
  }
  return assertNoParentCycleInner(parent.parent, child);
}

export function assertNoParentCycle(issue) {
  return assertNoParentCycleInner(issue.parent, issue);
}
