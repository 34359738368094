<template>
  <div class="IssueParentPath">
    <a
      class="issue-parent-title"
      v-for="parent in issueParents"
      :href="parent.url"
      :key="parent.id"
      @click="click"
      >{{ parent.title }}</a
    >
  </div>
</template>

<script>
import { App } from '@';
export default {
  props: ['issue', 'includeProject'],
  computed: {
    issueParents() {
      var result = [];
      function addParent(is) {
        var parent = is.parent;
        if (parent != null && !parent.isProject()) {
          result.unshift(parent);
          addParent(parent);
        }
      }
      addParent(this.issue);
      if (result.length === 0 && this.includeProject) {
        result.push(App.vm.currentProject.issue);
      }
      return result;
    },
  },
  methods: {
    click(ev) {
      this.$emit('click', ev);
    },
  },
};
</script>
<style lang="scss">
.IssueParentPath {
  a {
    margin-right: 7px;
    font-size: 14px;
    //color: $gray-light;
    font-weight: 300;
    &:after {
      content: '>';
      font-weight: 300;
      position: relative;
      left: 3px;
      text-decoration: none;
    }
  }
}
</style>
