export default function() {
  let preventScroll = false;
  document.body.addEventListener(
    'touchmove',
    function(ev) {
      if (preventScroll) {
        ev.preventDefault();
      }
    },
    { passive: false }
  );

  return {
    enable() {
      preventScroll = false;
    },
    disable() {
      preventScroll = true;
    },
  };
}
