<template>
  <div class="view-comments" :class="{ empty: dateMap.empty && !showCreateComment }">
    <div class="wrapper">
      <div class="comments-container">
        <div v-for="dateHolder in dateMap.dates">
          <h3 class="date-header">{{ formatDate(dateHolder.date) }}</h3>

          <ViewComment
            v-for="comment in dateHolder"
            :comment="comment"
            :key="comment.id"
            @destroyed="commentDestroyed"
          ></ViewComment>
        </div>

        <CreateComment
          v-if="showCreateComment"
          @change="createComment"
          :issue="issue"
        ></CreateComment>

        <footer class="list-footer" v-if="!showCreateComment">
          <a-btn flat color="accent" @click="showCreateComment = true">
            <i class="icon-bubble2"></i>
            <span>Add Comment</span>
          </a-btn>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { App, Model } from '@/namespace';
import DateMap from '@/common/model/DateMap';
import DateFns from '@/common/date-fns';

import './view-comments.scss';
import ViewComment from './ViewComment.vue';
import CreateComment from './CreateComment.vue';
export default {
  props: ['issue'],
  data: function () {
    return {
      dateMap: DateMap.createNew(this.issue.comments),
      showCreateComment: false,
    };
  },
  watch: {
    'issue.comments': function () {
      // TODO this has stopped working in vue 3
      this.dateMap = DateMap.createNew(this.issue.comments);
    },
  },
  methods: {
    commentDestroyed(comment) {
      this.dateMap.remove(comment);
    },
    createComment(text) {
      if (_.isEmpty(text)) {
        this.showCreateComment = false;
        return;
      }
      var comment = new Model.Comment({
        parentId: this.issue.id,
        member: App.currentProjectMember(),
        text: text,
      });

      comment.save().then(() => {
        this.issue.comments.add(comment);
        this.showCreateComment = false;
        this.dateMap = DateMap.createNew(this.issue.comments); // since watch has stopped working
      });
    },
  },

  components: {
    ViewComment,
    CreateComment,
  },
};
</script>
