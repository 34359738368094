import { render } from "./SelectProjectType.vue?vue&type=template&id=f301a70c"
import script from "./SelectProjectType.vue?vue&type=script&lang=js"
export * from "./SelectProjectType.vue?vue&type=script&lang=js"

import "./SelectProjectType.vue?vue&type=style&index=0&id=f301a70c&lang=scss"
script.render = render

export default script
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QOptionGroup});
