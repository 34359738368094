<template>
  <div class="UpdatePassword">
    <div class="page-content with-footer">
      <section>
        <q-form class>
          <q-input v-model="credentials.password" label="Password" type="password" />

          <q-input v-model="credentials.confirmPassword" label="Confirm Password" type="password" />

          <q-input v-model="credentials.currentPassword" label="Current Password" type="password" />
        </q-form>
      </section>
    </div>
    <footer>
      <a-btn @click="save" color="primary">Change Password</a-btn>
    </footer>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';

export default {
  smallModal: true,
  data: function () {
    return { credentials: new Model.Credentials() };
  },
  methods: {
    save() {
      var credentials = this.credentials;
      if (credentials.password != credentials.confirmPassword) {
        App.displayError('Password and Confirm Password dont match');
        return;
      }

      return credentials
        .changePassword(credentials)
        .then(() => {
          App.displayInfo('Password has been changed');
          this.$emit('close');
        })
        .catch(App.handleError);
    },
  },
};
</script>
<style lang="scss">
.UpdatePassword {
  footer button {
    margin-left: auto;
  }
  section {
    margin-bottom: 0;
  }
}
</style>
