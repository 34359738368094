<template>
  <div class="confirmmodal confirm-modal">
    <div class="page-content with-footer">
      <h2>{{ title }}</h2>

      <div class="text" v-html="text"></div>

      <input
        v-if="inputType === 'text'"
        type="text"
        class="input white"
        autocomplete="off"
        name="confirm-input"
        v-model="inputVal"
      />
      <input
        v-if="inputType === 'email'"
        type="email"
        class="input white"
        autocomplete="off"
        name="confirm-input"
        v-model="inputVal"
      />
      <input
        v-if="inputType === 'password'"
        type="password"
        class="input white"
        autocomplete="off"
        name="confirm-input"
        id="confirm-input"
        v-model="inputVal"
      />
    </div>
    <footer>
      <a href="#" id="cancel" @click="cancel">Cancel</a>
      <a href="#" id="confirm" @click="confirm">Confirm</a>
    </footer>
  </div>
</template>
<script>
import './confirmmodal.scss';
export default {
  props: ['text', 'title', 'onConfirm', 'inputType', 'inputValue'],
  data: function() {
    return { inputVal: this.inputValue };
  },
  methods: {
    confirm(ev) {
      ev.stop();
      this.onConfirm(this.inputVal);
      this.$emit('close');
    },
    cancel(ev) {
      ev.stop();
      this.$emit('close');
    },
  },
};
</script>
