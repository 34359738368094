<template>
  <FilterableMenu @select="select" @close="close" :opt="opt">
    <template v-slot:default="{ item }">
      <div>{{ item.title }}</div>
    </template>
  </FilterableMenu>
</template>
<script>
import { App } from '@';

import CreateSprintPopover from './CreateSprintPopover.vue';
import EditSprintPopover from './EditSprintPopover.vue';

import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
export default {
  props: ['activeSprint', 'onSelect'],
  data: function() {
    const project = App.vm.currentProject;
    const boardTerm = project.boardTerm;
    return {
      opt: {
        isActive: item => this.activeSprint === item,
        items: project.sprints,
        headerItems: [],
        footerItems: [
          {
            text: `View all ${boardTerm}s`,
            icon: '-',
            url: project.projectUrl('/sprints'),
          },

          {
            text: 'Edit ' + this.activeSprint.title,
            icon: 'icon-pen6',
            disabled: this.activeSprint.backlog,
            select: () =>
              App.openPopover(EditSprintPopover, {
                sprint: this.activeSprint,
              }),
          },
          {
            text: `Create New ${boardTerm}`,
            icon: 'icon-plus3',
            select: () =>
              App.openPopover(CreateSprintPopover, {
                onCreate: sprint => {
                  App.router.navigate(sprint.url);
                },
              }),
          },
        ].filter(menuItem => !menuItem.disabled),
      },
    };
  },

  methods: {
    select: function(item) {
      this.onSelect && this.onSelect(item);
      this.$emit('select', item);
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
