<template>
  <FilterableMenu @close="close" :opt="opt">
    <template v-slot:default="{ item }">
      <div class="member-icon member" :member-id="item.id">
        <i class="icon-"></i>
      </div>
      <div class="text">{{ item.displayName }}</div>
    </template>
  </FilterableMenu>
</template>
<script>
import { App } from '@';

import saveProperty from '@/actions/saveProperty/saveProperty';
import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
import { ViewMembers } from '@/pages/modals';
export default {
  props: ['issue', 'noSave'],

  data: function() {
    return {
      opt: {
        isActive: item => this.issue.memberMap.containsValue(item),
        items: App.vm.currentProject.members,
        select: item => {
          if (this.noSave) {
            this.issue.setProperty('member', item);
          } else {
            saveProperty(this.issue, 'member', item);
          }
        },
        footerItems: [
          {
            text: 'Edit Members',
            select: () => {
              App.openModal(ViewMembers, {
                projectId: App.vm.currentProject.id,
                onSelect: member => {
                  this.opt.select(member);
                },
              });
            },
          },
        ],
      },
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
