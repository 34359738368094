var toRemoves = [],
  removeChild = Element.prototype.removeChild;

export function cleanupLiveRemove() {
  toRemoves.forEach(toRemove => {
    removeChild.call(toRemove.node, toRemove.child);
  });
  toRemoves = [];
}

Element.prototype.removeChild = function(child) {
  if (child._disableLiveRemove) {
    child.style.display = 'none';
    toRemoves.push({ node: this, child: child });
    return;
  }
  removeChild.call(this, child);
};
