<template>
  <FilterableMenu @close="close" :opt="opt">
    <template v-slot:default="{ item }">
      <div class="status-icon" :status-id="item.id">
        <i class="icon-"></i>
      </div>
      <div class="text" v-if="item.key === 'archived' && currentStatus.key !== 'archived'">
        Archive Item
      </div>
      <div class="text" v-else>{{ item.name }}</div>
    </template>
  </FilterableMenu>
</template>
<script>
import { App } from '@';

import saveProperty from '@/actions/saveProperty/saveProperty';

import FilterableMenu from '@/components/FilterableMenu/FilterableMenu.vue';
export default {
  props: ['issue', 'noSave'],

  data: function() {
    return {
      opt: {
        isActive: item => this.currentStatus === item,
        items: App.vm.currentProject.activeStatuses,
        footerItems: App.vm.currentProject.statuses.filter(status => status.key === 'archived'),
        select: item => {
          if (this.noSave) {
            this.issue.setProperty('status', item);
          } else {
            saveProperty(this.issue, 'status', item);
          }
        },
      },
    };
  },
  computed: {
    currentStatus() {
      return this.issue.statusMap.calculatedValue();
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: { FilterableMenu },
};
</script>
