<template>
  <div class="menu page_view_notifications">
    <header>
      <div class="header-title">Notifications</div>
    </header>
    <div class="list no-shadow" v-if="notifications.list">
      <div
        class="list-row notification"
        v-for="notification in notifications.list"
        :already-viewed="notification.alreadyViewed"
      >
        <div class="title" v-html="notification.text"></div>
        <div class="date">{{ formatDate(notification.time) }}</div>
      </div>
    </div>

    <div v-else>You have no notifications</div>
  </div>
</template>
<script>
import { App } from '@/namespace';

import { findNotifications } from '@/models/notificationService';
import DateFns from '@/common/date-fns';
export default {
  props: ['notifications'],
  getData: function () {
    return { notifications: findNotifications() };
  },

  methods: {
    formatDate(date) {
      return DateFns.format(date, 'dd MMM HH:mm');
    },
  },

  mounted() {
    App.vm.profile.resetNotificationCount();
  },
};
</script>
