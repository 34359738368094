<template>
  <div class="page-content ViewProjectActivity medium">
    <section>
      <div class="main-title">Project Activity</div>

      <div class="activity-list" v-if="activityMap">
        <div v-for="dateHolder in activityMap.dates">
          <h3 class="date-header">{{ formatDate(dateHolder.date) }}</h3>

          <div class="activity" v-for="activity in dateHolder">
            <ActivityRow :activity="activity" />
            <div class="date">
              {{ formatTime(activity.at) }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';

import ActivityRow from './ActivityRow';
export default {
  menu: 'activity',
  props: ['project'],
  route: '/:projectId/activity',

  async getData(props) {
    const projectPromise = Model.Project.find(props.projectId);
    return {
      project: projectPromise,
      activityM: projectPromise.then(project => project.fillProjectActivity()),
    };
  },
  created() {
    App.vm.currentProject = this.project;
  },
  components: { ActivityRow },
  computed: {
    activityMap() {
      return this.project.activityMap;
    },
  },
};
</script>
<style lang="scss">
.ViewActivity {
}
</style>
