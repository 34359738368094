<template>
  <div class="checkbox" :class="{ 'checkbox-fixed': fixed }" :value="selected" @click="toggle">
    <i class="icon-"></i>
  </div>
</template>
<script>
export default {
  props: ['selected', 'fixed'],

  methods: {
    toggle(ev) {
      this.$emit('toggle', ev);
      this.$emit('click', ev);
    },
  },
};
</script>
