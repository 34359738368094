import { Util } from '@/namespace';
import _ from 'lodash';
import './model/listextension';
import './element-extension';
import isTouchDevice from 'is-touch-device';
import eventMixin from './eventMixin';
import getBrowser from './getBrowser';
import scrollInputIntoView from './scrollInputIntoView';
import log from './log';
import { jsonajax } from './jsonajax';
import { convertModelMap } from '@/common/model/convertmodels';
export function handleModelResponse(data) {
  return convertModelMap(data.models);
}

function resolveMap(promiseMap) {
  var promiseArray = [];
  var keyArray = [];

  _.map(promiseMap, function(promise, key) {
    promiseArray.push(promise);
    keyArray.push(key);
  });

  return Promise.all(promiseArray).then(function(resolvedPromiseArray) {
    var resolvedMap = {};
    _.each(keyArray, function(key, index) {
      resolvedMap[key] = resolvedPromiseArray[index];
    });
    return resolvedMap;
  });
}

function timer(f, timeout) {
  var timeoutPointer;

  function stop() {
    //console.log('timer stop -----------------------------------')
    if (timeoutPointer) {
      clearTimeout(timeoutPointer);
    }
    timeoutPointer = null;
  }

  return {
    start: function() {
      stop();
      //console.log('timer start --------------------------------------')
      timeoutPointer = setTimeout(function() {
        f.call();
      }, timeout);
    },
    stop: function(delay) {
      if (delay != null) {
        setTimeout(stop, delay);
      }
      stop();
    },
  };
}

_.assign(Util, {
  timer,
  log,

  eventMixin: eventMixin,
  trigger: function() {
    console.log('trigger event, not implemented');
  },
  assert: function(test) {
    if (!test) {
      console.error('assertion failed');
      throw 'assertion failed';
    }
  },
  jsonajax,
  scrollInputIntoView,
  isTouchDevice,
  isMobile: function() {
    return document.querySelector('body').outerWidth() < 580;
  },
  isTabletOrSmaller: function() {
    return document.querySelector('body').outerWidth() < 980;
  },
  isIos() {
    return !!navigator.platform && /iPad|iPhone/.test(navigator.platform);
  },
  priorityComparator(a, b) {
    if (a === b) {
      return 0;
    }
    return a.priority - b.priority;
  },
  getBrowser,
  compare(a, b) {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  },
  resolveMap: resolveMap,
});

export default Util;
