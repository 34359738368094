<template>
  <div class="page-content page_view_issue wide bottom-margin-on-touch ViewProject">
    <section class="split2">
      <div class="main">
        <text-input
          v-model="project.title"
          class="main-title"
          placeholder="Project Title"
          @save="save"
        ></text-input>
        <TipTapEditor v-model="project.description" @update:modelValue="save" />
      </div>
      <aside></aside>
    </section>
    <section
      class="item-collection full-width"
      active-view="list"
      :class="{ 'no-0': project.issue.issues.length === 0 }"
    >
      <div class="mw-wide item-collection-settings-section">
        <item-collection-settings
          :collectionSettings="collectionSettings"
        ></item-collection-settings>
      </div>
      <IssueSections :collectionSettings="collectionSettings" ref="issueSections"></IssueSections>
    </section>

    <section v-if="showArchivedLinks">
      <div class="archived-links">
        <a-btn :href="projectUrl('?archived=true')" color="primary" flat>View archived Items</a-btn>
        <a-btn :href="projectUrl('?archived=all')" color="primary">View all Items</a-btn>
      </div>
    </section>
    <teleport to="#navbar" v-if="isPageActive">
      <a-btn class="" id="create" @click="createNew" :unelevated="false">
        <i class="icon-plus3"></i>
        <span>New Item</span>
      </a-btn>
    </teleport>
  </div>
</template>
<script>
import { App, Model } from '@/namespace';
import { pageMixin } from '@/common';
import { CreateIssue } from '@/pages/modals';
export default {
  menu: 'items',
  props: ['project', 'archived'],
  route: '/:projectId/project',
  mixins: [pageMixin],
  data: function () {
    var collectionSettings = {
      filterQuery: null,
      statusStandardRule: null,
      includeArchived: false,
      issue: this.project.issue,
    };
    if (this.archived === 'true') {
      collectionSettings.statusStandardRule = Model.Status.getByKey(this.project.id, 'archived');
    }
    if (this.archived === 'all') {
      collectionSettings.includeArchived = true;
    }
    return { collectionSettings, pageActive: true };
  },
  getData(props) {
    return {
      project: Model.Project.find(props.projectId),
    };
  },

  created() {
    App.vm.currentProject = this.project;
  },
  computed: {
    showArchivedLinks() {
      return this.project.issue.statusMap.containsKey('archived') && !this.archived;
    },
  },

  methods: {
    save() {
      this.project.save();
    },

    projectUrl(appender) {
      var url = this.project.issue.getUrl() + appender;
      return url;
    },
    createNew() {
      const attributes = {
        parent: this.project.issue,
        sprintId: 'backlog',
      };

      App.openPopup(CreateIssue, { attributes });
    },
  },
};
</script>
<style lang="scss">
.ViewProject {
  section.item-collection {
    margin-top: 0;
  }
  section.split2 {
    margin-bottom: 0;
  }
}
</style>
