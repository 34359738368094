<template>
  <a class="file FileView" :href="file.fileurl" target="_blank">
    <div class="cover">
      <div class="file-icon">
        <i :class="file.iconClass"></i>
      </div>

      <div class="thumbnail" v-if="file.thumburl" :style="backgroundImageStyle"></div>

      <div class="action-overlay">
        <a href="#" id="delete-file" @click="deleteFile">
          <i class="icon-bin"></i>
        </a>
      </div>
    </div>

    <div class="filename">{{ file.filename }}</div>
  </a>
</template>
<script>
export default {
  props: ['file'],
  created() {
    console.log('created');
  },
  computed: {
    backgroundImageStyle() {
      var thumburl = this.file.thumburl;
      var s = `url('${thumburl}')`;
      return { 'background-image': s };
    },
  },
  methods: {
    deleteFile(ev) {
      ev.stop();
      this.file.delete().then(() => {
        this.$emit('deleted', this.file);
      });
    },
  },
};
</script>
