<template>
  <div
    href="#"
    class="IssueCount"
    v-tooltip="tooltipItemCount"
    @click="toggleShowSubIssues"
    :class="{ open: wrapper.open }"
  >
    <template v-if="wrapper.issues.length">
      <div class="arrow"></div>
      <div class="count">
        {{ wrapper.issues.length }}
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ['wrapper', 'preventClicks'],
  methods: {
    toggleShowSubIssues: function(ev) {
      if (this.preventClicks) {
        return;
      }

      ev.preventDefault();
      return this.wrapper.toggleShowSubIssues();
    },
  },
  computed: {
    tooltipItemCount() {
      const { wrapper } = this;
      return {
        content() {
          var count = wrapper.issues.length;

          if (wrapper.isPartial()) {
            if (wrapper.wrapperContext.isOnlyPropertyInContext('sprint')) {
              return (
                'The item shown is partial. The item has ' +
                count +
                ' sub items in ' +
                wrapper.wrapperContext.sprint.title
              );
            }

            return 'The item shown is partial, ' + count + ' of its sub items are included';
          } else {
            return count == 1 ? 'The item has 1 sub item' : 'The item has ' + count + ' sub items';
          }
        },
      };
    },
  },
};
</script>
<style lang="scss">
.IssueCount {
  height: 44px;
  position: relative;
  width: 32px;
  left: 4px;
  align-self: start;
  cursor: pointer;
  .count {
    font-size: 13px;
    color: #a0a0a0;
    text-align: center;
    position: relative;
    line-height: 43px;
    left: 8px;
    @media (max-width: 580px) {
      left: 20px;
    }
  }

  .arrow {
    position: absolute;
    color: #c0c0c0;
    font-size: 8px;
    top: 14px;
    left: 8px;
    line-height: 16px;
    text-align: center;

    &:before {
      font-family: 'icomoon';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      content: '\edbc';
    }
  }

  &.open {
    .arrow {
      transform: rotate(90deg);
    }
  }
}
</style>
