<template>
  <div>
    <div class="page-content with-footer">
      <section>
        <div class="">This will finish {{ sprint.title }}</div>
      </section>

      <section class="" v-if="!nextSprint">
        <div class="flex-row" @click="toggleCreateSprint">
          <checkbox :selected="createSprint" />
          <div>Create a new sprint.</div>
        </div>

        <div :class="{ faded: !createSprint }">
          <text-input
            v-model="newSprintTitle"
            class="title white"
            placeholder="Sprint Title"
          ></text-input>
        </div>
      </section>

      <section :class="{ faded: !sprintAvailable }" v-if="issueWrapper">
        <div class="flex-row" @click="toggleMoveIssues">
          <checkbox :selected="moveIssues" />

          <div>
            The following Items are not done. Move them to
            <template v-if="nextSprint">{{ nextSprint.title }}</template>
            <template v-else>{{ newSprintTitle }}</template>
          </div>
        </div>

        <div class="item-list hide-last-row-border box" :class="{ faded: !moveIssues }">
          <SimpleIssueRow v-for="wrapper in issueWrapper.children" :wrapper="wrapper" />
        </div>
      </section>
    </div>
    <footer>
      <a-btn color="primary" @click="finish">Finish Sprint</a-btn>
    </footer>
  </div>
</template>

<script>
import { App, Model, _ } from '@/index';

import filterRules from '@/models/issuewrapper/filterRules';
import createTopWrapper from '@/models/issuewrapper/createTopWrapper';
import { getSuggestedSprintTitle, setSprintFinished } from '@/models/sprintHandler';

export default {
  props: ['sprint'],
  data: function () {
    var project = App.vm.currentProject,
      sprints = project.sprints,
      nextSprint = sprints[sprints.indexOf(this.sprint) + 1],
      nextSprintExists = nextSprint && !nextSprint.backlog && !nextSprint.finished,
      issueWrapper = createTopWrapper(project.issue, {
        openLevel: 1,
        sprint: this.sprint,
        rule: filterRules.unfinished(),
      });

    return {
      project,
      issueWrapper,
      createSprint: !nextSprintExists,
      nextSprint: nextSprintExists ? nextSprint : null,
      newSprintTitle: nextSprintExists ? null : getSuggestedSprintTitle(project),
      moveIssues: !!issueWrapper,
    };
  },
  computed: {
    sprintAvailable() {
      return this.createSprint || this.nextSprint;
    },
  },
  methods: {
    toggleCreateSprint() {
      this.createSprint = !this.createSprint;
    },
    toggleMoveIssues() {
      this.moveIssues = !this.moveIssues;
    },
    finish(ev) {
      let nextSprint = this.nextSprint,
        promise = Promise.resolve();

      if (this.createSprint) {
        nextSprint = Model.Sprint.createNew(this.project, {
          title: this.newSprintTitle,
        });
        promise = nextSprint.save();
      }
      promise
        .then(() => {
          if (nextSprint && this.moveIssues) {
            moveIssues(this.issueWrapper, nextSprint);
          }
          setSprintFinished(this.sprint);
          this.sprint.save();
        })
        .then(() => {
          this.$emit('close');
        });

      ev.currentTarget.preloader(promise);
    },
  },
};

function moveIssues(issueWrapper, nextSprint) {
  moveUnfinishedIssues(issueWrapper.issues);
  function moveUnfinishedIssues(issues) {
    _.each(issues, function (is) {
      var status = is.statusMap.calculatedValue();
      if (status.isDone()) {
        return;
      }
      if (is.statusMap.containsDone()) {
        moveUnfinishedIssues(is.issues);
        return;
      }

      is.saveProperty('sprint', nextSprint, { propagateDown: true });
    });
  }
}
</script>
