import Login from './login/Login.vue';
import ResetPassword from './resetpassword/ResetPassword.vue';
import Register from './register/Register.vue';
import ViewProjects from '@/pages/viewprojects/ViewProjects.vue';
import ViewProject from '@/pages/viewproject/ViewProject.vue';
import ViewIssue from '@/pages/viewissue/ViewIssue.vue';
import ViewSprint from '@/pages/viewsprint/ViewSprint.vue';
import ViewSprints from '@/pages/viewsprints/ViewSprints.vue';
import ViewMembers from '@/pages/viewmembers/ViewMembers.vue';
import ViewMember from '@/pages/viewmember/ViewMember.vue';
import ViewTag from '@/pages/viewtag/ViewTag.vue';
import ProjectSettings from './projectsettings/ProjectSettings.vue';
import AboutAgiloo from './about/AboutAgiloo.vue';
import CookiePolicy from './about/CookiePolicy.vue';
import Pricing from './about/Pricing.vue';
import PrivacyPolicy from './about/PrivacyPolicy.vue';
import TermsConditions from './about/TermsConditions.vue';
import UserSettings from './usersettings/UserSettings.vue';
import SendFeedback from './sendfeedback/SendFeedback.vue';
import Start from './start/StartPage.vue';
import HelpCenter from './help/HelpCenter.vue';
import BillingPage from './billing/BillingPage.vue';
import TestPage from './TestPage/TestPage.vue';
import ViewDocuments from './ViewDocuments/ViewDocuments.vue';
import ViewDocument from './ViewDocuments/ViewDocument.vue';
import ViewProjectActivity from './ViewProjectActivity/ViewProjectActivity.vue';
const pages = [
  Start,
  HelpCenter,
  Login,
  ResetPassword,
  Register,
  ViewProjects,
  ViewProject,
  ViewIssue,
  ViewSprint,
  ViewSprints,
  ViewMembers,
  ViewMember,
  ViewTag,
  ProjectSettings,
  UserSettings,
  SendFeedback,
  AboutAgiloo,
  CookiePolicy,
  Pricing,
  PrivacyPolicy,
  TermsConditions,
  BillingPage,
  TestPage,
  ViewDocuments,
  ViewDocument,
  ViewProjectActivity,
];

export default pages;
