import _ from 'lodash';

export default {
  withTag(tag) {
    return (issue, level) => {
      const originIssue = issue.asIssue();
      if (isValidTag(originIssue)) {
        return true;
      }
      if (level.start > 1 && findInParent(originIssue, isValidTag, level.current)) {
        return true;
      }

      function isValidTag(is) {
        return is.tags && is.tags.contains(tag);
      }
    };
  },

  withTags(tags) {
    return (issue, level) => {
      const originIssue = issue.asIssue();
      if (isValidTag(originIssue)) {
        return true;
      }
      if (level.start > 1 && findInParent(originIssue, isValidTag, level.current)) {
        return true;
      }
      function isValidTag(is) {
        return is.tags && is.tags.containsAny(tags);
      }
    };
  },

  withIssueTypes(issueTypes) {
    return (issue, level) => {
      const originIssue = issue.asIssue();
      if (isValidType(originIssue)) {
        return true;
      }
      if (level.start > 1 && findInParent(originIssue, isValidType, level.current)) {
        return true;
      }
      function isValidType(is) {
        return _.some(issueTypes, type => type.id === is.issueType);
      }
    };
  },

  withTitle(textRegex) {
    return (issue, level) => {
      const originIssue = issue.asIssue();

      if (isValidTitle(originIssue)) {
        return true;
      }

      if (level.start > 1 && findInParent(originIssue, isValidTitle, level.current)) {
        return true;
      }

      function isValidTitle(is) {
        return is.title.search(textRegex) != -1;
      }
    };
  },
};

function findInParent(issue, isValid, levelCountdown) {
  if (levelCountdown == 1) {
    return false;
  }
  if (isValid(issue.parent)) {
    return true;
  }
  return findInParent(issue.parent, isValid, levelCountdown - 1);
}
