import BaseModel from '@/models/base/BaseModel';
import _ from 'lodash';
export default class EstimateMap extends BaseModel {
  get hasSubs() {
    return this.subsEst > 0;
  }

  static make(wrapper) {
    let est = 0,
      done = 0,
      subsUsed = true,
      subsEst = 0,
      isDone = wrapper.getPropertyMap('status').isDone();

    subsEst = est = _.sumBy(wrapper.issues, is => {
      if (is.isWrapper()) {
        return is.getEstimateMap().est;
      } else {
        return is.totalEstimate;
      }
    });

    if (
      wrapper.issue.totalEstimate > est &&
      !wrapper.isPartial() &&
      wrapper.level > 0 // on top level, always show sub issues estimate
    ) {
      subsUsed = false;
      est = wrapper.issue.totalEstimate;
    }

    if (isDone) {
      done = est;
    } else {
      done = sumDoneOfIssues(wrapper.issues);
    }

    return new EstimateMap({ est, done, subsUsed, subsEst });
  }
}

function sumDoneOfIssues(issues) {
  return _.sumBy(issues, is => {
    var progress = is.getPropertyMap('status').getProgress();
    if (progress === 'done') {
      return is.isWrapper() ? is.getEstimateMap().done : is.totalEstimate;
    } else if (progress === 'todo') {
      return 0;
    } else {
      return sumDoneOfIssues(is.issues);
    }
  });
}
