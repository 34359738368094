import DateFns from '@/common/date-fns';
import _ from 'lodash';
import { nextTick } from 'vue';
export function formatUpdateTime(date) {
  if (date == null) {
    return null;
  }
  if (DateFns.isSameDay(date, new Date())) {
    return formatTime(date);
  }
  return formatDate(date);
}

export function formatTime(date) {
  if (date == null) {
    return null;
  }
  return DateFns.format(date, 'HH:mm');
}

export function formatDate(date) {
  if (date == null) {
    return null;
  }
  return DateFns.format(date, 'do MMMM yyyy');
}

export function capitalize(string) {
  return _.capitalize(string);
}

export { nextTick };
