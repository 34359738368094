import MemberLink from './member-link.vue';
import IssueLink from './issue-link.vue';
import StatusLink from './status-link.vue';
import SprintLink from './sprint-link.vue';
import DocumentLink from './document-link.vue';
export function initPropertyLinks(app) {
  app.component('MemberLink', MemberLink);
  app.component('IssueLink', IssueLink);
  app.component('StatusLink', StatusLink);
  app.component('SprintLink', SprintLink);
  app.component('DocumentLink', DocumentLink);
}

export { MemberLink, IssueLink, StatusLink, SprintLink, DocumentLink };
